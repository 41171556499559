
import { UrlConfig } from 'config';
import { ApiResponse } from 'models/Api/ApiResponse';
import { RawUserData, UserRawOrderInfo, UserOrderInfo } from 'models/User/User';
import { UserModel, OrderModel, AddressModel } from 'models';
import { ItemAskPaymentLinkResponse } from './types/marketplace';
import { AjaxService } from 'services';
import { RawOrderData } from 'models/Order/types';

export class MarketplaceApiClient {
  public static async activateSelling(): Promise<UserModel> {
    const url = UrlConfig.MARKETPLACE.USER_ACTIVATE_SELLING;
    const response: ApiResponse<RawUserData> = await AjaxService.postData(url);
    const user = new UserModel(response.data);
    return user;
  }

  public static async generateSellerOnboardingLink(): Promise<string> {
    const url = UrlConfig.MARKETPLACE.USER_ONBOARD_SELLING;
    const response: ApiResponse<string> = await AjaxService.postData(url);
    const onboardingLink = response.data;
    return onboardingLink;
  }

  public static async generateSellerDashboardLink(): Promise<string> {
    const url = UrlConfig.MARKETPLACE.USER_DASHBOARD_LINK;
    const response: ApiResponse<string> = await AjaxService.postData(url);
    const onboardingLink = response.data;
    return onboardingLink;
  }

  public static async generateItemPaymentLink(itemAskId: string, useEscrow: boolean): Promise<ItemAskPaymentLinkResponse> {
    const url = UrlConfig.MARKETPLACE.ITEM_ASK_CREATE_PAYMENTLINK;
    const response: ApiResponse<string> = await AjaxService.postData(url, {
        item_ask_id: itemAskId,
        use_escrow: useEscrow
    });
    const paymentLink = response.data;
    return { paymentLink, itemAskId };
  }

  public static async acceptItemBid(itemBidId: string, useEscrow: boolean): Promise<string> {
    const url = UrlConfig.MARKETPLACE.ITEM_BID_ACCEPT;
    const response: ApiResponse<string> = await AjaxService.postData(url, {
        item_bid_id: itemBidId,
        use_escrow: useEscrow
    });
    const orderId = response.data;
    return orderId;
  }

  public static async getUserOrders(): Promise<UserOrderInfo> {
    const url = UrlConfig.MARKETPLACE.USER_ORDERS;
    const response: ApiResponse<UserRawOrderInfo> = await AjaxService.getData(url);
    const orders: UserOrderInfo = {
      buyer: response.data?.buyer.map(rawOrder => new OrderModel(rawOrder)),
      seller: response.data?.seller.map(rawOrder => new OrderModel(rawOrder))
    };
    return orders;
  }

  public static async getOrderById(id: string): Promise<OrderModel> {
    const url = UrlConfig.MARKETPLACE.ORDER + `/${id}`;
    const response: ApiResponse<RawOrderData> = await AjaxService.getData(url);
    const order = new OrderModel(response.data);
    return order;
  }

  public static async addOrderAddress(orderId: string, address: AddressModel): Promise<string> {
    const url = UrlConfig.MARKETPLACE.ORDER_ADD_ADDRESS;
    await AjaxService.postData(url, {
        order_id: orderId,
        address: address.toObject()
    });

    return orderId;
  }
}
