import React from "react";
import { useNavigate, useLocation, useParams, Params, NavigateFunction } from "react-router-dom";
import { QuerySearchParameters } from 'constants/url';
import { NavigationService } from "services";
import { ItemBidModel, ItemAskModel, UserModel } from "models";
import { ItemBidType } from "models/Item/ItemBid/types";

export interface RouterProps {
  navigate: NavigateFunction;
  readonly params: Params<string>;
  location: Location;
}

export type WithRouterProps<T> = T & RouterProps;

export const withRouter = (Component: React.ComponentType<any>) => {
  const WithRouter = (props: any) => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    return <Component {...props} location={location} navigate={navigate} params={params} />;
  }
  return WithRouter;
}

export const useAuthCheck = (user: UserModel, navigate: (path: string) => void, action: () => void) => {
  if (!user.isAuthenticated()) {
    navigate(NavigationService.getAuthLoginPath());
  } else if (!user.isConfirmed()) {
    navigate(NavigationService.getUserConfirmationPath());
  } else {
    action();
  }
};

export const getItemActionRoute = (
  user: UserModel,
  entity: ItemBidModel | ItemAskModel,
  actionType: 'Edit' | 'Remove' | 'Contact' | 'Sell' | 'Buy',
): string => {
  if (!user.isAuthenticated()) {
    return NavigationService.getAuthLoginPath();
  } else if (!user.isConfirmed()) {
    return NavigationService.getUserConfirmationPath();
  }
  const itemId = entity.itemId;
  const itemName = entity.item?.getDisplayName();
  const baseRoute = NavigationService.getItemDetailsPath(itemId, itemName);
  const queryParamKey =
    entity.type === ItemBidType
      ? QuerySearchParameters.BID_ID
      : QuerySearchParameters.ASK_ID;
  return `${baseRoute}?${queryParamKey}=${entity.id}&${QuerySearchParameters.ACTION}=${actionType}`;
};

