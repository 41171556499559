import { QuerySearchParameters } from "constants/url";
import { JSONObject } from "models/Api/types";

export function getSelectedTabFromURL(location: Location): number {
	const queryParams = new URLSearchParams(location.search);
	const tabIndex = queryParams.get(QuerySearchParameters.TAB);
	return tabIndex ? parseInt(tabIndex, 10) : 0;
};

export function getPageFromURL(location: Location): number {
	const queryParams = new URLSearchParams(location.search);
	const page = queryParams.get(QuerySearchParameters.PAGE);
	return page ? parseInt(page, 10) : 1;
};

export function getEligibleQueryParamsFromUrl(location: Location, eligibleQueryParams: QuerySearchParameters[] = []): JSONObject {
	const queryParams: JSONObject = {};
	const searchParams = new URLSearchParams(location.search);
	searchParams.forEach((value, key) => {
		if (eligibleQueryParams.length === 0 || eligibleQueryParams.includes(key as QuerySearchParameters)) {
			if (key === QuerySearchParameters.TAB || key === QuerySearchParameters.PAGE) {
				queryParams[key] = parseInt(value)
			} else {
				queryParams[key] = value;
			}
		}
	});

	return queryParams;
}

export function slugify(text: string) {
	return text
		.toString()
		.toLowerCase()
		.trim()
		.replace(/'/g, '')
		.replace(/[\s\W-]+/g, '-');
}