import { combineReducers } from 'redux-loop';
import { ApplicationState } from './types';
import { artistReducer } from './Artist/reducer';
import { defaultState as defaultArtistState } from './Artist/consts';
import { itemReducer } from './Item/reducer';
import { defaultState as defaultItemState } from './Item/consts';
import { authReducer } from './Auth/reducer';
import { defaultState as defaultAuthState } from './Auth/consts';
import { portfolioReducer } from './Portfolio/reducer';
import { defaultState as defaultPortfolioState } from './Portfolio/consts';
import { wishlistReducer } from './Wishlist/reducer';
import { defaultState as defaultWishlistState } from './Wishlist/consts';
import { userReducer } from './User/reducer';
import { defaultState as defaultUserState } from './User/consts';
import { popupReducer } from './Popup/reducer';
import { defaultState as defaultPopupState } from './Popup/consts';
import { listingReducer } from './Listing/reducer';
import { defaultState as defaultListingState } from './Listing/consts';
import { materialReducer } from './Material/reducer';
import { defaultState as defaultMaterialState } from './Material/consts';
import { itemDataSuggestionReducer } from './ItemDataSuggestion/reducer';
import { defaultState as defaultItemDataSuggestionState } from './ItemDataSuggestion/consts';
import { salesVenueReducer } from './SalesVenue/reducer';
import { defaultState as defaultSalesVenueState } from './SalesVenue/consts';
import { itemSaleSuggestionReducer } from './ItemSaleSuggestion/reducer';
import { defaultState as defaultItemSaleSuggestionState } from './ItemSaleSuggestion/consts';
import { itemListingGroupingReducer } from './ItemListingGrouping/reducer';
import { defaultState as defaultItemListingGroupingState } from './ItemListingGrouping/consts';
import { listingDataSuggestionReducer } from './ListingDataSuggestion/reducer';
import { defaultState as defaultListingDataSuggestionState } from './ListingDataSuggestion/consts';
import { homeReducer } from './Home/reducer';
import { defaultState as defaultHomeState } from './Home/consts';
import { activeMarketReducer } from './ActiveMarket/reducer';
import { defaultState as defaultActiveMarketState } from './ActiveMarket/consts';
import { orderReducer  } from './Order/reducer';
import { defaultState as defaultOrderState } from './Order/consts';

export const rootReducer = combineReducers({
  artists: artistReducer,
  auth: authReducer,
  items: itemReducer,
  portfolios: portfolioReducer,
  wishlists: wishlistReducer,
  users: userReducer,
  popups: popupReducer,
  listings: listingReducer,
  materials: materialReducer,
  itemDataSuggestions: itemDataSuggestionReducer,
  itemSaleSuggestions: itemSaleSuggestionReducer,
  listingDataSuggestions: listingDataSuggestionReducer,
  salesVenues: salesVenueReducer,
  itemListingGroupings: itemListingGroupingReducer,
  home: homeReducer,
  activeMarket: activeMarketReducer,
  orders: orderReducer
});

export const defaultState: ApplicationState = {
  artists: defaultArtistState,
  auth: defaultAuthState,
  items: defaultItemState,
  portfolios: defaultPortfolioState,
  wishlists: defaultWishlistState,
  users: defaultUserState,
  popups: defaultPopupState,
  listings: defaultListingState,
  materials: defaultMaterialState,
  itemDataSuggestions: defaultItemDataSuggestionState,
  itemSaleSuggestions: defaultItemSaleSuggestionState,
  listingDataSuggestions: defaultListingDataSuggestionState,
  salesVenues: defaultSalesVenueState,
  itemListingGroupings: defaultItemListingGroupingState,
  home: defaultHomeState,
  activeMarket: defaultActiveMarketState,
  orders: defaultOrderState
};
