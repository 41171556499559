import {
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	Button,
	HStack,
	Box,
	Text
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Condition } from 'models/types';

export interface ConditionDropdownProps {
	onChange: (value: Condition) => void;
	defaultValue?: Condition;
	customLabels?: Partial<Record<Condition, string>>;
	isDisabled?: boolean;
	size?: 'sm' | 'md' | 'lg';
}

export default function ConditionDropdown({
	defaultValue,
	onChange,
	customLabels,
	isDisabled,
	size = 'sm',
}: ConditionDropdownProps) {
	const defaultOptionColors: Record<Condition, string> = {
		[Condition.POOR]: 'red.600',
		[Condition.FAIR]: 'yellow.300',
		[Condition.GOOD]: 'green.200',
		[Condition.EXCELLENT]: 'green.700',
		[Condition.MINT]: 'purple.700',
	};
	
	const defaultConditionOptions = Object.values(Condition).map((cond) => ({
		value: cond,
		label: cond,
		color: defaultOptionColors[cond],
	}));

	const options = defaultConditionOptions.map((option) => ({
		...option,
		label: customLabels?.[option.value] || option.label,
	}));

	const selectedOption = options.find((option) => option.value === defaultValue) || options[0];

	return (
		<Menu>
			<MenuButton as={Button} rightIcon={<ChevronDownIcon />} size={size} disabled={isDisabled}>
				<HStack spacing={2} marginLeft={2}>
					<Box boxSize={3} borderRadius="full" bg={selectedOption.color} />
					<Text fontSize={size}>{selectedOption.label}</Text>
				</HStack>
			</MenuButton>
			<MenuList>
				{options.map((option) => (
					<MenuItem key={option.value} onClick={() => onChange(option.value)} zIndex={1000}>
						<HStack spacing={2}>
							<Box boxSize={3} borderRadius="full" bg={option.color} />
							<Text fontSize={size}>{option.label}</Text>
						</HStack>
					</MenuItem>
				))}
			</MenuList>
		</Menu>
	);
};