import {
  Box,
  Avatar,
  Flex,
  Heading,
  Text,
  Image,
  Spacer,
  Badge,
  Icon,
  useColorModeValue
} from '@chakra-ui/react';
import {
  GiThorHammer,
} from 'react-icons/gi';
import { ItemModel } from 'models';

export interface BasicItemCardProps {
  item: ItemModel;
  onClick?: () => void;
  useAvatar?: boolean;
  selected?: boolean;
}

export enum BasicItemCardSizingConfig {
  CARD_HEIGHT = '80px',
  PANEL_MIN_HEIGHT = '80px'
}

export enum BasicItemCardStylingConfig {
  BORDER_COLOR_LIGHT = '#f1f3f7',
  BORDER_COLOR_DARK = '#3f434c'
}

export default function BasicItemCard({ item, onClick, useAvatar = false, selected = false }: BasicItemCardProps) {
  function renderItemImage() {
    if (useAvatar) {
      return <Avatar backgroundColor={'white'} marginRight={4} name={item.name} src={item.image} />
    } else {
      return <Image fit={'contain'} marginRight={4} src={item.image} alt={item.name} width={BasicItemCardSizingConfig.CARD_HEIGHT} height={BasicItemCardSizingConfig.CARD_HEIGHT} />;
    }
  }

  function renderPricingData() {
    if (item.hasMarketSummaryData()) {
      return (
        <Flex direction="column" gap={2} alignItems="flex-end">
          <Heading fontSize="sm">{item.getEstimatedMarketPriceForDisplay()}</Heading>
          <Flex direction={'row'} alignItems={'center'} gap={1}>
            <Text fontSize={'xs'}>{item.getSoldListingsQuantity()}</Text>
            <Flex
              padding={1}
              align={'center'}
              justify={'center'}
              rounded={'full'}
              border="1px">
              <Icon as={GiThorHammer} w={3} h={3} />
            </Flex>
          </Flex>
        </Flex>
      );
    } else if (item.hasReleasePriceData()) {
      return (
        <Flex direction="column" alignItems="flex-end" width={'100%'} gap={2}>
          <Flex direction={'column'}>
            <Text fontSize={'xs'}>{item.getReleaseConvertedPriceForDisplay()}</Text>
            <Heading fontSize={'10px'}>Retail Price</Heading>
          </Flex>
          <Badge colorScheme='pink'>
            No Sales
          </Badge>
        </Flex>
      );
    }
  }

  return (
    <Box
      minHeight={BasicItemCardSizingConfig.CARD_HEIGHT}
      width="100%"
      overflow='hidden'
      onClick={onClick}
      cursor={onClick ? 'pointer' : ''}
      padding={2}
      border="1px solid"
      borderColor={useColorModeValue(BasicItemCardStylingConfig.BORDER_COLOR_LIGHT, BasicItemCardStylingConfig.BORDER_COLOR_DARK)}
      backgroundColor={selected ? useColorModeValue('gray.200', 'gray.800') : ''}
      _hover={onClick ? { 'backgroundColor': useColorModeValue('gray.200', 'gray.800') } : {}}
    >
      <Flex direction="row" alignItems="center" justifyContent="flex-start">
        {renderItemImage()}
        <Flex direction="column" textAlign="left" gap={2} paddingRight={4}>
          <Heading fontSize="sm">{item.getDisplayName()}</Heading>
          <Text fontSize="xs">{item.getArtistName()}</Text>
        </Flex>
        <Spacer />
        {renderPricingData()}
      </Flex>
    </Box>
  );
}
