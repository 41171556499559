import { Moment } from 'moment';
import { RawItemData } from '../types';
import { Condition } from '../../types';

export interface ItemBidTimestamps {
  createdAt: Moment;
  updatedAt: Moment;
  expiresAt: Moment;
}

export enum ItemBidStatus {
  ACTIVE = 'Active',
  EXPIRED = 'Expired',
  REJECTED = 'Rejected',
  PENDING = 'Pending',
  VERIFIED = 'Verified',
  COMPLETE = 'Complete',
  ACCEPTED = 'Accepted'
}

export interface RawItemBidData {
  id: string;
  item_id?: string;
  item?: RawItemData
  user_id: string;
  amount: number;
  currency: string;
  status: ItemBidStatus;
  verified: boolean;
  expiration_days: number;
  notes: string;
  condition: Condition;
  use_escrow?: boolean;
  timestamps?: {
    created_at: string;
    updated_at: string;
    expires_at: string;
  };
}

export const ItemBidType = 'Bid';