import { Component } from 'react';
import { connect, Dispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { ApplicationState } from 'reducers/types';
import { AuthActions } from 'reducers/Auth/actions';
import { UserActions } from 'reducers/User/actions';
import { ArtistActions } from 'reducers/Artist/actions';
import { MaterialActions } from 'reducers/Material/actions';
import { SalesVenueActions } from 'reducers/SalesVenue/actions';
import { PortfolioActions } from 'reducers/Portfolio/actions';
import { AuthSelectors } from 'reducers/Auth/selectors';
import { PopupSelectors } from 'reducers/Popup/selectors';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { NavBar, ProtectedRoute, AppBanner, MixpanelTracking } from 'connected-components';
import { AlertPopup, Footer, ScrollToTop } from 'components';
import {
  Box,
  Grid,
  Flex
} from '@chakra-ui/react';
import { CredentialService, MixpanelService } from 'services';
import { UserModel } from 'models';
import { Toast } from 'models/types';
import { ArtistListView } from './Artist/ArtistListView';
import { ArtistDetailsView } from './Artist/ArtistDetailsView';
import { ArtistSuggestionView } from './Artist/ArtistSuggestionView';
import { ItemDetailsView } from './Item/ItemDetailsView';
import { ItemSuggestionView } from './Item/ItemSuggestionView';
import { ItemSearchView } from './Search/ItemSearchView';
import { AuthView } from './Auth/AuthView';
import { UserProfileDetailsView } from './User/UserProfileDetailsView';
import { UserSettingsView } from './User/UserSettingsView';
import { UserFollowingView } from './User/UserFollowingView';
import { UserOnboardingView } from './User/UserOnboardingView';
import { UserConfirmationView } from './User/UserConfirmationView';
import { UserForgotPasswordView } from './User/UserForgotPasswordView';
import { UserActiveMarketView } from './User/UserActiveMarketView';
import { UserOrdersView } from './User/UserOrdersView';
import { OrderDetailsView } from './Order/OrderDetailsView';
import { PortfolioDetailsView } from './Portfolio/PortfolioDetailsView';
import { WishlistDetailsView } from './Wishlist/WishlistDetailsView';
import { JoinWaitlistView } from './Waitlist/JoinWaitlistView';
import { HomeView } from './Home/HomeView';
import { TradingFloorView } from './TradingFloor/TradingFloorView';
import { PrivacyContentView } from './Privacy/PrivacyContentView';
import { ContactUsView } from './Contact/ContactUsView';
import { ExploreView } from './Explore/ExploreView';
import { NotFoundView } from './Error/NotFoundView';

interface AppViewProps {
  getAuthenticatedUser: () => any;
  refreshAuthToken: () => any;
  setUser: (user: UserModel) => any;
  clearUser: () => any;
  clearPortfolio: () => any;
  logout: () => any;
  authenticatedUser: UserModel;
  toasts: Toast[];
  getAllArtists: () => any;
  getAllMaterials: () => any;
  getAllSalesVenues: () => any;
}

class App extends Component<AppViewProps, {}> {
  componentDidMount() {
    const { getAllArtists, getAllMaterials, getAllSalesVenues } = this.props;
    if (CredentialService.isAuthenticated()) {
      this.props.getAuthenticatedUser();
    }
    getAllArtists();
    getAllMaterials();
    getAllSalesVenues();
  }

  componentDidUpdate(prevProps: AppViewProps) {
    const { authenticatedUser, setUser, clearUser, clearPortfolio, refreshAuthToken } = this.props;
    if (!!authenticatedUser.id && authenticatedUser.id !== prevProps.authenticatedUser.id) {
      setUser(authenticatedUser);
      MixpanelService.identify(authenticatedUser.id);
      MixpanelService.setPeopleProperties({
        $name: authenticatedUser.name, 
        $email: authenticatedUser.email
      });
      refreshAuthToken();
    }

    if (prevProps.authenticatedUser.isAuthenticated() && !authenticatedUser.isAuthenticated()) {
      MixpanelService.reset();
      clearUser();
      clearPortfolio();
    }
  }

  renderPopupSection() {
    const popups = this.props.toasts.map((toast: Toast, index: number) => <AlertPopup key={`appToast_${index}`} toast={toast} />);
    return (
      <Box width="100%" position='fixed' bottom="20" gap={2} zIndex={1000}>
        <Flex direction="column-reverse" justifySelf="center" justifyContent="center" alignItems="center" gap={2}>
          {popups}
        </Flex>
      </Box>
    );
  }

  render() {
    return (
      <Box textAlign="center" fontSize="xl">
        <Grid height="100vh">
          <BrowserRouter>
            <MixpanelTracking />
            <NavBar />
            <AppBanner />
            {this.renderPopupSection()}
            <ScrollToTop>
              <Routes>
                <Route path="/" element={<HomeView />} />
                <Route path="/home" element={<HomeView />} />
                <Route path="/artists" element={
                  <ProtectedRoute>
                    <ArtistListView />
                  </ProtectedRoute>
                } />
                <Route path="/artist/:slug/:id" element={
                  <ArtistDetailsView />
                } />
                <Route path="/artist/:id" element={<ItemDetailsView />} />
                <Route path="/artist/suggest" element={
                  <ProtectedRoute>
                    <ArtistSuggestionView />
                  </ProtectedRoute>
                } />
                <Route path="/item/:slug/:id" element={
                  <ItemDetailsView />
                } />
                <Route path="/item/:id" element={<ItemDetailsView />} />
                <Route path="/item/search" element={
                  <ProtectedRoute>
                    <ItemSearchView />
                  </ProtectedRoute>
                } />
                <Route path="/explore" element={
                  <ExploreView />
                } />
                <Route path="/item/suggest" element={
                  <ProtectedRoute>
                    <ItemSuggestionView />
                  </ProtectedRoute>
                } />
                <Route path="/profile" element={
                  <ProtectedRoute>
                    <UserProfileDetailsView />
                  </ProtectedRoute>
                } />
                <Route path="/orders" element={
                  <ProtectedRoute>
                    <UserOrdersView />
                  </ProtectedRoute>
                } />

                <Route path="/settings" element={
                  <ProtectedRoute>
                    <UserSettingsView />
                  </ProtectedRoute>
                } />
                <Route path="/order/:id" element={
                  <ProtectedRoute>
                    <OrderDetailsView />
                  </ProtectedRoute>
                } />
                <Route path="/portfolio/:id" element={
                  <ProtectedRoute>
                    <PortfolioDetailsView />
                  </ProtectedRoute>
                } />
                <Route path="/wishlist/:id" element={
                  <ProtectedRoute>
                    <WishlistDetailsView />
                  </ProtectedRoute>
                } />
                <Route path="/following" element={
                  <ProtectedRoute>
                    <UserFollowingView />
                  </ProtectedRoute>
                } />
                <Route path="/active/market" element={
                  <ProtectedRoute>
                    <UserActiveMarketView />
                  </ProtectedRoute>
                } />
                <Route path="/trading-floor" element={
                  <TradingFloorView />
                } />
                <Route path="/onboarding" element={
                  <ProtectedRoute>
                    <UserOnboardingView />
                  </ProtectedRoute>
                } />
                <Route path="/auth/:type" element={<AuthView />} />
                <Route path="/user/confirmation" element={<UserConfirmationView />} />
                <Route path="/user/forgot-password" element={<UserForgotPasswordView />} />
                <Route path="/privacy" element={<PrivacyContentView />} />
                <Route path="/contact" element={<ContactUsView />} />
                <Route path="/waitlist" element={<JoinWaitlistView />} />
                <Route path="*" element={<NotFoundView />} />
              </Routes>
            </ScrollToTop>
            <Box marginTop="40px" />
            <Footer />
          </BrowserRouter>
        </Grid>
      </Box>
    );
  }
}

function mapStateToProps(state: ApplicationState) {
  return {
    authenticatedUser: AuthSelectors.getAuthenticatedUser(state),
    toasts: PopupSelectors.getToasts(state)
  }
}

function mapDispatchToProps(dispatch: Dispatch<ApplicationState>) {
  return bindActionCreators(
    {
      setUser: UserActions.setUser,
      clearUser: UserActions.clearUser,
      clearPortfolio: PortfolioActions.clearPortfolio,
      getAuthenticatedUser: AuthActions.getAuthenticatedUser,
      refreshAuthToken: AuthActions.refreshAuthToken,
      logout: AuthActions.logout,
      getAllArtists: ArtistActions.getAllArtists,
      getAllMaterials: MaterialActions.getAllMaterials,
      getAllSalesVenues: SalesVenueActions.getAllSalesVenues
    },
    dispatch
  );
}

export const AppView = connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
