import React from 'react';
import {
  Box,
  Flex,
  Icon,
  Progress,
  useColorModeValue
} from '@chakra-ui/react';
import { IconType } from 'react-icons';
import { FaCheck, FaTimes, FaInfoCircle } from 'react-icons/fa';

interface ProgressBarProps {
  progress: number;
  icon: IconType;
  dynamicColor?: boolean;
  staticColor?: string;
  height?: number;
}

export default function ProgressBar({
  progress,
  icon,
  dynamicColor = false,
  staticColor = 'blue.500',
  height = 4,
}: ProgressBarProps) {
  const getDynamicColor = (progress: number): string => {
    if (progress < 33) return 'red.500';
    if (progress < 67) return 'orange.500';
    return 'green.500';
  };

  const progressColor = dynamicColor ? getDynamicColor(progress) : staticColor;
  const barBg = useColorModeValue(`${progressColor}`, `${progressColor}`);
  const circleBg = useColorModeValue(`${progressColor}`, `${progressColor}`);

  return (
    <Flex align="center" position="relative" width="100%">
      <Progress
        value={progress}
        flex="1"
        height={height}
        borderRadius="full"
        sx={{
          '& > div': {
            backgroundColor: barBg,
          },
        }}
        zIndex={0}
      />
      {/* Circular icon on the far right */}
      <Box
        position="absolute"
        right="0"
        transform="translateX(20%)"
        w={height * 2}
        h={height * 2}
        borderRadius="full"
        bg={circleBg}
        display="flex"
        alignItems="center"
        justifyContent="center"
        zIndex={1}
      >
        <Icon as={icon} color="white" boxSize={height} />
      </Box>
    </Flex>
  );
}
