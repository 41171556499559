import { useState } from 'react';
import { Stack, Box, Checkbox, Heading, List, ListItem, Icon } from '@chakra-ui/react';
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai';
import { ItemAskModel, ItemModel } from 'models';
import Wizard from '../Wizard';
import ItemAskCard from '../../Item/ItemAskCard/ItemAskCard';
import GuidanceCard from '../../GuidanceCard/GuidanceCard';
import PaymentBreakdown from '../../PaymentBreakdown/PaymentBreakdown';

interface ItemBuyWizardProps {
	item: ItemModel;
	itemAsk: ItemAskModel;
	isLoading?: boolean;
	hasErrors?: boolean;
	onSubmit?: (ask: ItemAskModel, useEscrow: boolean) => void;
	onInfoGuideClick?: () => void;
}

export default function ItemBuyWizard({
	item,
	itemAsk,
	onInfoGuideClick,
	isLoading = false,
	hasErrors = false,
	onSubmit = () => { },
}: ItemBuyWizardProps) {
	const [agreed, setAgreed] = useState(false);
	const [useEscrow, setUseEscrow] = useState(false);
	const isTermsValid = agreed;

	const onWizardSubmit = () => {
		onSubmit(itemAsk, useEscrow);
	};

	function renderFeeBreakdownStep() {
		return (
			<Box height={{ base: 'auto', md: 360 }}>
				<PaymentBreakdown transaction={itemAsk} type={'ask'} useEscrow={useEscrow} onToggleEscrow={setUseEscrow} onInfoGuideClick={onInfoGuideClick} />
			</Box>
		);
	}

	function renderTermsStep() {
		const termsList = (
			<List spacing={2} styleType="disc" pl={4} m={0}>
				<ListItem>
					I have carefully reviewed the listing details including condition and provenance.
				</ListItem>
				<ListItem>
					I understand that my purchase is protected under our Buyer Guarantee which ensures secure payment,
					reliable delivery, and support in case of any issues.
				</ListItem>
				<ListItem>
					I understand that the total purchase amount: {itemAsk.getFormattedTotalAmount(useEscrow)} will be charged to me on checkout.
				</ListItem>
			</List>
		);

		return (
			<Box height={{ base: 'auto', md: 360 }}>
				<Stack spacing={4}>
					<ItemAskCard
						item={item}
						askAmount={itemAsk.amount}
						askCreatedDays={itemAsk.getCreatedDaysAgo()}
						askUpdatedDays={itemAsk.getUpdatedDaysAgo()}
						updated={itemAsk.hasBeenUpdated()}
						condition={itemAsk.condition}
					/>
					<Stack spacing={2}>
						<GuidanceCard header="Terms of Purchase" text={termsList} />
						<Checkbox isChecked={agreed} onChange={(e) => setAgreed(e.target.checked)}>
							<Heading fontSize="xs">
								I agree to the terms and conditions of my purchase.
							</Heading>
						</Checkbox>
					</Stack>
				</Stack>
			</Box>
		);
	}

	function renderWizardFinishingSuccessStep() {
		const content = hasErrors ? (
			<Stack gap={4} alignItems="center">
				<Icon as={AiFillCloseCircle} color="red.500" w={10} h={10} />
				<Heading size="xs" textAlign="center">
					Your Purchase Could Not Be Submitted.
					<br />
					Please Try Again.
				</Heading>
			</Stack>
		) : (
			<Stack gap={4} alignItems="center">
				<Icon as={AiFillCheckCircle} color="green.500" w={10} h={10} />
				<Heading size="xs" textAlign="center">
					Your Purchase Has Been Submitted.
				</Heading>
			</Stack>
		);
		return <Box height={{ base: 'auto', md: 340 }}>{content}</Box>;
	}

	const wizardSteps = [
		{
			label: 'Preview',
			content: renderFeeBreakdownStep(),
			requiresValidation: false,
		},
		{
			label: 'Confirm',
			content: renderTermsStep(),
			requiresValidation: true,
			isValidated: isTermsValid,
		},
	];

	return (
		<Stack gap={2} width="100%">
			<Wizard
				steps={wizardSteps}
				finishStep={renderWizardFinishingSuccessStep()}
				loading={isLoading}
				onSubmit={onWizardSubmit}
			/>
		</Stack>
	);
}
