import { useForm } from 'react-hook-form';
import { Stack, Flex, Heading, Textarea } from '@chakra-ui/react';
import BasicItemCard from '../../Item/BasicItemCard/BasicItemCard';
import ItemBidCard from '../../Item/ItemBidCard/ItemBidCard';
import ActionButton from '../../Button/ActionButton';
import { ItemModel, ItemBidModel } from 'models';

export interface ItemBidContactFormProps {
  item: ItemModel;
  itemBid: ItemBidModel;
  onSubmit: (itemBid: ItemBidModel, message: string) => void;
  isLoading?: boolean;
}

export interface ItemBidContactFormData {
	message: string;
}


export default function ItemBidContactForm({
  item,
  itemBid,
  onSubmit,
  isLoading
}: ItemBidContactFormProps) {
  const { register, handleSubmit } = useForm<ItemBidContactFormData>();

  const onFormSubmit = (data: ItemBidContactFormData) => {
    onSubmit(itemBid, data.message);
  };

  function renderMessageInput() {
    return (
      <Flex justifyContent="center" alignItems="center" direction="column" gap={2}>
        <Textarea
          {...register('message', { required: true })}
          placeholder="Type your message here..."
          resize="vertical"
          fontSize="sm"
        />
      </Flex>
    );
  }

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <Stack spacing={8}>
        <BasicItemCard item={item} />
        <Heading fontSize="sm">Send A Message</Heading>
        <ItemBidCard
          item={item}
          bidAmount={itemBid.amount}
          bidCreatedDays={itemBid.getCreatedDaysAgo()}
          bidUpdatedDays={itemBid.getUpdatedDaysAgo()}
          condition={itemBid.condition}
          expired={itemBid.isExpired()}
          updated={itemBid.hasBeenUpdated()}
          useAvatar={false}
          showIcons={false}
        />
        {renderMessageInput()}
        <Stack alignItems="center">
          <ActionButton
            type="submit"
            text="Send Inquiry"
            loading={isLoading}
            size="md"
            disabled={isLoading}
          />
        </Stack>
      </Stack>
    </form>
  );
}
