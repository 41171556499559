import { Text, Button } from '@chakra-ui/react';
import { BsFolder } from 'react-icons/bs';
import { toDollarFormat } from 'utils/format';
import UserProfileCard from './UserProfileCard';

interface UserProfilePortfolioCardProps {
	portfolioValue: number;
	onManage?: () => void;
}

export default function UserProfilePortfolioCard({ portfolioValue, onManage }: UserProfilePortfolioCardProps) {
	return (
		<UserProfileCard
			title="Portfolio"
			subtitle="Manage & Track Your Portfolio"
			icon={BsFolder}
			bottomButton={
				<Button variant="outline" size="sm" onClick={onManage}>
					Manage Portfolio
				</Button>
			}
			children={
				<Text fontSize="lg" fontWeight="bold">
					{toDollarFormat(portfolioValue)}
				</Text>

			}
		/>
	);
}