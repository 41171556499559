import { Component } from 'react';
import { connect, Dispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { RouterProps, withRouter } from "utils/route";
import { generateSEOTitle } from 'utils/seo';
import { ApplicationState } from 'reducers/types';
import { ItemSelectors } from 'reducers/Item/selectors';
import { ItemActions } from 'reducers/Item/actions';
import { ListingSelectors } from 'reducers/Listing/selectors';
import { ListingActions } from 'reducers/Listing/actions';
import { UserSelectors } from 'reducers/User/selectors';
import {
  Box,
  Flex,
  Text,
  Icon
} from '@chakra-ui/react';
import { RiAuctionLine } from 'react-icons/ri';
import { IoGridOutline } from 'react-icons/io5';
import { UserProfileBadge, ArtistGrid, TabGroup, PaginatedListingGrid, PaginatedItemGrid, EmptyState, Loader, SEOHelmet } from 'components';
import { UserModel, ArtistModel, ItemModel, ListingModel, PaginationModel } from 'models';
import { NavigationService } from 'services';
import { AppConstants } from '../../constants';

interface UserFollowingViewProps extends RouterProps {
  user: UserModel;
  paginatedItems: PaginationModel<ItemModel>;
  paginatedListings: PaginationModel<ListingModel>;
  followingArtistsLoading: boolean;
  followingArtistsItemsLoading: boolean;
  followingArtistsListingsLoading: boolean;
  getFollowingArtistItems: (page?: number) => void;
  getFollowingArtistListings: (page?: number) => void;
}

class UserFollowing extends Component<UserFollowingViewProps, {}> {
  componentDidMount() {
    const { getFollowingArtistItems, getFollowingArtistListings } = this.props;
    getFollowingArtistItems();
    getFollowingArtistListings();
  }

  componentDidUpdate(prevProps: UserFollowingViewProps) {
    const { user, navigate } = this.props;
    if (prevProps.user.isAuthenticated() && !user.isAuthenticated()) {
      navigate(NavigationService.getAuthLoginPath());
    }
  }

  onExploreArtistsClick = () => {
    this.props.navigate(
      NavigationService.getArtistsPath()
    );
  }

  onArtistClick = (artist: ArtistModel) => {
    this.props.navigate(
      NavigationService.getArtistDetailsPath(artist.id, artist.name)
    );
  }

  onItemClick = (item: ItemModel) => {
    this.props.navigate(
      NavigationService.getItemDetailsPath(item.id, item.getDisplayName())
    );
  }

  onListingItemClick = (id: string, name: string) => {
    this.props.navigate(NavigationService.getItemDetailsPath(id, name));
  };

  onListingArtistClick = (id: string, name: string) => {
    this.props.navigate(NavigationService.getArtistDetailsPath(id, name));
  }

  onItemsPageSelect = (page: number) => {
    this.props.getFollowingArtistItems(page);
  }

  onListingsPageSelect = (page: number) => {
    this.props.getFollowingArtistListings(page);
  }

  renderProfileSectionHeader(header: string) {
    return (
      <Flex p={{ base: '12px 20px', md: '12px 0px' }} mb='12px'>
        <Text fontSize='lg' fontWeight='bold'>
          {header}
        </Text>
      </Flex>
    )
  }

  renderUserFollowingArtists() {
    const { user, followingArtistsLoading } = this.props;
    return (
      <Box my={{ sm: "24px", xl: "0px" }} textAlign={{ base: 'center', md: 'left' }} borderRadius="16px">
        {this.renderProfileSectionHeader('Artists')}
        <ArtistGrid onArtistCardClick={this.onArtistClick} artists={user.getFollowingArtists()} gridDisplay={true} isLoading={followingArtistsLoading} />
      </Box>
    );
  }

  renderUserFollowingArtistsItems() {
    const { paginatedItems, followingArtistsItemsLoading } = this.props;
    return (
      <Box my={{ sm: "24px", xl: "0px" }} textAlign={{ base: 'center', md: 'left' }} borderRadius="16px">
        {this.renderProfileSectionHeader('Items')}
        <PaginatedItemGrid
          paginatedItems={paginatedItems}
          isLoading={followingArtistsItemsLoading}
          onPageSelect={this.onItemsPageSelect}
          scrollMode={false}
          onItemCardClick={this.onItemClick} />
      </Box>
    );
  }

  renderUserFollowingArtistsListings() {
    const { paginatedListings, followingArtistsListingsLoading } = this.props;
    return (
      <Box my={{ sm: "24px", xl: "0px" }} textAlign={{ base: 'center', md: 'left' }} borderRadius="16px">
        {this.renderProfileSectionHeader('Listings')}
        <PaginatedListingGrid
          paginatedListings={paginatedListings}
          isLoading={followingArtistsListingsLoading}
          onPageSelect={this.onListingsPageSelect}
          scrollMode={false}
          onListingItemClick={this.onListingItemClick}
          onListingArtistClick={this.onListingArtistClick}
        />
      </Box>
    );
  }


  renderTabs() {
    const labels = [
      <Flex gap="4px" alignItems="center" direction={{ base: 'column', md: 'row' }}>
        <Icon as={IoGridOutline} />
        <Text> Items </Text>
      </Flex>,
      <Flex gap="4px" alignItems="center" direction={{ base: 'column', md: 'row' }}>
        <Icon as={RiAuctionLine} />
        <Text> Past Sales </Text>
      </Flex>
    ];

    const content = [
      this.renderUserFollowingArtistsItems(),
      this.renderUserFollowingArtistsListings()
    ];
    return (
      <TabGroup labels={labels} content={content} />
    );
  }

  renderContent() {
    const { user, followingArtistsLoading } = this.props;
    if (user.isFollowingArtists()) {
      return (
        <Box>
          <UserProfileBadge user={user} />
          {this.renderUserFollowingArtists()}
          {this.renderTabs()}
        </Box>
      )
    } else if (followingArtistsLoading) {
      return (
        <Loader />
      );
    } else {
      return <EmptyState header="No Artists" description="No Artists Being Followed" buttonText="Browse Artists" showButton={true} onButtonClick={this.onExploreArtistsClick} />;
    }
  }

  render() {
    return (
      <>
        <SEOHelmet
          title={generateSEOTitle('Following')}
          description={`View item and sale highlights from all the artists that you follow.`}
        />
        <Box maxWidth={`${AppConstants.GRIDPAGE_WIDTH}px`} paddingTop={['80px', '100px', '100px']} justifySelf="center" minWidth={['100%', `${AppConstants.GRIDPAGE_WIDTH}px`]}>
          {this.renderContent()}
        </Box>
      </>
    );
  }
}

function mapStateToProps(state: ApplicationState) {
  return {
    user: UserSelectors.getUser(state),
    paginatedItems: ItemSelectors.getPaginatedItems(state),
    followingArtistsLoading: UserSelectors.getUserFollowingArtistsLoading(state),
    followingArtistsItemsLoading: ItemSelectors.getItemSearchLoading(state),
    followingArtistsListingsLoading: ListingSelectors.getListingSearchLoading(state),
    paginatedListings: ListingSelectors.getPaginatedListings(state)
  }
}

function mapDispatchToProps(dispatch: Dispatch<ApplicationState>) {
  return bindActionCreators(
    {
      getFollowingArtistItems: (page?: number) => ItemActions.getUserFollowingArtistsItems(page),
      getFollowingArtistListings: (page?: number) => ListingActions.getUserFollowingArtistsListings(page),
    },
    dispatch
  );
}

export const UserFollowingView = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserFollowing));
