import { ItemModel, ListingModel, MarketModel, ArtistModel, PaginationModel } from 'models';
import { JSONObject } from 'models/Api/types';
import { ItemActiveMarketData } from 'models/Item/types';

export enum ItemActionTypes {
  GET_ITEM_BY_ID = 'GET_ITEM_BY_ID',
  GET_ITEM_BY_ID_SUCCESS = 'GET_ITEM_BY_ID_SUCCESS',
  GET_ITEM_BY_ID_FAILURE = 'GET_ITEM_BY_ID_FAILURE',
  GET_ALL_ITEMS = 'GET_ALL_ITEMS',
  GET_ALL_ITEMS_SUCCESS = 'GET_ALL_ITEMS_SUCCESS',
  GET_ALL_ITEMS_FAILURE = 'GET_ALL_ITEMS_FAILURE',
  GET_ITEM_LISTINGS = 'GET_ITEM_LISTINGS',
  GET_ITEM_LISTINGS_SUCCESS = 'GET_ITEM_LISTINGS_SUCCESS',
  GET_ITEM_LISTINGS_FAILURE = 'GET_ITEM_LISTINGS_FAILURE',
  GET_ITEM_MARKET = 'GET_ITEM_MARKET',
  GET_ITEM_MARKET_SUCCESS = 'GET_ITEM_MARKET_SUCCESS',
  GET_ITEM_MARKET_FAILURE = 'GET_ITEM_MARKET_FAILURE',
  GET_ITEM_ACTIVE_MARKET = 'GET_ITEM_ACTIVE_MARKET',
  GET_ITEM_ACTIVE_MARKET_SUCCESS = 'GET_ITEM_ACTIVE_MARKET_SUCCESS',
  GET_ITEM_ACTIVE_MARKET_FAILURE = 'GET_ITEM_ACTIVE_MARKET_FAILURE',
  GET_ITEM_ARTIST = 'GET_ITEM_ARTIST',
  GET_ITEM_ARTIST_SUCCESS = 'GET_ITEM_ARTIST_SUCCESS',
  GET_ITEM_ARTIST_FAILURE = 'GET_ITEM_ARTIST_FAILURE',
  SEARCH_ITEM = 'SEARCH_ITEM',
  SEARCH_ITEM_SUCCESS = 'SEARCH_ITEM_SUCCESS',
  SEARCH_ITEM_FAILURE = 'SEARCH_ITEM_FAILURE',
  SEARCH_ITEM_BY_QUERY = 'SEARCH_ITEM_BY_QUERY',
  SEARCH_ITEM_BY_QUERY_SUCCESS = 'SEARCH_ITEM_BY_QUERY_SUCCESS',
  SEARCH_ITEM_BY_QUERY_FAILURE = 'SEARCH_ITEM_BY_QUERY_FAILURE',
  GET_USER_FOLLOWING_ARTISTS_ITEMS = 'GET_USER_FOLLOWING_ARTISTS_ITEMS',
  GET_USER_FOLLOWING_ARTISTS_ITEMS_SUCCESS = 'GET_USER_FOLLOWING_ARTISTS_ITEMS_SUCCESS',
  GET_USER_FOLLOWING_ARTISTS_ITEMS_FAILURE = 'GET_USER_FOLLOWING_ARTISTS_ITEMS_FAILURE',
  GET_ITEM_SUGGESTIONS = 'GET_ITEM_SUGGESTIONS',
  GET_ITEM_SUGGESTIONS_SUCCESS = 'GET_ITEM_SUGGESTIONS_SUCCESS',
  GET_ITEM_SUGGESTIONS_FAILURE = 'GET_ITEM_SUGGESTIONS_FAILURE',
  PLACE_ITEM_ASK = 'PLACE_ITEM_ASK',
  PLACE_ITEM_ASK_SUCCESS = 'PLACE_ITEM_ASK_SUCCESS',
  PLACE_ITEM_ASK_FAILURE = 'PLACE_ITEM_ASK_FAILURE',
  PLACE_ITEM_BID = 'PLACE_ITEM_BID',
  PLACE_ITEM_BID_SUCCESS = 'PLACE_ITEM_BID_SUCCESS',
  PLACE_ITEM_BID_FAILURE = 'PLACE_ITEM_BID_FAILURE',
  DELETE_ITEM_ASK = 'DELETE_ITEM_ASK',
  DELETE_ITEM_ASK_SUCCESS = 'DELETE_ITEM_ASK_SUCCESS',
  DELETE_ITEM_ASK_FAILURE = 'DELETE_ITEM_ASK_FAILURE',
  DELETE_ITEM_BID = 'DELETE_ITEM_BID',
  DELETE_ITEM_BID_SUCCESS = 'DELETE_ITEM_BID_SUCCESS',
  DELETE_ITEM_BID_FAILURE = 'DELETE_ITEM_BID_FAILURE',
  UPDATE_ITEM_ASK = 'UPDATE_ITEM_ASK',
  UPDATE_ITEM_ASK_SUCCESS = 'UPDATE_ITEM_ASK_SUCCESS',
  UPDATE_ITEM_ASK_FAILURE = 'UPDATE_ITEM_ASK_FAILURE',
  UPDATE_ITEM_BID = 'UPDATE_ITEM_BID',
  UPDATE_ITEM_BID_SUCCESS = 'UPDATE_ITEM_BID_SUCCESS',
  UPDATE_ITEM_BID_FAILURE = 'UPDATE_ITEM_BID_FAILURE',
  PLACE_ITEM_INQUIRY = 'PLACE_ITEM_INQUIRY',
  PLACE_ITEM_INQUIRY_SUCCESS = 'PLACE_ITEM_INQUIRY_SUCCESS',
  PLACE_ITEM_INQUIRY_FAILURE = 'PLACE_ITEM_INQUIRY_FAILURE',
  GENERATE_ASK_PAYMENT_LINK = 'GENERATE_ASK_PAYMENT_LINK',
  GENERATE_ASK_PAYMENT_LINK_SUCCESS = 'GENERATE_ASK_PAYMENT_LINK_SUCCESS',
  GENERATE_ASK_PAYMENT_LINK_FAILURE = 'GENERATE_ASK_PAYMENT_LINK_FAILURE',
  ACCEPT_ITEM_BID = 'ACCEPT_ITEM_BID',
  ACCEPT_ITEM_BID_SUCCESS = 'ACCEPT_ITEM_BID_SUCCESS',
  ACCEPT_ITEM_BID_FAILURE = 'ACCEPT_ITEM_BID_FAILURE',

};

export interface ItemActionPayload {
  id?: string;
  itemId?: string;
  searchQuery?: JSONObject;
  query?: string;
  page?: number;
  item?: ItemModel;
  market?: MarketModel;
  activeMarket?: ItemActiveMarketData;
  listings?: ListingModel[];
  artist?: ArtistModel;
  paginatedItems?: PaginationModel<ItemModel>;
  items?: ItemModel[];
  bidData?: JSONObject;
  inquiryData?: JSONObject;
  askData?: JSONObject;
  itemBidId?: string;
  itemAskId?: string;
  useEscrow?: boolean;
  paymentLink?: string;
  orderId?: string;
};

export interface ItemAction {
  type: ItemActionTypes;
  payload?: ItemActionPayload;
};

export interface ItemStateLoading {
  item: boolean;
  market: boolean;
  listings: boolean;
  artist: boolean;
  search: boolean;
  items: boolean;
  activeMarket: boolean;
  activeMarketAction: boolean;
  inquiryAction: boolean;
  marketplaceAction: boolean;
};

export interface ItemState {
  item: ItemModel;
  paginatedItems: PaginationModel<ItemModel>;
  items: ItemModel[];
  error: Error | undefined;
  loading: ItemStateLoading;
};
