import React, { useState } from 'react';
import moment from 'moment';
import {
	BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend
} from 'recharts';
import {
	Flex,
	Text,
	Box,
	Card,
	CardHeader,
	Spacer
} from '@chakra-ui/react';
import { getFullYearlyData, getFullDailyDataForMonth, getFullMonthlyDataForYear } from 'utils/market';
import { DayXTick, MonthXTick, YearXTick, PriceYTick } from './Ticks';
import { MarketVolumeChartTooltip } from './Tooltips';
import { YearlySummary, YearlySummaryItem } from 'models/Market/types';
import { ListingModel } from 'models';
import { MarketModel } from 'models';
import { MetricModel } from 'models/Market/Metric';

interface MarketVolumeChartProps {
	market: MarketModel;
	listings?: ListingModel[];
}

const MarketVolumeChart: React.FC<MarketVolumeChartProps> = ({ market, listings = [] }) => {
	const [selectedYear, setSelectedYear] = useState<number | null>(null);
	const [selectedMonth, setSelectedMonth] = useState<number | null>(null);

	const data: YearlySummary = market.yearlySummary;
	const yearlyData: YearlySummaryItem[] = market.getYearlyData();

	const handleBarClick = (payload: MetricModel | undefined) => {
		if (payload?.key) {
			if (selectedYear && !selectedMonth) {
				setSelectedMonth(Number(payload.key));
			} else if (!selectedYear) {
				const actualYearData = yearlyData.find(y => y.overview.key === Number(payload.key));
				if (actualYearData) {
					setSelectedYear(Number(payload.key));
				}
			}
		}
	};

	const renderTitle = () => {
		return (
			<Text textAlign="left" fontSize='md' fontWeight='bold' mb='6px'>
				Volume History
			</Text>
		);
	};

	const renderChartBackButton = () => {
		if (selectedMonth) {
			return (
				<Flex direction={'row'} width={'100%'} paddingLeft={8}>
					<Text textAlign={'center'} fontSize={10} fontWeight={'bold'}>
						{moment(selectedMonth, 'M').format('MMMM')}, {selectedYear}
					</Text>
					<Spacer />
					<Text textAlign={'right'} fontSize={10} cursor={'pointer'} color={'blue.500'} fontWeight={'bold'} onClick={() => setSelectedMonth(null)} colorScheme="blue">
						Back to Monthly View
					</Text>
				</Flex>
			);
		} else if (selectedYear) {
			return (
				<Flex direction={'row'} width={'100%'} paddingLeft={8}>
					<Text textAlign={'left'} fontSize={'xs'} fontWeight={'bold'}>
						{selectedYear}
					</Text>
					<Spacer />
					<Text textAlign={'right'} fontSize={10} cursor={'pointer'} color={'blue.500'} fontWeight={'bold'} onClick={() => setSelectedYear(null)} colorScheme="blue">
						Back to Yearly View
					</Text>
				</Flex>
			);
		}
	}

	const renderChartData = () => {
		if (selectedYear && selectedMonth) {
			return (
				<BarChart data={getFullDailyDataForMonth(selectedYear, selectedMonth, listings)}>
					<XAxis dataKey="key" tick={DayXTick} />
					<YAxis tick={PriceYTick} />
					<Tooltip content={(props) => <MarketVolumeChartTooltip selectedYear={selectedYear} selectedMonth={selectedMonth} listings={listings} {...props} />} />
					<Bar name="Total Volume" dataKey="totalSales" fill="#8884d8" />
					<Legend iconSize={14} wrapperStyle={{fontSize: "12px", fontWeight: "bold"}} />
				</BarChart>
			);
		} else if (selectedYear) {
			return (
				<BarChart data={getFullMonthlyDataForYear(selectedYear, data)}>
					<XAxis dataKey="key" tick={MonthXTick} />
					<YAxis tick={PriceYTick} />
					<Tooltip content={(props) => <MarketVolumeChartTooltip selectedYear={selectedYear} selectedMonth={selectedMonth} listings={listings} {...props} />} />
					<Bar name="Total Volume" dataKey="totalSales" fill="#8884d8" onClick={handleBarClick} />
					<Legend iconSize={14} wrapperStyle={{fontSize: "12px", fontWeight: "bold"}} />
				</BarChart>
			);
		} else {
			return (
				<BarChart data={getFullYearlyData(yearlyData).map(yearData => yearData.overview)} margin={{ top: 10, right: 10, left: 10, bottom: 10 }}>
					<XAxis dataKey="key" tickCount={12}  tick={YearXTick} />
					<YAxis tick={PriceYTick} />
					<Tooltip content={(props) => <MarketVolumeChartTooltip selectedYear={selectedYear} selectedMonth={selectedMonth} listings={listings} {...props} />} />
					<Bar name="Total Volume" dataKey="totalSales" fill="#8884d8" onClick={handleBarClick} />
					<Legend iconSize={14} wrapperStyle={{fontSize: "12px", fontWeight: "bold"}} />
				</BarChart>
			)
		}
	};

	return (
		<Card paddingY={4} paddingRight={2} borderRadius={8} width={'100%'} display={'flex'} flexDirection={'column'}>
			<CardHeader paddingX={4} paddingY={0} mb={4}>
				<Flex
					direction='column'
					alignSelf='flex-start'>
					{renderTitle()}
				</Flex>
			</CardHeader>
			<Box w='100%' height="300px" justifyContent="center" alignItems="center">
				<ResponsiveContainer>
					{renderChartData()}
				</ResponsiveContainer>
			</Box>
			<Box minHeight={4} width="100%">
				{renderChartBackButton()}
			</Box>
		</Card>
	);
}

export default MarketVolumeChart;
