import { slugify } from "utils/url";

export class NavigationService {
  public static getHomePath(): string {
    return '/home';
  }

  public static getArtistDetailsPath(id: string, name?: string): string {
    if (name) {
      return `/artist/${slugify(name)}/${id}`;
    } else {
      return `/artist/${id}`;
    }
  }

  public static getArtistsPath(): string {
    return '/artists';
  }

  public static getAuthLoginPath(): string {
    return '/auth/login';
  }

  public static getAuthRegisterPath(): string {
    return '/auth/register';
  }

  public static getUserProfilePath(): string {
    return '/profile';
  }

  public static getUserActiveMarketPath(): string {
    return '/active/market'
  }

  public static getUserOrdersPath(): string {
    return '/orders';
  }

  public static getUserOnboardingPath(): string {
    return '/onboarding';
  }

  public static getUserSettingsPath(): string {
    return '/settings';
  }

  public static getUserFollowingPath(): string {
    return '/following';
  }

  public static getUserConfirmationPath(): string {
    return '/user/confirmation';
  }

  public static getItemDetailsPath(id: string, name?: string): string {
    if (name) {
      return `/item/${slugify(name)}/${id}`;
    } else {
      return `/item/${id}`;
    }
  }

  public static getOrderDetailsPath(id: string): string {
    return `/order/${id}`;
  }

  public static getItemSearchPath(): string {
    return '/item/search';
  }

  public static getItemSuggestionPath(): string {
    return '/item/suggest';
  }

  public static getPortfolioDetailsPath(id: string): string {
    return `/portfolio/${id}`;
  }

  public static getWishlistDetailsPath(id: string): string {
    return `/wishlist/${id}`;
  }

  public static getPrivacyContentPath(): string {
    return '/privacy';
  }

  public static getContactPath(): string {
    return '/contact';
  }

  public static getWaitlistPath(): string {
    return '/waitlist';
  }

  public static getUserForgotPasswordPath(): string {
    return '/user/forgot-password';
  }

  public static getExplorePath(): string {
    return '/explore';
  }

  public static getTradingFloorPath(): string {
    return '/trading-floor';
  }

}
