import {
  isValidPhoneNumber,
  CountryCode
} from 'libphonenumber-js'

export function isValidPhone(phone?: string | number, countryCode?: string): boolean {
  if (phone) {
    return isValidPhoneNumber(String(phone), countryCode?.toUpperCase() as CountryCode);
  }

  return false;
};