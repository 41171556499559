import { useForm } from 'react-hook-form';
import { Stack, Flex, Heading, Icon } from '@chakra-ui/react';
import { RiErrorWarningFill } from 'react-icons/ri';
import BasicItemCard from '../../Item/BasicItemCard/BasicItemCard';
import ItemBidCard from '../../Item/ItemBidCard/ItemBidCard';
import ActionButton from '../../Button/ActionButton';
import UnauthorizedAccess from '../../UnauthorizedAccess/UnauthorizedAccess';
import { ItemModel, ItemBidModel, UserModel } from 'models';

export interface ItemBidRemovalFormProps {
  item: ItemModel;
  bid: ItemBidModel;
  user: UserModel;
  onSubmit: (bid: ItemBidModel) => void;
  isLoading?: boolean;
}

export default function ItemBidRemovalForm({
  item,
  bid,
  user,
  onSubmit,
  isLoading
}: ItemBidRemovalFormProps) {
  const { handleSubmit } = useForm();

  const onFormSubmit = () => {
    onSubmit(bid);
  };

  function renderRemoveWarning() {
    return (
      <Flex justifyContent="center" alignItems="center" direction="row" gap={1} color="red.600">
        <Icon as={RiErrorWarningFill} />
        <Heading fontSize="sm">Are You Sure You Want To Remove This Bid?</Heading>
      </Flex>
    );
  }

  function renderRemoveForm() {
    return (
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <Stack spacing={8}>
          <BasicItemCard item={item} />
          {renderRemoveWarning()}

          <ItemBidCard
            item={item}
            bidAmount={bid.amount}
            bidCreatedDays={bid.getCreatedDaysAgo()}
            bidUpdatedDays={bid.getUpdatedDaysAgo()}
            condition={bid.condition}
            expired={bid.isExpired()}
            updated={bid.hasBeenUpdated()}
            useAvatar={false}
            showIcons={false}
          />

          <Stack alignItems="center">
            <ActionButton
              type="submit"
              text="Remove Bid"
              loading={isLoading}
              size="md"
              disabled={isLoading}
            />
          </Stack>
        </Stack>
      </form>
    );
  }

  function renderUnauthorizedContent() {
    return (
      <UnauthorizedAccess
        title="Unauthorized"
        description="You are unauthorized to remove this bid."
      />
    );
  }

  function renderContent() {
    if (bid.belongsToUser(user)) {
      return renderRemoveForm();
    } else {
      return renderUnauthorizedContent();
    }
  }

  return (
    renderContent()
  );
}
