import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Stack, Box, Flex, Icon, Text, useBreakpointValue, useColorModeValue } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { FaInstagram, FaCheck, FaInfoCircle } from 'react-icons/fa';
import { FiPhoneCall } from "react-icons/fi";
import { IoPersonAddOutline, IoLocationOutline } from "react-icons/io5";
import { HiOutlineCake } from "react-icons/hi";
import { BsPersonBadge } from "react-icons/bs";
import { AddressModel, UserModel } from 'models';
import { AppConfig } from 'config';
import Wizard from '../Wizard';
import UserDataForm, { UserDataFormData } from '../../Form/UserDataForm/UserDataForm';
import SearchAddressForm from '../../Form/SearchAddressForm/SearchAddressForm';
import AddressForm from '../../Form/AddressForm/AddressForm';
import ProgressBar from '../../ProgressBar/ProgressBar';
import { isValidPhone } from 'utils/phone';

const MotionBox = motion(Box);


interface StepWrapperProps {
	stepKey: string;
	icon: React.ElementType;
	title: string;
	description: string;
	extraText?: string;
	children: React.ReactNode;
}

const StepWrapper = React.memo(({
	stepKey,
	icon,
	title,
	description,
	extraText,
	children,
	...props
}: StepWrapperProps) => {
	const boxShadow = useColorModeValue('md', 'md-dark');
	const background = useBreakpointValue({ base: 'transparent', sm: 'bg-surface' });

	const animationVariants = {
		initial: { opacity: 0, x: 20 },
		animate: { opacity: 1, x: 0 },
		exit: { opacity: 0, x: -20 },
	};

	return (
		<MotionBox
			key={stepKey}
			minH="400px"
			width={{ base: '100%', md: '480px' }}
			py={8}
			px={10}
			bg={background}
			boxShadow={boxShadow}
			borderRadius="xl"
			{...animationVariants}
			transition={{ duration: 0.3 }}
			{...props}
		>
			<Stack gap={8}>
				<Stack gap={4} alignItems="center">
					<Flex
						w={16}
						h={16}
						align="center"
						justify="center"
						rounded="full"
						border="2px"
					>
						<Icon w={8} h={8} as={icon} />
					</Flex>
					<Text fontSize="md">{title}</Text>
					<Text fontSize="sm" fontWeight="light">{description}</Text>
				</Stack>
				{/* Render step-specific form or content */}
				{children}
				{extraText && (
					<Text fontSize="xs" fontWeight="light">{extraText}</Text>
				)}
			</Stack>
		</MotionBox>
	);
});


export interface UserOnboardingWizardData extends UserDataFormData {
	address?: AddressModel
};

export interface UserOnboardingWizardProps {
	user?: UserModel;
	onSubmit?: (data: UserOnboardingWizardData) => void;
	submitLoading?: boolean;
}

export default function UserOnboardingWizard({
	user,
	onSubmit,
	submitLoading = false,
}: UserOnboardingWizardProps) {
	const [activeStep, setActiveStep] = useState(0);
	const [onboardingData, setOnboardingData] = useState<UserOnboardingWizardData>({
		name: user?.name || '',
		number: user?.number || undefined,
		birthYear: user?.birthYear || undefined,
		instagram: user?.instagram || '',
		image: user?.image || '',
		country: user?.country || undefined,
		address: user?.address?.primaryAddress ||
			new AddressModel({
				line1: '',
				line2: '',
				city: '',
				state: '',
				postal_code: '',
				country: '',
			}),
	});

	useEffect(() => {
		if (user) {
			setOnboardingData({
				name: user.name || '',
				number: user.number || undefined,
				birthYear: user.birthYear || undefined,
				instagram: user.instagram || '',
				image: user.image || '',
				country: user.country || undefined,
				address: user.address?.primaryAddress ||
					new AddressModel({
						line1: '',
						line2: '',
						city: '',
						state: '',
						postal_code: '',
						country: '',
					}),
			});
		}
	}, [user]);

	const handleFormChange = useCallback((newData: Partial<UserDataFormData>) => {
		setOnboardingData(prev => ({ ...prev, ...newData }));
	}, []);

	const handleAddressChange = useCallback((updatedAddress: AddressModel) => {
		setOnboardingData(prev => ({ ...prev, address: updatedAddress }));
	}, []);

	const onWizardSubmit = useCallback(() => {
		onSubmit?.(onboardingData);
	}, [onboardingData, onSubmit]);

	const wizardSteps = useMemo(() => [
		{
			label: '',
			icon: BsPersonBadge,
			content: (
				<StepWrapper
					stepKey="name"
					icon={BsPersonBadge}
					title="Add Your Name"
					description="This Name Will Be Used For Any Orders Or Sales You Make."
				>
					<UserDataForm
						defaultFormData={{ name: onboardingData.name }}
						displayOptions={['name']}
						requiredOptions={['name']}
						showSubmit={false}
						hideLabels={true}
						onChange={handleFormChange}
					/>
				</StepWrapper>
			),
			requiresValidation: true,
			isValidated: !!onboardingData.name,
		},
		{
			label: '',
			icon: FiPhoneCall,
			content: (
				<StepWrapper
					stepKey="number"
					icon={FiPhoneCall}
					title="Add Your Phone Number"
					description="Your Number Will Be Kept Private & Used For Any Order Related Communication."
					extraText={`You Can Opt Out of SMS Notifications From ${AppConfig.APP_NAME} Anytime.`}
				>
					<UserDataForm
						defaultFormData={{ number: onboardingData.number }}
						displayOptions={['number']}
						requiredOptions={['number']}
						showSubmit={false}
						hideLabels={true}
						onChange={handleFormChange}
					/>
				</StepWrapper>
			),
			requiresValidation: true,
			isValidated: isValidPhone(onboardingData.number?.baseNumber, onboardingData.number?.countryCode),
		},
		{
			label: '',
			icon: HiOutlineCake,
			content: (
				<StepWrapper
					stepKey="birthYear"
					icon={HiOutlineCake}
					title="Add Your Birth Year"
					description="This is Optional And Won't Be Part of Your Public Profile."
				>
					<UserDataForm
						defaultFormData={{ birthYear: onboardingData.birthYear }}
						displayOptions={['birthYear']}
						requiredOptions={['birthYear']}
						showSubmit={false}
						hideLabels={true}
						onChange={handleFormChange}
					/>
				</StepWrapper>
			),
			requiresValidation: false,
			isValidated: true,
		},
		{
			label: '',
			icon: IoLocationOutline,
			content: (
				<StepWrapper
					stepKey="address1"
					icon={IoLocationOutline}
					title="Add Your Primary Address"
					description="Your Address is Kept Private And Used For Buying and Selling."
					extraText="You Can Specify Additional Addresses Later."
				>
					<SearchAddressForm
						defaultAddress={onboardingData.address}
						onSelect={handleAddressChange}
						disabled={false}
					/>
				</StepWrapper>
			),
			requiresValidation: true,
			isValidated: onboardingData.address && onboardingData.address.line1.trim() !== '',
		},
		{
			label: '',
			icon: IoLocationOutline,
			content: (
				<StepWrapper
					stepKey="address2"
					icon={IoLocationOutline}
					title="Address (Step 2)"
					description="If Applicable, Add Your Apartment or Unit Number."
				>
					<AddressForm
						defaultAddress={onboardingData.address}
						displayOptions={['line2']}
						requiredOptions={[]}
						onChange={handleAddressChange}
						disabled={false}
					/>
				</StepWrapper>
			),
			requiresValidation: false,
			isValidated: true,
		},
		{
			label: '',
			icon: FaInstagram,
			content: (
				<StepWrapper
					stepKey="instagram"
					icon={FaInstagram}
					title="Add Your Instagram"
					description="Your Instagram is Kept Private And Helps Us Additionally Vet You As a Buyer or Seller."
				>
					<UserDataForm
						defaultFormData={{ instagram: onboardingData.instagram }}
						displayOptions={['instagram']}
						requiredOptions={[]}
						showSubmit={false}
						hideLabels={true}
						onChange={handleFormChange}
					/>
				</StepWrapper>
			),
			requiresValidation: false,
			isValidated: true,
		},
		{
			label: '',
			icon: IoPersonAddOutline,
			content: (
				<StepWrapper
        stepKey="avatar"
        icon={IoPersonAddOutline}
        title="Add Your Avatar"
        description="This Step Is Optional And Helps Us Provide You A Richer Browsing Experience."
      >
        <UserDataForm
          defaultFormData={{ image: onboardingData.image }}
          displayOptions={['image']}
          requiredOptions={[]}
          showSubmit={false}
          hideLabels={true}
          onChange={handleFormChange}
        />
      </StepWrapper>
			),
			requiresValidation: false,
			isValidated: true,
		},
	], [onboardingData, handleFormChange, handleAddressChange]);

	const totalSteps = wizardSteps.length;
	const progressPercentage = ((activeStep + 1) / totalSteps) * 100;
	const progressIcon = activeStep === totalSteps - 1 ? FaCheck : wizardSteps[activeStep].icon;

	return (
		<Stack>
			<ProgressBar progress={progressPercentage} icon={progressIcon} dynamicColor={true} height={4} />
			<Wizard
				steps={wizardSteps}
				onSubmit={onWizardSubmit}
				loading={submitLoading}
				showStepper={false}
				onStepChange={setActiveStep}
			/>
		</Stack>
	);
}
