import { ItemAskModel, ItemBidModel } from "models";

export function sortByVerificationAndExpiration<T extends ItemBidModel | ItemAskModel>(
    items: T[]
  ): T[] {
    return items.sort((a, b) => {
      if (a.isVerified() && !b.isVerified()) return -1; // Verified comes first
      if (!a.isVerified() && b.isVerified()) return 1;  // Non-verified comes later
      if (a.isExpired() && !b.isExpired()) return 1;    // Expired comes last
      if (!a.isExpired() && b.isExpired()) return -1;   // Non-expired comes earlier
      return 0;                                         // Maintain relative order otherwise
    });
  };

  export function getMarketValueTextConfig(enteredPrice: number, marketPrice: number, isAsk: boolean): { text: string, color: string } {
    let text: string = '';
    let color: string = '';
    const isMarketValue = enteredPrice === marketPrice;
    const isUnderMarketValue = enteredPrice < marketPrice;
    const isOverMarketValue = enteredPrice > marketPrice;

    if (isMarketValue) {
      text = 'Fair Value';
      color = 'blue.500';
    } else if (isUnderMarketValue) {
      text = 'Under FMV';
      color = isAsk ? 'green.500' : 'red.500';
    } else if (isOverMarketValue) {
      text = 'Over FMV';
      color = isAsk ? 'red.500' : 'green.500';
    }

    return { text, color }
  }