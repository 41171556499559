import { Component } from 'react';
import moment, { Moment } from 'moment';
import { connect, Dispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { RouterProps, withRouter, useAuthCheck } from "utils/route";
import { ApplicationState } from 'reducers/types';
import { UserSelectors } from 'reducers/User/selectors';
import { ItemSelectors } from 'reducers/Item/selectors';
import { AuthSelectors } from 'reducers/Auth/selectors';
import { MaterialSelectors } from 'reducers/Material/selectors';
import { SalesVenueSelectors } from 'reducers/SalesVenue/selectors';
import { ArtistSelectors } from 'reducers/Artist/selectors';
import { ItemDataSuggestionSelectors } from 'reducers/ItemDataSuggestion/selectors';
import { ItemSaleSuggestionSelectors } from 'reducers/ItemSaleSuggestion/selectors';
import { PortfolioActions } from 'reducers/Portfolio/actions';
import { PortfolioSelectors } from 'reducers/Portfolio/selectors';
import { WishlistActions } from 'reducers/Wishlist/actions';
import { WishlistSelectors } from 'reducers/Wishlist/selectors';
import { UserActions } from 'reducers/User/actions';
import { ItemActions } from 'reducers/Item/actions';
import { ItemDataSuggestionActions } from 'reducers/ItemDataSuggestion/actions';
import { ItemSaleSuggestionActions } from 'reducers/ItemSaleSuggestion/actions';
import {
  Box,
  Icon,
  SimpleGrid,
  Flex,
  Stack,
  Text,
  Heading
} from '@chakra-ui/react';
import {
  BiRuler,
  BiDollar,
  BiCalendar
} from 'react-icons/bi';
import {
  AiFillPrinter,
  AiOutlineLineChart,
  AiOutlineVerticalAlignTop,
  AiOutlinePlusCircle
} from 'react-icons/ai';
import {
  GiMaterialsScience
} from 'react-icons/gi';
import {
  FaRegClone,
  FaSign
} from 'react-icons/fa';
import {
  ImStatsDots
} from 'react-icons/im';
import {
  IoMdStats,
} from 'react-icons/io';
import { IoStorefrontOutline } from "react-icons/io5";
import {
  RiAuctionLine,
  RiScalesLine
} from "react-icons/ri";
import {
  GiThorHammer
} from 'react-icons/gi';
import {
  ArtistBanner,
  ItemInfoBlock,
  InfoAccordion,
  ListingGrid,
  TabGroup,
  Slider,
  StatsCard,
  EmptyState,
  Modal,
  Drawer,
  ItemSaleSuggestionForm,
  ItemDataSuggestionForm,
  PortfolioItemManagementForm,
  ItemCard,
  MarketPriceChart,
  MarketVolumeChart,
  Loader,
  ItemFlagWizard,
  ItemBidWizard,
  ItemAskWizard,
  ItemContainer,
  ItemContainerSkeleton,
  ItemGridSkeleton,
  TradingFloor,
  BasicItemCard,
  ItemBidRemovalForm,
  ItemAskRemovalForm,
  ItemBidEditWizard,
  ItemAskEditWizard,
  ItemBidGuideWizard,
  ItemAskGuideWizard,
  ItemBidContactForm,
  ItemAskContactForm,
  ItemSellWizard,
  ItemBuyWizard,
  LockedContent,
  GuidanceCard,
  InfoGuide,
  SEOHelmet
} from 'components';
import { ItemDataSuggestionFormData, ItemDataSuggestionFormOption } from 'components/Form/ItemDataSuggestionForm/ItemDataSuggestionForm';
import { ItemSaleSuggestionFormData } from 'components/Form/ItemSaleSuggestionForm/ItemSaleSuggestionForm';
import { PortfolioItemManagementFormData, PortfolioItemPurchaseInfo } from 'components/Form/PortfolioItemManagementForm/PortfolioItemManagementForm';
import { ItemModel, ArtistModel, UserModel, MaterialModel, PortfolioModel, SalesVenueModel, WishlistModel, ItemBidModel, ItemAskModel } from 'models';
import { ItemPurchaseInfo } from 'models/Portfolio/types';
import { AppConstants } from 'constants/app';
import { JSONObject } from 'models/Api/types';
import { Condition } from 'models/types';
import { FeatureToggleService, NavigationService } from 'services';
import { QuerySearchParameters } from 'constants/url';
import { FeatureToggleKeys } from '../../constants/toggles';
import { convertJSONObjectToSnakecase } from 'utils/object';
import { getEligibleQueryParamsFromUrl } from 'utils/url';
import { generateSEOTitle, generateItemSEOLink, createItemStructuredData } from 'utils/seo';
import { ItemBidFormData } from 'components/Form/ItemBidForm/ItemBidForm';
import { ItemAskFormData } from 'components/Form/ItemAskForm/ItemAskForm';
import { AppConfig } from 'config';
import { ItemBidAdditionalDataFormData } from 'components/Form/ItemBidAdditionalDataForm/ItemBidAdditionalDataForm';
import { ItemAskAdditionalDataFormData } from 'components/Form/ItemAskAdditionalDataForm/ItemAskAdditionalDataForm';

interface ItemDetailsViewProps extends RouterProps {
  item: ItemModel;
  user: UserModel;
  userLoading: boolean;
  portfolio: PortfolioModel;
  portfolioLoading: boolean;
  portfolioActionLoading: boolean;
  wishlist: WishlistModel;
  wishlistLoading: boolean;
  wishlistActionLoading: boolean;
  itemLoading: boolean;
  itemListingsLoading: boolean;
  itemMarketLoading: boolean;
  itemActiveMarketLoading: boolean;
  itemActiveMarketActionLoading: boolean;
  userPortfolioLoading: boolean;
  userWishlistLoading: boolean;
  userFollowingArtistsLoading: boolean;
  userArtistActionLoading: boolean;
  itemDataSuggestionLoading: boolean;
  itemSaleSuggestionLoading: boolean;
  marketplaceActionLoading: boolean;
  getItem: (id: string) => void;
  getItemListings: (id: string) => void;
  getItemArtist: (artistId: string) => void;
  addItemToPortfolio: (itemId: string, purchaseInfo: ItemPurchaseInfo[]) => void;
  replaceItemInPortfolio: (itemId: string, purchaseInfo: ItemPurchaseInfo[]) => void;
  addItemToWishlist: (itemId: string) => void;
  removeItemFromWishlist: (itemId: string) => void;
  followArtist: (artistId: string) => void;
  unfollowArtist: (artistId: string) => void;
  itemSuggestionLoading: boolean;
  itemSuggestions: ItemModel[];
  getItemSuggestions: (id: string) => void;
  materialList: MaterialModel[];
  artistList: ArtistModel[];
  salesVenueList: SalesVenueModel[];
  submitItemDataSuggestion: (itemId: string, data: JSONObject) => void;
  submitItemSaleSuggestion: (itemId: string, price: number, link: string, date: Moment, sourceId: string) => void;
  placeItemBid: (itemId: string, bidData: JSONObject) => void;
  placeItemAsk: (itemId: string, askData: JSONObject) => void;
  placeItemInquiry: (itemId: string, inquiryData: JSONObject) => void;
  deleteItemBid: (itemBidId: string, itemId: string) => void;
  deleteItemAsk: (itemAskId: string, itemId: string) => void;
  updateItemBid: (itemBidId: string, itemId: string, bidData: JSONObject) => void;
  updateItemAsk: (itemAskId: string, itemId: string, askData: JSONObject) => void;
  generateItemAskPaymentLink: (itemAskId: string, useEscrow: boolean) => void;
  acceptItemBid: (itemBidId: string, useEscrow: boolean) => void;
}

interface ItemDetailsViewModalState {
  reportResultModal: boolean;
  dataSuggestionModal: boolean;
  portfolioAdditionModal: boolean;
  dataItemSuggestionModal: ItemDataSuggestionFormOption | undefined;
  flagItem: boolean;
  itemBid: boolean;
  itemAsk: boolean;
  removeItemBid: ItemBidModel | undefined;
  removeItemAsk: ItemAskModel | undefined;
  editItemBid: ItemBidModel | undefined;
  editItemAsk: ItemAskModel | undefined;
  contactItemBid: ItemBidModel | undefined;
  contactItemAsk: ItemAskModel | undefined;
  sellItem: ItemBidModel | undefined;
  buyItem: ItemAskModel | undefined;
}

interface ItemDetailsViewDrawerState {
  marketInfoGuide: boolean;
}

interface ItemDetailsViewMarketplaceState {
  selectedAskForPayment: string | undefined;
}

interface ItemDetailsViewState {
  fetchedAdditionalData: {
    suggestions: boolean,
    artist: boolean,
    listings: boolean
  },
  modals: ItemDetailsViewModalState;
  drawers: ItemDetailsViewDrawerState;
  marketplace: ItemDetailsViewMarketplaceState;
  pendingQueryParams: JSONObject | null;
}

export enum ItemDetailsSizingConfig {
  SECTION_SPACING = '40px',
  CONTENT_SPACING = '20px',
  MARKET_BUTTON_HEIGHT = '48px',
  MARKET_BUTTON_WIDTH = '40%',
  ITEM_IMAGE_MAX_HEIGHT = '480px'
}

class ItemDetails extends Component<ItemDetailsViewProps, ItemDetailsViewState> {
  state = {
    fetchedAdditionalData: {
      suggestions: false,
      artist: false,
      listings: false
    },
    modals: {
      reportResultModal: false,
      dataSuggestionModal: false,
      portfolioAdditionModal: false,
      dataItemSuggestionModal: undefined,
      flagItem: false,
      itemBid: false,
      itemAsk: false,
      removeItemBid: undefined,
      removeItemAsk: undefined,
      editItemBid: undefined,
      editItemAsk: undefined,
      contactItemBid: undefined,
      contactItemAsk: undefined,
      sellItem: undefined,
      buyItem: undefined
    },
    drawers: {
      marketInfoGuide: false
    },
    pendingQueryParams: null,
    marketplace: {
      selectedAskForPayment: undefined
    }
  };

  componentDidMount() {
    const { params, location } = this.props;
    const queryParams: JSONObject = getEligibleQueryParamsFromUrl(location, [QuerySearchParameters.BID_ID, QuerySearchParameters.ASK_ID, QuerySearchParameters.ACTION]);
    if (params && params.id) {
      this.props.getItem(params.id);
    }
    if (queryParams) {
      this.setState({ pendingQueryParams: queryParams });
    }
  }

  componentDidUpdate(prevProps: ItemDetailsViewProps) {
    const { item, user, params } = this.props;

    if (params.id && this.hasRouteChanged(prevProps)) {
      this.props.getItem(params.id);
      this.setState({ fetchedAdditionalData: { suggestions: false, artist: false, listings: false } });
    }

    if (!this.state.fetchedAdditionalData['suggestions']) {
      this.props.getItemSuggestions(params.id || item.id);
      this.setState({
        fetchedAdditionalData: {
          ...this.state.fetchedAdditionalData,
          suggestions: true
        }
      });
    }

    if (user.isAuthenticated()) {
      if (!this.state.fetchedAdditionalData['artist']) {
        this.props.getItemArtist(params.id || item.id);
        this.setState({
          fetchedAdditionalData: {
            ...this.state.fetchedAdditionalData,
            artist: true
          }
        });
      }

      if (!this.state.fetchedAdditionalData['listings']) {
        this.props.getItemListings(params.id || item.id);
        this.setState({
          fetchedAdditionalData: {
            ...this.state.fetchedAdditionalData,
            listings: true
          }
        });
      }
    }

    if (this.state.pendingQueryParams && !prevProps.item.hasActiveMarketData() && item.hasActiveMarketData()) {
      this.processActiveMarketQueryParams();
    }

    if (this.state.marketplace.selectedAskForPayment) {
      const { selectedAskForPayment } = this.state.marketplace;
      const currentAsk = this.props.item.activeMarket.asks.find(ask => ask.id === selectedAskForPayment);
      if (currentAsk && currentAsk.paymentLink && 
        (!prevProps.item.activeMarket.asks.find(ask => ask.id === selectedAskForPayment)?.paymentLink)) {
          window.open(currentAsk.paymentLink, '_blank');
          this.setState({ marketplace: {
            selectedAskForPayment: undefined
          }});
      }
    }
  }

  processActiveMarketQueryParams() {
    const { pendingQueryParams } = this.state;
    const { item } = this.props;

    if (!pendingQueryParams) return;

    const { bidId, askId, action } = pendingQueryParams;

    const matchingBid = item.getBids().find((bid) => bid.id === bidId);
    const matchingAsk = item.getAsks().find((ask) => ask.id === askId);

    if (matchingBid && action === 'Edit') {
      this.onEditItemBidModalOpen(matchingBid);
    } else if (matchingBid && action === 'Remove') {
      this.onRemoveItemBidModalOpen(matchingBid);
    } else if (matchingBid && action === 'Contact') {
      this.onContactItemBidModalOpen(matchingBid);
    } else if (matchingBid && action === 'Sell') {
      this.onSellItemModelOpen(matchingBid);
    } else if (matchingAsk && action === 'Edit') {
      this.onEditItemAskModalOpen(matchingAsk);
    } else if (matchingAsk && action === 'Remove') {
      this.onRemoveItemAskModalOpen(matchingAsk);
    } else if (matchingAsk && action === 'Contact') {
      this.onContactItemAskModalOpen(matchingAsk);
    } else if (matchingAsk && action === 'Buy') {
      this.onBuyItemModelOpen(matchingAsk);
    }

    this.setState({ pendingQueryParams: null });
  }

  hasRouteChanged = (prevProps: ItemDetailsViewProps): boolean => {
    const { params } = this.props;
    return !!params.id && (params.id !== prevProps.params.id);
  }

  getPortfolioData = (): PortfolioModel | null => {
    const { portfolio, user } = this.props;
    if (portfolio && portfolio.id) {
      return portfolio;
    } else if (user && user.hasFetchedPortfolio()) {
      return user.portfolio;
    }
    return null;
  }

  getWishlistData = (): WishlistModel | null => {
    const { wishlist, user } = this.props;
    if (wishlist && wishlist.id) {
      return wishlist;
    } else if (user && user.hasFetchedWishlist()) {
      return user.wishlist;
    }
    return null;
  }

  onUnauthorizedAccessForMarketplaceClick = () => {
    const { navigate } = this.props;
    navigate(NavigationService.getUserProfilePath());
  }

  onItemArtistClick = () => {
    const { item, navigate } = this.props;
    navigate(
      NavigationService.getArtistDetailsPath(item.artistId, item.getArtistName())
    );
  }

  onListingArtistClick = (artistId: string, artistName: string) => {
    const { navigate } = this.props;
    navigate(
      NavigationService.getArtistDetailsPath(artistId, artistName)
    );
  }

  onItemSuggestionGridClick = (item: ItemModel) => {
    this.props.navigate(NavigationService.getItemDetailsPath(item.id, item.getDisplayName()));
  };

  onLockedContentLoginClick = () => {
    this.props.navigate(NavigationService.getAuthLoginPath());
  };

  getPortfolioItemManagementFormData = (): PortfolioItemManagementFormData | undefined => {
    const { item } = this.props;
    const portfolio = this.getPortfolioData();
    const purchaseData = portfolio?.getPortfolioItemPurchaseInfo(item.id) || [];
    if (purchaseData.length) {
      return {
        numPurchases: purchaseData.length || 1,
        purchaseInfo: purchaseData.map(purchase => ({
          price: purchase.price,
          quantity: purchase.quantity,
          date: purchase.date.format('YYYY-MM-DD')
        }))
      }
    } else {
      return undefined;
    }
  }


  getSuggestionFormData = (): ItemDataSuggestionFormData => {
    const { item } = this.props;
    return {
      releasePrice: item.getReleaseConvertedPrice() || undefined,
      publisher: item.publisher,
      material: item.material,
      materialIds: item.getMaterialIds() || [],
      edition: item.getEditionSize() || undefined,
      date: item.getReleaseDate()?.format('YYYY-MM-DD'),
      dimensions: item.dimensions || undefined
    };
  }

  getSuggestionFormDisabledSettings = (): ItemDataSuggestionFormOption[] => {
    const { item } = this.props;
    const settings: ItemDataSuggestionFormOption[] = [];
    if (!!item.getReleaseConvertedPrice()) {
      settings.push('releasePrice');
    }

    if (!!item.publisher) {
      settings.push('publisher');
    }

    if (!!item.materialIds.length) {
      settings.push('materialIds');
    }

    if (!!item.getEditionSize()) {
      settings.push('edition');
    }

    if (!!item.getReleaseDate()) {
      settings.push('date');
    }

    if (!!item.measurements) {
      settings.push('dimensions');
    }

    return settings;
  }

  getItemSuggestionsData = (): ItemModel[] => {
    const { itemSuggestions } = this.props;
    return itemSuggestions.slice(0, 4);
  }

  getItemMaterialNames = (): string => {
    const { item, materialList } = this.props;
    const itemMaterialIds = item.getMaterialIds();
    const itemMaterialNames = materialList.filter(material => itemMaterialIds.includes(material.id)).map(material => material.name);
    return itemMaterialNames.join(', ')
  }

  onReportResultModalOpen = () => {
    const { user, navigate } = this.props;
    useAuthCheck(user, navigate, () => {
      this.setState({
        modals: {
          ...this.state.modals,
          reportResultModal: true
        }
      });
    });
  }

  onContactItemBidModalOpen = (bid: ItemBidModel) => {
    const { user, navigate } = this.props;
    useAuthCheck(user, navigate, () => {
      this.setState({
        modals: {
          ...this.state.modals,
          contactItemBid: bid
        }
      });
    });
  }

  onSellItemModelOpen = (bid: ItemBidModel) => {
    const { user, navigate } = this.props;
    useAuthCheck(user, navigate, () => {
      this.setState({
        modals: {
          ...this.state.modals,
          sellItem: bid
        }
      });
    });
  }

  onBuyItemModelOpen = (ask: ItemAskModel) => {
    const { user, navigate } = this.props;
    useAuthCheck(user, navigate, () => {
      this.setState({
        modals: {
          ...this.state.modals,
          buyItem: ask
        }
      });
    });
  }

  onContactItemAskModalOpen = (ask: ItemAskModel) => {
    const { user, navigate } = this.props;
    useAuthCheck(user, navigate, () => {
      this.setState({
        modals: {
          ...this.state.modals,
          contactItemAsk: ask
        }
      });
    });
  }

  onRemoveItemBidModalOpen = (bid: ItemBidModel) => {
    const { user, navigate } = this.props;
    useAuthCheck(user, navigate, () => {
      this.setState({
        modals: {
          ...this.state.modals,
          removeItemBid: bid
        }
      });
    });
  }

  onEditItemBidModalOpen = (bid: ItemBidModel) => {
    const { user, navigate } = this.props;
    useAuthCheck(user, navigate, () => {
      this.setState({
        modals: {
          ...this.state.modals,
          editItemBid: bid
        }
      });
    });
  }

  onBidItemModelOpen = () => {
    const { user, navigate } = this.props;
    useAuthCheck(user, navigate, () => {
      this.setState({
        modals: {
          ...this.state.modals,
          itemBid: true
        }
      });
    });
  }

  onRemoveItemAskModalOpen = (ask: ItemAskModel) => {
    const { user, navigate } = this.props;
    useAuthCheck(user, navigate, () => {
      this.setState({
        modals: {
          ...this.state.modals,
          removeItemAsk: ask
        }
      });
    });
  }

  onEditItemAskModalOpen = (ask: ItemAskModel) => {
    const { user, navigate } = this.props;
    useAuthCheck(user, navigate, () => {
      this.setState({
        modals: {
          ...this.state.modals,
          editItemAsk: ask
        }
      });
    });
  }

  onAskItemModelOpen = () => {
    const { user, navigate } = this.props;
    useAuthCheck(user, navigate, () => {
      this.setState({
        modals: {
          ...this.state.modals,
          itemAsk: true
        }
      });
    })
  }

  onMarketInfoGuideDrawerOpen = () => {
    this.setState({
      drawers: {
        ...this.state.drawers,
        marketInfoGuide: true
      }
    });
  }

  onMarketInfoGuideDrawerClose = () => {
    this.setState({
      drawers: {
        ...this.state.drawers,
        marketInfoGuide: false
      }
    });
  }

  onReportResultModalClose = () => {
    this.setState({
      modals: {
        ...this.state.modals,
        reportResultModal: false
      }
    });
  }

  onBidItemModalClose = () => {
    this.setState({
      modals: {
        ...this.state.modals,
        itemBid: false
      }
    });
  }

  onSellItemModelClose = () => {
    this.setState({
      modals: ({
        ...this.state.modals,
        sellItem: undefined
      })
    })
  }

  onBuyItemModelClose = () => {
    this.setState({
      modals: ({
        ...this.state.modals,
        buyItem: undefined
      })
    })
  }

  onRemoveItemBidModalClose = () => {
    this.setState({
      modals: {
        ...this.state.modals,
        removeItemBid: undefined
      }
    });
  }

  onEditItemBidModalClose = () => {
    this.setState({
      modals: {
        ...this.state.modals,
        editItemBid: undefined
      }
    });
  }

  onContactItemBidModalClose = () => {
    this.setState({
      modals: {
        ...this.state.modals,
        contactItemBid: undefined
      }
    })
  }

  onContactItemAskModalClose = () => {
    this.setState({
      modals: {
        ...this.state.modals,
        contactItemAsk: undefined
      }
    })
  }

  onAskItemModalClose = () => {
    this.setState({
      modals: {
        ...this.state.modals,
        itemAsk: false
      }
    });
  }

  onRemoveItemAskModalClose = () => {
    this.setState({
      modals: {
        ...this.state.modals,
        removeItemAsk: undefined
      }
    });
  }

  onEditItemAskModalClose = () => {
    this.setState({
      modals: {
        ...this.state.modals,
        editItemAsk: undefined
      }
    });
  }

  onDataSuggestionModalOpen = () => {
    const { user, navigate } = this.props;
    useAuthCheck(user, navigate, () => {
      this.setState({
        modals: {
          ...this.state.modals,
          dataSuggestionModal: true
        }
      });
    })
  }

  onDataSuggestionModalClose = () => {
    this.setState({
      modals: {
        ...this.state.modals,
        dataSuggestionModal: false
      }
    });
  }

  onFlagItemModalOpen = () => {
    const { user, navigate } = this.props;
    useAuthCheck(user, navigate, () => {
      this.setState({
        modals: {
          ...this.state.modals,
          flagItem: true
        }
      });
    });
  }

  onFlagItemModalClose = () => {
    this.setState({
      modals: {
        ...this.state.modals,
        flagItem: false
      }
    });
  }


  onPortfolioAdditionModalOpen = () => {
    const { user, navigate } = this.props;
    useAuthCheck(user, navigate, () => {
      this.setState({
        modals: {
          ...this.state.modals,
          portfolioAdditionModal: true
        }
      });
    });
  }

  onPortfolioAdditionModalClose = () => {
    this.setState({
      modals: {
        ...this.state.modals,
        portfolioAdditionModal: false
      }
    });
  }

  onDataItemSuggestionModalOpen = (dataItem: ItemDataSuggestionFormOption) => {
    const { user, navigate } = this.props;
    useAuthCheck(user, navigate, () => {
      this.setState({
        modals: {
          ...this.state.modals,
          dataItemSuggestionModal: dataItem
        }
      });
    });
  }

  onDataItemSuggestionModalClose = () => {
    this.setState({
      modals: {
        ...this.state.modals,
        dataItemSuggestionModal: undefined
      }
    });
  }

  onArtistContainerButtonClick = () => {
    const { user, item, followArtist, unfollowArtist, navigate } = this.props;
    useAuthCheck(user, navigate, () => {
      if (user.followsArtist(item.artistId)) {
        unfollowArtist(item.artistId);
      } else {
        followArtist(item.artistId);
      }
    });
  }

  onPortfolioIconButtonClick = () => {
    this.onPortfolioAdditionModalOpen();
  }

  onPortfolioManagementFormSubmit = (data: PortfolioItemManagementFormData) => {
    const { item, addItemToPortfolio, replaceItemInPortfolio } = this.props;
    const portfolio = this.getPortfolioData();
    const purchaseInfo: ItemPurchaseInfo[] = data.purchaseInfo.map((item: PortfolioItemPurchaseInfo) => ({
      date: moment(item.date),
      quantity: item.quantity,
      price: item.price
    }));

    if (portfolio?.containsItem(item.id)) {
      replaceItemInPortfolio(item.id, purchaseInfo);
    } else {
      addItemToPortfolio(item.id, purchaseInfo);
    }

    this.onPortfolioAdditionModalClose();
  }

  onItemAskPurchaseClick = (itemAsk: ItemAskModel, useEscrow: boolean) => {
    const { generateItemAskPaymentLink } = this.props;
    this.setState({ 
      marketplace: {
        ...this.state.marketplace,
        selectedAskForPayment: itemAsk.id
      }
    });
    generateItemAskPaymentLink(itemAsk.id, useEscrow);
    this.onBuyItemModelClose();
  }

  onItemSellPurchaseClick = (itemBid: ItemBidModel, useEscrow: boolean) => {
    const { acceptItemBid } = this.props;
    acceptItemBid(itemBid.id, useEscrow);
    this.onSellItemModelClose();
  }

  getItemBidWizardDefaultData = (): ItemBidFormData | undefined => {
    const { item, user } = this.props;
    const itemBid: ItemBidModel | undefined = item.getItemBidForUser(user.id);
    if (itemBid) {
      return {
        bidExpiration: itemBid.expirationDays,
        bidPrice: itemBid.amount
      }
    } else {
      return undefined;
    }
  }

  getItemAskWizardDefaultData = (): ItemAskFormData | undefined => {
    const { item, user } = this.props;
    const itemAsk: ItemAskModel | undefined = item.getItemAskForUser(user.id);
    if (itemAsk) {
      return {
        askExpiration: itemAsk.expirationDays,
        askPrice: itemAsk.amount
      }
    } else {
      return undefined;
    }
  }

  onItemBidWizardSubmit = (data: ItemBidFormData, additionalData: ItemBidAdditionalDataFormData, useEscrow?: boolean) => {
    const { item, placeItemBid } = this.props;
    const bidData: JSONObject = {
      amount: data.bidPrice || 0,
      expiration_days: data.bidExpiration || 0,
      condition: additionalData.condition || Condition.GOOD,
      notes: additionalData.notes || '',
      use_escrow: useEscrow || false
    };
    placeItemBid(item.id, bidData);
    this.onBidItemModalClose();
  }

  onItemAskWizardSubmit = (data: ItemAskFormData, additionalData: ItemAskAdditionalDataFormData, useEscrow?: boolean) => {
    const { item, placeItemAsk } = this.props;
    const askData: JSONObject = {
      amount: data.askPrice || 0,
      expiration_days: data.askExpiration || 0,
      condition: additionalData.condition || Condition.GOOD,
      notes: additionalData.notes || '',
      use_escrow: useEscrow || false
    };
    placeItemAsk(item.id, askData);
    this.onAskItemModalClose();
  }

  onRemoveItemBidFormSubmit = (itemBid: ItemBidModel) => {
    const { item, deleteItemBid } = this.props;
    deleteItemBid(itemBid.id, item.id);
    this.onRemoveItemBidModalClose();
  }

  onContactItemBidFormSubmit = (itemBid: ItemBidModel, message: string) => {
    const { item, placeItemInquiry } = this.props;
    const inquiryData: JSONObject = {
      item_id: item.id,
      entity_id: itemBid.id,
      entity_type: 'Bid',
      message: message
    };
    placeItemInquiry(item.id, inquiryData);
    this.onContactItemBidModalClose();
  }

  onContactItemAskFormSubmit = (itemAsk: ItemAskModel, message: string) => {
    const { item, placeItemInquiry } = this.props;
    const inquiryData: JSONObject = {
      item_id: item.id,
      entity_id: itemAsk.id,
      entity_type: 'Ask',
      message: message
    };
    placeItemInquiry(item.id, inquiryData);
    this.onContactItemAskModalClose();
  }

  onEditItemBidWizardSubmit = (itemBid: ItemBidModel, data?: ItemBidFormData, additionalData?: ItemBidAdditionalDataFormData, useEscrow?: boolean) => {
    const { item, updateItemBid } = this.props;
    const bidData: JSONObject = {};
    if (data?.bidPrice && data.bidPrice > 0) {
      bidData.amount = data.bidPrice;
    }
    if (data?.bidExpiration && data.bidExpiration > 0) {
      bidData.expiration_days = data.bidExpiration;
    }
    if (additionalData?.condition) {
      bidData.condition = additionalData.condition;
    }

    if (additionalData?.notes) {
      bidData.notes = additionalData.notes;
    }

    if (useEscrow) {
      bidData.use_escrow = true;
    } else if (!useEscrow && itemBid.useEscrow) {
      bidData.use_escrow = false;
    }

    updateItemBid(itemBid.id, item.id, bidData);
    this.onEditItemBidModalClose();
  }

  onEditItemAskWizardSubmit = (itemAsk: ItemAskModel, data?: ItemAskFormData, additionalData?: ItemAskAdditionalDataFormData, useEscrow?: boolean) => {
    const { item, updateItemAsk } = this.props;
    const askData: JSONObject = {};
    if (data?.askPrice && data.askPrice > 0) {
      askData.amount = data.askPrice;
    }
    if (data?.askExpiration && data.askExpiration > 0) {
      askData.expiration_days = data.askExpiration;
    }

    if (additionalData?.condition) {
      askData.condition = additionalData.condition;
    }

    if (additionalData?.notes) {
      askData.notes = additionalData.notes;
    }

    if (useEscrow) {
      askData.use_escrow = true;
    } else if (!useEscrow && itemAsk.useEscrow) {
      askData.use_escrow = false;
    }

    updateItemAsk(itemAsk.id, item.id, askData);
    this.onEditItemAskModalClose();
  }

  onRemoveItemAskFormSubmit = (itemAsk: ItemAskModel) => {
    const { item, deleteItemAsk } = this.props;
    deleteItemAsk(itemAsk.id, item.id);
    this.onRemoveItemAskModalClose();
  }

  onItemDataSuggestionFormSubmit = (data: ItemDataSuggestionFormData) => {
    const { item, submitItemDataSuggestion } = this.props;
    const snakeCasedData: JSONObject = convertJSONObjectToSnakecase(data as JSONObject);
    submitItemDataSuggestion(item.id, snakeCasedData);
    this.onDataItemSuggestionModalClose();
    this.onDataSuggestionModalClose();
  }

  onItemSaleSuggestionFormSubmit = (data: ItemSaleSuggestionFormData) => {
    const { item, submitItemSaleSuggestion } = this.props;
    submitItemSaleSuggestion(item.id, data.rawPrice, data.link, moment(data.date.getDate()), data.sourceId);
    this.onReportResultModalClose();
  }

  onWishlistIconButtonClick = () => {
    const { user, item, addItemToWishlist, removeItemFromWishlist, navigate } = this.props;
    const wishlist = this.getWishlistData();
    useAuthCheck(user, navigate, () => {
      if (wishlist?.containsItem(item.id)) {
        removeItemFromWishlist(item.id);
      } else {
        addItemToWishlist(item.id);
      }
    });
  }

  onItemContainerBidClick = () => {
    const { user, item, navigate } = this.props;
    useAuthCheck(user, navigate, () => {
      const itemBid: ItemBidModel | undefined = item.getItemBidForUser(user.id);
      if (itemBid) {
        this.onEditItemBidModalOpen(itemBid);
      } else {
        this.onBidItemModelOpen();
      }
    });
  }

  onItemContainerAskClick = () => {
    const { user, item, navigate } = this.props;
    useAuthCheck(user, navigate, () => {
      const itemAsk: ItemAskModel | undefined = item.getItemAskForUser(user.id);
      if (itemAsk) {
        this.onEditItemAskModalOpen(itemAsk);
      } else {
        this.onAskItemModelOpen();
      }
    });
  }

  renderDescription() {
    const { item } = this.props;
    if (FeatureToggleService.isToggleEnabled(FeatureToggleKeys.ITEM_DETAILS_DESCRIPTION)) {
      return (
        <Box marginTop={ItemDetailsSizingConfig.SECTION_SPACING}>
          <InfoAccordion header={'DESCRIPTION'} content={item.description} />
        </Box>
      );
    }
  }

  renderItemInfoSlider() {
    const { item, itemLoading } = this.props;

    const content = [
      { label: 'Size', value: item.getDimensionsForDisplay(), icon: BiRuler, onFallbackContentClick: () => this.onDataItemSuggestionModalOpen('dimensions') },
      { label: 'Edition Size', value: item.edition, icon: FaRegClone, onFallbackContentClick: () => this.onDataItemSuggestionModalOpen('edition') },
      { label: 'Release Date', value: item.date?.format('MM/DD/YYYY'), icon: BiCalendar, onFallbackContentClick: () => this.onDataItemSuggestionModalOpen('date') },
      { label: 'Retail Price', value: item.releasePrice, icon: BiDollar, onFallbackContentClick: () => this.onDataItemSuggestionModalOpen('releasePrice') },
      { label: 'Publisher', value: item.publisher, icon: AiFillPrinter, onFallbackContentClick: () => this.onDataItemSuggestionModalOpen('publisher') },
      { label: 'Material', value: this.getItemMaterialNames(), icon: GiMaterialsScience, onFallbackContentClick: () => this.onDataItemSuggestionModalOpen('materialIds') },
    ].map((block, index: number) => (
      <ItemInfoBlock key={`item_info_block_${index}`} label={block.label} value={block.value} icon={block.icon} fallbackIcon={AiOutlinePlusCircle} onFallbackContentClick={block.onFallbackContentClick} isLoading={itemLoading} />
    ));

    return (
      <Box marginTop={ItemDetailsSizingConfig.SECTION_SPACING}>
        <Slider content={content} slidesPerView={4} />
      </Box>
    );
  }

  renderArtistBanner() {
    const { item } = this.props;
    const artist: ArtistModel | null = item.getArtist();
    if (artist && FeatureToggleService.isToggleEnabled(FeatureToggleKeys.ITEM_DETAILS_ARTIST_BANNER)) {
      return (
        <Box marginTop={ItemDetailsSizingConfig.SECTION_SPACING}>
          <ArtistBanner artist={artist} />
        </Box>
      );
    }
  }

  renderContainer() {
    const { item, artistList, itemLoading, user, userLoading, userPortfolioLoading, portfolioLoading, portfolioActionLoading, userWishlistLoading, wishlistLoading, wishlistActionLoading, userFollowingArtistsLoading, userArtistActionLoading, itemActiveMarketLoading, itemActiveMarketActionLoading, marketplaceActionLoading } = this.props;
    const portfolio = this.getPortfolioData();
    const wishlist = this.getWishlistData();
    const itemArtist = item.getArtist();
    const itemArtistFromList = artistList.find(artist => artist.id === item.artistId);


    const content = itemLoading ? (
      <ItemContainerSkeleton />
    ) : (
      <ItemContainer
        item={item}
        artist={itemArtist || itemArtistFromList}
        user={user}
        isFollowingArtist={user.followsArtist(item.artistId)}
        portfolioButtonLoading={userLoading || userPortfolioLoading || portfolioLoading || portfolioActionLoading}
        wishlistButtonLoading={userLoading || userWishlistLoading || wishlistLoading || wishlistActionLoading}
        followArtistLoading={userLoading || userFollowingArtistsLoading || userArtistActionLoading}
        marketButtonsLoading={userLoading || itemActiveMarketLoading || itemActiveMarketActionLoading}
        marketplaceActionLoading={userLoading || marketplaceActionLoading}
        portfolioContainsItem={portfolio?.containsItem(item.id) || false}
        wishlistContainsItem={wishlist?.containsItem(item.id) || false}
        onPortfolioClick={this.onPortfolioIconButtonClick}
        onWishlistClick={this.onWishlistIconButtonClick}
        onEditClick={this.onDataSuggestionModalOpen}
        onFlagClick={this.onFlagItemModalOpen}
        onFollowArtistClick={this.onArtistContainerButtonClick}
        onArtistClick={this.onItemArtistClick}
        onItemBidClick={this.onItemContainerBidClick}
        onItemAskClick={this.onItemContainerAskClick}
        onMarketInfoGuideClick={this.onMarketInfoGuideDrawerOpen}
        onSellClick={this.onSellItemModelOpen}
        onBuyClick={this.onBuyItemModelOpen}
        authenticated={user.isAuthenticated()}
      />
    );

    return (
      <Box padding={{ base: "10px", md: "0px" }}>
        {content}
      </Box>
    )
  }

  renderSoldListings() {
    const { item, itemListingsLoading, user } = this.props;
    if (user.isAuthenticated()) {
      if (item.hasSoldListings()) {
        return (
          <Stack marginTop={ItemDetailsSizingConfig.SECTION_SPACING} gap={4}>
            <InfoGuide text={'Note: We Only Show Publicly Available, Verifiable Auction House Sales Data.'} dismissable={true} />
            <ListingGrid gridListings={item.getSoldListings()} isLoading={itemListingsLoading} showFilters={true} onListingArtistClick={this.onListingArtistClick} />
          </Stack>
        );
      } else {
        return (
          <EmptyState header="No Recorded Sales" description="This Item Has No Recorded Sales" buttonText="Report Sale" showButton={true} onButtonClick={this.onReportResultModalOpen} />
        );
      }
    } else {
      return (
        <LockedContent description={`Please Log In To View Past Sales`} onButtonClick={this.onLockedContentLoginClick} />
      );
    }

  }

  renderMarketOverview() {
    const { user } = this.props;
    if (user.isAuthenticated()) {
      return (
        <Box padding={{ base: "10px", md: "0px" }}>
          {this.renderMarketStats()}
          {this.renderCharts()}
        </Box>
      );
    } else {
      return (
        <LockedContent description={`Please Log In To View Market Data`} onButtonClick={this.onLockedContentLoginClick} />
      );
    }
  }

  renderCharts() {
    const { item, itemLoading, itemMarketLoading } = this.props;
    const market = item.getMarket();
    if (itemLoading || itemMarketLoading) {
      return <Loader />
    } else if (market && item.hasMarketData()) {
      const content = [
        <MarketPriceChart market={market} listings={item.getSoldListings()} startingPrice={item.hasReleasePriceData() ? item.getReleaseConvertedPrice() : null} />,
        <MarketVolumeChart market={market} listings={item.getSoldListings()} />
      ];
      return (
        <SimpleGrid columns={[1, 2, 2]} spacing={ItemDetailsSizingConfig.CONTENT_SPACING} marginTop={ItemDetailsSizingConfig.SECTION_SPACING}>
          {content}
        </SimpleGrid>
      );
    } else {
      return (
        <EmptyState header="No Market Data" description="This Item Has No Recorded Sales" buttonText="Report Sale" showButton={true} onButtonClick={this.onReportResultModalOpen} />
      );
    }
  }

  renderMarketStats() {
    const { item } = this.props;
    const market = item.getMarket();
    if (market && item.hasMarketData()) {
      const content = [
        { title: 'Total Listings', stat: `${market.overview.totalListings}`, icon: <IoMdStats size={'2em'} /> },
        { title: 'Mean Price', stat: item.getOverviewMeanPriceForDisplay(), icon: <RiScalesLine size={'2em'} /> },
        { title: 'Max Price', stat: item.getOverviewMaxPriceForDisplay(), icon: <AiOutlineVerticalAlignTop size={'2em'} /> },
        { title: 'Growth', stat: item.getOverviewGrowthForDisplay(), icon: <AiOutlineLineChart size={'2em'} /> },
      ].map((block, index: number) => (
        <StatsCard key={`item_market_statcard_${index}`} title={block.title} stat={block.stat} icon={block.icon} />
      ));

      return (
        <SimpleGrid columns={[2, 2, 4]} spacing={ItemDetailsSizingConfig.CONTENT_SPACING}>
          {content}
        </SimpleGrid>
      );
    }
  }

  renderTabs() {
    const labels = [
      <Flex gap="4px" alignItems="center" direction={{ base: 'column', md: 'row' }}>
        <Icon as={ImStatsDots} />
        <Text> Past Market </Text>
      </Flex>,
      <Flex gap="4px" alignItems="center" direction={{ base: 'column', md: 'row' }}>
        <Icon as={RiAuctionLine} />
        <Text> Past Sales </Text>
      </Flex>,
      <Flex gap="4px" alignItems="center" direction={{ base: 'column', md: 'row' }}>
        <Icon as={IoStorefrontOutline} />
        <Text> Trading Floor </Text>
      </Flex>,
      <Flex gap="4px" alignItems="center" direction={{ base: 'column', md: 'row' }}>
        <Icon as={AiOutlinePlusCircle} />
        <Text> Report Sale </Text>
      </Flex>

    ];
    const content = [
      this.renderMarketOverview(),
      this.renderSoldListings(),
      this.renderItemTradingFloor(),
      this.renderReportSaleContent(),
    ];

    return (
      <TabGroup labels={labels} content={content} />
    );
  }

  renderReportSaleContent() {
    const { item, user, salesVenueList, itemSaleSuggestionLoading } = this.props;
    if (user.isAuthenticated()) {
      return (
        <Box padding={{ base: "10px", md: "0px" }}>
          <ItemSaleSuggestionForm item={item} salesVenues={salesVenueList} onSubmit={this.onItemSaleSuggestionFormSubmit} submitLoading={itemSaleSuggestionLoading} />
        </Box>
      );
    } else {
      return (
        <LockedContent description={`Please Log In To Report A Sale`} onButtonClick={this.onLockedContentLoginClick} />
      );
    }
  }

  renderItemTradingFloor() {
    const { item, user, itemLoading, itemActiveMarketLoading, itemActiveMarketActionLoading } = this.props;
    if (user.isAuthenticated()) {
      return (
        <Box padding={{ base: "10px", md: "0px" }}>
          <Stack spacing={8}>
            <BasicItemCard item={item} />
            <TradingFloor
              asks={item.activeMarket.asks}
              bids={item.activeMarket.bids}
              user={user}
              showSearchFilter={false}
              onItemArtistNameClick={this.onItemArtistClick}
              isLoading={itemLoading || itemActiveMarketLoading || itemActiveMarketActionLoading}
              onRemoveBidClick={this.onRemoveItemBidModalOpen}
              onRemoveAskClick={this.onRemoveItemAskModalOpen}
              onEditBidClick={this.onEditItemBidModalOpen}
              onEditAskClick={this.onEditItemAskModalOpen}
              onContactBidClick={this.onContactItemBidModalOpen}
              onContactAskClick={this.onContactItemAskModalOpen}
            />
          </Stack>
        </Box>
      );
    } else {
      return (
        <LockedContent description={`Please Log In To View Trading Floor`} onButtonClick={this.onLockedContentLoginClick} />
      );
    }
  }

  renderFlagItemModalContent() {
    const { item } = this.props;

    return (
      <ItemFlagWizard item={item} />
    );
  }

  renderItemBidModalContent() {
    const { item } = this.props;

    return (
      <ItemBidWizard item={item} onSubmit={this.onItemBidWizardSubmit} defaultBid={this.getItemBidWizardDefaultData()} />
    );
  }

  renderItemAskModalContent() {
    const { item, user } = this.props;

    return (
      <ItemAskWizard user={user} item={item} onSubmit={this.onItemAskWizardSubmit} defaultAsk={this.getItemAskWizardDefaultData()} onUnauthorizedClick={this.onUnauthorizedAccessForMarketplaceClick} />
    );
  }

  renderContactItemBidModalContent() {
    const { item } = this.props;
    const { modals } = this.state;
    if (modals.contactItemBid) {
      return (
        <ItemBidContactForm item={item} itemBid={modals.contactItemBid} onSubmit={this.onContactItemBidFormSubmit} />
      );
    } else {
      return <Box />;
    }
  }

  renderContactItemAskModalContent() {
    const { item } = this.props;
    const { modals } = this.state;
    if (modals.contactItemAsk) {
      return (
        <ItemAskContactForm item={item} itemAsk={modals.contactItemAsk} onSubmit={this.onContactItemAskFormSubmit} />
      );
    } else {
      return <Box />;
    }
  }

  renderEditItemBidModalContent() {
    const { item, user } = this.props;
    const { modals } = this.state;
    if (modals.editItemBid) {
      return (
        <ItemBidEditWizard item={item} user={user} itemBid={modals.editItemBid} onSubmit={this.onEditItemBidWizardSubmit} />
      );
    } else {
      return <Box />;
    }
  }

  renderSellItemModalContent() {
    const { item } = this.props;
    const { modals } = this.state;
    if (modals.sellItem) {
      return (
        <ItemSellWizard item={item} itemBid={modals.sellItem} onSubmit={this.onItemSellPurchaseClick} onInfoGuideClick={this.onMarketInfoGuideDrawerOpen}/>
      );
    } else {
      return <Box />;
    }
  }

  renderBuyItemModalContent() {
    const { item } = this.props;
    const { modals } = this.state;
    if (modals.buyItem) {
      return (
        <ItemBuyWizard item={item} itemAsk={modals.buyItem} onSubmit={this.onItemAskPurchaseClick} onInfoGuideClick={this.onMarketInfoGuideDrawerOpen}/>
      );
    } else {
      return <Box />;
    }
  }

  renderEditItemAskModalContent() {
    const { item, user } = this.props;
    const { modals } = this.state;
    if (modals.editItemAsk) {
      return (
        <ItemAskEditWizard user={user} item={item} itemAsk={modals.editItemAsk} onSubmit={this.onEditItemAskWizardSubmit} />
      );
    } else {
      return <Box />;
    }
  }

  renderRemoveItemBidModalContent() {
    const { item, user, itemActiveMarketLoading, itemActiveMarketActionLoading } = this.props;
    const { modals } = this.state;
    if (modals.removeItemBid) {
      return (
        <ItemBidRemovalForm user={user} item={item} bid={modals.removeItemBid} onSubmit={this.onRemoveItemBidFormSubmit} isLoading={itemActiveMarketLoading || itemActiveMarketActionLoading} />
      );
    } else {
      return <Box />;
    }
  }

  renderRemoveItemAskModalContent() {
    const { item, user, itemActiveMarketLoading, itemActiveMarketActionLoading } = this.props;
    const { modals } = this.state;
    if (modals.removeItemAsk) {
      return (
        <ItemAskRemovalForm user={user} item={item} ask={modals.removeItemAsk} onSubmit={this.onRemoveItemAskFormSubmit} isLoading={itemActiveMarketLoading || itemActiveMarketActionLoading} />
      );
    } else {
      return <Box />;
    }
  }


  renderReportModalContent() {
    const { item, salesVenueList, itemSaleSuggestionLoading } = this.props;
    return (
      <ItemSaleSuggestionForm item={item} salesVenues={salesVenueList} onSubmit={this.onItemSaleSuggestionFormSubmit} submitLoading={itemSaleSuggestionLoading} />
    );
  }

  renderDataSuggestionModalContent() {
    const { item, materialList, itemDataSuggestionLoading } = this.props;
    return (
      <ItemDataSuggestionForm materialList={materialList} item={item} defaultFormData={this.getSuggestionFormData()} disabledOptions={this.getSuggestionFormDisabledSettings()} onSubmit={this.onItemDataSuggestionFormSubmit} submitLoading={itemDataSuggestionLoading} />
    );
  }

  renderPortfolioAdditionModalContent() {
    const { item, portfolioActionLoading } = this.props;
    return (
      <PortfolioItemManagementForm item={item} defaultFormData={this.getPortfolioItemManagementFormData()} onSubmit={this.onPortfolioManagementFormSubmit} submitLoading={portfolioActionLoading} />
    );
  }

  renderDataItemSuggestionModalContent() {
    const { item, materialList, itemDataSuggestionLoading } = this.props;
    const { modals } = this.state;
    if (modals.dataItemSuggestionModal) {
      return (
        <ItemDataSuggestionForm materialList={materialList} item={item} displayOptions={[modals.dataItemSuggestionModal]} requiredOptions={[modals.dataItemSuggestionModal]} onSubmit={this.onItemDataSuggestionFormSubmit} submitLoading={itemDataSuggestionLoading} />
      );
    } else {
      return <Box />;
    }
  }

  renderMarketInfoGuideDrawerContent() {
    const { item } = this.props;
    const labels = [
      <Flex gap="4px" alignItems="center" direction={{ base: 'column', md: 'row' }}>
        <Icon as={GiThorHammer} />
        <Text> Buying </Text>
      </Flex>,
      <Flex gap="4px" alignItems="center" direction={{ base: 'column', md: 'row' }}>
        <Icon as={FaSign} />
        <Text> Selling </Text>
      </Flex>
    ];

    const content = [
      <ItemBidGuideWizard item={item} />,
      <ItemAskGuideWizard item={item} />
    ];

    return (
      <Stack>
        <GuidanceCard
          header={`Buying & Selling on ${AppConfig.APP_NAME} is Easy!`}
          text={"We've simplified the process of buying & selling art into 3 easy steps! Our sales team will work with you to manage all negotiations and no sale or purchase will ever be made without your full explicit constent."} />
        <TabGroup labels={labels} content={content} />
      </Stack>
    );
  }

  renderItemSuggestionGrid() {
    const { itemSuggestionLoading, itemSuggestions } = this.props;
    const content = itemSuggestionLoading ? (
      <ItemGridSkeleton rows={1} gridDisplay={false} />
    ) : (
      <Slider content={
        itemSuggestions.map(
          (item: ItemModel, index: number) =>
            <ItemCard onClick={() => this.onItemSuggestionGridClick(item)} key={`item_suggestions_card_${index}`} item={item} clickOnImage clickOnName />
        )
      } slidesPerView={4} slidesPerMobileView={2} />
    );

    return (
      <Flex direction="column" gap={4} textAlign={'left'} marginY={8}>
        <Heading fontSize={{ base: 'xl', md: '2xl' }} padding={{ base: 3, md: 0 }}> Related Works </Heading>
        {content}
      </Flex>
    );
  }

  renderModals() {
    const { item } = this.props;
    const { modals } = this.state;
    const portfolio = this.getPortfolioData();
    const portfolioModalTitle = portfolio?.containsItem(item.id) ? 'Update Portfolio' : 'Add to Portfolio';
    return (
      <Stack>
        <Modal title={'Report Sale'} isOpen={modals.reportResultModal} onClose={this.onReportResultModalClose} content={this.renderReportModalContent()} showCloseButton={false} showSubmitButton={false} />
        <Modal title={'Suggest Data'} isOpen={modals.dataSuggestionModal} onClose={this.onDataSuggestionModalClose} content={this.renderDataSuggestionModalContent()} showCloseButton={false} showSubmitButton={false} />
        <Modal title={'Suggest Data'} isOpen={!!modals.dataItemSuggestionModal} onClose={this.onDataItemSuggestionModalClose} content={this.renderDataItemSuggestionModalContent()} showCloseButton={false} showSubmitButton={false} />
        <Modal title={portfolioModalTitle} isOpen={!!modals.portfolioAdditionModal} onClose={this.onPortfolioAdditionModalClose} content={this.renderPortfolioAdditionModalContent()} showCloseButton={false} showSubmitButton={false} />
        <Modal title={'Flag item'} isOpen={!!modals.flagItem} onClose={this.onFlagItemModalClose} content={this.renderFlagItemModalContent()} showCloseButton={false} showSubmitButton={false} />
        <Modal title={'Place Bid'} isOpen={!!modals.itemBid} onClose={this.onBidItemModalClose} content={this.renderItemBidModalContent()} showCloseButton={false} showSubmitButton={false} />
        <Modal title={'Place Ask'} isOpen={!!modals.itemAsk} onClose={this.onAskItemModalClose} content={this.renderItemAskModalContent()} showCloseButton={false} showSubmitButton={false} />
        <Modal title={'Remove Bid'} isOpen={!!modals.removeItemBid} onClose={this.onRemoveItemBidModalClose} content={this.renderRemoveItemBidModalContent()} showCloseButton={false} showSubmitButton={false} />
        <Modal title={'Remove Ask'} isOpen={!!modals.removeItemAsk} onClose={this.onRemoveItemAskModalClose} content={this.renderRemoveItemAskModalContent()} showCloseButton={false} showSubmitButton={false} />
        <Modal title={'Edit Bid'} isOpen={!!modals.editItemBid} onClose={this.onEditItemBidModalClose} content={this.renderEditItemBidModalContent()} showCloseButton={false} showSubmitButton={false} />
        <Modal title={'Edit Ask'} isOpen={!!modals.editItemAsk} onClose={this.onEditItemAskModalClose} content={this.renderEditItemAskModalContent()} showCloseButton={false} showSubmitButton={false} />
        <Modal title={'Submit Inqury'} isOpen={!!modals.contactItemBid} onClose={this.onContactItemBidModalClose} content={this.renderContactItemBidModalContent()} showCloseButton={false} showSubmitButton={false} />
        <Modal title={'Submit Inqury'} isOpen={!!modals.contactItemAsk} onClose={this.onContactItemAskModalClose} content={this.renderContactItemAskModalContent()} showCloseButton={false} showSubmitButton={false} />
        <Modal title={'Sell Item'} isOpen={!!modals.sellItem} onClose={this.onSellItemModelClose} content={this.renderSellItemModalContent()} showCloseButton={false} showSubmitButton={false} />
        <Modal title={'Buy Item'} isOpen={!!modals.buyItem} onClose={this.onBuyItemModelClose} content={this.renderBuyItemModalContent()} showCloseButton={false} showSubmitButton={false} />
      </Stack>
    );
  }

  renderDrawers() {
    const { drawers } = this.state;
    return (
      <Stack>
        <Drawer title={'Learn About Buying & Selling'} isOpen={drawers.marketInfoGuide} onClose={this.onMarketInfoGuideDrawerClose} content={this.renderMarketInfoGuideDrawerContent()} />
      </Stack>
    )
  }

  render() {
    const { item } = this.props;
    return (
      <>
        <SEOHelmet
          title={generateSEOTitle(item.getDisplayName())}
          description={`Discover realtime market insights and other details about ${item.getDisplayName()}.`}
          link={generateItemSEOLink(item)}
          structuredData={createItemStructuredData(item)}
        />
        <Box maxWidth={`${AppConstants.GRIDPAGE_WIDTH}px`} minWidth={{ base: '100%', md: `${AppConstants.GRIDPAGE_WIDTH}px` }} paddingTop={['80px', '100px', '100px']} justifySelf="center">
          {this.renderContainer()}
          {this.renderDescription()}
          {this.renderItemInfoSlider()}
          {this.renderItemSuggestionGrid()}
          {this.renderTabs()}
          {this.renderArtistBanner()}
          {this.renderModals()}
          {this.renderDrawers()}
        </Box>
      </>
    );
  }
}

function mapStateToProps(state: ApplicationState) {
  return {
    item: ItemSelectors.getItem(state),
    user: UserSelectors.getUser(state),
    userLoading: UserSelectors.getUserLoading(state) || AuthSelectors.getUserLoading(state),
    userPortfolioLoading: UserSelectors.getUserPortfolioLoading(state),
    userWishlistLoading: UserSelectors.getUserWishlistLoading(state),
    itemListingsLoading: ItemSelectors.getItemListingsLoading(state),
    itemMarketLoading: ItemSelectors.getItemMarketLoading(state),
    itemActiveMarketLoading: ItemSelectors.getItemActiveMarketLoading(state),
    itemActiveMarketActionLoading: ItemSelectors.getItemActiveMarketActionLoading(state),
    itemLoading: ItemSelectors.getItemLoading(state),
    itemDataSuggestionLoading: ItemDataSuggestionSelectors.getItemDataSuggestionCreateLoading(state),
    itemSaleSuggestionLoading: ItemSaleSuggestionSelectors.getItemSaleSuggestionCreateLoading(state),
    userArtistActionLoading: UserSelectors.getUserArtistActionLoading(state),
    userFollowingArtistsLoading: UserSelectors.getUserFollowingArtistsLoading(state),
    itemSuggestionLoading: ItemSelectors.getItemsLoading(state),
    itemSuggestions: ItemSelectors.getItems(state),
    materialList: MaterialSelectors.getMaterialList(state),
    artistList: ArtistSelectors.getArtistList(state),
    salesVenueList: SalesVenueSelectors.getSalesVenueList(state),
    portfolio: PortfolioSelectors.getPortfolio(state),
    portfolioLoading: PortfolioSelectors.getPortfolioLoading(state),
    portfolioActionLoading: PortfolioSelectors.getPortfolioActionLoading(state),
    wishlist: WishlistSelectors.getWishlist(state),
    wishlistLoading: WishlistSelectors.getWishlistLoading(state),
    wishlistActionLoading: WishlistSelectors.getWishlistActionLoading(state),
    marketplaceActionLoading: ItemSelectors.getItemMarketplaceActionLoading(state)
  }
}

function mapDispatchToProps(dispatch: Dispatch<ApplicationState>) {
  return bindActionCreators(
    {
      getItem: (id: string) => ItemActions.getItemById(id),
      getItemArtist: (id: string) => ItemActions.getItemArtist(id),
      getItemListings: (id: string) => ItemActions.getItemListings(id),
      addItemToPortfolio: (itemId: string, purchaseInfo: ItemPurchaseInfo[]) => PortfolioActions.addItemToPortfolio(itemId, purchaseInfo),
      replaceItemInPortfolio: (itemId: string, purchaseInfo: ItemPurchaseInfo[]) => PortfolioActions.replaceItemInPortfolio(itemId, purchaseInfo),
      addItemToWishlist: (itemId: string) => WishlistActions.addItemToWishlist(itemId),
      removeItemFromWishlist: (itemId: string) => WishlistActions.removeItemFromWishlist(itemId),
      followArtist: (id: string) => UserActions.followArtist(id),
      unfollowArtist: (id: string) => UserActions.unfollowArtist(id),
      getItemSuggestions: (id: string) => ItemActions.getItemSuggestions(id),
      submitItemDataSuggestion: (itemId: string, data: JSONObject) => ItemDataSuggestionActions.createItemDataSuggestion(itemId, data),
      submitItemSaleSuggestion: (itemId: string, price: number, link: string, date: Moment, sourceId: string) => ItemSaleSuggestionActions.createItemSaleSuggestion(itemId, price, link, date, sourceId),
      placeItemBid: (itemId: string, bidData: JSONObject) => ItemActions.placeItemBid(itemId, bidData),
      placeItemAsk: (itemId: string, askData: JSONObject) => ItemActions.placeItemAsk(itemId, askData),
      deleteItemBid: (itemBidId: string, itemId: string) => ItemActions.deleteItemBid(itemBidId, itemId),
      deleteItemAsk: (itemAskId: string, itemId: string) => ItemActions.deleteItemAsk(itemAskId, itemId),
      updateItemBid: (itemBidId: string, itemId: string, bidData: JSONObject) => ItemActions.updateItemBid(itemBidId, itemId, bidData),
      updateItemAsk: (itemAskId: string, itemId: string, askData: JSONObject) => ItemActions.updateItemAsk(itemAskId, itemId, askData),
      placeItemInquiry: (itemId: string, inquiryData: JSONObject) => ItemActions.placeItemInquiry(itemId, inquiryData),
      generateItemAskPaymentLink: (itemAskId: string, useEscrow: boolean) => ItemActions.generateItemAskPaymentLink(itemAskId, useEscrow),
      acceptItemBid: (itemBidId: string, useEscrow: boolean) => ItemActions.acceptItemBid(itemBidId, useEscrow)
    },
    dispatch
  );
}

export const ItemDetailsView = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ItemDetails));
