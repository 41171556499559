import { useForm } from 'react-hook-form';
import { Stack, Flex, Heading, Textarea } from '@chakra-ui/react';
import BasicItemCard from '../../Item/BasicItemCard/BasicItemCard';
import ItemAskCard from '../../Item/ItemAskCard/ItemAskCard';
import ActionButton from '../../Button/ActionButton';
import { ItemModel, ItemAskModel } from 'models';

export interface ItemAskContactFormProps {
  item: ItemModel;
  itemAsk: ItemAskModel;
  onSubmit: (itemAsk: ItemAskModel, message: string) => void;
  isLoading?: boolean;
}

export interface ItemAskContactFormData {
	message: string;
}


export default function ItemAskContactForm({
  item,
  itemAsk,
  onSubmit,
  isLoading
}: ItemAskContactFormProps) {
  const { register, handleSubmit } = useForm<ItemAskContactFormData>();

  const onFormSubmit = (data: ItemAskContactFormData) => {
    onSubmit(itemAsk, data.message);
  };

  function renderMessageInput() {
    return (
      <Flex justifyContent="center" alignItems="center" direction="column" gap={2}>
        <Textarea
          {...register('message', { required: true })}
          placeholder="Type your message here..."
          resize="vertical"
          fontSize="sm"
        />
      </Flex>
    );
  }

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <Stack spacing={8}>
        <BasicItemCard item={item} />
        <Heading fontSize="sm">Send A Message</Heading>
        <ItemAskCard
          item={item}
          askAmount={itemAsk.amount}
          askCreatedDays={itemAsk.getCreatedDaysAgo()}
          askUpdatedDays={itemAsk.getUpdatedDaysAgo()}
          updated={itemAsk.hasBeenUpdated()}
          expired={itemAsk.isExpired()}
          condition={itemAsk.condition}
          useAvatar={false}
          showIcons={false}
        />
        {renderMessageInput()}
        <Stack alignItems="center">
          <ActionButton
            type="submit"
            text="Send Inquiry"
            loading={isLoading}
            size="md"
            disabled={isLoading}
          />
        </Stack>
      </Stack>
    </form>
  );
}
