import {
  Box,
  Flex,
  Stack,
  Heading,
  Text,
  Avatar,
  Spacer,
  Button,
  ButtonGroup,
  Icon,
  Tooltip,
  Link
} from '@chakra-ui/react';
import { BsCheckCircleFill, BsTelephone, BsGear } from 'react-icons/bs';
import { IoLocationOutline, IoMailOutline, IoPersonCircleOutline } from "react-icons/io5";
import { FaInstagram } from 'react-icons/fa';
import { BiCalendar } from 'react-icons/bi';
import { FaUserCog } from 'react-icons/fa';
import { UserModel } from 'models/User/User';
import { getCountryNameFromCountryCode } from 'utils/country';

export interface UserBannerProps {
  user: UserModel;
  onSettingsClick?: () => void;
  onAvatarClick?: () => void;
}

export default function UserBanner({
  user,
  onSettingsClick,
  onAvatarClick,
}: UserBannerProps) {

  function renderUserContactIcons() {
    const icons = [];
    if (user.email) {
      icons.push(
        <Tooltip label={user.email} key="userBanner_email">
          <Link
            href={`mailto:${user.email}`}
            fontSize="md"
            _hover={{ color: 'blue.500' }}
          >
            <Icon as={IoMailOutline} />
          </Link>
        </Tooltip>
      );
    }

    if (user.number?.baseNumber) {
      const phoneNumber = user.getFormattedNumber();
      icons.push(
        <Tooltip label={phoneNumber} key="userBanner_phone">
          <Box fontSize="md" cursor="default" _hover={{ color: 'blue.500' }}>
            <Icon as={BsTelephone} />
          </Box>
        </Tooltip>
      );
    }

    if (user.hasInstagram()) {
      icons.push(
        <Tooltip label="Instagram" key="userBanner_instagram">
          <Link
            href={`https://instagram.com/${user.instagram}`}
            isExternal
            fontSize="md"
            _hover={{ color: 'blue.500' }}
          >
            <Icon as={FaInstagram} />
          </Link>
        </Tooltip>
      );
    }

    if (!icons.length) return null;

    return (
      <Flex alignItems="center" justifyContent={{ base: 'center', md: 'flex-start' }} gap={2} paddingX={1}>
        {icons}
      </Flex>
    );
  }


  function renderAvatarSection() {
    return (
      <Stack gap={2}>
        <Avatar
          size="2xl"
          src={user.getImage()}
          name={user.name || user.userName}
          cursor={onAvatarClick ? 'pointer' : 'auto'}
          onClick={onAvatarClick}
        />
        <Button leftIcon={<FaUserCog />} size={'xs'} onClick={onAvatarClick}>
          Change Avatar
        </Button>
      </Stack>
    );
  }

  function renderUserInfo() {
    let userCountry: string = '';
    const userAddress = user.getPrimaryAddress();
    if (userAddress) {
      userCountry = getCountryNameFromCountryCode(userAddress.country)
    } else if (user.country) {
      userCountry = user.country.name;
    }
    return (
      <Stack>
        <Flex justifyContent={{ base: 'center', md: 'flex-start' }} alignItems="center">
          <Heading size="md">@{user.userName}</Heading>
          {user.verification.email && (
            <Icon as={BsCheckCircleFill} color="blue.500" ml={2} />
          )}
        </Flex>
        <Box>
        <Flex justify={{ base: 'center', md: 'flex-start' }} alignItems={'center'} gap={2}>
          {user.name && (
            <Flex alignItems={'center'} gap={1}>
              <Icon size={'sm'} as={IoPersonCircleOutline} />
              <Text fontSize={'sm'}>{user.name}</Text>
            </Flex>
          )}
          {user.name && user.hasBirthYear() && (<Box>
            •
          </Box>)}
          {user.hasBirthYear() && (
            <Flex alignItems={'center'} gap={1}>
            <Icon size={'sm'} as={BiCalendar} />
            <Text fontSize={'sm'}>{user.getBirthYear()}</Text>
          </Flex>)}
        </Flex>
        {userCountry  && (
          <Flex alignItems="center">
            <Icon as={IoLocationOutline} color="gray.500" mr={1} />
            <Text fontSize="sm" color="gray.500">
              {userCountry}
            </Text>
          </Flex>
        )}

        </Box>
        {renderUserContactIcons()}
      </Stack>
    );
  }

  function renderBannerButtons() {
    return (
      <ButtonGroup
        spacing={4}
        justifyContent="flex-end"
      >
        <Button leftIcon={<BsGear />} border="1px" padding={2} onClick={onSettingsClick}>
          Settings
        </Button>
      </ButtonGroup>
    );
  }

  return (
    <Box
      paddingY={4}
      borderRadius="lg"
      boxShadow="sm"
      width="100%"
    >
      <Flex
        direction={{ base: 'column', md: 'row' }}
        align={'center'}
        gap={{base: 4, md: 8}}
      >
        {renderAvatarSection()}
        {renderUserInfo()}
        <Spacer />
        {renderBannerButtons()}
      </Flex>
    </Box>
  );
}
