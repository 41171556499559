import { AddressModel, OrderModel } from 'models';

export enum OrderActionTypes {
  GET_ORDER_BY_ID = 'GET_ORDER_BY_ID',
  GET_ORDER_BY_ID_SUCCESS = 'GET_ORDER_BY_ID_SUCCESS',
  GET_ORDER_BY_ID_FAILURE = 'GET_ORDER_BY_ID_FAILURE',

  ADD_ORDER_ADDRESS = 'ADD_ORDER_ADDRESS',
  ADD_ORDER_ADDRESS_SUCCESS = 'ADD_ORDER_ADDRESS_SUCCESS',
  ADD_ORDER_ADDRESS_FAILURE = 'ADD_ORDER_ADDRESS_FAILURE'
};

export interface OrderActionPayload {
  id?: string;
  order?: OrderModel;
  orderId?: string;
  address?: AddressModel;
};

export interface OrderAction {
  type: OrderActionTypes;
  payload?: OrderActionPayload;
};

export interface OrderStateLoading {
  order: boolean;
  addressAction: boolean;
};

export interface OrderState {
  order: OrderModel;
  error: Error | undefined;
  loading: OrderStateLoading;
};
