import { Stack, Box, Heading, Text, Card } from '@chakra-ui/react';
import { ItemModel } from 'models';
import { Condition } from 'models/types';
import Wizard from '../Wizard';
import BasicItemCard from 'components/Item/BasicItemCard/BasicItemCard';
import ItemAskAdditionalDataForm from 'components/Form/ItemAskAdditionalDataForm/ItemAskAdditionalDataForm';
import ItemAskCard from 'components/Item/ItemAskCard/ItemAskCard';

interface ItemAskGuideWizardProps {
  item: ItemModel;
}

export default function ItemAskGuideWizard({ item }: ItemAskGuideWizardProps) {
  const amount =
    item.getEstimatedMarketPrice() ||
    item.getReleaseConvertedPrice() ||
    1000;

  function renderStepContent(header: string, content: JSX.Element) {
    return (
      <Stack spacing={4}>
        <Heading size="sm" padding={2}>{header}</Heading>
        <Box height={350}>
          {content}
        </Box>
      </Stack>
    );
  }

  const step1Content = renderStepContent('Step 1: Specify an Ask Amount', (
    <Stack spacing={4}>
      <BasicItemCard item={item} />
      <Text fontWeight={300} fontSize="sm" textAlign={'left'} padding={2}>
        Specify an asking price for your item to being selling. Our pricing engine provides guidance on the fair market value of the work to guide you.
      </Text>
    </Stack>

  ));

  const step2Content = renderStepContent('Step 2: Provide Optional Details', (
    <Stack paddingX={2} spacing={4}>
      <ItemAskAdditionalDataForm isDisabled={true} defaultFormData={{condition: Condition.EXCELLENT}}/>
      <Text fontWeight={300} fontSize="sm" textAlign={'left'}>
        You can further specify the condition of your item to help level expectations with any buyer. Furthermore, you can add notes to provide some additional context relating to your offering.
      </Text>
    </Stack>
  ));

  const step3Content = renderStepContent('Step 3: Review, Submit & Relax', (
    <Stack spacing={4}>
      <ItemAskCard
        item={item}
        askAmount={amount}
        askCreatedDays={0}
        condition={Condition.GOOD}
        userOwnsAsk={true}
        shorten={true}
      />
      <Text fontWeight={300} fontSize="sm" textAlign={'left'} padding={2}>
        Once your ask has been placed, it will be added to our trading floor and ranked based on how competitive your asking price is. Our sales team will reach out for any potential inquiries or counters on your ask. No sale will ever be made without your explicit consent.
      </Text>
    </Stack>
  ));

  const wizardSteps = [
    { label: '', content: step1Content },
    { label: '', content: step2Content },
    { label: '', content: step3Content },
  ];

  return (
    <Stack width="100%">
      <Wizard
        steps={wizardSteps}
      />
    </Stack>
  );
};
