import { Button, Flex, Heading, Text } from '@chakra-ui/react';
import { AiOutlineHeart } from 'react-icons/ai';
import UserProfileCard from './UserProfileCard';

interface UserProfileWishlistCardProps {
  wishlistItemCount: number;
  onManage?: () => void;
}

export default function UserProfileWishlistCard({
  wishlistItemCount,
  onManage,
}: UserProfileWishlistCardProps) {
  const wishlistLabel = wishlistItemCount > 1 ? 'Items' : 'Item';

  return (
    <UserProfileCard
      title="Wishlist"
      subtitle="Manage & Track Your Favorites"
      icon={AiOutlineHeart}
      bottomButton={
        <Button variant="outline" size="sm" onClick={onManage}>
          Manage Wishlist
        </Button>
      }
      children={
        <Flex gap={1} align="center">
          <Heading fontSize="md">{wishlistItemCount}</Heading>
          <Text fontSize="sm">{wishlistLabel}</Text>
        </Flex>
      }
    />
  );
}
