import {
	Box,
	Avatar,
	Flex,
	Heading,
	Text,
	Stack,
	Image,
	Spacer,
	Icon,
	Tooltip,
	useColorModeValue,
	Badge
} from '@chakra-ui/react';
import { OrderModel } from 'models';
import { toDollarFormat } from 'utils/format';
import { BiCreditCard } from 'react-icons/bi';
import { FaMoneyBillWave, FaShippingFast } from 'react-icons/fa';
import { OrderPaymentStatus, OrderPayoutStatus, OrderStatus } from 'models/Order/types';

export interface SellerOrderCardProps {
	order: OrderModel;
	useAvatar?: boolean;
	onItemNameClick?: (orderId: string, orderName: string) => void;
	onItemArtistNameClick?: (artistId: string, artistName: string) => void;
}

export enum SellerOrderCardSizingConfig {
	CARD_HEIGHT = '80px'
}

export enum SellerOrderCardStylingConfig {
	BORDER_COLOR_LIGHT = '#f1f3f7',
	BORDER_COLOR_DARK = '#3f434c'
}

export default function SellerOrderCard({
	order,
	useAvatar = false,
	onItemNameClick,
	onItemArtistNameClick
}: SellerOrderCardProps) {

	const getOrderStatusColor = (status: OrderStatus): string => {
		switch (status) {
			case OrderStatus.UNCONFIRMED: return 'orange';
			case OrderStatus.CONFIRMED: return 'blue';
			case OrderStatus.IN_TRANSIT: return 'blue';
			case OrderStatus.COMPLETE: return 'green';
			case OrderStatus.CANCELLED: return 'red';
			default: return 'gray';
		}
	}

	const getPaymentStatusColor = (status: OrderPaymentStatus): string => {
		switch (status) {
			case OrderPaymentStatus.PENDING: return 'orange';
			case OrderPaymentStatus.COMPLETE: return 'green';
			case OrderPaymentStatus.FAILED: return 'red';
			default: return 'gray';
		}
	}

	const getPayoutStatusColor = (status: OrderPayoutStatus): string => {
		switch (status) {
			case OrderPayoutStatus.PENDING: return 'orange';
			case OrderPayoutStatus.COMPLETE: return 'green';
			case OrderPayoutStatus.FAILED: return 'red';
			default: return 'gray';
		}
	}

	function renderItemImage() {
		const item = order.item
		if (item) {
			if (useAvatar) {
				return (
					<Avatar
						backgroundColor="white"
						marginRight={4}
						name={item.name}
						src={item.image}
					/>
				);
			} else {
				return (
					<Image
						fit="contain"
						marginRight={4}
						src={item.image}
						alt={item.name}
						width={SellerOrderCardSizingConfig.CARD_HEIGHT}
						height={SellerOrderCardSizingConfig.CARD_HEIGHT}
					/>
				);
			}

		}
	}

	function renderOrderNumber() {
		return (
			<Heading
				_hover={onItemNameClick ? { color: 'blue.500', cursor: 'pointer' } : {}}
				fontSize="sm"
				onClick={() => {}}
			>
				Order 1234-5678
			</Heading>
		);
	}

	function renderOrderItemName() {
		const item = order.item;
		if (item) {
			return (
				<Heading
					_hover={onItemNameClick ? { color: 'blue.500', cursor: 'pointer' } : {}}
					fontSize="xs"
					onClick={() => onItemNameClick?.(item.id, item.getDisplayName())}
				>
					{item.name}
				</Heading>
			);
		}
	}

	function renderOrderArtistName() {
		const item = order.item;
		if (item) {
			return (
				<Text
					_hover={onItemArtistNameClick ? { color: "blue.500", cursor: "pointer" } : {}}
					fontSize="xs"
					onClick={() => onItemArtistNameClick?.(item.artistId, item.getArtistName())}>
					{item.getArtistName()}
				</Text>
			);
		}

	}

	function renderOrderStatusBadge() {
		return (
			<Badge colorScheme={getOrderStatusColor(order.orderStatus)} width="fit-content">
				{order.orderStatus}
			</Badge>
		);
	}

	function renderStatusIcons() {
		return (
			<Flex direction="row" gap={2}>
				<Tooltip label={`Payment Status: ${order.paymentStatus}`}>
					<Flex>
						<Icon
							as={BiCreditCard}
							w={4}
							h={4}
							color={getPaymentStatusColor(order.paymentStatus)}
						/>
					</Flex>
				</Tooltip>
				{renderShippingIcon()}
				<Tooltip label={`Payout Status: ${order.payoutStatus}`}>
					<Flex>
						<Icon
							as={FaMoneyBillWave}
							w={4}
							h={4}
							color={getPayoutStatusColor(order.payoutStatus)}
						/>
					</Flex>
				</Tooltip>
			</Flex>
		);
	}

	function renderShippingIcon() {
		const shippedAt = order.timestamps.shippedAt;
		const deliveredAt = order.timestamps.deliveredAt;

		let shippingColor = 'gray';
		let tooltipLabel = 'Shipment Pending';

		if (shippedAt) {
			shippingColor = 'blue';
			tooltipLabel = 'Shipped - In Transit';
		}

		if (deliveredAt) {
			shippingColor = 'green';
			tooltipLabel = 'Delivered';
		}
		return (
			<Tooltip label={tooltipLabel}>
				<Flex>
					<Icon as={FaShippingFast} w={4} h={4} color={shippingColor} />
				</Flex>
			</Tooltip>
		);
	}

	function renderOrderAmount() {
		return (
			<Flex direction="column" alignItems="flex-end">
				<Heading fontSize="sm">{toDollarFormat(order.amounts.netTotal)}</Heading>
				<Text fontSize="xs">Net Total</Text>
			</Flex>
		);
	}

	return (
		<Box
			minHeight={SellerOrderCardSizingConfig.CARD_HEIGHT}
			width="100%"
			overflow="hidden"
			padding={2}
			border="1px solid"
			borderColor={useColorModeValue(
				SellerOrderCardStylingConfig.BORDER_COLOR_LIGHT,
				SellerOrderCardStylingConfig.BORDER_COLOR_DARK
			)}
		>
			<Flex direction="row" alignItems="center">
				{renderItemImage()}
				<Flex direction="column" textAlign="left" gap={2}>
					{renderOrderNumber()}
					{renderOrderItemName()}
					<Stack spacing={1}>
						{renderOrderArtistName()}
						{renderStatusIcons()}
						{renderOrderStatusBadge()}
					</Stack>
				</Flex>
				<Spacer />
				{renderOrderAmount()}
			</Flex>
		</Box>
	);
}
