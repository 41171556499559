import { JSONObject } from "models/Api/types";

export interface RawAddress {
	city?: string;
	country?: string;
	line1?: string;
	line2?: string;
	postal_code?: string;
	state?: string;
}

export class AddressModel {
	city: string;
	country: string;
	line1: string;
	line2: string;
	postalCode: string;
	state: string;

	constructor(data: RawAddress) {
		this.city = data?.city || "";
		this.country = data?.country || "";
		this.line1 = data?.line1 || "";
		this.line2 = data?.line2 || "";
		this.postalCode = data?.postal_code || "";
		this.state = data?.state || "";
	}

	public toObject(): JSONObject {
		return {
			line1: this.line1,
			line2: this.line2,
			city: this.city,
			state: this.state,
			postal_code: this.postalCode,
			country: this.country,
		};
	}

	public getDisplayText(): string {
		const parts: string[] = [];
		if (this.line1) parts.push(this.line1);
		if (this.line2) parts.push(this.line2);
		if (this.city) parts.push(this.city);
		if (this.state) parts.push(this.state);
		if (this.postalCode) parts.push(this.postalCode);
		if (this.country) parts.push(this.country);
		return parts.join(', ');
	}
}