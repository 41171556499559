import { useState, useEffect } from 'react';
import {
  Box,
  Text,
  Flex,
  Spacer,
  Square,
  Icon,
  Tooltip
} from '@chakra-ui/react';
import { PaginationModel, ListingModel } from 'models';
import { processDateSort, processNumberSort } from 'utils/sort';
import { filterListingsByQuery } from 'utils/search';
import ListingAccordion from '../ListingAccordion/ListingAccordion';
import Loader from '../../Loader/Loader';
import SearchBar from '../../SearchBar/SearchBar';
import EmptyState from '../../EmptyState/EmptyState'
import PaginationFooter from '../../Pagination/PaginationFooter/PaginationFooter';
import ListingGridFilters from '../ListingGridFilters/ListingGridFilters';
import SortDropdown from '../../SortDropdown/SortDropdown';
import InfiniteScroll from 'react-infinite-scroller';
import ListingGridSkeleton from '../ListingGridSkeleton/ListingGridSkeleton';
import { ListingSortFilter } from '../types';
import { AiOutlineFilter, AiFillFilter } from 'react-icons/ai';

export interface PaginatedListingGridProps {
  paginatedListings: PaginationModel<ListingModel>;
  onScrollEndLoad?: () => void;
  onPageSelect?: (page: number) => void;
  isLoading: boolean;
  showFilters?: boolean;
  showFiltersLabel?: boolean;
  scrollMode?: boolean;
  onListingItemClick?: (id: string, name: string) => void;
  onListingArtistClick?: (id: string, name: string) => void;
  showAssociatedItems?: boolean;
  showAssociationAction?: boolean;
  onItemSuggestionClick?: (listing: ListingModel) => void;
  onTagListingClick?: (listing: ListingModel) => void;
}

export default function PaginatedListingGrid({ paginatedListings, isLoading, onScrollEndLoad, onPageSelect, onListingItemClick, onListingArtistClick, onItemSuggestionClick, onTagListingClick, showFilters = true, showFiltersLabel = true, scrollMode = true, showAssociatedItems = true, showAssociationAction = true  }: PaginatedListingGridProps) {
  const [listings, setListings] = useState<PaginationModel<ListingModel>>(paginatedListings);
  const [listingsQuery, setListingsQuery] = useState<string>('');
  const [selectedYears, setSelectedYears] = useState<number[]>([]);
  const [selectedPrices, setSelectedPrices] = useState<number[]>([]);
  const [selectedShowOriginal, setSelectedShowOriginal] = useState<boolean>(false);
  const [selectedShowSet, setSelectedShowSet] = useState<boolean>(false);
  const [listingsSort, setListingsSort] = useState<ListingSortFilter>(ListingSortFilter.DEFAULT);
  const [sortType, setSortType] = useState<'asc' | 'desc'>('desc');
  const [displayFilters, setDisplayFilters] = useState<boolean>(false);

  useEffect(() => {
    if (paginatedListings.hasData()) {
      setListings(paginatedListings);
      applyFiltersAndSort();
    }
  }, [paginatedListings]);

  useEffect(() => {
    applyFiltersAndSort();
  }, [listingsQuery, selectedYears, selectedPrices, listingsSort, sortType, selectedShowSet, selectedShowOriginal]);

  const isOnFilterMode = (): boolean => {
    return listingsQuery.length > 0 || listingsSort !== ListingSortFilter.DEFAULT || hasFiltersApplied();
  }

  const onClearFilters = () => {
    setListingsQuery('');
    setSelectedYears([]);
    setSelectedPrices([]);
    setSelectedShowSet(false);
    setSelectedShowOriginal(false);
    setDisplayFilters(false);
  }

  const hasFiltersApplied = (): boolean => {
    return selectedYears.length > 0 || selectedPrices.length > 0 || selectedShowOriginal || selectedShowSet;
  }
  const toggleFilterDisplay = (): void => {
    setDisplayFilters(!displayFilters);
  }

  const toggleShowOriginalListings = (): void => {
    setSelectedShowOriginal(!selectedShowOriginal);
  }

  const toggleShowSetListings = (): void => {
    setSelectedShowSet(!selectedShowSet);
  }

  const applyFiltersAndSort = () => {
    let filteredListings = paginatedListings;

    if (listingsQuery) {
      filteredListings = filterPaginatedListingsByQuery(filteredListings, listingsQuery);
    }
    
    if (selectedYears.length > 0) {
      filteredListings = filterListingsBySaleDateRange(filteredListings, selectedYears);
    }

    if (selectedPrices.length > 0) {
      filteredListings = filterListingsBySalePriceRange(filteredListings, selectedPrices);
    }

    if (selectedShowOriginal) {
      filteredListings = filterPaginatedListingsByOriginal(filteredListings);
    }

    if (selectedShowSet) {
      filteredListings = filterPaginatedListingsBySet(filteredListings);
    }

    setListings(processSortKey(filteredListings, listingsSort));
  };

  const onListingSalePriceRangeSelect = (minPrice: number, maxPrice: number): void => {
    const priceRange = [minPrice, maxPrice];
    setSelectedPrices(priceRange);
  }

  const onListingSaleDateRangeSelect = (minYear: number, maxYear: number): void => {
    const yearRange = [minYear, maxYear];
    setSelectedYears(yearRange);
  }

  const onListingSearch = (query: string): void => {
    setListingsQuery(query);
  }

  const onLoadMore = (): void => {
    if (paginatedListings.hasMorePages() && !isLoading && !isOnFilterMode()) {
      onScrollEndLoad?.();
    }
  }

  const filterPaginatedListingsBySet = (listings: PaginationModel<ListingModel>): PaginationModel<ListingModel> => {
    const newListingData = listings.data.filter((listing: ListingModel) => listing.isSet());
    return new PaginationModel<ListingModel>({ per_page: listings.perPage, current_page: listings.currentPage, total_count: listings.totalCount, total_pages: listings.totalPages, data: newListingData});
  }

  const filterPaginatedListingsByOriginal = (listings: PaginationModel<ListingModel>): PaginationModel<ListingModel> => {
    const newListingData = listings.data.filter((listing: ListingModel) => listing.isOriginal());
    return new PaginationModel<ListingModel>({ per_page: listings.perPage, current_page: listings.currentPage, total_count: listings.totalCount, total_pages: listings.totalPages, data: newListingData});
  }

  const filterPaginatedListingsByQuery = (listings: PaginationModel<ListingModel>, query: string): PaginationModel<ListingModel> => {
    const newListingData = filterListingsByQuery(listings.data, query);
    return new PaginationModel<ListingModel>({ per_page: listings.perPage, current_page: listings.currentPage, total_count: listings.totalCount, total_pages: listings.totalPages, data: newListingData});
  }

  const filterListingsBySaleDateRange = (listings: PaginationModel<ListingModel>, dateYearRanges: number[]): PaginationModel<ListingModel> => {
    const newListingData = listings.data.filter((listing: ListingModel) => listing.getDateYear() >= dateYearRanges[0] &&  listing.getDateYear() <= dateYearRanges[1]);
    return new PaginationModel<ListingModel>({ per_page: listings.perPage, current_page: listings.currentPage, total_count: listings.totalCount, total_pages: listings.totalPages, data: newListingData});
  }

  const filterListingsBySalePriceRange = (listings: PaginationModel<ListingModel>, priceRanges: number[]): PaginationModel<ListingModel> => {
    const newListingData = listings.data.filter((listing: ListingModel) => listing.rawPrice >= priceRanges[0] && listing.rawPrice <= priceRanges[1]);
    return new PaginationModel<ListingModel>({ per_page: listings.perPage, current_page: listings.currentPage, total_count: listings.totalCount, total_pages: listings.totalPages, data: newListingData});
  }

  const processSortKey = (listings: PaginationModel<ListingModel>, sortKey: ListingSortFilter): PaginationModel<ListingModel> => {
    let sortedListings = [...listings.data];
    const isAscending: boolean = sortType === 'asc';

    if (sortType === 'asc') {
      if (sortKey === ListingSortFilter.TITLE) {
        sortedListings = sortedListings.sort((a, b) => (a.name > b.name ? 1 : -1));
      }
    } else {
      if (sortKey === ListingSortFilter.TITLE) {
        sortedListings = sortedListings.sort((a, b) => a.name > b.name ? -1 : 1);
      }
    }

    if (sortKey === ListingSortFilter.SALE_DATE) {
      sortedListings = sortedListings.sort((a, b) => processDateSort(a.date, b.date, !isAscending));
    } else if (sortKey === ListingSortFilter.PRICE) {
      sortedListings = sortedListings.sort((a, b) => processNumberSort(a.rawPrice, b.rawPrice, isAscending));
    }

    return new PaginationModel<ListingModel>({ per_page: listings.perPage, current_page: listings.currentPage, total_count: listings.totalCount, total_pages: listings.totalPages, data: sortedListings});
  }

  const getListingData = (): ListingModel[] => {
    if (isOnFilterMode()) {
      return listings.data;
    } else {
      return paginatedListings.data;
    }
  }

  const scrollLoadEnabled: boolean = !isLoading && (listings?.hasMorePages() || false) && !isOnFilterMode() && (paginatedListings.hasData());


  function renderFiltersSort() {
    return (
      <SortDropdown<ListingSortFilter>
        options={ListingSortFilter}
        selectedSortBy={listingsSort}
        sortType={sortType}
        onSortBySelect={setListingsSort}
        onSortTypeToggle={() => setSortType(sortType === 'asc' ? 'desc' : 'asc')}
      />
    );
  }

  function renderFilters() {
    if (showFilters) {
      return (
        <Flex minWidth='fit-content' alignItems={{base: 'start', md: 'center'}} gap='2' marginBottom="40px" p={{base: '20px', md: '8px 0px'}} direction={{base: 'column', md: 'row'}}>
          <Box p='2'>
            {showFiltersLabel && <Text fontSize='xs'>Filters: </Text>}
          </Box>
          <Flex direction={{ base: 'column', md: 'row' }} width="100%" gap={2}>
            <SearchBar onChange={onListingSearch} defaultValue={listingsQuery} />
            <Flex direction='row' gap={2} width={'100%'}>
              {renderFilterButton()}
              <Spacer />
              {renderFiltersSort()}
            </Flex>
          </Flex>
        </Flex>
      );
    }
  }

  function renderFilterButton() {
    return (
      <Tooltip label={'Filter Results'}>
        <Square size={10} border={'1px solid'} borderColor={'gray.300'} cursor={'pointer'} borderRadius={'md'} onClick={() => toggleFilterDisplay()}>
          <Icon as={displayFilters ? AiFillFilter : AiOutlineFilter} />
        </Square>
      </Tooltip>
    );
  }

  function renderListingGridFilters() {
    if (displayFilters) {
      return (
        <ListingGridFilters 
          listings={paginatedListings.data}
          showTypeFilter={true}
          onListingPriceRangeSelect={onListingSalePriceRangeSelect}
          onListingSaleDateRangeSelect={onListingSaleDateRangeSelect}
          onListingShowOriginalToggle={toggleShowOriginalListings}
          onListingShowSetToggle={toggleShowSetListings}
          defaultPriceRange={selectedPrices}
          defaultSaleDateRange={selectedYears}
          defaultShowOriginal={selectedShowOriginal}
          defaultShowSet={selectedShowSet}
          showClearOption={hasFiltersApplied()}
          onResetFilters={onClearFilters}
        />
      );
    }
  }

  function renderData() {
    const listingData = getListingData();
    if (!scrollMode && isLoading) {
      return <ListingGridSkeleton rows={10} />
    } if (listingData.length) {
      const pageFooterModeStyles = !scrollMode ? {
        overflow: 'scroll',
        maxH: 800
      } : {};
      return (
        <Flex direction="column" gap="12px" {...pageFooterModeStyles}>
          {getListingData().map((listing: ListingModel, index: number) => <ListingAccordion key={`listing_grid_listing_${index}`} listing={listing} onItemClick={onListingItemClick} onArtistClick={onListingArtistClick} onItemSuggestionClick={onItemSuggestionClick} onTagListingClick={onTagListingClick} showAssociatedItem={showAssociatedItems} showAssociationAction={showAssociationAction} />)}
        </Flex>
      );
    } else if (!isLoading) {
      return (
        <EmptyState 
          header="No Listings" 
          description="No Results Found" 
          showButton={isOnFilterMode()}
          onButtonClick={() => onClearFilters()}
          buttonText={'Clear Filters'} />
      );
    }
  }

  function renderInfiniteScrollLoader() {
    if (!!onScrollEndLoad) {
      return (
        <Flex justifyContent="center" alignItems="center">
          <Loader key="listingGrid_infiniteScroll_loader"/>
        </Flex>
      );
    } else {
      return <Box/>;
    }
  }

  function renderPagesFooter() {
    if (!scrollMode) {
      return (
        <PaginationFooter currentPage={paginatedListings.currentPage} lastPage={paginatedListings.totalPages} siblingsCount={2} onPageChange={(page) => onPageSelect?.(page)} />
      );
    }
  }

  function renderContent() {
    if (scrollMode) {
      return (
        <InfiniteScroll
          pageStart={0}
          loadMore={onLoadMore}
          hasMore={scrollLoadEnabled}
          loader={renderInfiniteScrollLoader()}
        >
          {renderData()}
        </InfiniteScroll>
      );
    } else {
      return renderData();
    }
  }

  function renderScrollModeLoader() {
    if (isLoading && scrollMode && !paginatedListings.hasData()) {
      return (
        <Flex justifyContent="center" alignItems="center">
          <Loader key="listingGrid_content_loader" />
        </Flex>
      );
    } else {
      return null;
    }
  }

  return (
    <Box width="100%">
      {renderFilters()}
      {renderListingGridFilters()}
      {renderContent()}
      {renderPagesFooter()}
      {renderScrollModeLoader()}
    </Box>
  );
}
