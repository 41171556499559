import { Component } from 'react';
import { connect, Dispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { RouterProps, withRouter } from "utils/route";
import { ApplicationState } from 'reducers/types';
import { AuthSelectors } from 'reducers/Auth/selectors';
import { UserSelectors } from 'reducers/User/selectors';
import { UserActions } from 'reducers/User/actions';
import {
  Box,
} from '@chakra-ui/react';
import { Content, Loader } from 'components';
import { UserModel } from 'models';
import { NavigationService } from 'services';
import { AppConstants } from '../../constants';
import emailConfirmationJpg from '../../assets/images/email-confirmation.jpg';
import emailConfirmedGif from '../../assets/gif/email-confirmed.gif';

interface UserConfirmationViewProps extends RouterProps {
  user: UserModel;
  mailLoading: boolean;
  userLoading: boolean;
  confirmationLoading: boolean;
  sendConfirmationEmail: () => void;
  confirmUser: (token: string) => void;
}

class UserConfirmation extends Component<UserConfirmationViewProps, {}> {
  componentDidMount() {
    const { location, confirmUser } = this.props;
    const params = new URLSearchParams(location.search);
    const token = params.get('confirmationToken');
    if (token) {
      confirmUser(token);
    }
  }

  onFinishSetupClick = () => {
    this.props.navigate(
      NavigationService.getUserOnboardingPath()
    );
  }

  renderEmailConfirmationContent() {
    const { user, mailLoading, sendConfirmationEmail, userLoading, confirmationLoading } = this.props;
    const content = `We have sent an email to ${user.email} to confirm the validity of your email address. After receiving the email, follow the provided link to complete your registration`;
    return (
      <Content
        header={'Email Confirmation'}
        description={content}
        buttonText={'Resend Email'}
        showButton={true}
        onButtonClick={sendConfirmationEmail}
        buttonLoading={mailLoading}
        imagePath={emailConfirmationJpg}
        loading={userLoading || confirmationLoading}
      />
    );
  }

  renderSuccessContent() {
    const { userLoading } = this.props;
    const content = `Your account has been confirmed! Please continue with your account setup `;
    return (
      <Content
        header={'Account Confirmed'}
        description={content}
        buttonText={'Finish Setup'}
        showButton={true}
        onButtonClick={this.onFinishSetupClick}
        imagePath={emailConfirmedGif}
        loading={userLoading} />
    );
  }

  renderLoadingContent() {
    return <Loader />;
  }

  renderContent() {
    const { user, userLoading, confirmationLoading } = this.props;
    if (user.isConfirmed()) {
      return this.renderSuccessContent();
    } else if (userLoading || confirmationLoading) {
      return this.renderLoadingContent();
    } else {
      return this.renderEmailConfirmationContent();
    }
  }

  render() {
    return (
      <Box maxWidth={`${AppConstants.GRIDPAGE_WIDTH}px`} paddingTop={['80px', '100px', '100px']} justifySelf="center" minWidth={['100%', `${AppConstants.GRIDPAGE_WIDTH}px`]}>
        {this.renderContent()}
      </Box>
    );
  }
}

function mapStateToProps(state: ApplicationState) {
  return {
    user: UserSelectors.getUser(state),
    userLoading: UserSelectors.getUserLoading(state) || AuthSelectors.getUserLoading(state),
    mailLoading: UserSelectors.getUserMailActionLoading(state),
    confirmationLoading: UserSelectors.getUserConfirmationActionLoading(state)
  }
}

function mapDispatchToProps(dispatch: Dispatch<ApplicationState>) {
  return bindActionCreators(
    {
      sendConfirmationEmail: () => UserActions.sendConfirmationEmail(),
      confirmUser: (token: string) => UserActions.confirmUser(token)
    },
    dispatch
  );
}

export const UserConfirmationView = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserConfirmation));
