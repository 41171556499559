import { Component } from 'react';
import { connect, Dispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { RouterProps, withRouter, useAuthCheck } from "utils/route";
import { ApplicationState } from 'reducers/types';
import { UserSelectors } from 'reducers/User/selectors';
import { PortfolioSelectors } from 'reducers/Portfolio/selectors';
import {
  Box,
  Flex,
  Stack,
  Spacer,
  Icon,
  Text,
} from '@chakra-ui/react';
import { UserModel, PortfolioModel, WishlistModel, AddressModel } from 'models';
import { UserBanner, SEOHelmet, UserProfileActiveMarketCard, UserProfilePortfolioCard, UserProfileFollowingCard, UserProfileSellerCard, UserProfileOnboardingCard, UserProfileEmailCard, UserProfileWishlistCard, Modal, Drawer, AddressWizard, UserAvatarUploadForm, ChangePasswordForm, UserProfileAddressCard } from 'components';
import { ChangePasswordFormData } from 'components/Form/ChangePasswordForm/ChangePasswordForm';
import { NavigationService } from 'services';
import { AppConstants } from '../../constants';
import { QuerySearchParameters } from 'constants/url';
import { WishlistSelectors } from 'reducers/Wishlist/selectors';
import { UserActions } from 'reducers/User/actions';
import { IoHomeOutline } from "react-icons/io5";
import { generateSEOTitle } from 'utils/seo';


interface UserProfileDetailsViewProps extends RouterProps {
  user: UserModel;
  portfolio: PortfolioModel;
  wishlist: WishlistModel;
  sellerOnboardingLink: string;
  sellerDashboardLink: string;
  userLoading: boolean;
  userPortfolioLoading: boolean;
  portfolioLoading: boolean;
  userWishlistLoading: boolean;
  wishlistLoading: boolean;
  userFollowingArtistsLoading: boolean;
  userMarketplaceActionLoading: boolean;
  userActiveMarketLoading: boolean;
  userImageActionLoading: boolean;
  userPasswordActionLoading: boolean;
  getActiveMarket: () => void;
  migrateUserImage: (url: string) => void;
  changePassword: (oldPassword: string, newPassword: string) => void;
  activateSelling: () => void;
  generateSellerOnboardingLink: () => void;
  generateSellerDashboardLink: () => void;
  addPrimaryAddress: (address: AddressModel) => void;
}


interface UserProfileDetailsViewState {
  modals: {
    avatar: boolean;
    changePassword: boolean;
  },
  drawers: {
    address: boolean
  }
}


class UserProfileDetails extends Component<UserProfileDetailsViewProps, UserProfileDetailsViewState> {
  state = {
    modals: {
      avatar: false,
      changePassword: false
    },
    drawers: {
      address: false
    }
  }

  componentDidMount() {
    const { user, getActiveMarket, generateSellerOnboardingLink, generateSellerDashboardLink } = this.props;
    if (user && user.isAuthenticated()) {
      getActiveMarket();
    }

    if (user && user.hasStripeConnectedAccountId()) {
      if (!user.isSellerVerified()) {
        generateSellerOnboardingLink();
      } else {
        generateSellerDashboardLink();
      }
    }
  }

  componentDidUpdate(prevProps: UserProfileDetailsViewProps) {
    const { user, getActiveMarket, generateSellerOnboardingLink, generateSellerDashboardLink } = this.props;
    if (user.isAuthenticated() && !prevProps.user.isAuthenticated()) {
      getActiveMarket();
    }

    if (user.hasStripeConnectedAccountId() && !prevProps.user.hasStripeConnectedAccountId()) {
      if (!user.isSellerVerified()) {
        generateSellerOnboardingLink();
      } else {
        generateSellerDashboardLink();
      }
    }
  }

  onUserBannerButtonClick = () => {
    this.setState({
      modals: {
        ...this.state.modals,
        avatar: true
      }
    });
  };

  closeAvatarModal = () => {
    this.setState({
      modals: {
        ...this.state.modals,
        avatar: false
      }
    });
  };

  onAddressWizardOpen = () => {
    this.setState(prevState => ({
      drawers: { ...prevState.drawers, address: true }
    }));
  };

  onAddressWizardClose = () => {
    this.setState(prevState => ({
      drawers: { ...prevState.drawers, address: false }
    }));
  };

  onUserChangePasswordClick = () => {
    this.setState({
      modals: {
        ...this.state.modals,
        changePassword: true
      }
    });
  };

  closeChangePasswordModal = () => {
    this.setState({
      modals: {
        ...this.state.modals,
        changePassword: false
      }
    });
  };


  onBrowseItems = () => {
    this.props.navigate(
      NavigationService.getExplorePath() + `?${QuerySearchParameters.TAB}=1`
    );
  };

  onUserBannerSettingsClick = () => {
    this.props.navigate(
      NavigationService.getUserSettingsPath()
    );
  }

  onUserConfirmationClick = () => {
    this.props.navigate(
      NavigationService.getUserConfirmationPath()
    );
  }

  onUserFollowingManageClick = () => {
    this.props.navigate(
      NavigationService.getUserFollowingPath()
    );
  }

  onUserActiveMarketManageClick = () => {
    this.props.navigate(
      NavigationService.getUserActiveMarketPath()
    );
  }

  onUserOnboardingClick = () => {
    this.props.navigate(
      NavigationService.getUserOnboardingPath()
    );
  }

  onUserPortfolioManageClick = () => {
    const { user } = this.props;
    this.props.navigate(
      NavigationService.getPortfolioDetailsPath(user.getPortfolioId())
    );
  }

  onUserWishlistManageClick = () => {
    const { user } = this.props;
    this.props.navigate(
      NavigationService.getWishlistDetailsPath(user.getWishlistId())
    );
  }

  onBrowseArtists = () => {
    this.props.navigate(
      NavigationService.getArtistsPath()
    );
  };

  onActivateSelling = () => {
    const { user, navigate, activateSelling } = this.props;
    useAuthCheck(user, navigate, activateSelling);
  }

  onAddressWizardSubmit = (address: AddressModel) => {
    const { user, addPrimaryAddress } = this.props;
    addPrimaryAddress(address);
    this.onAddressWizardClose();
  }

  isFetchingPortfolioData = (): boolean => {
    const { userLoading, userPortfolioLoading, portfolioLoading } = this.props;
    return userLoading || userPortfolioLoading || portfolioLoading;
  }

  isFetchingWishlistData = (): boolean => {
    const { userLoading, userWishlistLoading } = this.props;
    return userLoading || userWishlistLoading;
  }

  renderBanner() {
    const { user } = this.props;
    return (
      <UserBanner
        user={user}
        onSettingsClick={this.onUserBannerSettingsClick}
        onAvatarClick={this.onUserBannerButtonClick}
      />
    );
  }

  renderCards() {
    const { user, sellerOnboardingLink, sellerDashboardLink, userMarketplaceActionLoading } = this.props;
    return (
      <Stack gap={4} mt={4}>
        <Flex gap={2} width={'100%'} flexDirection={{ base: 'column', md: 'row' }}>
          <UserProfileEmailCard user={user} onConfirmEmail={this.onUserConfirmationClick} onChangePassword={this.onUserChangePasswordClick} />
          <Spacer />
          <UserProfileAddressCard user={user} onManage={this.onAddressWizardOpen} />
        </Flex>
        <Flex gap={2} width={'100%'} flexDirection={{ base: 'column', md: 'row' }}>
          <UserProfileOnboardingCard user={user} onClick={this.onUserOnboardingClick} />
          <Spacer />
          <UserProfileSellerCard user={user} onActivateSelling={this.onActivateSelling} sellerOnboardingLink={sellerOnboardingLink} sellerDashboardLink={sellerDashboardLink} marketplaceActionLoading={userMarketplaceActionLoading} />
        </Flex>
        <Flex gap={2} width={'100%'} flexDirection={{ base: 'column', md: 'row' }}>
          <UserProfileActiveMarketCard user={user} onManage={this.onUserActiveMarketManageClick} />
          <Spacer />
          <UserProfileFollowingCard followingCount={user.followingArtists.length} onManage={this.onUserFollowingManageClick} />
        </Flex>
        <Flex gap={2} width={'100%'} flexDirection={{ base: 'column', md: 'row' }}>
          <UserProfilePortfolioCard portfolioValue={user.portfolio?.getPortfolioBasicMarketSummary()?.value} onManage={this.onUserPortfolioManageClick} />
          <Spacer />
          <UserProfileWishlistCard wishlistItemCount={user.getWishlistItemCount()} onManage={this.onUserWishlistManageClick} />
        </Flex>
      </Stack>
    );
  }

  renderModals() {
    const { user, migrateUserImage, changePassword, userImageActionLoading, userPasswordActionLoading } = this.props;
    const { modals } = this.state;
    return (
      <Stack>
        <Modal
          title="Change Avatar"
          isOpen={modals.avatar}
          onClose={this.closeAvatarModal}
          content={
            <UserAvatarUploadForm
              user={user}
              onSubmit={(url: string) => {
                migrateUserImage(url);
                this.closeAvatarModal();
              }}
              submitLoading={userImageActionLoading}
            />
          }
          showCloseButton={false}
          showSubmitButton={false}
        />
        <Modal
          title="Change Password"
          isOpen={modals.changePassword}
          onClose={this.closeChangePasswordModal}
          content={
            <ChangePasswordForm
              user={user}
              onSubmit={(data: ChangePasswordFormData) => {
                changePassword(data.oldPassword, data.newPassword);
                this.closeChangePasswordModal();
              }}
              submitLoading={userPasswordActionLoading}
              showBadge={false}
            />
          }
          showCloseButton={false}
          showSubmitButton={false}
        />
      </Stack>
    );
  }

  renderDrawers() {
    const { user } = this.props;
    const { drawers } = this.state;
    return (
      <Stack>
        <Drawer
          title="Manage Your Address"
          isOpen={drawers.address}
          onClose={this.onAddressWizardClose}
          content={
            <Stack gap={8}>
              <Stack gap={4} alignItems="center">
                <Flex
                  w={16}
                  h={16}
                  align="center"
                  justify="center"
                  rounded="full"
                  border="2px"
                >
                  <Icon w={8} h={8} as={IoHomeOutline} />
                </Flex>
                <Text fontSize="md">{'Manage Your Address'}</Text>
                <Text fontSize="sm" fontWeight="light" textAlign="center">{'This Is Your Primary Address And Is Used For Buying and Selling.'}</Text>
              </Stack>
              <AddressWizard
                onSubmit={this.onAddressWizardSubmit}
                defaultAddress={user.getPrimaryAddress()}
                showStepper={false}
              />
            </Stack>
          }
        />
      </Stack>
    );
  }

  render() {
    return (
      <>
        <SEOHelmet
          title={generateSEOTitle('Profile')}
          description={`View and update your profile.`}
        />
        <Box maxWidth={`${AppConstants.GRIDPAGE_WIDTH}px`} paddingTop={['80px', '100px', '100px']} justifySelf="center" minWidth={['100%', `${AppConstants.GRIDPAGE_WIDTH}px`]}>
          {this.renderBanner()}
          {this.renderCards()}
        </Box>
        {this.renderModals()}
        {this.renderDrawers()}
      </>
    );
  }
}

function mapStateToProps(state: ApplicationState) {
  return {
    user: UserSelectors.getUser(state),
    userLoading: UserSelectors.getUserLoading(state),
    sellerOnboardingLink: UserSelectors.getSellerOnboardingLink(state),
    sellerDashboardLink: UserSelectors.getSellerDashboardLink(state),
    userPortfolioLoading: UserSelectors.getUserPortfolioLoading(state),
    userWishlistLoading: UserSelectors.getUserWishlistLoading(state),
    userImageActionLoading: UserSelectors.getUserImageActionLoading(state),
    userPasswordActionLoading: UserSelectors.getUserPasswordActionLoading(state),
    userFollowingArtistsLoading: UserSelectors.getUserFollowingArtistsLoading(state),
    userActiveMarketLoading: UserSelectors.getUserActiveMarketLoading(state),
    userMarketplaceActionLoading: UserSelectors.getUserMarketplaceActionLoading(state),
    portfolio: PortfolioSelectors.getPortfolio(state),
    portfolioLoading: PortfolioSelectors.getPortfolioLoading(state),
    wishlist: WishlistSelectors.getWishlist(state),
    wishlistLoading: WishlistSelectors.getWishlistLoading(state),
  }
}

function mapDispatchToProps(dispatch: Dispatch<ApplicationState>) {
  return bindActionCreators(
    {
      migrateUserImage: UserActions.migrateImage,
      getActiveMarket: UserActions.getUserActiveMarket,
      changePassword: UserActions.changePassword,
      activateSelling: UserActions.activateSelling,
      generateSellerOnboardingLink: UserActions.generateSellerOnboardingLink,
      generateSellerDashboardLink: UserActions.generateSellerDashboardLink,
      addPrimaryAddress: UserActions.addPrimaryAddress
    },
    dispatch
  );
}

export const UserProfileDetailsView = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserProfileDetails));