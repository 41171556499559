import { OrderModel } from 'models';
import { OrderState } from './types';

export const defaultState: OrderState = {
  order: new OrderModel(),
  error: undefined,
  loading: {
    order: false,
    addressAction: false
  }
};
