import moment from 'moment';
import { ItemModel } from '../Item';
import { UserModel } from '../../User/User';
import { ItemAskStatus, ItemAskTimestamps, RawItemAskData, ItemAskType } from './types';
import { Condition } from 'models/types';
import { AppConfig } from 'config';

export class ItemAskModel {
    id: string;
    itemId: string;
    item: ItemModel | null;
    userId: string;
    amount: number;
    currency: string;
    status: ItemAskStatus;
    verified: boolean;
    expirationDays: number;
    notes: string;
    condition: Condition | undefined;
    timestamps: ItemAskTimestamps;
    type: string;
    useEscrow: boolean;
    paymentLink?: string;

    constructor(rawItemAskData: RawItemAskData) {
      this.id = rawItemAskData.id || '';
      this.itemId = rawItemAskData.item?.id || rawItemAskData.item_id || '';
      this.item = rawItemAskData.item ? new ItemModel(rawItemAskData.item) : null;
      this.userId = rawItemAskData.user_id || '';
      this.amount = rawItemAskData.amount || 0;
      this.currency = rawItemAskData.currency || 'USD';
      this.status = rawItemAskData.status || ItemAskStatus.PENDING;
      this.verified = rawItemAskData.verified || false;
      this.expirationDays = rawItemAskData.expiration_days || 0;
      this.notes = rawItemAskData.notes || '';
      this.condition = rawItemAskData.condition || undefined;
      this.paymentLink = '';
      this.useEscrow = rawItemAskData.use_escrow || false;
      this.timestamps = {
        createdAt: moment(rawItemAskData.timestamps?.created_at),
        updatedAt: moment(rawItemAskData.timestamps?.updated_at),
        expiresAt: moment(rawItemAskData.timestamps?.expires_at)
      };

      this.type = ItemAskType;

    }
  
    public isExpired(): boolean {
      return this.status === ItemAskStatus.EXPIRED;
    }

    public isAccepted(): boolean {
      return this.status === ItemAskStatus.ACCEPTED;
    }

    public isComplete() : boolean {
      return this.status === ItemAskStatus.COMPLETE;
    }

    public isVerified(): boolean {
      return this.verified;
    }
  
    public getFormattedAmount(): string {
      return this.amount.toLocaleString('en-US', {
        style: 'currency',
        currency: this.currency,
      });
    }
  
    public getTimeRemainingForDisplay(): string {
      const duration = moment.duration(this.timestamps.expiresAt.diff(moment()));
      return `${duration.days()} days, ${duration.hours()} hours`;
    }

    public getDaysLeft(): number {
      const duration = moment.duration(this.timestamps.expiresAt.diff(moment()));
      return Math.floor(duration.asDays());
    }

    public getCreatedDaysAgo(): number {
      const duration = moment.duration(this.timestamps.createdAt.diff(moment()));
      return Math.abs(Math.floor(duration.asDays()));
    }

    public getUpdatedDaysAgo(): number {
      const duration = moment.duration(this.timestamps.updatedAt.diff(moment()));
      return Math.abs(Math.floor(duration.asDays()));
    }

    public hasBeenUpdated(): boolean {
      return this.getUpdatedDaysAgo() < this.getCreatedDaysAgo();
    }

    public hasItemData(): boolean {
      return this.item != null;
    }

    public setItem(item: ItemModel): void {
      this.item = item;
    }

    public belongsToUser(user: UserModel): boolean {
      return this.userId === user.id;
    }

    public getCondition(): Condition | undefined {
      return this.condition;
    }

    public getPlatformFeePercentage(): number {
      return AppConfig.PLATFORM_BUYER_FEE_PERCENTAGE;
    }

    public getEscrowFeePercentage(): number {
      return AppConfig.PLATFORM_BUYER_FEE_PERCENTAGE;
    }

    public getPlatformFeeAmount(): number {
      const platformBuyerFees = AppConfig.PLATFORM_BUYER_FEE_PERCENTAGE;
      return this.amount * (platformBuyerFees / 100);
    }

    public getEscrowFeeAmount(): number {
      const platformBuyerFees = AppConfig.PLATFORM_BUYER_FEE_PERCENTAGE;
      return this.amount * (platformBuyerFees / 100);
    }

    public getBuyerTotalAmount(useEscrow?: boolean): number {
      const platformFeeAmount = this.getPlatformFeeAmount();
      const escrowFeeAmount = useEscrow ? this.getEscrowFeeAmount() : 0;
      const totalAmount = this.amount + platformFeeAmount + escrowFeeAmount;
      return totalAmount;
    }

    public getFormattedPlatformFeeAmount(): string {
      return this.getPlatformFeeAmount().toLocaleString('en-US', {
        style: 'currency',
        currency: this.currency,
      });
    }

    public getFormattedEscrowFeeAmount(): string {
      return this.getEscrowFeeAmount().toLocaleString('en-US', {
        style: 'currency',
        currency: this.currency,
      });
    }

    public getFormattedTotalAmount(useEscrow?: boolean): string {
      return this.getBuyerTotalAmount(useEscrow).toLocaleString('en-US', {
        style: 'currency',
        currency: this.currency,
      });
    }

    public getPaymentLink(): string {
      return this.paymentLink || '';
    }

    public setPaymentLink(link: string) {
      this.paymentLink = link;
    }
  }