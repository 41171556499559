import { Badge, Box, HStack, Text } from '@chakra-ui/react';
import { Condition } from 'models/types';

export interface ConditionBadgeProps {
  condition: Condition;
  customLabel?: string;
}

export default function ConditionBadge({
  condition,
  customLabel,
}: ConditionBadgeProps) {
    const defaultOptionColors: Record<Condition, string> = {
		[Condition.POOR]: 'red.600',
		[Condition.FAIR]: 'yellow.300',
		[Condition.GOOD]: 'green.200',
		[Condition.EXCELLENT]: 'green.700',
		[Condition.MINT]: 'purple.700',
	};
  const label = customLabel || condition;

  return (
    <Badge px={2} borderRadius="md">
      <HStack spacing={1}>
        <Box boxSize={2} borderRadius="full" bg={defaultOptionColors[condition]} />
        <Text fontSize="xs">{label}</Text>
      </HStack>
    </Badge>
  );
};