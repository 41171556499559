import {
    Box,
    Heading,
    Stack,
    Text,
    Avatar,
    useColorModeValue,
  } from '@chakra-ui/react';
  import ActionButton from '../Button/ActionButton';
  import keyholeGif from '../../assets/gif/keyhole.gif';
  
  export interface LockedContentProps {
    header?: string;
    description?: string;
    buttonText?: string;
    onButtonClick?: () => void;
    showButton?: boolean;
    showAvatar?: boolean;
  }
  
  export default function LockedContent({
    header = 'Content Locked',
    description = 'Please Log In To View Content.',
    buttonText = 'Log In',
    onButtonClick = () => {},
    showButton = true,
    showAvatar = true,
  }: LockedContentProps) {
    function renderAvatar() {
      if (showAvatar) {
        return <Avatar src={keyholeGif} size="xl" marginBottom="24px" />;
      }
    }
  
    function renderDescription() {
      if (description) {
        return <Text textAlign="center">{description}</Text>;
      }
    }
  
    function renderButton() {
      if (showButton) {
        return <ActionButton text={buttonText} onClick={onButtonClick} />;
      }
    }
  
    return (
      <Box
        backgroundColor={useColorModeValue('white', 'black')}
        ml={0}
        mr={0}
        borderRadius={8}
        boxShadow="0px 4px 10px rgba(0, 0, 0, 0.05)"
      >
        <Box height="40px" />
        <Stack
          alignItems="center"
          justifyContent="center"
          paddingLeft={16}
          paddingRight={16}
          paddingBottom={16}
          spacing={6}
        >
          <Stack
            spacing={2}
            borderRadius={8}
            alignItems="center"
            justifyContent="center"
          >
            {renderAvatar()}
            <Heading size="lg" textAlign="center">
              {header}
            </Heading>
            {renderDescription()}
          </Stack>
          {renderButton()}
        </Stack>
      </Box>
    );
  }
  