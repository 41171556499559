import { useState, useMemo } from 'react';
import {
	Area, YAxis, XAxis, ResponsiveContainer, ReferenceLine, CartesianGrid, ComposedChart, Tooltip, Legend
} from 'recharts';
import { PortfolioHistoryModel } from 'models';
import { MarketDateFilter } from 'models/types';
import { PriceYTick, TimeseriesXTick } from './Ticks';
import { MarketDateFilters } from './Filters';
import { PortfolioHistoryChartTooltip } from './Tooltips';
import {
	Flex,
	Text,
	Box,
	useBreakpointValue
} from '@chakra-ui/react';
import RefreshBadge from 'components/RefreshBadge/RefreshBadge';
import { gradientOffset, getXAxisTicksAmountForTimeChart } from 'utils/chart';

interface PortfolioHistoryChartProps {
	portfolioHistoryData: PortfolioHistoryModel;
	showLastCalculationDate?: boolean;
	showBorder?: boolean;
}

function PortfolioHistoryChart({ portfolioHistoryData, showLastCalculationDate, showBorder }: PortfolioHistoryChartProps) {
	const [filter, setFilter] = useState<MarketDateFilter>(MarketDateFilter.MAX);
	const isMobile = useBreakpointValue({ base: true, md: false });

	const { filteredData } = useMemo(
		() => {
			const filteredData = portfolioHistoryData.filterDataByMarketDateFilter(filter, true);
			return { filteredData };
		}, [filter, portfolioHistoryData]
	);

	const { off, fullRed } = useMemo(() => {
		const off = gradientOffset(filteredData, 'value', 0);
		const startPoint = filteredData[0]?.value ?? 0;
		const endPoint = filteredData[filteredData.length - 1]?.value ?? 0;
		const fullRed = startPoint > endPoint;
		return { off, fullRed };
	}, [filteredData]);

	const renderTitle = () => {
		return (
			<Flex direction={'row'} gap={4} alignItems={'center'}>
				<Text textAlign="left" fontSize='md' fontWeight='bold' mb='6px'>
					Portfolio History
				</Text>
				{portfolioHistoryData.recalculate && <RefreshBadge label={'Updating'} />}
			</Flex>
		);
	};

	const renderXAxis = () => {
		const totalTicks = getXAxisTicksAmountForTimeChart(!!isMobile, filter);
		const dataLength = filteredData.length;

		let interval = Math.ceil(dataLength / totalTicks);

		return (
			<XAxis tick={(props) => <TimeseriesXTick {...props} data={filteredData} />} interval={interval} />
		);
	};

	const renderChartData = () => {
		return (
			<ComposedChart data={filteredData}>
				<CartesianGrid horizontal vertical={false} />
				{renderXAxis()}
				<YAxis tick={PriceYTick} />
				<Tooltip content={(props) => <PortfolioHistoryChartTooltip {...props} />} />
				<Area
					name="Value"
					dataKey="value"
					fill="url(#splitColor)"
					stroke="url(#splitColor)"
					type="monotone"
					legendType="rect"
				/>
				<Area
					type="monotone" name="Cost" dataKey="cost" fill="#8884d8" stroke="none"
				/>
				<defs>
					<linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
						<stop offset={off} stopColor={fullRed ? "red" : "green"} stopOpacity={1} />
						{!fullRed && <stop offset={off} stopColor="red" stopOpacity={1} />}
					</linearGradient>
				</defs>
				<ReferenceLine y={0} stroke="#000" strokeDasharray="3 3" />
				<Legend iconType='circle' iconSize={14} wrapperStyle={{ fontSize: "12px", fontWeight: "bold" }} />
			</ComposedChart>
		);
	};

	function renderLastCalculationDate() {
		return (
			<Text fontWeight='light' textAlign={'start'} fontSize={'xs'}>
				{portfolioHistoryData.lastCalculationDate?.format('llll')}
			</Text>
		)
	}

	function renderFilters() {
		return (
			<MarketDateFilters selectedFilter={filter} onFilterSelect={setFilter} />
		);
	}

	return (
		<Box>
			<Flex gap={2} paddingX={4} paddingY={2} direction='column' alignSelf='flex-start' width={'100%'} alignItems={{ base: 'center', 'md': 'flex-start' }}>
				{renderTitle()}
				{showLastCalculationDate && renderLastCalculationDate()}
				{renderFilters()}
			</Flex>
			<Box w='100%' height="300px" justifyContent="center" alignItems="center">
				<ResponsiveContainer>
					{renderChartData()}
				</ResponsiveContainer>
			</Box>
		</Box>
	);
}

export default PortfolioHistoryChart;
