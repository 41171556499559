import { UserModel, PortfolioModel, WishlistModel, ArtistModel, AddressModel } from 'models';
import { JSONObject } from 'models/Api/types';
import { ItemActiveMarketData } from 'models/Item/types';
import { UserOrderInfo } from 'models/User/User';
import { UserActionTypes, UserAction } from './types';

export class UserActions {
  public static setUser(user: UserModel): UserAction {
    return {
      type: UserActionTypes.SET_USER,
      payload: { user }
    };
  }

  public static clearUser(): UserAction {
    return {
      type: UserActionTypes.CLEAR_USER
    };
  }

  public static getUserPortfolio(id: string): UserAction {
    return {
      type: UserActionTypes.GET_USER_PORTFOLIO,
      payload: { id }
    };
  }

  public static getUserWishlist(id: string): UserAction {
    return {
      type: UserActionTypes.GET_USER_WISHLIST,
      payload: { id }
    };
  }

  public static getUserActiveMarket(): UserAction {
    return {
      type: UserActionTypes.GET_USER_ACTIVE_MARKET
    };
  }

  public static getUserOrders(): UserAction {
    return {
      type: UserActionTypes.GET_ORDERS
    };
  }

  public static getUserFollowingArtists(): UserAction {
    return {
      type: UserActionTypes.GET_USER_FOLLOWING_ARTISTS
    };
  }

  public static followArtist(artistId: string): UserAction {
    return {
      type: UserActionTypes.FOLLOW_ARTIST,
      payload: { artistId }
    };
  }

  public static unfollowArtist(artistId: string): UserAction {
    return {
      type: UserActionTypes.UNFOLLOW_ARTIST,
      payload: { artistId }
    };
  }

  public static migrateImage(imageUrl?: string): UserAction {
    return {
      type: UserActionTypes.MIGRATE_USER_IMAGE,
      payload: { imageUrl }
    }
  }

  public static changePassword(oldPassword: string, newPassword: string): UserAction {
    return {
      type: UserActionTypes.CHANGE_USER_PASSWORD,
      payload: { oldPassword, newPassword }
    };
  }

  public static sendConfirmationEmail(): UserAction {
    return {
      type: UserActionTypes.SEND_CONFIRMATION_EMAIL,
    };
  }

  public static sendForgotPasswordEmail(email: string): UserAction {
    return {
      type: UserActionTypes.SEND_FORGOT_PASSWORD_EMAIL,
      payload: { email }
    };
  }

  public static confirmUser(token: string): UserAction {
    return {
      type: UserActionTypes.CONFIRM_EMAIL,
      payload: { token }
    };
  }

  public static changeForgottenPassword(token: string, newPassword: string): UserAction {
    return {
      type: UserActionTypes.CHANGE_FORGOTTEN_PASSWORD,
      payload: { token, newPassword }
    };
  }

  public static joinUserWaitlist(email: string): UserAction {
    return {
      type: UserActionTypes.JOIN_USER_WAITLIST,
      payload: { email }
    };
  }

  public static sendInboundContactRequest(email: string, name: string, message: string): UserAction {
    return {
      type: UserActionTypes.SEND_INBOUND_CONTACT_REQUEST,
      payload: { email, name, message }
    };
  }

  public static updateUserData(updatedData: JSONObject) {
    return {
      type: UserActionTypes.UPDATE_USER_DATA,
      payload: { updatedData }
    };
  }

  public static deleteItemBid(itemBidId: string, itemId: string): UserAction {
    return {
      type: UserActionTypes.DELETE_ITEM_BID,
      payload: { itemBidId, itemId }
    };
  }

  public static deleteItemAsk(itemAskId: string, itemId: string): UserAction {
    return {
      type: UserActionTypes.DELETE_ITEM_ASK,
      payload: { itemAskId, itemId }
    };
  }

  public static updateItemBid(itemBidId: string, itemId: string, bidData: JSONObject): UserAction {
    return {
      type: UserActionTypes.UPDATE_ITEM_BID,
      payload: { itemBidId, itemId, bidData }
    };
  }

  public static updateItemAsk(itemAskId: string, itemId: string, askData: JSONObject): UserAction {
    return {
      type: UserActionTypes.UPDATE_ITEM_ASK,
      payload: { itemAskId, itemId, askData }
    };
  }

  public static activateSelling(): UserAction {
    return {
      type: UserActionTypes.ACTIVATE_SELLING
    };
  }

  public static generateSellerOnboardingLink(): UserAction {
    return {
      type: UserActionTypes.GENERATE_SELLER_ONBOARDING_LINK
    };
  }

  public static generateSellerDashboardLink(): UserAction {
    return {
      type: UserActionTypes.GENERATE_SELLER_DASHBOARD_LINK
    };
  }

  public static addPrimaryAddress(address: AddressModel): UserAction {
    return {
      type: UserActionTypes.ADD_PRIMARY_ADDRESS,
      payload: { address }
    };
  }
}

export class UserInternalActions {
  public static getUserPortfolioSuccess(portfolio: PortfolioModel): UserAction {
    return {
      type: UserActionTypes.GET_USER_PORTFOLIO_SUCCESS,
      payload: { portfolio }
    };
  }

  public static getUserPortfolioFailure(): UserAction {
    return {
      type: UserActionTypes.GET_USER_PORTFOLIO_FAILURE
    };
  }

  public static getUserWishlistSuccess(wishlist: WishlistModel): UserAction {
    return {
      type: UserActionTypes.GET_USER_WISHLIST_SUCCESS,
      payload: { wishlist }
    };
  }

  public static getUserWishlistFailure(): UserAction {
    return {
      type: UserActionTypes.GET_USER_WISHLIST_FAILURE
    };
  }

  public static getUserFollowingArtistsSuccess(artistList: ArtistModel[]): UserAction {
    return {
      type: UserActionTypes.GET_USER_FOLLOWING_ARTISTS_SUCCESS,
      payload: { artistList }
    };
  }

  public static getUserFollowingArtistsFailure(): UserAction {
    return {
      type: UserActionTypes.GET_USER_FOLLOWING_ARTISTS_FAILURE
    };
  }

  public static followArtistSuccess(): UserAction {
    return {
      type: UserActionTypes.FOLLOW_ARTIST_SUCCESS
    };
  }

  public static followArtistFailure(): UserAction {
    return {
      type: UserActionTypes.FOLLOW_ARTIST_FAILURE
    };
  }

  public static unfollowArtistSuccess(): UserAction {
    return {
      type: UserActionTypes.UNFOLLOW_ARTIST_SUCCESS
    };
  }

  public static unfollowArtistFailure(): UserAction {
    return {
      type: UserActionTypes.UNFOLLOW_ARTIST_FAILURE
    };
  }

  public static migrateImageSuccess(newImageUrl: string): UserAction {
    return {
      type: UserActionTypes.MIGRATE_USER_IMAGE_SUCCESS,
      payload: { imageUrl: newImageUrl }
    }
  }

  public static migrateImageFailure(): UserAction {
    return {
      type: UserActionTypes.MIGRATE_USER_IMAGE_FAILURE,
    }
  }

  public static changePasswordSuccess(): UserAction {
    return {
      type: UserActionTypes.CHANGE_USER_PASSWORD_SUCCESS
    };
  }

  public static changePasswordFailure(): UserAction {
    return {
      type: UserActionTypes.CHANGE_USER_PASSWORD_FAILURE
    };
  }

  public static sendConfirmationEmailSuccess(): UserAction {
    return {
      type: UserActionTypes.SEND_CONFIRMATION_EMAIL_SUCCESS
    };
  }

  public static sendConfirmationEmailFailure(): UserAction {
    return {
      type: UserActionTypes.SEND_CONFIRMATION_EMAIL_FAILURE
    };
  }

  public static sendForgotPasswordEmailSuccess(): UserAction {
    return {
      type: UserActionTypes.SEND_FORGOT_PASSWORD_EMAIL_SUCCESS
    };
  }

  public static sendForgotPasswordEmailFailure(): UserAction {
    return {
      type: UserActionTypes.SEND_FORGOT_PASSWORD_EMAIL_FAILURE
    };
  }

  public static confirmUserSuccess(user: UserModel): UserAction {
    return {
      type: UserActionTypes.CONFIRM_EMAIL_SUCCESS,
      payload: { user }
    };
  }

  public static confirmUserFailure(): UserAction {
    return {
      type: UserActionTypes.CONFIRM_EMAIL_FAILURE
    };
  }

  public static changeForgottenPasswordSuccess(): UserAction {
    return {
      type: UserActionTypes.CHANGE_FORGOTTEN_PASSWORD_SUCCESS
    };
  }

  public static changeForgottenPasswordFailure(): UserAction {
    return {
      type: UserActionTypes.CHANGE_FORGOTTEN_PASSWORD_FAILURE
    };
  }

  public static joinUserWaitlistSuccess(): UserAction {
    return {
      type: UserActionTypes.JOIN_USER_WAITLIST_SUCCESS
    };
  }

  public static joinUserWaitlistFailure(): UserAction {
    return {
      type: UserActionTypes.JOIN_USER_WAITLIST_FAILURE
    };
  }

  public static sendInboundContactRequestSuccess(): UserAction {
    return {
      type: UserActionTypes.SEND_INBOUND_CONTACT_REQUEST_SUCCESS
    };
  }

  public static sendInboundContactRequestFailure(): UserAction {
    return {
      type: UserActionTypes.SEND_INBOUND_CONTACT_REQUEST_FAILURE
    };
  }

  public static updateUserDataSuccess(user: UserModel): UserAction {
    return {
      type: UserActionTypes.UPDATE_USER_DATA_SUCCESS,
      payload: { user }
    };
  }

  public static updateUserDataFailure(): UserAction {
    return {
      type: UserActionTypes.UPDATE_USER_DATA_FAILURE
    };
  }

  public static getUserActiveMarketSuccess(activeMarket: ItemActiveMarketData): UserAction {
    return {
      type: UserActionTypes.GET_USER_ACTIVE_MARKET_SUCCESS,
      payload: { activeMarket }
    };
  }

  public static getUserActiveMarketFailure(): UserAction {
    return {
      type: UserActionTypes.GET_USER_ACTIVE_MARKET_FAILURE
    };
  }

  public static getUserOrdersSuccess(orders: UserOrderInfo): UserAction {
    return {
      type: UserActionTypes.GET_ORDERS_SUCCESS,
      payload: { orders }
    };
  }

  public static getUserOrdersFailure(): UserAction {
    return {
      type: UserActionTypes.GET_ORDERS_FAILURE
    };
  }

  public static deleteItemBidSuccess(): UserAction {
    return {
      type: UserActionTypes.DELETE_ITEM_BID_SUCCESS,
    };
  }

  public static deleteItemBidFailure(): UserAction {
    return {
      type: UserActionTypes.DELETE_ITEM_BID_FAILURE
    };
  }

  public static deleteItemAskSuccess(): UserAction {
    return {
      type: UserActionTypes.DELETE_ITEM_ASK_SUCCESS,
    };
  }

  public static deleteItemAskFailure(): UserAction {
    return {
      type: UserActionTypes.DELETE_ITEM_ASK_FAILURE
    };
  }

  public static updateItemBidSuccess(itemId: string): UserAction {
    return {
      type: UserActionTypes.UPDATE_ITEM_BID_SUCCESS,
      payload: { itemId }
    };
  }

  public static updateItemBidFailure(): UserAction {
    return {
      type: UserActionTypes.UPDATE_ITEM_BID_FAILURE
    };
  }

  public static updateItemAskSuccess(itemId: string): UserAction {
    return {
      type: UserActionTypes.UPDATE_ITEM_ASK_SUCCESS,
      payload: { itemId }
    };
  }

  public static updateItemAskFailure(): UserAction {
    return {
      type: UserActionTypes.UPDATE_ITEM_ASK_FAILURE
    };
  }

  public static activateSellingSuccess(user: UserModel): UserAction {
    return {
      type: UserActionTypes.ACTIVATE_SELLING_SUCCESS,
      payload: { user }
    };
  }

  public static activateSellingFailure(): UserAction {
    return {
      type: UserActionTypes.ACTIVATE_SELLING_FAILURE,
    };
  }

  public static generateSellerOnboardingLinkSuccess(onboardingLink: string): UserAction {
    return {
      type: UserActionTypes.GENERATE_SELLER_ONBOARDING_LINK_SUCCESS,
      payload: { onboardingLink }
    };
  }

  public static generateSellerOnboardingLinkFailure(): UserAction {
    return {
      type: UserActionTypes.GENERATE_SELLER_ONBOARDING_LINK_FAILURE,
    };
  }

  public static generateSellerDashboardLinkSuccess(dashboardLink: string): UserAction {
    return {
      type: UserActionTypes.GENERATE_SELLER_DASHBOARD_LINK_SUCCESS,
      payload: { dashboardLink }
    };
  }

  public static generateSellerDashboardLinkFailure(): UserAction {
    return {
      type: UserActionTypes.GENERATE_SELLER_DASHBOARD_LINK_FAILURE,
    };
  }

  public static addPrimaryAddressSuccess(address: AddressModel): UserAction {
    return {
      type: UserActionTypes.ADD_PRIMARY_ADDRESS_SUCCESS,
      payload: { address }
    };
  }

  public static addPrimaryAddressFailure(): UserAction {
    return {
      type: UserActionTypes.ADD_PRIMARY_ADDRESS_FAILURE
    };
  }
}
