import { Condition } from 'models/types';
import { Moment } from 'moment';
import { RawItemData } from '../types';

export interface ItemAskTimestamps {
  createdAt: Moment;
  updatedAt: Moment;
  expiresAt: Moment;
}

export enum ItemAskStatus {
  ACTIVE = 'Active',
  EXPIRED = 'Expired',
  REJECTED = 'Rejected',
  PENDING = 'Pending',
  VERIFIED = 'Verified',
  COMPLETE = 'Complete',
  ACCEPTED = 'Accepted'
}

export interface RawItemAskData {
  id: string;
  item_id?: string;
  item?: RawItemData;
  user_id: string;
  amount: number;
  currency: string;
  status: ItemAskStatus;
  verified?: boolean;
  expiration_days?: number;
  notes: string;
  condition: Condition;
  use_escrow?: boolean;
  timestamps?: {
    created_at: string;
    updated_at: string;
    expires_at: string;
  };
}

export const ItemAskType = 'Ask';