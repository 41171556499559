import { Component } from 'react';
import { connect, Dispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { RouterProps, withRouter } from "utils/route";
import { generateSEOTitle } from "utils/seo";
import { ApplicationState } from 'reducers/types';
import { WishlistSelectors } from 'reducers/Wishlist/selectors';
import { UserSelectors } from 'reducers/User/selectors';
import { AuthSelectors } from 'reducers/Auth/selectors';
import { ArtistSelectors } from 'reducers/Artist/selectors';
import { WishlistActions } from 'reducers/Wishlist/actions';
import {
  Box,
  Flex,
  Spacer,
  Text,
  Icon
} from '@chakra-ui/react';
import { WishlistModel, ItemModel, UserModel, ArtistModel } from 'models';
import { ItemGrid, Loader, EmptyState, TabGroup, UserWishlistBadge, ArtistGrid, ArtistDistributionPieChart, TypeDistributionPieChart, PaginatedListingGrid, SEOHelmet } from 'components';
import { NavigationService } from 'services';
import { AppConstants } from '../../constants';
import { ImStatsDots } from 'react-icons/im'
import { IoGridOutline } from 'react-icons/io5';
import { AiFillFormatPainter } from 'react-icons/ai';
import { RiAuctionLine } from 'react-icons/ri';
import { QuerySearchParameters } from 'constants/url';

interface WishlistDetailsViewProps extends RouterProps {
  wishlist: WishlistModel;
  user: UserModel;
  wishlistLoading: boolean;
  wishlistActionLoading: boolean;
  wishlistMarketLoading: boolean;
  wishlistListingsLoading: boolean;
  getWishlistById: (id: string) => void;
  getWishlistListings: (id: string, page?: number) => void;
  artistList: ArtistModel[];
  userLoading: boolean;
}

interface WishlistDetailsState { }

class WishlistDetails extends Component<WishlistDetailsViewProps, WishlistDetailsState> {
  componentDidMount() {
    const { params, getWishlistById } = this.props;
    if (params && params.id) {
      getWishlistById(params.id);
    }
  }

  componentDidUpdate(prevProps: WishlistDetailsViewProps) {
    const { params, getWishlistById } = this.props;

    if (params.id && this.hasRouteChanged(prevProps)) {
      getWishlistById(params.id);
    }
  }

  hasRouteChanged = (prevProps: WishlistDetailsViewProps): boolean => {
    const { params } = this.props;
    return !!params.id && (params.id !== prevProps.params.id);
  };

  onItemClick = (item: ItemModel) => {
    this.props.navigate(NavigationService.getItemDetailsPath(item.id, item.getDisplayName()));
  };

  onArtistClick = (artist: ArtistModel) => {
    this.props.navigate(
      NavigationService.getArtistDetailsPath(artist.id, artist.name)
    );
  };

  onBrowseItems = () => {
    this.props.navigate(
      NavigationService.getExplorePath() + `?${QuerySearchParameters.TAB}=1`
    );
  };

  onBrowseArtists = () => {
    this.props.navigate(
      NavigationService.getArtistsPath()
    );
  };

  onPaginatedListingPageSelect = (page: number) => {
    const { wishlist, getWishlistListings } = this.props;
    getWishlistListings(wishlist.id, page);
  }

  isFetchingWishlistData = (): boolean => {
    const { wishlistLoading, wishlistActionLoading } = this.props;
    return wishlistLoading || wishlistActionLoading;
  };


  getWishlistArtists = (): ArtistModel[] => {
    const { artistList, wishlist } = this.props;
    return wishlist.getArtistIds()
      .map(artistId => artistList.find(artist => artist.id === artistId))
      .filter((artist): artist is ArtistModel => artist !== undefined);
  };


  renderProfileSectionHeader(header: string) {
    return (
      <Flex p={{ base: '12px 20px', md: '12px 0px' }} mb='12px'>
        <Text fontSize='lg' fontWeight='bold'>
          {header}
        </Text>
        <Spacer />
      </Flex>
    )
  }

  renderWishlistItems() {
    const { wishlist } = this.props;

    return (
      <Box my={{ sm: "24px", xl: "0px" }} textAlign={{ base: 'center', md: 'left' }} borderRadius="16px">
        {this.renderProfileSectionHeader('Items')}
        <ItemGrid items={wishlist.getWishlistItems()} isLoading={this.isFetchingWishlistData()} gridDisplay={true} onItemCardClick={this.onItemClick} loadingRows={10} onBrowseClick={this.onBrowseItems} />
      </Box>
    );
  }

  renderWishlistArtists() {
    const artists = this.getWishlistArtists();
    return (
      <Box my={{ sm: "24px", xl: "0px" }} textAlign={{ base: 'center', md: 'left' }} borderRadius="16px">
        {this.renderProfileSectionHeader('Artists')}
        <ArtistGrid onArtistCardClick={this.onArtistClick} artists={artists} gridDisplay={true} isLoading={this.isFetchingWishlistData()} onBrowseClick={this.onBrowseArtists} />
      </Box>
    );
  }

  renderDistributionCharts() {
    const { wishlist, artistList } = this.props;
    return (
      <Flex direction={{ base: 'column', md: 'row' }} gap={2}>
        <ArtistDistributionPieChart items={wishlist.getWishlistItems()} artists={artistList} />
        <TypeDistributionPieChart items={wishlist.getWishlistItems()} />
      </Flex>
    );
  }

  renderWishlistStats() {
    const { wishlist, wishlistLoading } = this.props;
    let content;

    if (!wishlistLoading && wishlist.hasItems()) {
      content = this.renderDistributionCharts();
    } else if (wishlistLoading) {
      content = <Loader />
    } else {
      content = (
        <EmptyState header="No Stats" description="No Items in Wishlist" showButton={false} />
      );
    }

    return (
      <Box my={{ sm: "24px", xl: "0px" }} textAlign={{ base: 'center', md: 'left' }} borderRadius="16px">
        {this.renderProfileSectionHeader('Statistics')}
        {content}
      </Box>
    );
  }

  renderWishlistListings() {
    const { wishlist, wishlistListingsLoading } = this.props;
    const listings = wishlist.listings;
    return (
      <Box my={{ sm: "24px", xl: "0px" }} textAlign={{ base: 'center', md: 'left' }} borderRadius="16px">
        {this.renderProfileSectionHeader('Listings')}
        {listings && <PaginatedListingGrid paginatedListings={listings} isLoading={wishlistListingsLoading} scrollMode={false} showAssociationAction={false} onPageSelect={this.onPaginatedListingPageSelect} />}
      </Box>
    );
  }

  renderTabs() {
    const labels = [
      <Flex gap="4px" alignItems="center" direction={{ base: 'column', md: 'row' }}>
        <Icon as={IoGridOutline} />
        <Text> Items </Text>
      </Flex>,
      <Flex gap="4px" alignItems="center" direction={{ base: 'column', md: 'row' }}>
        <Icon as={ImStatsDots} />
        <Text> Stats </Text>
      </Flex>,
      <Flex gap="4px" alignItems="center" direction={{ base: 'column', md: 'row' }}>
        <Icon as={AiFillFormatPainter} />
        <Text> Artists </Text>
      </Flex>,
      <Flex gap="4px" alignItems="center" direction={{ base: 'column', md: 'row' }}>
        <Icon as={RiAuctionLine} />
        <Text> Sales </Text>
      </Flex>
    ];

    const content = [
      this.renderWishlistItems(),
      this.renderWishlistStats(),
      this.renderWishlistArtists(),
      this.renderWishlistListings()
    ];

    return (
      <TabGroup labels={labels} content={content} />
    );
  }


  renderContent() {
    const { user, wishlist, userLoading, wishlistLoading } = this.props;
    return (
      <Box>
        <UserWishlistBadge user={user} wishlist={wishlist} userLoading={userLoading} dataLoading={wishlistLoading} />
        {this.renderTabs()}
      </Box>
    );
  }

  render() {
    return (
      <>
        <SEOHelmet
          title={generateSEOTitle('Wishlist')}
          description={`Real-time price tracking and insights for your favorite art pieces`}
        />
        <Box maxWidth={`${AppConstants.GRIDPAGE_WIDTH}px`} paddingTop={['80px', '100px', '100px']} justifySelf="center" minWidth={['100%', `${AppConstants.GRIDPAGE_WIDTH}px`]}>
          {this.renderContent()}
        </Box>
      </>
    );
  }
}

function mapStateToProps(state: ApplicationState) {
  return {
    wishlist: WishlistSelectors.getWishlist(state),
    wishlistLoading: WishlistSelectors.getWishlistLoading(state),
    wishlistActionLoading: WishlistSelectors.getWishlistActionLoading(state),
    wishlistMarketLoading: WishlistSelectors.getWishlistMarketLoading(state),
    wishlistListingsLoading: WishlistSelectors.getWishlistListingsLoading(state),
    user: UserSelectors.getUser(state),
    artistList: ArtistSelectors.getArtistList(state),
    userLoading: UserSelectors.getUserLoading(state) || AuthSelectors.getUserLoading(state),
  }
}

function mapDispatchToProps(dispatch: Dispatch<ApplicationState>) {
  return bindActionCreators(
    {
      getWishlistById: (id: string) => WishlistActions.getWishlistById(id),
      getWishlistListings: (id: string, page?: number) => WishlistActions.getWishlistListings(id, page),
    },
    dispatch
  );
}

export const WishlistDetailsView = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(WishlistDetails));
