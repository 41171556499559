import { Component } from 'react';
import { connect, Dispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { RouterProps, withRouter, getItemActionRoute } from "utils/route";
import { ApplicationState } from 'reducers/types';
import { UserActions } from 'reducers/User/actions';
import { UserSelectors } from 'reducers/User/selectors';
import {
  Box,
  Flex,
  Heading,
  Spacer,
  Icon,
  Stack
} from '@chakra-ui/react';
import { NavigationService } from 'services';
import { ArtistModel, ItemBidModel, ItemAskModel, ItemModel, UserModel } from 'models';
import { Hero, ArtistAvatarGrid, ItemGrid, WaitlistCard, CategoryCard, Slider, ListingGrid, ArtistAvatarGridSkeleton, TradingFloor, InfoGuide, ArtistIndexBanner, ArtistIndexBannerSkeleton, SEOHelmet } from 'components';
import { getSampleSizedArray } from 'utils/array';
import { FiArrowRight } from 'react-icons/fi';
import { BsFillArrowRightCircleFill } from 'react-icons/bs';
import { AppConstants } from '../../constants';
import { QuerySearchParameters } from 'constants/url';
import { HomeSelectors } from 'reducers/Home/selectors';
import { HomeActions } from 'reducers/Home/actions';
import { HomeFeedData } from 'models/Content/types';
import { capitalize } from 'utils/string';
import { generateSEOTitle, generateSEOLink } from 'utils/seo';


interface HomeViewProps extends RouterProps {
  user: UserModel;
  userWaitlistActionLoading: boolean;
  joinUserWaitlist: (email: string) => void;
  homeFeedData: HomeFeedData | undefined;
  homeFeedDataLoading: boolean;
  hasHomeFeedData: boolean;
  getFeedContent: () => void;
}

class Home extends Component<HomeViewProps> {
  componentDidMount() {
    this.props.getFeedContent();
  }

  onArtistClick = (artist: ArtistModel) => {
    this.props.navigate(NavigationService.getArtistDetailsPath(artist.id, artist.name));
  };

  onItemClick = (item: ItemModel) => {
    this.props.navigate(NavigationService.getItemDetailsPath(item.id, item.getDisplayName()));
  };

  onListingItemClick = (id: string, name: string) => {
    this.props.navigate(NavigationService.getItemDetailsPath(id, name));
  };

  onTradingFloorItemClick = (id: string, name: string) => {
    this.props.navigate(NavigationService.getItemDetailsPath(id, name));
  };

  onListingArtistClick = (id: string, name: string) => {
    this.props.navigate(NavigationService.getArtistDetailsPath(id, name));
  }

  onItemArtistClick = (id: string, name: string) => {
    this.props.navigate(NavigationService.getArtistDetailsPath(id, name));
  }

  onItemBidEditClick = (itemBid: ItemBidModel) => {
    const { user } = this.props;
    this.props.navigate(getItemActionRoute(user, itemBid, 'Edit'));
  }

  onItemBidRemoveClick = (itemBid: ItemBidModel) => {
    const { user } = this.props;
    this.props.navigate(getItemActionRoute(user, itemBid, 'Remove'));
  }

  onItemBidContactClick = (itemBid: ItemBidModel) => {
    const { user } = this.props;
    this.props.navigate(getItemActionRoute(user, itemBid, 'Contact'));
  }

  onItemBidSellClick = (itemBid: ItemBidModel) => {
    const { user } = this.props;
		this.props.navigate(getItemActionRoute(user, itemBid, 'Sell'));
	}

  onItemAskEditClick = (itemAsk: ItemAskModel) => {
    const { user } = this.props;
    this.props.navigate(getItemActionRoute(user, itemAsk, 'Edit'));
  }

  onItemAskRemoveClick = (itemAsk: ItemAskModel) => {
    const { user } = this.props;
    this.props.navigate(getItemActionRoute(user, itemAsk, 'Remove'));
  }

  onItemAskContactClick = (itemAsk: ItemAskModel) => {
    const { user } = this.props;
    this.props.navigate(getItemActionRoute(user, itemAsk, 'Contact'));
  }

  onItemAskBuyClick = (itemAsk: ItemAskModel) => {
    const { user } = this.props;
		this.props.navigate(getItemActionRoute(user, itemAsk, 'Buy'));
	}

  onClickArtistSeeMore = () => {
    this.props.navigate(NavigationService.getArtistsPath());
  }

  onClickTradingFloorSeeMore = () => {
    this.props.navigate(NavigationService.getTradingFloorPath());
  }

  onClickListingsSeeMore = () => {
    this.props.navigate(NavigationService.getExplorePath() + `?${QuerySearchParameters.TAB}=2`);
  }

  onClickFeaturedItemsSeeMore = () => {
    this.props.navigate(NavigationService.getExplorePath() + `?${QuerySearchParameters.TAB}=1`);
  }

  onClickNewReleasedItemsSeeMore = () => {
    this.props.navigate(NavigationService.getItemSearchPath() + `?${QuerySearchParameters.SORT_BY}=release_info.date&${QuerySearchParameters.SORT_TYPE}=descending&${QuerySearchParameters.TAB}=1`);
  }

  onBiggestMoverItemsSeeMore = () => {
    this.props.navigate(NavigationService.getItemSearchPath() + `?${QuerySearchParameters.SORT_BY}=market_summary.last_sale_percentage_change&${QuerySearchParameters.SORT_TYPE}=descending&${QuerySearchParameters.TAB}=1`);
  }

  onClickCategoryCard = (type: string) => {
    this.props.navigate(NavigationService.getItemSearchPath() + `?${QuerySearchParameters.TYPE}=${capitalize(type)}&${QuerySearchParameters.TAB}=1`);
  }

  onExploreButtonClick = () => {
    this.props.navigate(NavigationService.getExplorePath());
  }

  onTradingFloorExploreClick = () => {
    this.props.navigate(NavigationService.getTradingFloorPath());
  }


  getFeaturedItemSectionData = (): ItemModel[] => {
    const { homeFeedData } = this.props;
    return getSampleSizedArray(homeFeedData?.featuredItems || [], 8);
  }

  renderLandingPageHero() {
    const heros = [
      (
        <Hero
          title="Demystifying The Art Market"
          subtitle='We harness the power of AI to provide real-time market valuations, analysis, and sales history.'
          image="https://storage.googleapis.com/artcore-image-set/assets/artcore-logo-rotating.gif"
          ctaText="Explore"
          onCtaClick={this.onExploreButtonClick} />
      ),
      (
        <Hero
          title="Seamless Portfolio Management"
          subtitle='Traditional inventory management systems can be a headache to handle. Our technology handles everything for you.'
          image="https://storage.googleapis.com/artcore-image-set/content-images/Portfolio%20Ex%20New.png"
          ctaText="Explore"
          onCtaClick={this.onExploreButtonClick} />
      ),
      (
        <Hero
          title="Dedicated Marketplace"
          subtitle='Our Trading Floor utilizes our propriety valuation engine and provides a seamless overview of the best deals in the market!'
          image="https://storage.googleapis.com/artcore-image-set/content-images/imageedit_6_5586248441.png"
          ctaText="Explore"
          onCtaClick={this.onTradingFloorExploreClick} />
      )
    ];

    return (
      <Slider content={heros} enableAutoPlay={true} slidesPerView={1} slidesPerMobileView={1} transitionMs={1000} itemPadding={20} />
    );
  }

  renderArtistSection() {
    const { homeFeedData, homeFeedDataLoading } = this.props;

    const content = homeFeedData?.featuredArtists && !homeFeedDataLoading ? (
      <ArtistAvatarGrid artists={homeFeedData?.featuredArtists || []} rows={2} showArtistName={true} onClick={this.onArtistClick} />
    ) : (
      <ArtistAvatarGridSkeleton artistsPerRow={4} rows={2} />
    );

    return (
      <Flex direction='column' textAlign={{ base: 'center', md: 'left' }} gap='60px' marginTop={20}>
        <Flex direction='row' padding={{ base: 3, md: 0 }}>
          <Heading size='md'>{'Featured Artists'}</Heading>
          <Spacer />
          <Flex onClick={this.onClickArtistSeeMore} cursor={'pointer'} gap={1} alignItems={'center'} _hover={{ color: "blue.500" }}>
            <Heading fontSize="sm">See More</Heading>
            <Icon as={BsFillArrowRightCircleFill} />
          </Flex>
        </Flex>
        {content}
      </Flex>
    );
  }

  renderFeaturedItemSection() {
    const { homeFeedDataLoading } = this.props;

    return (
      <Flex direction='column' textAlign={{ base: 'center', md: 'left' }} gap='60px' marginTop={20}>
        <Flex onClick={this.onClickFeaturedItemsSeeMore} direction='row' padding={{ base: 3, md: 0 }}>
          <Heading size='md'>{'Featured Items'}</Heading>
          <Spacer />
          <Flex cursor={'pointer'} gap={1} alignItems={'center'} _hover={{ color: "blue.500" }}>
            <Heading fontSize="sm">See More</Heading>
            <Icon as={BsFillArrowRightCircleFill} />
          </Flex>
        </Flex>
        <ItemGrid onItemCardClick={this.onItemClick} items={this.getFeaturedItemSectionData()} isLoading={homeFeedDataLoading} showFilters={false} loadingRows={2} />
      </Flex>
    );
  }

  renderNewReleasedItemSection() {
    const { homeFeedData, homeFeedDataLoading } = this.props;

    return (
      <Flex direction='column' textAlign={{ base: 'center', md: 'left' }} gap='60px' marginTop={20}>
        <Flex onClick={this.onClickNewReleasedItemsSeeMore} direction='row' padding={{ base: 3, md: 0 }}>
          <Heading size='md'>{'New Releases'}</Heading>
          <Spacer />
          <Flex cursor={'pointer'} gap={1} alignItems={'center'} _hover={{ color: "blue.500" }}>
            <Heading fontSize="sm">See More</Heading>
            <Icon as={BsFillArrowRightCircleFill} />
          </Flex>
        </Flex>
        <ItemGrid onItemCardClick={this.onItemClick} items={homeFeedData?.newReleasedItems.slice(0, 8) || []} isLoading={homeFeedDataLoading} showFilters={false} loadingRows={2} />
      </Flex>
    );
  }

  renderBiggestMoverItemSection() {
    const { homeFeedData, homeFeedDataLoading } = this.props;

    return (
      <Flex direction='column' textAlign={{ base: 'center', md: 'left' }} gap='60px' marginTop={20}>
        <Flex onClick={this.onBiggestMoverItemsSeeMore} direction='row' padding={{ base: 3, md: 0 }}>
          <Heading size='md'>{'Biggest Market Movers'}</Heading>
          <Spacer />
          <Flex cursor={'pointer'} gap={1} alignItems={'center'} _hover={{ color: "blue.500" }}>
            <Heading fontSize="sm">See More</Heading>
            <Icon as={BsFillArrowRightCircleFill} />
          </Flex>
        </Flex>
        <ItemGrid onItemCardClick={this.onItemClick} items={homeFeedData?.biggestMarketMoverItems.slice(0, 4) || []} isLoading={homeFeedDataLoading} showFilters={false} loadingRows={1} />
      </Flex>
    );
  }

  renderListingSection() {
    const { homeFeedData, homeFeedDataLoading } = this.props;

    return (
      <Flex direction='column' textAlign={{ base: 'center', md: 'left' }} gap='60px' marginTop={20}>
        <Flex direction='row' padding={{ base: 3, md: 0 }}>
          <Heading size='md'>{'Past Sales'}</Heading>
          <Spacer />
          <Flex onClick={this.onClickListingsSeeMore} cursor={'pointer'} gap={1} alignItems={'center'} _hover={{ color: "blue.500" }}>
            <Heading fontSize="sm">See More</Heading>
            <Icon as={BsFillArrowRightCircleFill} />
          </Flex>
        </Flex>
        <Stack gap={4}>
          <InfoGuide text={'Note: We Only Show Publicly Available, Verifiable Auction House Sales Data.'} dismissable={true} />
          <Flex maxH={800} overflow={'scroll'}>
            <ListingGrid gridListings={homeFeedData?.latestSales || []} isLoading={homeFeedDataLoading} showAssociatedItems={true} showAssociationAction={false} onListingItemClick={this.onListingItemClick} onListingArtistClick={this.onListingArtistClick} />
          </Flex>
        </Stack>
      </Flex>
    );
  }

  renderNewestArtistsSection() {
    const { homeFeedData, homeFeedDataLoading } = this.props;
    let artistSectionContent: JSX.Element | undefined;
    if (homeFeedDataLoading) {
      artistSectionContent = (
        <ArtistIndexBannerSkeleton />
      );
    } else if (homeFeedData?.newestCreatedArtists?.length && homeFeedData?.newestCreatedArtistsIndexes?.length) {
      const sliderContent: JSX.Element[] = homeFeedData.newestCreatedArtists.map(artist => {
        const indexData = homeFeedData.newestCreatedArtistsIndexes.find(index => index.artistId === artist.id);
        return (
          <ArtistIndexBanner artist={artist} editionIndexData={indexData} buttonText={'Explore'} buttonIcon={<Icon as={FiArrowRight} />} onNameClick={this.onArtistClick} buttonOnClick={this.onArtistClick} />
        );
      });
      artistSectionContent = (
        <Slider content={sliderContent} enableAutoPlay={true} autoPlaySpeed={10000} slidesPerView={1} slidesPerMobileView={1} transitionMs={1000} itemPadding={20} />
      );
    }

    if (artistSectionContent) {
      return (
        <Flex direction='column' textAlign={{ base: 'center', md: 'left' }} gap={{ base: 0, md: 16 }} marginTop={20}>
          <Flex direction='row' padding={{ base: 3, md: 0 }}>
            <Heading size='md'>{'New Artist Additions'}</Heading>
          </Flex>
          <Box height={{ base: 840, md: 600 }}>
            {artistSectionContent}
          </Box>
        </Flex>
      );
    }
  }

  renderTradingFloorSection() {
    const { homeFeedData, homeFeedDataLoading, user } = this.props;
    return (
      <Flex direction='column' textAlign={{ base: 'center', md: 'left' }} gap='60px' marginTop={20}>
        <Flex direction='row' padding={{ base: 3, md: 0 }}>
          <Heading size='md'>{'Trading Floor'}</Heading>
          <Spacer />
          <Flex onClick={this.onClickTradingFloorSeeMore} cursor={'pointer'} gap={1} alignItems={'center'} _hover={{ color: "blue.500" }}>
            <Heading fontSize="sm">See More</Heading>
            <Icon as={BsFillArrowRightCircleFill} />
          </Flex>
        </Flex>
        <TradingFloor
          asks={homeFeedData?.activeMarket?.asks || []}
          bids={homeFeedData?.activeMarket?.bids || []}
          user={user}
          showSearchFilter={true}
          onItemArtistNameClick={this.onItemArtistClick}
          onItemNameClick={this.onTradingFloorItemClick}
          isLoading={homeFeedDataLoading}
          onEditBidClick={this.onItemBidEditClick}
          onRemoveBidClick={this.onItemBidRemoveClick}
          onEditAskClick={this.onItemAskEditClick}
          onRemoveAskClick={this.onItemAskRemoveClick}
          onContactBidClick={this.onItemBidContactClick}
          onContactAskClick={this.onItemAskContactClick}
          onSellBidClick={this.onItemBidSellClick}
          onBuyAskClick={this.onItemAskBuyClick}
        />
      </Flex>
    );
  }

  renderCategoryCards() {
    const content = (
      <Flex direction={{ base: 'column', md: 'row' }} gap='60px' alignItems='center'>
        <CategoryCard
          title="Sculptures"
          imageSrc="https://storage.googleapis.com/artcore-image-set/content-images/Sculpture%20Cover.webp"
          buttonText="Explore"
          onButtonClick={() => this.onClickCategoryCard('sculpture')}
        />
        <CategoryCard
          title="Prints"
          imageSrc="https://storage.googleapis.com/artcore-image-set/content-images/Print%20Cover.webp"
          buttonText="Explore"
          onButtonClick={() => this.onClickCategoryCard('prints')}
        />
        <CategoryCard
          title="Misc."
          imageSrc="https://storage.googleapis.com/artcore-image-set/content-images/Misc%20Cover.webp"
          buttonText="Explore"
          onButtonClick={() => this.onClickCategoryCard('misc.')}
        />
      </Flex>
    );
    return (
      <Flex direction='column' textAlign={{ base: 'center', md: 'left' }} gap='60px' marginTop={20}>
        <Flex direction='row' padding={{ base: 3, md: 0 }}>
          <Heading size='md'>{'Explore Categories'}</Heading>
        </Flex>
        {content}
      </Flex>
    );
  }

  renderWaitlistCard() {
    const { user, joinUserWaitlist, userWaitlistActionLoading } = this.props;
    if (!user.isAuthenticated()) {
      return (
        <Flex marginTop={20}>
          <WaitlistCard onButtonClick={joinUserWaitlist} buttonLoading={userWaitlistActionLoading} />
        </Flex>
      );
    }
  }

  render() {

    return (
      <>
        <SEOHelmet
          title={generateSEOTitle('Home')}
          description={`The Next Generation Art Marketplace. Discover and invest in blue-chip and emerging artists.`}
          link={generateSEOLink(NavigationService.getHomePath())}
        />
        <Box maxWidth={`${AppConstants.GRIDPAGE_WIDTH}px`} paddingTop={['80px', '100px', '100px']} justifySelf="center" minWidth={['100%', `${AppConstants.GRIDPAGE_WIDTH}px`]}>
          {this.renderLandingPageHero()}
          {this.renderWaitlistCard()}
          {this.renderArtistSection()}
          {this.renderNewestArtistsSection()}
          {this.renderFeaturedItemSection()}
          {this.renderCategoryCards()}
          {this.renderBiggestMoverItemSection()}
          {this.renderNewReleasedItemSection()}
          {this.renderTradingFloorSection()}
          {this.renderListingSection()}
        </Box>
      </>
    );
  }
}

function mapStateToProps(state: ApplicationState) {
  return {
    user: UserSelectors.getUser(state),
    userWaitlistActionLoading: UserSelectors.getUserWaitlistActionLoading(state),
    homeFeedData: HomeSelectors.getHomeFeedData(state),
    homeFeedDataLoading: HomeSelectors.getHomeFeedDataLoading(state),
    hasHomeFeedData: HomeSelectors.hasHomeFeedData(state)
  }
}

function mapDispatchToProps(dispatch: Dispatch<ApplicationState>) {
  return bindActionCreators(
    {
      joinUserWaitlist: (email: string) => UserActions.joinUserWaitlist(email),
      getFeedContent: () => HomeActions.getFeedContent()
    },
    dispatch
  );
}

export const HomeView = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Home));
