import {
	Box,
	Button,
	Avatar,
	Flex,
	Heading,
	Text,
	Stack,
	Image,
	Spacer,
	Icon,
	Tooltip,
	useColorModeValue,
	Badge
} from '@chakra-ui/react';
import { ItemModel } from 'models';
import { Condition } from 'models/types';
import { FcClock } from "react-icons/fc";
import { BsTrashFill } from 'react-icons/bs';
import { AiOutlineDollar } from 'react-icons/ai';
import { FiEdit } from 'react-icons/fi';
import { toDollarFormat } from 'utils/format';
import { getMarketValueTextConfig } from 'utils/activemarket';
import { FeatureToggleService } from 'services';
import { FeatureToggleKeys } from 'constants/toggles';
import { MdOutlineMessage } from "react-icons/md";
import GrowthBadge from '../../Badge/GrowthBadge';
import ConditionBadge from '../../ConditionBadge/ConditionBadge';

export interface ItemBidCardProps {
	item: ItemModel;
	bidAmount: number;
	bidCreatedDays?: number;
	bidUpdatedDays?: number;
	condition?: Condition;
	accepted?: boolean;
	complete?: boolean;
	expired?: boolean;
	updated?: boolean;
	useAvatar?: boolean;
	onItemNameClick?: (itemId: string, itemName: string) => void;
	onItemArtistNameClick?: (artistId: string, artistName: string) => void;
	onEditClick?: () => void;
	onRemoveClick?: () => void;
	onContactClick?: () => void;
	onSellClick?: () => void;
	showIcons?: boolean;
	userOwnsBid?: boolean;
	shorten?: boolean;
}

export enum ItemBidCardSizingConfig {
	CARD_HEIGHT = '80px',
	PANEL_MIN_HEIGHT = '80px'
}

export enum ItemBidCardStylingConfig {
	BORDER_COLOR_LIGHT = '#f1f3f7',
	BORDER_COLOR_DARK = '#3f434c'
}

export default function ItemBidCard({
	item,
	bidAmount,
	bidCreatedDays,
	bidUpdatedDays,
	condition,
	accepted,
	complete,
	expired,
	updated,
	onItemNameClick,
	onItemArtistNameClick,
	onEditClick,
	onRemoveClick,
	onContactClick,
	onSellClick,
	useAvatar = false,
	showIcons = false,
	userOwnsBid = false,
	shorten = false
}: ItemBidCardProps) {
	const marketPrice = item.getEstimatedMarketPrice();
	const isMarketValue = bidAmount === marketPrice;
	const marketplaceEnabled = FeatureToggleService.isToggleEnabled(FeatureToggleKeys.ENABLE_MARKETPLACE);

	function renderItemImage() {
		if (useAvatar) {
			return <Avatar backgroundColor={'white'} marginRight={4} name={item.name} src={item.image} />
		} else {
			return (
				<Image
					fit={'contain'}
					marginRight={4}
					src={item.image}
					alt={item.name}
					width={ItemBidCardSizingConfig.CARD_HEIGHT}
					height={ItemBidCardSizingConfig.CARD_HEIGHT}
				/>
			);
		}
	}

	function renderIconWithTooltip(icon: JSX.Element, tooltipLabel: string) {
		return (
			<Tooltip label={tooltipLabel}>
				<Flex>
					{icon}
				</Flex>
			</Tooltip>
		);
	}

	function renderIcons() {
		if (showIcons && !complete && !accepted && !expired) {
			if (userOwnsBid) {
				return (
					<Flex direction={'row'} justifyContent={'flex-end'} gap={1}>
						{renderIconWithTooltip((
							<Icon
								_hover={onEditClick ? { color: "blue.500", cursor: "pointer" } : {}}
								as={FiEdit}
								height={4}
								onClick={onEditClick}
							/>
						), 'Edit Bid')}
						{renderIconWithTooltip((
							<Icon
								_hover={onRemoveClick ? { color: "red.500", cursor: "pointer" } : {}}
								as={BsTrashFill}
								height={4}
								onClick={onRemoveClick}
							/>
						), 'Remove Bid')}
					</Flex>
				);
			} else {
				return (
					<Flex direction={'row'} justifyContent={'flex-end'} gap={1}>
						<Button size="xs" leftIcon={<Icon as={MdOutlineMessage} />} onClick={onContactClick}>
							Inquire
						</Button>
						{marketplaceEnabled && (
							<Button disabled={expired} colorScheme='orange' size="xs" leftIcon={<Icon as={AiOutlineDollar} />} onClick={onSellClick}>
								Sell
							</Button>
						)}
					</Flex>
				);
			}
		}
	}

	function renderBidMarketDataText() {
		const { text, color } = getMarketValueTextConfig(bidAmount, marketPrice, false);
		if (isMarketValue) {
			return (
				<Tooltip label={'Priced at Fair Market Value'} >
					<Badge colorScheme={'purple'} mb={1}>
						{text}
					</Badge>
				</Tooltip>
			);
		} else {
			return (
				<Tooltip label={'FMV = Fair Market Value'} >
					<Text textAlign={'right'} fontSize="xs" color={color}>
						{text}
					</Text>
				</Tooltip>
			);
		}
	}

	function renderBidMarketDataStatistics() {
		const noDataLabel = shorten ? 'No Data' : 'No Market Data';
		return marketPrice ? (
			<Stack spacing={1} alignItems={'flex-end'}>
				{!isMarketValue && <GrowthBadge starting={marketPrice} ending={bidAmount} />}
				{renderBidMarketDataText()}
			</Stack >
		) : (
			<Badge alignSelf={'flex-end'} colorScheme={'pink'} mb={1} width={'fit-content'}>
				{noDataLabel}
			</Badge>
		);
	}

	function renderBidData() {
		return (
			<Flex gap={2} direction={'column'} alignItems="flex-end" alignSelf={"flex-start"}>
				<Heading fontSize="sm">{toDollarFormat(bidAmount)}</Heading>
				<Stack spacing={1}>
					{renderBidMarketDataStatistics()}
					{renderIcons()}
				</Stack>
			</Flex>
		);
	}

	function renderBidCreatedDuration() {
		let durationText: string;

		if (updated) {
			durationText = bidUpdatedDays ? `Updated ${bidUpdatedDays} Days Ago` : 'Updated Today';
		} else {
			durationText = bidCreatedDays ? `Created ${bidCreatedDays} Days Ago` : 'Created Today';
		}
		return (
			<Flex direction="row" alignItems="center" gap={1}>
				<Icon as={FcClock} w={3} h={3} />
				<Text fontSize="xs">{durationText}</Text>
			</Flex>
		);
	}

	function renderBidBadges() {
		const badges = [];
		badges.push(
			<Badge colorScheme='blue' width={'fit-content'} key={'bidBadge_label'}>
				Bid
			</Badge>
		);

		if (condition) {
			badges.push(
				<ConditionBadge condition={condition} customLabel={
					condition === Condition.MINT ? `${condition}` : `${condition}+`
				} />
			);
		}

		if (expired) {
			badges.push(
				<Badge colorScheme='red' width={'fit-content'} key={'bidBadge_expired'}>
					Expired
				</Badge>
			)
		} else if (accepted) {
			badges.push(
				<Badge colorScheme='green' width={'fit-content'} key={'askBadge_expired'}>
					Accepted
				</Badge>
			);
		} else if (complete) {
			badges.push(
				<Badge colorScheme='green' width={'fit-content'} key={'askBadge_expired'}>
					Complete
				</Badge>
			);
		}

		return (
			<Flex direction={'row'} gap={2}>
				{badges}
			</Flex>
		);
	}

	function renderBidItemName() {
		return (
			<Heading
				_hover={onItemNameClick ? { color: "blue.500", cursor: "pointer" } : {}}
				fontSize="sm"
				onClick={() => onItemNameClick?.(item.id, item.getDisplayName())}>
				{item.name}
			</Heading>
		);
	}

	function renderBidArtistName() {
		return (
			<Text
				_hover={onItemArtistNameClick ? { color: "blue.500", cursor: "pointer" } : {}}
				fontSize="xs"
				onClick={() => onItemArtistNameClick?.(item.artistId, item.getArtistName())}>
				{item.getArtistName()}
			</Text>
		);
	}


	return (
		<Box
			width={'100%'}
			minHeight={ItemBidCardSizingConfig.CARD_HEIGHT}
			padding={2}
			border="1px solid"
			borderColor={useColorModeValue(ItemBidCardStylingConfig.BORDER_COLOR_LIGHT, ItemBidCardStylingConfig.BORDER_COLOR_DARK)}
		>
			<Stack>
				<Flex direction="row" alignItems="center" justifyContent="flex-start">
					{renderItemImage()}
					<Flex direction="column" textAlign="left" gap={2} paddingRight={shorten ? 0 : { base: 2, md: 4 }}>
						{renderBidItemName()}
						<Stack spacing={1}>
							{renderBidArtistName()}
							{renderBidCreatedDuration()}
							{renderBidBadges()}
						</Stack>
					</Flex>
					<Spacer />
					{renderBidData()}
				</Flex>
			</Stack>
		</Box>
	);
}
