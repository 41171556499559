import { Loop, LoopReducer } from 'redux-loop';
import { OrderHandlers } from './handlers';
import { OrderState, OrderAction, OrderActionTypes } from './types';
import { defaultState } from './consts';

export const orderReducer: LoopReducer<OrderState, OrderAction> = (
  state: OrderState = defaultState,
  action: OrderAction
): OrderState | Loop<OrderState, OrderAction> => {
  switch (action.type) {
    case OrderActionTypes.GET_ORDER_BY_ID:
      return OrderHandlers.handleGetOrderById(state, action);
    case OrderActionTypes.GET_ORDER_BY_ID_SUCCESS:
      return OrderHandlers.handleGetOrderByIdSuccess(state, action);
    case OrderActionTypes.GET_ORDER_BY_ID_FAILURE:
      return OrderHandlers.handleGetOrderByIdFailure(state);
    case OrderActionTypes.ADD_ORDER_ADDRESS:
        return OrderHandlers.handleAddOrderAddress(state, action);
    case OrderActionTypes.ADD_ORDER_ADDRESS_SUCCESS:
        return OrderHandlers.handleAddOrderAddressSuccess(state, action);
    case OrderActionTypes.ADD_ORDER_ADDRESS_FAILURE:
        return OrderHandlers.handleAddOrderAddressFailure(state);
    default:
      return state;
  }
}
