import { useState, useMemo } from 'react';
import { Stack, Heading, Icon, Box } from '@chakra-ui/react';
import { ItemBidModel, ItemModel, UserModel, ItemAskModel } from 'models';
import { Condition } from 'models/types';
import { ItemAskStatus } from 'models/Item/ItemAsk/types';
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai';
import Wizard from '../Wizard';
import ItemBidForm, { ItemBidFormData } from '../../Form/ItemBidForm/ItemBidForm';
import ItemBidAdditionalDataForm, { ItemBidAdditionalDataFormData } from '../../Form/ItemBidAdditionalDataForm/ItemBidAdditionalDataForm';
import ItemBidCard from '../../Item/ItemBidCard/ItemBidCard';
import ItemBidEditCard from '../../Item/ItemBidEditCard/ItemBidEditCard';
import BasicItemCard from '../../Item/BasicItemCard/BasicItemCard';
import UnauthorizedAccess from '../../UnauthorizedAccess/UnauthorizedAccess';
import PaymentBreakdown from '../../PaymentBreakdown/PaymentBreakdown';

interface ItemBidEditWizardProps {
	item: ItemModel;
	user: UserModel;
	itemBid: ItemBidModel;
	onSubmit?: (itemBid: ItemBidModel, data?: ItemBidFormData, additionalData?: ItemBidAdditionalDataFormData, useEscrow?: boolean) => void;
	isLoading?: boolean;
	hasErrors?: boolean;
}

export default function ItemBidEditWizard({
	item,
	itemBid,
	user,
	onSubmit = () => { },
	isLoading = false,
	hasErrors = false,
}: ItemBidEditWizardProps) {
	const [editBidData, setEditBidData] = useState<ItemBidFormData>();
	const [additionalBidData, setAdditionalBidData] = useState<ItemBidAdditionalDataFormData>();
	const [useEscrow, setUseEscrow] = useState(itemBid.useEscrow);
	const opposingAskTransaction = useMemo(() => new ItemAskModel({
		id: '',
		item_id: item.id,
		user_id: '',
		amount: editBidData?.bidPrice || itemBid.amount,
		currency: 'USD',
		status: ItemAskStatus.PENDING,
		condition: additionalBidData?.condition || itemBid.condition || Condition.GOOD,
		notes: '',
		expiration_days: 30,
		verified: itemBid.isVerified() || false,
		use_escrow: useEscrow
	}), [item.id, editBidData?.bidPrice, additionalBidData?.condition, itemBid, useEscrow]);

	const onWizardSubmit = () => {
		onSubmit(itemBid, editBidData, additionalBidData, useEscrow);
	};

	const handleFormChange = (data: ItemBidFormData) => {
		setEditBidData(data);
	};

	const handleAdditionalDataChange = (data: ItemBidAdditionalDataFormData) => {
		setAdditionalBidData(data);
	};

	function renderBasicItemCard() {
		return (
			<BasicItemCard item={item} />
		);
	}

	function renderBidForm() {
		return (
			<ItemBidForm
				item={item}
				onChange={handleFormChange}
				defaultFormData={editBidData || { bidPrice: itemBid.amount }}
				showSubmit={false}
				showItemCard={false}
			/>
		);
	}

	function renderAdditionalDataForm() {
		return (
			<ItemBidAdditionalDataForm
				onChange={handleAdditionalDataChange}
				defaultFormData={additionalBidData || { 
					notes: itemBid.notes, 
					condition: itemBid.condition || Condition.GOOD
				}}
			/>
		);
	}

	function renderCurrentBidPreview() {
		return (
			<Stack spacing={4}>
				<Heading fontSize="sm">Current Bid</Heading>
				<ItemBidEditCard
					item={item}
					originalAmount={itemBid.amount}
					currentAmount={editBidData?.bidPrice}
					createdDaysAgo={itemBid.getCreatedDaysAgo()}
					updatedDaysAgo={itemBid.getUpdatedDaysAgo()}
					expired={itemBid.isExpired()}
					updated={itemBid.hasBeenUpdated()}
					noLongerExpired={(editBidData?.bidExpiration || 0) > 0}
					condition={additionalBidData?.condition || itemBid.getCondition()} />
			</Stack>
		);
	}

	function renderConfirmationStep() {
		return (
			<Box height={{ base: 'auto', md: 340 }}>
				<Stack spacing={4}>
					<ItemBidCard 
						item={item} 
						bidAmount={editBidData?.bidPrice || itemBid.amount} 
						bidCreatedDays={itemBid.getCreatedDaysAgo()}
						updated={true}
						bidUpdatedDays={0}
						expired={itemBid.isExpired() && !editBidData?.bidExpiration}
						condition={additionalBidData?.condition || itemBid.getCondition()} />
					<Heading fontSize="sm">Confirm Your Updated Bid</Heading>
					<PaymentBreakdown transaction={opposingAskTransaction} type={'ask'} useEscrow={useEscrow} onToggleEscrow={setUseEscrow} showInfoGuide={false} showTransactionCard={false} />
				</Stack>
			</Box>
		);
	}

	function renderWizardFinishingSuccessStep() {
		let content: JSX.Element;
		if (hasErrors) {
			content = (
				<Stack gap={4} alignItems="center">
					<Icon as={AiFillCloseCircle} color="red.500" w={10} h={10} />
					<Heading size="xs" textAlign="center">
						Your Bid Could Not Be Updated.<br />Please Try Again
					</Heading>
				</Stack>
			);
		} else {
			content = (
				<Stack gap={4} alignItems="center">
					<Icon as={AiFillCheckCircle} color="green.500" w={10} h={10} />
					<Heading size="xs" textAlign="center">
						Your Bid Has Been Updated
					</Heading>
				</Stack>
			);
		}

		return (
			<Box height={{ base: 'auto', md: 340 }}>
				{content}
			</Box>
		)
	}

	function renderEditStep() {
		if (itemBid.belongsToUser(user)) {
			return (
				<Box height={{ base: 'auto', md: 340 }}>
					<Stack spacing={4}>
						{renderBasicItemCard()}
						{renderCurrentBidPreview()}
						{renderBidForm()}
					</Stack>
				</Box>
			);
		} else {
			return (
				<UnauthorizedAccess
					title="Unauthorized"
					description="You are unauthorized to edit this bid."
				/>
			);
		}
	}

	function renderAdditionalDetailsStep() {
		if (itemBid.belongsToUser(user)) {
			return (
				<Box height={{ base: 'auto', md: 340 }}>
					<Stack spacing={4}>
						{renderCurrentBidPreview()}
						{renderAdditionalDataForm()}
					</Stack>
				</Box>
			);
		} else {
			return (
				<UnauthorizedAccess
					title="Unauthorized"
					description="You are unauthorized to edit this bid."
				/>
			);
		}
	}

	const wizardSteps = [
		{ label: 'Edit', content: renderEditStep(), requiresValidation: false },
		{ label: 'Details', description: 'Optional', content: renderAdditionalDetailsStep(), requiresValidation: false },
		{ label: 'Confirm', content: renderConfirmationStep(), requiresValidation: true, isValidated: !!editBidData || !!additionalBidData || useEscrow != itemBid.useEscrow },
	];

	return (
		<Stack gap={2} width="100%">
			<Wizard steps={wizardSteps} finishStep={renderWizardFinishingSuccessStep()} loading={isLoading} onSubmit={onWizardSubmit} />
		</Stack>
	);
}
