import { getGeocode } from 'use-places-autocomplete';
import { RawAddress } from 'models/Address/Address';
import { AddressModel } from 'models';

export class AddressApiClient {
  public static async getAddressFromDescription(description: string): Promise<AddressModel> {
    const results = await getGeocode({ address: description });
    const addressComponents = results[0].address_components;
    
    const getComponent = (type: string, shortName = false): string => {
      const component = addressComponents.find((comp: any) => comp.types.includes(type));
      return component ? (shortName ? component.short_name : component.long_name) : '';
    };

    const streetNumber = getComponent('street_number');
    const route = getComponent('route');
    const computedLine1 = `${streetNumber} ${route}`.trim() || description;
    const city = getComponent('locality') || getComponent('sublocality') || getComponent('postal_town') || '';
    const state = getComponent('administrative_area_level_1') || '';
    const postalCode = getComponent('postal_code') || '';
    const country = getComponent('country', true) || '';

    if (!computedLine1 || !city || !country) {
      throw new Error('Incomplete address');
    }

    const rawAddress: RawAddress = {
      line1: computedLine1,
      line2: '',
      city,
      state,
      postal_code: postalCode,
      country,
    };

    return new AddressModel(rawAddress);
  }

  public static async validateAddress(address: AddressModel): Promise<boolean> {
    const query = `${address.line1}, ${address.city}, ${address.state}, ${address.postalCode}, ${address.country}`;
    try {
      const results = await getGeocode({ address: query });
      return results && results.length > 0;
    } catch (error) {
      console.error('Address validation error:', error);
      return false;
    }
  }
}
