import {
	Box,
	Stack,
	Flex,
	Text,
	Heading,
	Image,
	Button,
	Icon,
	StackDivider
} from '@chakra-ui/react';
import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';
import { OrderModel } from 'models';
import { OrderPaymentStatus } from 'models/Order/types';
import { toDollarFormat } from 'utils/format';
import ConditionBadge from '../../ConditionBadge/ConditionBadge';

interface OrderReceiptProps {
	order: OrderModel;
	isBuyer: boolean;
	onItemNameClick?: (id: string, name: string) => void;
	onItemArtistClick?: (id: string, name: string) => void;
}

export default function OrderReceipt({ order, isBuyer, onItemNameClick, onItemArtistClick }: OrderReceiptProps) {
	const item = order.item;

	const finalLabel = isBuyer ? 'Total Amount:' : 'Net Amount:';
	const finalAmount = isBuyer ? order.amounts.grossTotal : order.amounts.netTotal;
	const createdDate = order.getCreatedDate();

	const fees = isBuyer ? order.fees.buyerFees : order.fees.sellerFees;

	function renderItemRow() {
		if (item) {
			return (
				<Flex align="center">
					<Box minW={{ base: '50px', md: '80px' }} maxW={{ base: '50px', md: '80px' }} h={{ base: '50px', md: '80px' }} mr={4}>
						<Image src={item.image} alt={item.name} objectFit="contain" width="100%" height="100%" />
					</Box>
					<Stack spacing={0} flex="1" alignItems={'flex-start'}>
						<Heading
							fontSize="sm"
							_hover={onItemNameClick ? { color: "blue.500", cursor: "pointer" } : {}}
							onClick={() => onItemNameClick?.(item.id, item.getDisplayName())}>
							1 x {item.name}
						</Heading>
						<Text 
							fontSize="xs"
							_hover={onItemArtistClick ? { color: "blue.500", cursor: "pointer" } : {}}
							onClick={() => onItemArtistClick?.(item.artistId, item.getArtistName())}>
							{item.artist}
						</Text>
						{order.condition && (
							<ConditionBadge condition={order.condition} />
						)}
					</Stack>
					<Flex justifyContent="flex-end">
						<Heading fontSize={"sm"}>{toDollarFormat(order.amounts.subTotal)}</Heading>
					</Flex>
				</Flex>
			);
		}

	}

	function renderPaymentBreakdown() {
		return (
			<Stack spacing={2}>
				<Flex justifyContent="space-between">
					<Text fontSize="sm" color="gray.600">Subtotal Amount</Text>
					<Text fontSize="sm" color="gray.600">{toDollarFormat(order.amounts.subTotal)}</Text>
				</Flex>
				{fees && fees.length > 0 && fees.map((fee, index) => (
					<Flex key={index} justifyContent="space-between">
						<Text fontSize="xs" color="gray.600">{fee.name}</Text>
						<Text fontSize="xs" color="gray.600">
							{fee.amount >= 0 ? '+' : '-'}{toDollarFormat(fee.amount)}
						</Text>
					</Flex>
				))}
				<Flex justifyContent="space-between">
					<Text fontSize="md" fontWeight="bold">{finalLabel}</Text>
					<Text fontSize="md" fontWeight="bold">{toDollarFormat(finalAmount)}</Text>
				</Flex>
			</Stack>
		);
	}

	return (
		<Stack
			spacing={4}
			p={4}
			borderWidth="1px"
			borderRadius="md"
			divider={<StackDivider borderColor="gray.100" />}
		>
			<Box textAlign="center">
				<Heading size="md" textAlign="center">Order # 123456</Heading>
				{createdDate && (
					<Text size="sm" fontWeight="light">{order.getCreatedDate()?.format('YYYY-MM-DD')}</Text>
				)}
			</Box>
			{renderItemRow()}
			{renderPaymentBreakdown()}
			{/* Payment Status Footer */}
			<Flex justifyContent="space-between" align="center">
				{order.paymentStatus === OrderPaymentStatus.COMPLETE ? (
					<Flex align="center" color="green.500">
						<Icon as={FaCheckCircle} w={5} h={5} mr={2} />
						<Text fontSize="sm" fontWeight="bold">Payment Complete</Text>
					</Flex>
				) : (
					<Flex align="center" color="red.500">
						<Icon as={FaExclamationCircle} w={5} h={5} mr={2} />
						<Text fontSize="sm" fontWeight="bold">Payment Due</Text>
					</Flex>
				)}
				{order.paymentStatus !== OrderPaymentStatus.COMPLETE && order.paymentLink && (
					<Button
						colorScheme="red"
						size="sm"
						onClick={() => window.open(order.paymentLink, '_blank')}
					>
						Make Payment
					</Button>
				)}
			</Flex>
		</Stack>
	);
}
