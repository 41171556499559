import moment from 'moment';
import { Cloneable } from '../Utils/Cloneable';
import { ItemPurchaseInfo, RawPortfolioItemData } from './types';

export class PortfolioItemModel extends Cloneable {
  itemId: string;
  purchaseInfo: ItemPurchaseInfo[];

  constructor(rawPortfolioItemData?: RawPortfolioItemData) {
    super();
    this.itemId = rawPortfolioItemData?.item_id || '';
    this.purchaseInfo = rawPortfolioItemData?.purchase_info.map(purchaseItem => ({
      price: purchaseItem.price,
      quantity: purchaseItem.quantity,
      date: moment(purchaseItem.date)
    })) || [];
  }

  public getMeanPurchasePrice() {
    let totalPrice = 0;
    let totalQuantity = 0;
    this.purchaseInfo.forEach(purchaseItem => {
      totalPrice += purchaseItem.price;
      totalQuantity += purchaseItem.quantity;
    });

    if (totalPrice && totalQuantity) {
      return totalPrice / totalQuantity;
    } else {
      return 0;
    }
  }

  public getTotalPurchasePrice(): number {
    let totalPrice = 0;
    this.purchaseInfo.forEach(purchaseItem => {
      totalPrice += (purchaseItem.price * purchaseItem.quantity);
    });
    
    return totalPrice;
  }

  public getMeanPurchasePriceForDisplay(currency?: string): string {
    return (this.getMeanPurchasePrice()).toLocaleString('en-US', {
      style: 'currency',
      currency: currency || 'USD',
    });
  }

  public getTotalPurchasePriceForDisplay(currency?: string): string {
    return (this.getTotalPurchasePrice()).toLocaleString('en-US', {
      style: 'currency',
      currency: currency || 'USD',
    });
  }
}
