import { ItemModel, ListingModel, MarketModel, ArtistModel, PaginationModel } from 'models';
import { ItemActionTypes, ItemAction } from './types';
import { JSONObject } from 'models/Api/types';
import { ItemActiveMarketData } from 'models/Item/types';


export class ItemActions {
  public static getItemById(id: string): ItemAction {
    return {
      type: ItemActionTypes.GET_ITEM_BY_ID,
      payload: { id },
    };
  }

  public static getItemListings(id: string, page?: number): ItemAction {
    return {
      type: ItemActionTypes.GET_ITEM_LISTINGS,
      payload: { id, page }
    }
  }

  public static getItemMarket(id: string): ItemAction {
    return {
      type: ItemActionTypes.GET_ITEM_MARKET,
      payload: { id },
    }
  }

  public static getItemActiveMarket(id: string): ItemAction {
    return {
      type: ItemActionTypes.GET_ITEM_ACTIVE_MARKET,
      payload: { id },
    }
  }

  public static getItemArtist(id: string): ItemAction {
    return {
      type: ItemActionTypes.GET_ITEM_ARTIST,
      payload: { id }
    };
  }

  public static searchItemsByQuery(query: string, page?: number): ItemAction {
    return {
      type: ItemActionTypes.SEARCH_ITEM_BY_QUERY,
      payload: { query, page }
    }
  }


  public static searchItem(searchQuery: JSONObject): ItemAction {
    return {
      type: ItemActionTypes.SEARCH_ITEM,
      payload: { searchQuery }
    }
  }

  public static getUserFollowingArtistsItems(page?: number): ItemAction {
    return {
      type: ItemActionTypes.GET_USER_FOLLOWING_ARTISTS_ITEMS,
      payload: { page }
    }
  }

  public static getItemSuggestions(id: string): ItemAction {
    return {
      type: ItemActionTypes.GET_ITEM_SUGGESTIONS,
      payload: { id }
    };
  }

  public static getAllItems(page?: number): ItemAction {
    return {
      type: ItemActionTypes.GET_ALL_ITEMS,
      payload: { page }
    };
  }

  public static placeItemBid(id: string, bidData: JSONObject): ItemAction {
    return {
      type: ItemActionTypes.PLACE_ITEM_BID,
      payload: { id, bidData }
    };
  }

  public static placeItemAsk(id: string, askData: JSONObject): ItemAction {
    return {
      type: ItemActionTypes.PLACE_ITEM_ASK,
      payload: { id, askData }
    };
  }

  public static deleteItemBid(itemBidId: string, itemId: string): ItemAction {
    return {
      type: ItemActionTypes.DELETE_ITEM_BID,
      payload: { itemBidId, itemId }
    }
  }

  public static deleteItemAsk(itemAskId: string, itemId: string): ItemAction {
    return {
      type: ItemActionTypes.DELETE_ITEM_ASK,
      payload: { itemAskId, itemId }
    }
  }

  public static updateItemBid(itemBidId: string, itemId: string, bidData: JSONObject): ItemAction {
    return {
      type: ItemActionTypes.UPDATE_ITEM_BID,
      payload: { itemBidId, itemId, bidData }
    }
  }

  public static updateItemAsk(itemAskId: string, itemId: string, askData: JSONObject): ItemAction {
    return {
      type: ItemActionTypes.UPDATE_ITEM_ASK,
      payload: { itemAskId, itemId, askData }
    }
  }

  public static placeItemInquiry(id: string, inquiryData: JSONObject): ItemAction {
    return {
      type: ItemActionTypes.PLACE_ITEM_INQUIRY,
      payload: { id, inquiryData }
    };
  }

  public static generateItemAskPaymentLink(itemAskId: string, useEscrow: boolean): ItemAction {
    return {
      type: ItemActionTypes.GENERATE_ASK_PAYMENT_LINK,
      payload: { itemAskId, useEscrow }
    }
  }

  public static acceptItemBid(itemBidId: string, useEscrow: boolean): ItemAction {
    return {
      type: ItemActionTypes.ACCEPT_ITEM_BID,
      payload: { itemBidId, useEscrow }
    }
  }
}

export class ItemInternalActions {
  public static getItemByIdSuccess(item: ItemModel): ItemAction {
    return {
      type: ItemActionTypes.GET_ITEM_BY_ID_SUCCESS,
      payload: { item },
    };
  }

  public static getItemByIdFailure(): ItemAction {
    return { type: ItemActionTypes.GET_ITEM_BY_ID_FAILURE };
  }

  public static getItemListingsSuccess(listings: ListingModel[]): ItemAction {
    return {
      type: ItemActionTypes.GET_ITEM_LISTINGS_SUCCESS,
      payload: { listings },
    };
  }

  public static getItemListingsFailure(): ItemAction {
    return { type: ItemActionTypes.GET_ITEM_LISTINGS_FAILURE };
  }

  public static getItemMarketSuccess(market: MarketModel): ItemAction {
    return {
      type: ItemActionTypes.GET_ITEM_MARKET_SUCCESS,
      payload: { market },
    };
  }

  public static getItemMarketFailure(): ItemAction {
    return { type: ItemActionTypes.GET_ITEM_MARKET_FAILURE };
  }

  public static getItemActiveMarketSuccess(activeMarket: ItemActiveMarketData): ItemAction {
    return {
      type: ItemActionTypes.GET_ITEM_ACTIVE_MARKET_SUCCESS,
      payload: { activeMarket },
    };
  }

  public static getItemActiveMarketFailure(): ItemAction {
    return { type: ItemActionTypes.GET_ITEM_ACTIVE_MARKET_FAILURE };
  }

  public static getItemArtistSuccess(artist: ArtistModel): ItemAction {
    return {
      type: ItemActionTypes.GET_ITEM_ARTIST_SUCCESS,
      payload: { artist },
    };
  }

  public static getItemArtistFailure(): ItemAction {
    return { type: ItemActionTypes.GET_ITEM_ARTIST_FAILURE };
  }

  public static searchItemByQuerySuccess(paginatedItems: PaginationModel<ItemModel>) {
    return {
      type: ItemActionTypes.SEARCH_ITEM_BY_QUERY_SUCCESS,
      payload: { paginatedItems },
    };
  }

  public static searchItemByQueryFailure() {
    return {
      type: ItemActionTypes.SEARCH_ITEM_BY_QUERY_FAILURE
    };
  }

  public static searchItemSuccess(paginatedItems: PaginationModel<ItemModel>) {
    return {
      type: ItemActionTypes.SEARCH_ITEM_SUCCESS,
      payload: { paginatedItems },
    };
  }

  public static searchItemFailure() {
    return {
      type: ItemActionTypes.SEARCH_ITEM_FAILURE
    };
  }

  public static getUserFollowingArtistsItemsSuccess(paginatedItems: PaginationModel<ItemModel>) {
    return {
      type: ItemActionTypes.GET_USER_FOLLOWING_ARTISTS_ITEMS_SUCCESS,
      payload: { paginatedItems },
    };
  }

  public static getUserFollowingArtistsItemsFailure() {
    return {
      type: ItemActionTypes.GET_USER_FOLLOWING_ARTISTS_ITEMS_FAILURE
    };
  }

  public static getAllItemsSuccess(paginatedItems: PaginationModel<ItemModel>) {
    return {
      type: ItemActionTypes.GET_ALL_ITEMS_SUCCESS,
      payload: { paginatedItems },
    };
  }

  public static getAllItemsFailure() {
    return {
      type: ItemActionTypes.GET_ALL_ITEMS_FAILURE
    };
  }

  public static getItemSuggestionsSuccess(items: ItemModel[]): ItemAction {
    return {
      type: ItemActionTypes.GET_ITEM_SUGGESTIONS_SUCCESS,
      payload: { items }
    };
  }

  public static getItemSuggestionsFailure(): ItemAction {
    return {
      type: ItemActionTypes.GET_ITEM_SUGGESTIONS_FAILURE,
    };
  }

  public static placeItemBidSuccess(id: string): ItemAction {
    return {
      type: ItemActionTypes.PLACE_ITEM_BID_SUCCESS,
      payload: { id }
    };
  }

  public static placeItemBidFailure(): ItemAction {
    return {
      type: ItemActionTypes.PLACE_ITEM_BID_FAILURE
    };
  }

  public static placeItemAskSuccess(id: string): ItemAction {
    return {
      type: ItemActionTypes.PLACE_ITEM_ASK_SUCCESS,
      payload: { id }
    };
  }

  public static placeItemAskFailure(): ItemAction {
    return {
      type: ItemActionTypes.PLACE_ITEM_ASK_FAILURE
    };
  }

  public static deleteItemBidSuccess(itemId: string): ItemAction {
    return {
      type: ItemActionTypes.DELETE_ITEM_BID_SUCCESS,
      payload: { itemId }
    };
  }

  public static deleteItemBidFailure(): ItemAction {
    return {
      type: ItemActionTypes.DELETE_ITEM_BID_FAILURE
    };
  }

  public static deleteItemAskSuccess(itemId: string): ItemAction {
    return {
      type: ItemActionTypes.DELETE_ITEM_ASK_SUCCESS,
      payload: { itemId }
    }
  }

  public static deleteItemAskFailure(): ItemAction {
    return {
      type: ItemActionTypes.DELETE_ITEM_ASK_FAILURE
    }
  }

  public static updateItemBidSuccess(itemId: string): ItemAction {
    return {
      type: ItemActionTypes.UPDATE_ITEM_BID_SUCCESS,
      payload: { itemId }
    };
  }

  public static updateItemBidFailure(): ItemAction {
    return {
      type: ItemActionTypes.UPDATE_ITEM_BID_FAILURE
    };
  }

  public static updateItemAskSuccess(itemId: string): ItemAction {
    return {
      type: ItemActionTypes.UPDATE_ITEM_ASK_SUCCESS,
      payload: { itemId }
    }
  }

  public static updateItemAskFailure(): ItemAction {
    return {
      type: ItemActionTypes.UPDATE_ITEM_ASK_FAILURE
    }
  }

  public static placeItemInquirySuccess(itemId: string): ItemAction {
    return {
      type: ItemActionTypes.PLACE_ITEM_INQUIRY_SUCCESS,
      payload: { itemId }
    }
  }

  public static placeItemInquiryFailure(): ItemAction {
    return {
      type: ItemActionTypes.PLACE_ITEM_INQUIRY_FAILURE
    }
  }

  public static generateItemAskPaymentLinkSuccess(itemAskId: string, paymentLink: string): ItemAction {
    return {
      type: ItemActionTypes.GENERATE_ASK_PAYMENT_LINK_SUCCESS,
      payload: { itemAskId, paymentLink }
    }
  }

  public static generateItemAskPaymentLinkFailure(): ItemAction {
    return {
      type: ItemActionTypes.GENERATE_ASK_PAYMENT_LINK_FAILURE
    }
  }

  public static acceptItemBidSuccess(orderId: string): ItemAction {
    return {
      type: ItemActionTypes.ACCEPT_ITEM_BID_SUCCESS,
      payload: { orderId }
    }
  }

  public static acceptItemBidFailure(): ItemAction {
    return {
      type: ItemActionTypes.ACCEPT_ITEM_BID_FAILURE
    }
  }
}
