import { Flex, Button, Heading, Box, Step, StepDescription, StepIcon, StepIndicator, StepNumber, StepSeparator, StepStatus, StepTitle, Stepper, useSteps, useBreakpointValue } from '@chakra-ui/react';
import Loader from '../Loader/Loader';
import { AiFillCheckCircle } from "react-icons/ai";

export interface WizardStep {
  label: string;
  description?: string;
  content: JSX.Element;
  requiresValidation?: boolean;
  isValidated?: boolean;
}

export interface WizardProps {
  steps: WizardStep[];
  finishStep?: JSX.Element;
  showButtons?: boolean;
  onSubmit?: () => void;
  loading?: boolean;
  showStepper?: boolean;
  onStepChange?: (activeStep: number) => void;
}

export default function Wizard({ steps, finishStep, showButtons = true, onSubmit = () => { }, loading = false, showStepper = true, onStepChange }: WizardProps) {
  const { activeStep, setActiveStep } = useSteps({ index: 0, count: steps.length });
  const isMobile = useBreakpointValue({ base: true, md: false });
  const orientation = isMobile ? 'vertical' : 'horizontal';
  const isLastStep = activeStep === steps.length - 1;
  const hasCompletedAllSteps = activeStep === steps.length;

  const isNextButtonDisabled = () => {
    const currentStep = steps[activeStep];
    return currentStep.requiresValidation && !currentStep.isValidated;
  };

  const goToNextStep = () => {
    const nextStep = Math.min(activeStep + 1, steps.length - 1);
    setActiveStep(nextStep);
    onStepChange?.(nextStep);
  };

  const goToPreviousStep = () => {
    const nextStep = Math.max(activeStep - 1, 0);
    setActiveStep(nextStep);
    onStepChange?.(nextStep);
  };

  function renderFinishingStep() {
    if (finishStep) {
      return finishStep;
    } else {
      return (
        <Box sx={{ my: 8, p: 8, rounded: "md" }}>
          <Heading fontSize="xl" textAlign="center">
            All steps completed!
          </Heading>
        </Box>
      );
    }
  }

  function renderStep(content: JSX.Element) {
    if (loading) {
      return (
        <Flex justifyContent={'center'} marginTop={4} marginBottom={4}>
          <Loader />
        </Flex>
      );
    } else {
      return (
        <Box rounded={'md'} marginTop={4} marginBottom={4}>
          {content}
        </Box>
      );
    }
  }

  function renderButtonContainer() {
    if (showButtons) {
      return (
        <Flex width="100%" justify="flex-end" gap={4} mt={4}>
          <Button
            isDisabled={activeStep === 0}
            onClick={goToPreviousStep}
            size="sm"
            variant="ghost"
          >
            Prev
          </Button>
          <Button size="sm" onClick={isLastStep ? onSubmit : goToNextStep} isDisabled={isNextButtonDisabled()}>
            {isLastStep ? "Finish" : "Next"}
          </Button>
        </Flex>
      );
    }
  }

  function renderStepper() {
    if (showStepper) {
      return (
        <Stepper index={activeStep} orientation={orientation}>
          {steps.map((step, index) => (
            <Step key={index} justifyContent={'center'} alignItems={'center'}>
              <StepIndicator >
                <StepStatus
                  complete={<StepIcon />}
                  incomplete={<StepNumber />}
                  active={<StepNumber />}
                />
              </StepIndicator>
              <Flex flexShrink='0' flexDirection={'column'}>
                <StepTitle
                  fontSize="sm"
                  whiteSpace="normal"
                  wordBreak="break-word"
                >
                  {step.label}
                </StepTitle>
                <StepDescription
                  fontSize="xs"
                  whiteSpace="normal"
                  wordBreak="break-word"
                >
                  {step.description}
                </StepDescription>
              </Flex>
              <StepSeparator />
            </Step>
          ))}
        </Stepper>
      );
    }
  }

  return (
    <Flex flexDirection={'column'} width="100%">
      {renderStepper()}
      {renderStep(steps[activeStep].content)}
      {hasCompletedAllSteps ? renderFinishingStep() : renderButtonContainer()}
    </Flex>
  );
}
