import { Controller, useForm } from 'react-hook-form';
import {
  Stack,
  FormControl,
  InputGroup,
  FormLabel,
  InputLeftElement,
  Input,
  FormErrorMessage,
} from '@chakra-ui/react';
import {
  FaLink,
  FaInstagram
} from 'react-icons/fa';
import { BiCalendar } from 'react-icons/bi';
import { BsTextareaT } from 'react-icons/bs';
import { CgWebsite } from 'react-icons/cg';
import { getFilteredObject } from 'utils/object';
import { isValidHttpUrl } from 'utils/string';
import CountryInput from '../../Input/CountryInput/CountryInput';
import BasicArtistCard from '../../Artist/BasicArtistCard/BasicArtistCard';
import FilepondUploader from '../../Uploader/FilepondUploader/FilepondUploader';
import ActionButton from '../../Button/ActionButton';
import { ArtistModel } from 'models';

export type ArtistDataSuggestionFormOption = 'birthYear' | 'country' | 'description' | 'artsy' | 'instagram' | 'website' | 'image' | 'name';

export interface ArtistDataSuggestionFormProps {
  artist?: ArtistModel;
  defaultFormData?: ArtistDataSuggestionFormData;
  onSubmit?: (data: ArtistDataSuggestionFormData) => void;
  submitLoading?: boolean;
  displayOptions?: ArtistDataSuggestionFormOption[];
  requiredOptions?: ArtistDataSuggestionFormOption[];
  disabledOptions?: ArtistDataSuggestionFormOption[];
}

export interface ArtistDataSuggestionFormData {
  birthYear?: number;
  country?: string;
  description?: string;
  artsy?: string;
  instagram?: string;
  website?: string;
  image?: string;
  name?: string;
}

export default function ArtistDataSuggestionForm({ artist, defaultFormData, onSubmit, submitLoading, displayOptions, requiredOptions = [], disabledOptions = []  }: ArtistDataSuggestionFormProps) {
  const {
    control,
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors, isDirty, dirtyFields },
  } = useForm<ArtistDataSuggestionFormData>({defaultValues: defaultFormData});


  const formDisplayOptions: Record<string, boolean> = (
    displayOptions || ['birthYear', 'country', 'description', 'artsy', 'instagram', 'website', 'image', 'name']
  ).reduce((acc: Record<string, boolean>, curr: ArtistDataSuggestionFormOption) => (acc[curr]=true, acc), {});

  const formDisabledOptions: Record<string, boolean> = disabledOptions.reduce((acc: Record<string, boolean>, curr: ArtistDataSuggestionFormOption) => (acc[curr]=true, acc), {});

  const formRequiredOptions: Record<string, boolean> = requiredOptions.reduce((acc: Record<string, boolean>, curr: ArtistDataSuggestionFormOption) => (acc[curr]=true, acc), {});

  const onFormSubmit = (data: ArtistDataSuggestionFormData) => {
    const changedData = getFilteredObject(Object.keys(dirtyFields), data as Record<string, unknown>);
    onSubmit?.(changedData);
  }

  function renderImageUploader() {
    const shouldDisplay = !!formDisplayOptions['image'];
    const isRequired = !!formRequiredOptions['image'];
    const isDisabled = !!formDisabledOptions['image'];

    return shouldDisplay && (
      <FormControl isInvalid={isRequired && !watch('image')?.length} isRequired={isRequired} isDisabled={isDisabled}>
        <FormLabel htmlFor="image" fontSize="sm">Image</FormLabel>
        <Controller
          control={control}
          name="image"
          rules={{ required: isRequired }}
          render={() => (
            <FilepondUploader
              onFileProcess={(url) => setValue('image', url, { shouldDirty: true })}
              onFileRevert={() => setValue('image', '')}
              maxFiles={1}
              label={'Drag & Drop your Image'} />
          )} />
        <FormErrorMessage>
          {(isRequired && !watch('image')?.length) && 'Image Must Be Uploaded'}
        </FormErrorMessage>
      </FormControl>
    );
  }

  function renderName() {
    const shouldDisplay = !!formDisplayOptions['name'];
    const isRequired = !!formRequiredOptions['name'];
    const isDisabled = !!formDisabledOptions['name'];

    return shouldDisplay && (
      <FormControl isDisabled={isDisabled} isRequired={isRequired} isInvalid={!!errors.name}>
        <FormLabel fontSize="sm" htmlFor="name">Name</FormLabel>
        <InputGroup>
          <InputLeftElement
            pointerEvents='none'
            color='gray.500'
            children={<BsTextareaT />}
          />
          <Input
            fontSize="sm"
            placeholder="Enter Name"
            {...register('name', {
              validate: {
                isDefined: v => (!isRequired || (v && v.length > 0)) || 'Name must be defined',
              }
            })} />
        </InputGroup>
        <FormErrorMessage>
          {errors.name && errors.name.message}
        </FormErrorMessage>
      </FormControl>
    );
  }

  function renderBirthYear() {
    const shouldDisplay = !!formDisplayOptions['birthYear'];
    const isRequired = !!formRequiredOptions['birthYear'];
    const isDisabled = !!formDisabledOptions['birthYear'];

    return shouldDisplay && (
      <FormControl isDisabled={isDisabled} isRequired={isRequired} isInvalid={!!errors.birthYear}>
        <FormLabel htmlFor="birthYear" fontSize="sm">Birth Year</FormLabel>
        <InputGroup>
          <InputLeftElement
            pointerEvents='none'
            color='gray.500'
            children={<BiCalendar />}
          />
          <Input
            {...register('birthYear', {
              validate: {
                isPositive: v =>  (!isRequired || (v && v > 1600)) || 'Birth Year is invalid',
              }
            })}
            fontSize="sm"
            type="number"
            placeholder='Enter Birth Year'
          />
        </InputGroup>
        <FormErrorMessage>
          {errors.birthYear && errors.birthYear.message}
        </FormErrorMessage>
      </FormControl>
    );
  }

  function renderArtsy() {
    const shouldDisplay = !!formDisplayOptions['artsy'];
    const isRequired = !!formRequiredOptions['artsy'];
    const isDisabled = !!formDisabledOptions['artsy'];

    return shouldDisplay && (
      <FormControl isDisabled={isDisabled} isRequired={isRequired} isInvalid={!!errors.artsy}>
        <FormLabel fontSize="sm" htmlFor="artsy">Artsy</FormLabel>
        <InputGroup>
          <InputLeftElement
            pointerEvents='none'
            color='gray.500'
            children={<FaLink />}
          />
          <Input
            {...register('artsy', {
              validate: {
                isDefined: v => (!isRequired || (v && v.length > 0)) || 'Artsy Link must be specified',
                isValidUrl: v => (!isRequired || (v && isValidHttpUrl(v))) || 'Artsy Link must be a valid url'
              },
            })}
            fontSize="sm"
            placeholder='Artsy Link'
            type="url"
          />
        </InputGroup>
        <FormErrorMessage>
          {errors.artsy && errors.artsy.message}
        </FormErrorMessage>
      </FormControl>
    );
  }

  function renderWebsite() {
    const shouldDisplay = !!formDisplayOptions['website'];
    const isRequired = !!formRequiredOptions['website'];
    const isDisabled = !!formDisabledOptions['website'];

    return shouldDisplay && (
      <FormControl isDisabled={isDisabled} isRequired={isRequired} isInvalid={!!errors.website}>
        <FormLabel fontSize="sm" htmlFor="artsy">Website</FormLabel>
        <InputGroup>
          <InputLeftElement
            pointerEvents='none'
            color='gray.500'
            children={<CgWebsite />}
          />
          <Input
            {...register('website', {
              validate: {
                isDefined: v => (!isRequired || (v && v.length > 0)) || 'Website Link must be specified',
                isValidUrl: v => (!isRequired || (v && isValidHttpUrl(v))) || 'Website Link must be a valid url'
              },
            })}
            fontSize="sm"
            placeholder='Website Link'
            type="url"
          />
        </InputGroup>
        <FormErrorMessage>
          {errors.website && errors.website.message}
        </FormErrorMessage>
      </FormControl>
    );
  }

  function renderInstagram() {
    const shouldDisplay = !!formDisplayOptions['instagram'];
    const isRequired = !!formRequiredOptions['instagram'];
    const isDisabled = !!formDisabledOptions['instagram'];

    return shouldDisplay && (
      <FormControl isDisabled={isDisabled} isRequired={isRequired} isInvalid={!!errors.instagram}>
        <FormLabel fontSize="sm" htmlFor="instagram">Instagram</FormLabel>
        <InputGroup>
          <InputLeftElement
            pointerEvents='none'
            color='gray.500'
            children={<FaInstagram />}
          />
          <Input
            {...register('instagram', {
              validate: {
                isDefined: v => (!isRequired || (v && v.length > 0)) || 'Instagram Link must be specified',
                isValidUrl: v => (!isRequired || (v && isValidHttpUrl(v))) || 'Instagram Link must be a valid url'
              },
            })}
            fontSize="sm"
            placeholder='Instagram Link'
            type="url"
          />
        </InputGroup>
        <FormErrorMessage>
          {errors.instagram && errors.instagram.message}
        </FormErrorMessage>
      </FormControl>
    );
  }

  function renderCountry() {
    const shouldDisplay = !!formDisplayOptions['country'];
    const isRequired = !!formRequiredOptions['country'];
    const isDisabled = !!formDisabledOptions['country'];

    return shouldDisplay && (
      <FormControl isDisabled={isDisabled} isRequired={isRequired} isInvalid={!!errors.country}>
        <FormLabel htmlFor="country" fontSize="sm">Country</FormLabel>
        <Controller
          control={control}
          name="country"
          rules={{
            required: isRequired,
            validate: (country) => (!isRequired || !!country) || 'Artist country must be specified'
          }}
          render={() => (
            <CountryInput
              defaultData={defaultFormData?.country}
              onChange={(data) => setValue('country', data, { shouldDirty: true })}
            />
          )} />
        <FormErrorMessage>
          {errors.country && errors.country.message}
        </FormErrorMessage>
      </FormControl>
    );
  }

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <Stack spacing={8}>
        {!!artist && <BasicArtistCard artist={artist} />}
        {renderImageUploader()}
        {renderName()}
        {renderBirthYear()}
        {renderArtsy()}
        {renderWebsite()}
        {renderInstagram()}
        {renderCountry()}
        <Stack alignItems="center">
          <ActionButton type='submit' text={'Submit'} loading={submitLoading} size={'md'} disabled={!isDirty} />
        </Stack>
      </Stack>
    </form>
  );
}
