import { useEffect, useState } from 'react';
import { Box, SimpleGrid, Text } from '@chakra-ui/react';
import { ItemBidModel, ItemAskModel, UserModel } from 'models';
import EmptyState from '../EmptyState/EmptyState';
import ItemAskCard from '../Item/ItemAskCard/ItemAskCard';
import ItemBidCard from '../Item/ItemBidCard/ItemBidCard';
import TradingFloorFilters from './TradingFloorFilters';
import TradingFloorSkeleton from './TradingFloorSkeleton';
import { filterItemsByQuery } from 'utils/search';
import { sortByVerificationAndExpiration } from 'utils/activemarket';

export interface TradingFloorProps {
	bids: ItemBidModel[];
	asks: ItemAskModel[];
	user?: UserModel;
	showSearchFilter?: boolean;
	onItemNameClick?: (itemId: string, itemName: string) => void;
	onItemArtistNameClick?: (artistId: string, artistName: string) => void;
	onRemoveAskClick?: (ask: ItemAskModel) => void;
	onRemoveBidClick?: (bid: ItemBidModel) => void;
	onEditAskClick?: (ask: ItemAskModel) => void;
	onEditBidClick?: (bid: ItemBidModel) => void;
	onContactBidClick?: (bid: ItemBidModel) => void;
	onSellBidClick?: (bid: ItemBidModel) => void;
	onBuyAskClick?: (ask: ItemAskModel) => void;
	onContactAskClick?: (ask: ItemAskModel) => void;
	isLoading?: boolean;
	loadingRows?: number
}

export default function TradingFloor({
	bids = [],
	asks = [],
	user,
	showSearchFilter,
	onItemNameClick,
	onItemArtistNameClick,
	onRemoveAskClick,
	onRemoveBidClick,
	onEditAskClick,
	onEditBidClick,
	onSellBidClick,
	onContactBidClick,
	onBuyAskClick,
	onContactAskClick,
	isLoading = false,
	loadingRows = 4
}: TradingFloorProps) {
	const [filteredBids, setFilteredBids] = useState(bids);
  const [filteredAsks, setFilteredAsks] = useState(asks);
  const [showBids, setShowBids] = useState<boolean>(false);
  const [showAsks, setShowAsks] = useState<boolean>(false);

	const displayBids = (showBids || (!showBids && !showAsks)) && bids.length > 0 && filteredBids.length > 0;
  const displayAsks = (showAsks || (!showBids && !showAsks)) && asks.length > 0 && filteredAsks.length > 0;

	useEffect(() => {
		if (bids?.length > 0) {
			setFilteredBids(sortByVerificationAndExpiration(bids));
		}

		if (asks?.length > 0) {
			setFilteredAsks(sortByVerificationAndExpiration(asks));
		}
	}, [bids, asks])

  const handleSearchChange = (query: string) => {
		const filteredBids = bids.filter(bid => {
			if (bid.item) {
				const filteredItems = filterItemsByQuery([bid.item], query);
				return filteredItems.length > 0;
			}
			return false;
		});
	
		const filteredAsks = asks.filter(ask => {
			if (ask.item) {
				const filteredItems = filterItemsByQuery([ask.item], query);
				return filteredItems.length > 0;
			}
			return false;
		});
	
		setFilteredBids(filteredBids);
		setFilteredAsks(filteredAsks);
	};

  const handleFilterChange = ({ showBids, showAsks }: { showBids: boolean; showAsks: boolean }) => {
    if (!showBids && !showAsks) {
      setShowBids(true);
      setShowAsks(true);
    } else {
      setShowBids(showBids);
      setShowAsks(showAsks);
    }
  };

	const hasData = bids.length || asks.length;

	function renderEmptyState() {
		return (
			<EmptyState header="No Data" description="There Are Currently No Active Bids or Asks" showButton={false} />
		);
	}

	function renderLoadingState() {
		return (
			<TradingFloorSkeleton rows={loadingRows} />
		);
	}

	function renderTradingFloorData() {
		const gridColumns = displayBids && displayAsks ? 2 : 1;

		return (
			<SimpleGrid columns={[1, gridColumns]} spacing={6} maxHeight={'600px'} overflow={'scroll'}>
				{displayBids && (
					<Box>
						<Text fontSize="md" fontWeight="bold" mb={2}>Bids</Text>
						<SimpleGrid columns={1} spacing={3}>
							{filteredBids.map(bid => bid.item ? (
								<ItemBidCard
									key={bid.id}
									item={bid.item}
									bidAmount={bid.amount}
									bidCreatedDays={bid.getCreatedDaysAgo()}
									bidUpdatedDays={bid.getUpdatedDaysAgo()}
									condition={bid.condition}
									expired={bid.isExpired()}
									accepted={bid.isAccepted()}
									complete={bid.isComplete()}
									updated={bid.hasBeenUpdated()}
									useAvatar={false}
									showIcons={true}
									userOwnsBid={bid.userId === user?.id}
									onItemNameClick={onItemNameClick}
									onItemArtistNameClick={onItemArtistNameClick}
									onRemoveClick={() => onRemoveBidClick?.(bid)}
									onEditClick={() => onEditBidClick?.(bid)}
									onContactClick={() => onContactBidClick?.(bid)}
									onSellClick={() => onSellBidClick?.(bid)}
								/>
							) : null)}
						</SimpleGrid>
					</Box>
				)}

				{displayAsks && (
					<Box>
						<Text fontSize="md" fontWeight="bold" mb={2}>Asks</Text>
						<SimpleGrid columns={1} spacing={3}>
							{filteredAsks.map(ask => ask.item ? (
								<ItemAskCard
									key={ask.id}
									item={ask.item}
									askAmount={ask.amount}
									askCreatedDays={ask.getCreatedDaysAgo()}
									askUpdatedDays={ask.getUpdatedDaysAgo()}
									condition={ask.condition}
									expired={ask.isExpired()}
									accepted={ask.isAccepted()}
									complete={ask.isComplete()}
									updated={ask.hasBeenUpdated()}
									useAvatar={false}
									showIcons={true}
									userOwnsAsk={ask.userId === user?.id}
									onItemNameClick={onItemNameClick}
									onItemArtistNameClick={onItemArtistNameClick}
									onRemoveClick={() => onRemoveAskClick?.(ask)}
									onEditClick={() => onEditAskClick?.(ask)}
									onContactClick={() => onContactAskClick?.(ask)}
									onBuyClick={() => onBuyAskClick?.(ask)}
								/>
							) : null)}
						</SimpleGrid>
					</Box>
				)}
			</SimpleGrid>
		);
	}

	function renderContent() {
		if (isLoading) {
			return renderLoadingState();
		} else if (hasData) {
			return renderTradingFloorData();
		} else {
			return renderEmptyState();
		}
	}

	return (
		<Box>
			<TradingFloorFilters
				showSearch={showSearchFilter}
				onSearchChange={handleSearchChange}
				onFilterChange={handleFilterChange}
			/>
			{renderContent()}
		</Box>
	);
}
