import React from 'react';
import {
  Icon,
  Button,
  CircularProgress,
  CircularProgressLabel,
} from '@chakra-ui/react';
import { CheckCircleIcon, InfoIcon } from '@chakra-ui/icons';
import { BsCardChecklist } from 'react-icons/bs';
import { UserModel } from 'models/User/User';
import UserProfileCard from './UserProfileCard';

export interface UserProfileOnboardingCardProps {
  user: UserModel;
  onClick?: () => void;
}

export default function UserProfileOnboardingCard({
  user,
  onClick,
}: UserProfileOnboardingCardProps) {
  const steps = [
    { label: 'Avatar', completed: Boolean(user.image) },
    { label: 'Instagram', completed: Boolean(user.instagram) },
    { label: 'Name', completed: Boolean(user.name) },
    { label: 'Phone', completed: Boolean(user.number?.baseNumber) },
    {
      label: 'Address',
      completed: user.hasPrimaryAddress()
    },
  ];

  const completedSteps = steps.filter((step) => step.completed).length;
  const totalSteps = steps.length;
  const progressPercent = (completedSteps / totalSteps) * 100;
  const missingSteps = steps
    .filter((step) => !step.completed)
    .map((step) => step.label);

  let buttonText = 'Complete Profile';
  let isButtonDisabled = false;
  let buttonLeftIcon: React.ElementType = InfoIcon;
  let progressTrackerColor = 'blue.400';

  if (missingSteps.length === 0) {
    buttonText = 'Profile Complete';
    isButtonDisabled = true;
    buttonLeftIcon = CheckCircleIcon;
    progressTrackerColor = 'green.500';
  } else if (missingSteps.length === 1) {
    buttonText = `Add ${missingSteps[0]}`;
  }

  const bottomButton = (
    <Button
      size="sm"
      colorScheme={isButtonDisabled ? 'green' : 'blue'}
      leftIcon={buttonLeftIcon ? <Icon as={buttonLeftIcon} /> : undefined}
      isDisabled={isButtonDisabled}
      onClick={onClick}
    >
      {buttonText}
    </Button>
  );

  return (
    <UserProfileCard
      title="Onboarding"
      subtitle="Complete your profile to get the best experience"
      icon={BsCardChecklist}
      bottomButton={bottomButton}
      children={(
        <CircularProgress
          value={progressPercent}
          size={10}
          thickness={8}
          color={progressTrackerColor}
          trackColor="gray.200"
        >
          <CircularProgressLabel fontSize="xs">
            {`${completedSteps}/${totalSteps}`}
          </CircularProgressLabel>
        </CircularProgress>
      )}
    />
  );
}
