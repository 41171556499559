import { useState, useEffect } from 'react';
import {
	FormControl,
	FormLabel,
	Input,
	Box,
	FormErrorMessage,
	Button
} from '@chakra-ui/react';
import usePlacesAutocomplete from 'use-places-autocomplete';
import { AddressModel } from 'models';
import { AddressApiClient } from 'api';

interface SearchAddressFormProps {
	onSelect: (address: AddressModel) => void;
	disabled?: boolean;
	showButton?: boolean;
	defaultAddress?: AddressModel;
}

export default function SearchAddressForm({ onSelect, disabled = false, showButton = false, defaultAddress }: SearchAddressFormProps) {
	const [error, setError] = useState<string>('');
	const {
		ready,
		value,
		suggestions: { status, data },
		setValue,
		clearSuggestions,
	} = usePlacesAutocomplete({ debounce: 300 });

	useEffect(() => {
		if (defaultAddress) {
			setValue(defaultAddress.getDisplayText(), false);
		}
	}, [defaultAddress, setValue]);

	const handleSelect = async (description: string) => {
		setValue(description, false);
		clearSuggestions();
		setError('');
		try {
			const address = await AddressApiClient.getAddressFromDescription(description);
			onSelect(address);
		} catch (e) {
			console.error('Error validating address:', e);
			setError('Please enter a valid address');
		}
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		if (value.trim() === '') {
			setError('Address cannot be empty');
			return;
		}
		await handleSelect(value);
	};

	return (
		<form onSubmit={handleSubmit}>
			<FormControl isDisabled={!ready || disabled} isInvalid={!!error}>
				<FormLabel fontSize="sm">Search Address</FormLabel>
				<Box position="relative">
					<Input
						fontSize="sm"
						placeholder="Enter your address"
						value={value}
						onChange={(e) => {
							setValue(e.target.value);
							setError('');
						}}
					/>
					{error && <FormErrorMessage fontSize="xs">{error}</FormErrorMessage>}
					{status === 'OK' && (
						<Box
							position="absolute"
							zIndex={1000}
							bg="white"
							borderWidth="1px"
							borderColor="gray.200"
							borderRadius="md"
							mt={1}
							w="100%"
							maxH="200px"
							overflowY="auto"
						>
							{data.map(({ place_id, description }) => (
								<Box
									key={place_id}
									p={2}
									cursor="pointer"
									_hover={{ bg: 'gray.100' }}
									fontSize="sm"
									onClick={() => handleSelect(description)}
								>
									{description}
								</Box>
							))}
						</Box>
					)}
				</Box>
				{showButton && (
					<Button mt={2} type="submit" size="sm" isDisabled={disabled}>
						Search
					</Button>
				)}
			</FormControl>
		</form>
	);
}
