import { useState } from 'react';
import {
	Stack,
	Heading,
	Icon,
	Box,
	Checkbox,
	List,
	ListItem,
} from '@chakra-ui/react';
import { ItemBidModel, ItemModel } from 'models';
import Wizard from '../Wizard';
import ItemBidCard from '../../Item/ItemBidCard/ItemBidCard';
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai';
import GuidanceCard from '../../GuidanceCard/GuidanceCard';
import PaymentBreakdown from '../../PaymentBreakdown/PaymentBreakdown';

interface ItemSellWizardProps {
	item: ItemModel;
	itemBid: ItemBidModel;
	isLoading?: boolean;
	hasErrors?: boolean;
	onSubmit?: (bid: ItemBidModel, useEscrow: boolean) => void;
	onInfoGuideClick?: () => void;
}

export default function ItemSellWizard({
	item,
	itemBid,
	onInfoGuideClick,
	isLoading = false,
	hasErrors = false,
	onSubmit = () => { },
}: ItemSellWizardProps) {
	const [agreed, setAgreed] = useState(false);
	const [useEscrow, setUseEscrow] = useState(false);
	const isTermsValid = agreed;

	const onWizardSubmit = () => {
		onSubmit(itemBid, useEscrow);
	};

	function renderTermsStep() {
		const termsList = (
			<List spacing={2} styleType="disc" pl={4} m={0}>
				<ListItem>
					I certify that my item is 100% authentic, and meets the minimum acceptable condition standards of this bid
				</ListItem>
				<ListItem>
					I agree to ship my item within 5 business days of receiving payment
				</ListItem>
				<ListItem>
					I understand that the net sale amount: {itemBid.getFormattedNetAmount(useEscrow)} will be paid out to me once the item is delivered
				</ListItem>
			</List>
		);

		return (
			<Box height={{ base: 'auto', md: 360 }}>
				<Stack spacing={4}>
					<ItemBidCard
						item={item}
						bidAmount={itemBid.amount}
						bidCreatedDays={itemBid.getCreatedDaysAgo()}
						bidUpdatedDays={itemBid.getUpdatedDaysAgo()}
						updated={itemBid.hasBeenUpdated()}
						condition={itemBid.condition}
					/>
					<Stack spacing={2}>
						<GuidanceCard header="Terms of Sale" text={termsList} />
						<Checkbox isChecked={agreed} onChange={(e) => setAgreed(e.target.checked)}>
							<Heading fontSize="xs">
								I agree to the terms and conditions of this sale.
							</Heading>
						</Checkbox>
					</Stack>
				</Stack>
			</Box>
		);
	}

	function renderFeeBreakdownStep() {
		return (
			<Box height={{ base: 'auto', md: 360 }}>
				<PaymentBreakdown transaction={itemBid} type={'bid'} useEscrow={useEscrow} onToggleEscrow={setUseEscrow} onInfoGuideClick={onInfoGuideClick} />
			</Box>
		);
	}

	function renderWizardFinishingSuccessStep() {
		let content: JSX.Element;
		if (hasErrors) {
			content = (
				<Stack gap={4} alignItems="center">
					<Icon as={AiFillCloseCircle} color="red.500" w={10} h={10} />
					<Heading size="xs" textAlign="center">
						Your Sale Could Not Be Submitted.<br />Please Try Again
					</Heading>
				</Stack>
			);
		} else {
			content = (
				<Stack gap={4} alignItems="center">
					<Icon as={AiFillCheckCircle} color="green.500" w={10} h={10} />
					<Heading size="xs" textAlign="center">
						Your Sale Has Been Submitted
					</Heading>
				</Stack>
			);
		}

		return (
			<Box height={{ base: 'auto', md: 340 }}>
				{content}
			</Box>
		);
	}

	const wizardSteps = [
		{
			label: 'Preview',
			content: renderFeeBreakdownStep(),
			requiresValidation: false,
		},
		{
			label: 'Confirm',
			content: renderTermsStep(),
			requiresValidation: true,
			isValidated: isTermsValid,
		},
	];

	return (
		<Stack gap={2} width="100%">
			<Wizard
				steps={wizardSteps}
				finishStep={renderWizardFinishingSuccessStep()}
				loading={isLoading}
				onSubmit={onWizardSubmit}
			/>
		</Stack>
	);
}
