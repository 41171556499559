import {
  ChakraProvider,
  ColorModeScript,
  extendTheme
} from "@chakra-ui/react";
import { AppView } from './views';

const theme = extendTheme();

export const App = () => (
  <ChakraProvider theme={theme}>
    <ColorModeScript />
    <AppView />
  </ChakraProvider>
);
