import { Loop, LoopReducer } from 'redux-loop';
import { ItemHandlers } from './handlers';
import { ItemState, ItemAction, ItemActionTypes } from './types';
import { defaultState } from './consts';

export const itemReducer: LoopReducer<ItemState, ItemAction> = (
  state: ItemState = defaultState,
  action: ItemAction
): ItemState | Loop<ItemState, ItemAction> => {
  switch (action.type) {
    case ItemActionTypes.GET_ITEM_BY_ID:
      return ItemHandlers.handleGetItemById(state, action);
    case ItemActionTypes.GET_ITEM_BY_ID_SUCCESS:
      return ItemHandlers.handleGetItemByIdSuccess(state, action);
    case ItemActionTypes.GET_ITEM_BY_ID_FAILURE:
      return ItemHandlers.handleGetItemByIdFailure(state);
    case ItemActionTypes.GET_ITEM_LISTINGS:
      return ItemHandlers.handleGetItemListings(state, action);
    case ItemActionTypes.GET_ITEM_LISTINGS_SUCCESS:
      return ItemHandlers.handleGetItemListingsSuccess(state, action);
    case ItemActionTypes.GET_ITEM_LISTINGS_FAILURE:
      return ItemHandlers.handleGetItemListingsFailure(state);
    case ItemActionTypes.GET_ITEM_MARKET:
      return ItemHandlers.handleGetItemMarket(state, action);
    case ItemActionTypes.GET_ITEM_MARKET_SUCCESS:
      return ItemHandlers.handleGetItemMarketSuccess(state, action);
    case ItemActionTypes.GET_ITEM_MARKET_FAILURE:
      return ItemHandlers.handleGetItemMarketFailure(state);
    case ItemActionTypes.GET_ITEM_ACTIVE_MARKET:
      return ItemHandlers.handleGetItemActiveMarket(state, action);
    case ItemActionTypes.GET_ITEM_ACTIVE_MARKET_SUCCESS:
      return ItemHandlers.handleGetItemActiveMarketSuccess(state, action);
    case ItemActionTypes.GET_ITEM_ACTIVE_MARKET_FAILURE:
      return ItemHandlers.handleGetItemActiveMarketFailure(state);
    case ItemActionTypes.GET_ITEM_ARTIST:
      return ItemHandlers.handleGetItemArtist(state, action);
    case ItemActionTypes.GET_ITEM_ARTIST_SUCCESS:
      return ItemHandlers.handleGetItemArtistSuccess(state, action);
    case ItemActionTypes.GET_ITEM_ARTIST_FAILURE:
      return ItemHandlers.handleGetItemArtistFailure(state);
    case ItemActionTypes.SEARCH_ITEM_BY_QUERY:
      return ItemHandlers.handleSearchItemByQuery(state, action);
    case ItemActionTypes.SEARCH_ITEM_BY_QUERY_SUCCESS:
      return ItemHandlers.handleSearchItemByQuerySuccess(state, action);
    case ItemActionTypes.SEARCH_ITEM_BY_QUERY_FAILURE:
      return ItemHandlers.handleSearchItemByQueryFailure(state);
    case ItemActionTypes.SEARCH_ITEM:
      return ItemHandlers.handleSearchItem(state, action);
    case ItemActionTypes.SEARCH_ITEM_SUCCESS:
      return ItemHandlers.handleSearchItemSuccess(state, action);
    case ItemActionTypes.SEARCH_ITEM_FAILURE:
      return ItemHandlers.handleSearchItemFailure(state);
    case ItemActionTypes.GET_USER_FOLLOWING_ARTISTS_ITEMS:
      return ItemHandlers.handleGetUserFollowingArtistsItems(state, action);
    case ItemActionTypes.GET_USER_FOLLOWING_ARTISTS_ITEMS_SUCCESS:
      return ItemHandlers.handleGetUserFollowingArtistsItemsSuccess(state, action);
    case ItemActionTypes.GET_USER_FOLLOWING_ARTISTS_ITEMS_FAILURE:
      return ItemHandlers.handleGetUserFollowingArtistsItemsFailure(state);
    case ItemActionTypes.GET_ITEM_SUGGESTIONS:
      return ItemHandlers.handleGetItemSuggestions(state, action);
    case ItemActionTypes.GET_ITEM_SUGGESTIONS_SUCCESS:
      return ItemHandlers.handleGetItemSuggestionsSuccess(state, action);
    case ItemActionTypes.GET_ITEM_SUGGESTIONS_FAILURE:
      return ItemHandlers.handleGetItemSuggestionsFailure(state);
    case ItemActionTypes.GET_ALL_ITEMS:
      return ItemHandlers.handleGetAllItems(state, action);
    case ItemActionTypes.GET_ALL_ITEMS_SUCCESS:
      return ItemHandlers.handleGetAllItemsSuccess(state, action);
    case ItemActionTypes.GET_ALL_ITEMS_FAILURE:
      return ItemHandlers.handleGetAllItemsFailure(state);
    case ItemActionTypes.PLACE_ITEM_BID:
      return ItemHandlers.handlePlaceItemBid(state, action);
    case ItemActionTypes.PLACE_ITEM_BID_SUCCESS:
      return ItemHandlers.handlePlaceItemBidSuccess(state, action);
    case ItemActionTypes.PLACE_ITEM_BID_FAILURE:
      return ItemHandlers.handlePlaceItemBidFailure(state);
    case ItemActionTypes.PLACE_ITEM_ASK:
      return ItemHandlers.handlePlaceItemAsk(state, action);
    case ItemActionTypes.PLACE_ITEM_ASK_SUCCESS:
      return ItemHandlers.handlePlaceItemAskSuccess(state, action);
    case ItemActionTypes.PLACE_ITEM_ASK_FAILURE:
      return ItemHandlers.handlePlaceItemAskFailure(state);
    case ItemActionTypes.DELETE_ITEM_BID:
      return ItemHandlers.handleDeleteItemBid(state, action);
    case ItemActionTypes.DELETE_ITEM_BID_SUCCESS:
      return ItemHandlers.handleDeleteItemBidSuccess(state, action);
    case ItemActionTypes.DELETE_ITEM_BID_FAILURE:
      return ItemHandlers.handleDeleteItemBidFailure(state);
    case ItemActionTypes.DELETE_ITEM_ASK:
      return ItemHandlers.handleDeleteItemAsk(state, action);
    case ItemActionTypes.DELETE_ITEM_ASK_SUCCESS:
      return ItemHandlers.handleDeleteItemAskSuccess(state, action);
    case ItemActionTypes.DELETE_ITEM_ASK_FAILURE:
      return ItemHandlers.handleDeleteItemAskFailure(state);
    case ItemActionTypes.UPDATE_ITEM_BID:
      return ItemHandlers.handleUpdateItemBid(state, action);
    case ItemActionTypes.UPDATE_ITEM_BID_SUCCESS:
      return ItemHandlers.handleUpdateItemBidSuccess(state, action);
    case ItemActionTypes.UPDATE_ITEM_BID_FAILURE:
      return ItemHandlers.handleUpdateItemBidFailure(state);
    case ItemActionTypes.UPDATE_ITEM_ASK:
      return ItemHandlers.handleUpdateItemAsk(state, action);
    case ItemActionTypes.UPDATE_ITEM_ASK_SUCCESS:
      return ItemHandlers.handleUpdateItemAskSuccess(state, action);
    case ItemActionTypes.UPDATE_ITEM_ASK_FAILURE:
      return ItemHandlers.handleUpdateItemAskFailure(state);
    case ItemActionTypes.PLACE_ITEM_INQUIRY:
      return ItemHandlers.handlePlaceItemInquiry(state, action);
    case ItemActionTypes.PLACE_ITEM_INQUIRY_SUCCESS:
      return ItemHandlers.handlePlaceItemInquirySuccess(state);
    case ItemActionTypes.PLACE_ITEM_INQUIRY_FAILURE:
      return ItemHandlers.handlePlaceItemInquiryFailure(state);
    case ItemActionTypes.GENERATE_ASK_PAYMENT_LINK:
      return ItemHandlers.handleGenerateItemAskPaymentLink(state, action);
    case ItemActionTypes.GENERATE_ASK_PAYMENT_LINK_SUCCESS:
      return ItemHandlers.handleGenerateItemAskPaymentLinkSuccess(state, action);
    case ItemActionTypes.GENERATE_ASK_PAYMENT_LINK_FAILURE:
      return ItemHandlers.handleGenerateItemAskPaymentLinkFailure(state);
    case ItemActionTypes.ACCEPT_ITEM_BID:
      return ItemHandlers.handleAcceptItemBid(state, action);
    case ItemActionTypes.ACCEPT_ITEM_BID_SUCCESS:
      return ItemHandlers.handleAcceptItemBidSuccess(state);
    case ItemActionTypes.ACCEPT_ITEM_BID_FAILURE:
      return ItemHandlers.handleAcceptItemBidFailure(state);
    default:
      return state;
  }
}
