import { Box, Flex, Heading, Text, Divider, Spacer, Icon } from '@chakra-ui/react';
import { IconType } from 'react-icons';

export interface UserProfileCardProps {
  title: string;
  subtitle?: string;
  children: JSX.Element;
  topButton?: JSX.Element;
  bottomButton?: JSX.Element;
  icon?: IconType;
}

export default function UserProfileCard({
  title,
  subtitle,
  children,
  topButton,
  bottomButton,
  icon
}: UserProfileCardProps) {
  return (
    <Box
      p={6}
      bg="gray.50"
      borderRadius="md"
      borderWidth="1px"
      width={'100%'}
      display="flex"
      flexDirection={'column'}
    >
      <Flex align="center" width={'100%'}>
        <Flex align="center" gap={2}>
          {icon && <Icon as={icon} />}
          <Box textAlign={'left'}>
            <Heading fontSize="md">{title}</Heading>
            {subtitle && (
              <Text fontSize="sm" fontWeight="hairline">
                {subtitle}
              </Text>
            )}
          </Box>
        </Flex>
        <Spacer />
        {topButton && <Box>{topButton}</Box>}
      </Flex>
      <Divider my={4} />
      <Flex align="flex-end" flex={1}>
        <Box>{children}</Box>
        <Spacer />
        {bottomButton && <Box>{bottomButton}</Box>}
      </Flex>
    </Box>
  );
}
