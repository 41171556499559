import moment, { Moment } from 'moment';
import { ItemModel } from "../Item/Item";
import { Fee, Condition } from '../types';
import { AddressModel } from "../Address/Address";
import { RawOrderData, OrderRatings, TrackingInfo, OrderStatus, OrderPaymentStatus, OrderPayoutStatus } from './types';

export class OrderModel {
	id: string;
	item: ItemModel | undefined;
	artistId: string;
	buyerId: string;
	sellerId: string;
	orderStatus: OrderStatus;
	paymentStatus: OrderPaymentStatus;
	payoutStatus: OrderPayoutStatus;
	useEscrow: boolean;
	fees: {
		buyerFees: Fee[];
		sellerFees: Fee[];
	};
	amounts: {
		grossTotal: number;
		netTotal: number;
		subTotal: number;
	};
	trackingInfo?: TrackingInfo | undefined;
	shippingAddress?: AddressModel | undefined;
	ratings?: OrderRatings;
	timestamps: {
		confirmedAt: Moment | null;
		createdAt: Moment | null;
		deliveredAt: Moment | null;
		paidAt: Moment | null;
		shippedAt: Moment | null;
		updatedAt: Moment | null;
	};
	condition: Condition | undefined;
	paymentLink?: string;

	constructor(raw?: RawOrderData) {
		this.id = raw?.id || '';
		this.item = raw?.item ? new ItemModel(raw.item) : undefined;
		this.artistId = raw?.artist_id || '';
		this.buyerId = raw?.buyer_id || '';
		this.sellerId = raw?.seller_id || '';
		this.orderStatus = raw?.order_status || OrderStatus.UNCONFIRMED;
		this.paymentStatus = raw?.payment_status || OrderPaymentStatus.PENDING;
		this.payoutStatus = raw?.payout_status || OrderPayoutStatus.PENDING;
		this.useEscrow = raw?.use_escrow || false;
		this.fees = {
			buyerFees: raw?.fees.buyer_fees || [],
			sellerFees: raw?.fees.seller_fees || [],
		};
		this.amounts = {
			grossTotal: raw?.amounts.gross_total || 0,
			netTotal: raw?.amounts.net_total || 0,
			subTotal: raw?.amounts.sub_total || 0,
		};
		this.trackingInfo = raw?.tracking_info;
		this.shippingAddress = raw?.shipping_address ? new AddressModel(raw.shipping_address) : undefined;
		this.ratings = raw?.ratings;
		this.timestamps = {
			confirmedAt: raw?.timestamps.confirmed_at ? moment(raw?.timestamps.confirmed_at) : null,
			createdAt: raw?.timestamps.created_at ? moment(raw?.timestamps.created_at) : null,
			deliveredAt: raw?.timestamps.delivered_at ? moment(raw?.timestamps.delivered_at) : null,
			paidAt: raw?.timestamps.paid_at ? moment(raw?.timestamps.paid_at) : null,
			shippedAt: raw?.timestamps.shipped_at ? moment(raw?.timestamps.shipped_at) : null,
			updatedAt: raw?.timestamps.updated_at ? moment(raw?.timestamps.updated_at) : null,
		};
		this.condition = raw?.condition || undefined;
		this.paymentLink = raw?.payment_link;
	}

	hasShippingAddress(): boolean {
		return !!this.shippingAddress;
	}

	getCreatedDate(): Moment | null {
		return this.timestamps.createdAt;
	}

	isUnconfirmed(): boolean {
		return this.orderStatus === OrderStatus.UNCONFIRMED;
	}
}