import moment from 'moment';
import { ArtistIndexTooltipData } from '../types';
import {
	Flex,
	Text,
	Heading,
} from '@chakra-ui/react';
import GrowthBadge from '../../Badge/GrowthBadge';
import { ArtistIndexData } from 'models/Artist/ArtistIndex';

interface ArtistIndexTooltipProps extends ArtistIndexTooltipData {
	indexPriceData: ArtistIndexData[];
}

export default function ArtistIndexTooltip({ active, payload, indexPriceData }: ArtistIndexTooltipProps) {
	if (active && payload && payload.length) {
		let previousValue = 0;
		let delta = 0;
		const currentValue = payload[0].value;
		const currentDate = moment(payload[0].payload?.date);
		const artistIndexDataIndex = indexPriceData.findIndex(v => moment(v.date).isSame(currentDate));
		if (artistIndexDataIndex > 0) {
			previousValue = indexPriceData[artistIndexDataIndex - 1].weightedIndex;
			delta = currentValue - previousValue;
		}

		return (
			<Flex direction="column" p={3} bg="white" borderWidth="1px" borderRadius="lg" shadow="md" gap={2}>
				<Text fontSize="sm">{currentDate.format('YYYY-MM-DD')}</Text>
				<Flex alignItems="center">
					<Heading fontSize="sm" mr={1}>{payload[0].value.toFixed(2)}</Heading>
					{!!artistIndexDataIndex && !!delta && <GrowthBadge starting={previousValue} ending={currentValue} />}
				</Flex>
			</Flex>
		);
	}

	return null;
};