import { Component } from 'react';
import { connect, Dispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { RouterProps, withRouter } from "utils/route";
import { ApplicationState } from 'reducers/types';
import { AuthActions } from 'reducers/Auth/actions';
import { AuthSelectors } from 'reducers/Auth/selectors';
import { UserActions } from 'reducers/User/actions';
import { UserSelectors } from 'reducers/User/selectors';
import {
  Box,
  Stack,
  Heading,
  Text,
  Button,
  HStack,
  Flex
} from '@chakra-ui/react';
import { LoginCard, RegisterCard, Logo, SEOHelmet } from 'components';
import { UserModel } from 'models';
import { FeatureToggleService, NavigationService } from 'services'
import { generateSEOLink, generateSEOTitle } from 'utils/seo';
import { FeatureToggleKeys } from '../../constants/toggles'
import { AppConstants } from '../../constants';


enum AuthStateType {
  LOGIN = 'login',
  REGISTER = 'register'
}

interface AuthViewProps extends RouterProps {
  user: UserModel;
  loginLoading: boolean;
  registerLoading: boolean;
  forgotPasswordLoading: boolean;
  loginByEmail: (email: string, password: string) => any;
  registerUser: (email: string, username: string, password: string) => any;
  sendForgotPasswordEmail: (eamil: string) => any;
}

interface AuthViewState {
  flowType: AuthStateType
}

class Auth extends Component<AuthViewProps, AuthViewState> {
  state = {
    flowType: this.props.params.type === 'register' ? AuthStateType.REGISTER : AuthStateType.LOGIN
  }

  componentDidUpdate(prevProps: AuthViewProps) {
    const { params, user, navigate } = this.props;
    const { flowType } = this.state;

    if (!prevProps.user.isAuthenticated() && user.isAuthenticated()) {
      if (flowType === AuthStateType.REGISTER) {
        navigate(NavigationService.getUserConfirmationPath());
      } else {
        if (user.isConfirmed()) {
          navigate(NavigationService.getHomePath());
        } else {
          navigate(NavigationService.getUserConfirmationPath());
        }
      }
    }

    if (this.hasRouteChanged(prevProps) && params.type) {
      this.setState({
        flowType: this.getFlowFromParamsType(params.type)
      })
    }
  }

  getFlowFromParamsType = (type: string): AuthStateType => {
    return type === 'register' ? AuthStateType.REGISTER : AuthStateType.LOGIN;
  }

  hasRouteChanged = (prevProps: AuthViewProps): boolean => {
    const { params } = this.props;
    return !!params.type && (params.type !== prevProps.params.type);
  }

  onLoginSwitchClick = () => {
    this.setState({
      flowType: AuthStateType.REGISTER
    });
  }

  onRegisterSwitchClick = () => {
    this.setState({
      flowType: AuthStateType.LOGIN
    });
  }

  renderLoginHeader() {
    return (
      <Stack spacing="8">
        <Stack spacing="6">
          <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
            <Heading fontSize="xl">
              Log in to your account
            </Heading>
            <HStack spacing="1" justify="center" fontSize="sm">
              <Text color="muted">Don't have an account?</Text>
              <Button variant="link" colorScheme="blue" onClick={this.onLoginSwitchClick}>
                Sign up
              </Button>
            </HStack>
          </Stack>
        </Stack>
      </Stack>
    );
  }

  renderRegisterHeader() {
    return (
      <Stack spacing="8">
        <Stack spacing="6">
          <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
            <Heading fontSize="xl">
              Create an account
            </Heading>
            <HStack spacing="1" justify="center" fontSize="sm">
              <Text color="muted">Already have an account?</Text>
              <Button variant="link" colorScheme="blue" onClick={this.onRegisterSwitchClick}>
                Log in
              </Button>
            </HStack>
          </Stack>
        </Stack>
      </Stack>
    );
  }

  renderLoginContent() {
    const { loginByEmail, loginLoading, sendForgotPasswordEmail, forgotPasswordLoading } = this.props;
    return (
      <Box>
        {this.renderLoginHeader()}
        <LoginCard onLoginClick={loginByEmail} onForgotPasswordClick={sendForgotPasswordEmail} showOAuthButtons={FeatureToggleService.isToggleEnabled(FeatureToggleKeys.AUTH_OAUTH_BUTTONS)} submitLoading={loginLoading || forgotPasswordLoading} />
      </Box>
    );
  }

  renderRegisterContent() {
    const { registerUser, registerLoading } = this.props;
    return (
      <Box>
        {this.renderRegisterHeader()}
        <RegisterCard onRegisterClick={registerUser} showOAuthButtons={FeatureToggleService.isToggleEnabled(FeatureToggleKeys.AUTH_OAUTH_BUTTONS)} registerLoading={registerLoading} />
      </Box>
    )
  }

  renderAuthContent() {
    if (this.state.flowType === AuthStateType.LOGIN) {
      return this.renderLoginContent();
    } else {
      return this.renderRegisterContent();
    }
  }

  renderLogo() {
    return (
      <Logo height={'120px'} width={'120px'} />
    )
  }

  render() {
    return (
      <>
      <SEOHelmet
          title={generateSEOTitle('Auth')}
          description={`Sign In Or Register.`}
          link={generateSEOLink(NavigationService.getAuthLoginPath())}
        />
        <Box maxWidth={`${AppConstants.GRIDPAGE_WIDTH}px`} paddingTop={['80px', '100px', '100px']} justifySelf="center" minWidth={['100%', '600px']}>
        <Flex direction={'column'} alignItems={'center'} gap={10}>
          {this.renderLogo()}
          {this.renderAuthContent()}
        </Flex>
      </Box>
      </>
    );
  }
}

function mapStateToProps(state: ApplicationState) {
  return {
    user: AuthSelectors.getAuthenticatedUser(state),
    loginLoading: AuthSelectors.getUserLoginLoading(state),
    registerLoading: AuthSelectors.getUserRegisterLoading(state),
    forgotPasswordLoading: UserSelectors.getUserMailActionLoading(state)
  }
}

function mapDispatchToProps(dispatch: Dispatch<ApplicationState>) {
  return bindActionCreators(
    {
      loginByEmail: AuthActions.loginByEmail,
      registerUser: AuthActions.registerUser,
      sendForgotPasswordEmail: UserActions.sendForgotPasswordEmail
    },
    dispatch
  );
}

export const AuthView = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Auth));
