import { Component } from 'react';
import { connect, Dispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { RouterProps, withRouter } from "utils/route";
import { ApplicationState } from 'reducers/types';
import { OrderSelectors } from 'reducers/Order/selectors';
import { UserSelectors } from 'reducers/User/selectors';
import { AuthSelectors } from 'reducers/Auth/selectors';
import { OrderActions } from 'reducers/Order/actions';
import { Box, Stack } from '@chakra-ui/react';
import { SEOHelmet, OrderContainer, Drawer, AddressWizard } from 'components';
import { OrderModel, ItemModel, UserModel, AddressModel } from 'models';
import { NavigationService } from 'services';
import { AppConstants } from '../../constants';
import { generateSEOTitle } from 'utils/seo';

interface OrderDetailsViewProps extends RouterProps {
	user: UserModel;
	order: OrderModel;
	orderLoading: boolean;
	userLoading: boolean;
	getOrder: (id: string) => void;
	addOrderAddress: (orderId: string, address: AddressModel) => void;
}

interface OrderDetailsState {
	drawers: {
		addressWizard: boolean;
	};
}

class OrderDetails extends Component<OrderDetailsViewProps, OrderDetailsState> {
	state = {
		drawers: {
			addressWizard: false
		}
	};

	componentDidMount() {
		const { params } = this.props;
		if (params && params.id) {
			this.props.getOrder(params.id);
		}
	}


	hasRouteChanged = (prevProps: OrderDetailsViewProps): boolean => {
		const { params } = this.props;
		return !!params.id && (params.id !== prevProps.params.id);
	}

	isBuyerOrder = () => {
		const { order, user } = this.props;
		return order.buyerId === user.id;
	}

	onAddressWizardOpen = () => {
		this.setState(prevState => ({
			drawers: { ...prevState.drawers, addressWizard: true }
		}));
	};

	onAddressWizardClose = () => {
		this.setState(prevState => ({
			drawers: { ...prevState.drawers, addressWizard: false }
		}));
	};

	onShippingAddressSubmit = (newAddress: AddressModel) => {
		const { addOrderAddress, order } = this.props;
		addOrderAddress(order.id, newAddress);
		this.onAddressWizardClose();
	};

	onOrderItemNameClick = (id: string, name: string) => {
		this.props.navigate(NavigationService.getItemDetailsPath(id, name));
	};

	onOrderItemArtistClick = (id: string, name: string) => {
		this.props.navigate(NavigationService.getArtistDetailsPath(id, name));
	};

	renderDrawers() {
		const { drawers } = this.state;
		return (
			<Stack>
				<Drawer
					title="Manage Shipping Address"
					isOpen={drawers.addressWizard}
					onClose={this.onAddressWizardClose}
					content={
						<AddressWizard
							onSubmit={this.onShippingAddressSubmit}
							disabled={false}
						/>
					}
				/>
			</Stack>
		);
	}

	renderContainer() {
		const { order } = this.props;
		return (
			<OrderContainer
				order={order}
				isBuyer={this.isBuyerOrder()}
				onAddressButtonClick={this.onAddressWizardOpen}
				onItemArtistClick={this.onOrderItemArtistClick}
				onItemNameClick={this.onOrderItemNameClick} />
		);
	}

	render() {
		return (
			<>
				<SEOHelmet
					title={generateSEOTitle('Order')}
					description="View your order."
				/>
				<Box maxWidth={`${AppConstants.GRIDPAGE_WIDTH}px`} minWidth={{ base: '100%', md: `${AppConstants.GRIDPAGE_WIDTH}px` }} paddingTop={['80px', '100px', '100px']} justifySelf="center">
					{this.renderContainer()}
				</Box>
				{this.renderDrawers()}
			</>
		);
	}
}

function mapStateToProps(state: ApplicationState) {
	return {
		order: OrderSelectors.getOrder(state),
		user: UserSelectors.getUser(state),
		userLoading: UserSelectors.getUserLoading(state) || AuthSelectors.getUserLoading(state),
		orderLoading: OrderSelectors.getOrderLoading(state),
	}
}

function mapDispatchToProps(dispatch: Dispatch<ApplicationState>) {
	return bindActionCreators(
		{
			getOrder: (id: string) => OrderActions.getOrderById(id),
			addOrderAddress: (orderId: string, address: AddressModel) => OrderActions.addOrderAddress(orderId, address)
		},
		dispatch
	);
}

export const OrderDetailsView = connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(OrderDetails));
