import {
	Box,
	Button,
	Avatar,
	Flex,
	Heading,
	Text,
	Stack,
	Image,
	Spacer,
	Icon,
	Badge,
	Tooltip,
	useColorModeValue
} from '@chakra-ui/react';
import { ItemModel } from 'models';
import { Condition } from 'models/types';
import { FcClock } from "react-icons/fc";
import { BsTrashFill } from 'react-icons/bs';
import { BiPurchaseTagAlt } from "react-icons/bi";
import { FiEdit } from 'react-icons/fi';
import { MdOutlineMessage } from 'react-icons/md';
import { toDollarFormat } from 'utils/format';
import { getMarketValueTextConfig } from 'utils/activemarket';
import { FeatureToggleService } from 'services';
import { FeatureToggleKeys } from 'constants/toggles';
import GrowthBadge from '../../Badge/GrowthBadge';
import ConditionBadge from '../../ConditionBadge/ConditionBadge';

export interface ItemAskCardProps {
	item: ItemModel;
	askAmount: number;
	askCreatedDays?: number;
	askUpdatedDays?: number;
	condition?: Condition;
	accepted?: boolean;
	complete?: boolean;
	updated?: boolean;
	expired?: boolean;
	useAvatar?: boolean;
	onItemNameClick?: (itemId: string, itemName: string) => void;
	onItemArtistNameClick?: (artistId: string, artistName: string) => void;
	onEditClick?: () => void;
	onRemoveClick?: () => void;
	onContactClick?: () => void;
	onBuyClick?: () => void;
	showIcons?: boolean;
	userOwnsAsk?: boolean;
	shorten?: boolean;
}

export enum ItemAskCardSizingConfig {
	CARD_HEIGHT = '80px',
	PANEL_MIN_HEIGHT = '80px'
}

export enum ItemAskCardStylingConfig {
	BORDER_COLOR_LIGHT = '#f1f3f7',
	BORDER_COLOR_DARK = '#3f434c'
}

export default function ItemAskCard({
	item,
	askAmount,
	askCreatedDays,
	askUpdatedDays,
	condition,
	accepted,
	complete,
	expired,
	updated,
	onItemNameClick,
	onItemArtistNameClick,
	onEditClick,
	onRemoveClick,
	onContactClick,
	onBuyClick,
	useAvatar = false,
	showIcons = false,
	userOwnsAsk = false,
	shorten = false
}: ItemAskCardProps) {
	const marketPrice = item.getEstimatedMarketPrice();
	const isMarketValue = askAmount === marketPrice;
	const marketplaceEnabled = FeatureToggleService.isToggleEnabled(FeatureToggleKeys.ENABLE_MARKETPLACE);

	function renderItemImage() {
		if (useAvatar) {
			return <Avatar backgroundColor={'white'} marginRight={4} name={item.name} src={item.image} />
		} else {
			return (
				<Image
					fit={'contain'}
					marginRight={4}
					src={item.image}
					alt={item.name}
					width={ItemAskCardSizingConfig.CARD_HEIGHT}
					height={ItemAskCardSizingConfig.CARD_HEIGHT}
				/>
			);
		}
	}

	function renderIconWithTooltip(icon: JSX.Element, tooltipLabel: string) {
		return (
			<Tooltip label={tooltipLabel}>
				<Flex>
					{icon}
				</Flex>
			</Tooltip>
		);
	}

	function renderAskBadges() {
		const badges = [];
		badges.push(
			<Badge colorScheme='orange' width={'fit-content'} key={'askBadge_label'}>
				Ask
			</Badge>
		);

		if (condition) {
			badges.push(
				<ConditionBadge condition={condition} />
			);
		}

		if (expired) {
			badges.push(
				<Badge colorScheme='red' width={'fit-content'} key={'askBadge_expired'}>
					Expired
				</Badge>
			);
		} else if (accepted) {
			badges.push(
				<Badge colorScheme='green' width={'fit-content'} key={'askBadge_expired'}>
					Accepted
				</Badge>
			);
		} else if (complete) {
			badges.push(
				<Badge colorScheme='green' width={'fit-content'} key={'askBadge_expired'}>
					Complete
				</Badge>
			);
		}

		return (
			<Flex direction={'row'} gap={2}>
				{badges}
			</Flex>
		);
	}

	function renderIcons() {
		if (showIcons && !complete && !accepted && !expired) {
			if (userOwnsAsk) {
				return (
					<Flex direction={'row'} justifyContent={'flex-end'} gap={1}>
						{renderIconWithTooltip((
							<Icon
								_hover={onEditClick ? { color: "blue.500", cursor: "pointer" } : {}}
								as={FiEdit}
								height={4}
								onClick={onEditClick}
							/>
						), 'Edit Ask')}
						{renderIconWithTooltip((
							<Icon
								_hover={onRemoveClick ? { color: "red.500", cursor: "pointer" } : {}}
								as={BsTrashFill}
								height={4}
								onClick={onRemoveClick}
							/>
						), 'Remove Ask')}
					</Flex>
				);
			} else {
				return (
					<Flex direction={'row'} justifyContent={'flex-end'} gap={1}>
						<Button size="xs" leftIcon={<Icon as={MdOutlineMessage} />} onClick={onContactClick}>
							Inquire
						</Button>
						{marketplaceEnabled && (
							<Button disabled={expired} colorScheme='blue' size="xs" leftIcon={<Icon as={BiPurchaseTagAlt} />} onClick={onBuyClick}>
								Buy
							</Button>
						)}
					</Flex>
				);
			}
		}
	}

	function renderBidMarketDataText() {
		const { text, color } = getMarketValueTextConfig(askAmount, marketPrice, true);
		if (isMarketValue) {
			return (
				<Tooltip label={'Priced at Fair Market Value'} >
					<Badge colorScheme={'purple'} mb={1}>
						{text}
					</Badge>
				</Tooltip>
			);
		} else {
			return (
				<Tooltip label={'FMV = Fair Market Value'} >
					<Text textAlign={'right'} fontSize="xs" color={color}>
						{text}
					</Text>
				</Tooltip>
			);
		}
	}

	function renderAskMarketDataStatistics() {
		const noDataLabel = shorten ? 'No Data' : 'No Market Data';
		return marketPrice ? (
			<Stack spacing={1} alignItems={'flex-end'}>
				{!isMarketValue && <GrowthBadge starting={marketPrice} ending={askAmount} inverse={true} />}
				{renderBidMarketDataText()}
			</Stack >
		) : (
			<Badge alignSelf={'flex-end'} colorScheme={'pink'} mb={1} width={'fit-content'}>
				{noDataLabel}
			</Badge>
		);
	}

	function renderAskData() {
		return (
			<Flex direction="column" gap={2} alignItems="flex-end" alignSelf={"flex-start"}>
				<Heading fontSize="sm">{toDollarFormat(askAmount)}</Heading>
				<Stack spacing={1}>
					{renderAskMarketDataStatistics()}
					{renderIcons()}
				</Stack>
			</Flex>
		);
	}

	function renderAskItemName() {
		return (
			<Heading
				_hover={onItemNameClick ? { color: "blue.500", cursor: "pointer" } : {}}
				fontSize="sm"
				onClick={() => onItemNameClick?.(item.id, item.getDisplayName())}>
				{item.name}
			</Heading>
		);
	}

	function renderAskArtistName() {
		return (
			<Text
				_hover={onItemArtistNameClick ? { color: "blue.500", cursor: "pointer" } : {}}
				fontSize="xs"
				onClick={() => onItemArtistNameClick?.(item.artistId, item.getArtistName())}>
				{item.getArtistName()}
			</Text>
		);
	}

	function renderAskCreatedDuration() {
		let durationText: string;

		if (updated) {
			durationText = askUpdatedDays ? `Updated ${askUpdatedDays} Days Ago` : 'Updated Today';
		} else {
			durationText = askCreatedDays ? `Created ${askCreatedDays} Days Ago` : 'Created Today';
		}
		return (
			<Flex direction="row" alignItems="center" gap={1}>
				<Icon as={FcClock} w={3} h={3} />
				<Text fontSize="xs">{durationText}</Text>
			</Flex>
		);
	}

	return (
		<Box
			minHeight={ItemAskCardSizingConfig.CARD_HEIGHT}
			width="100%"
			overflow='hidden'
			padding={2}
			border="1px solid"
			borderColor={useColorModeValue(ItemAskCardStylingConfig.BORDER_COLOR_LIGHT, ItemAskCardStylingConfig.BORDER_COLOR_DARK)}
		>
			<Flex direction="row" alignItems="center" justifyContent="flex-start">
				{renderItemImage()}
				<Flex direction="column" textAlign="left" gap={2} paddingRight={shorten ? 0 : { base: 2, md: 4 }}>
					{renderAskItemName()}
					<Stack spacing={1}>
						{renderAskArtistName()}
						{renderAskCreatedDuration()}
						{renderAskBadges()}
					</Stack>
				</Flex>
				<Spacer />
				{renderAskData()}
			</Flex>
		</Box>
	);
}
