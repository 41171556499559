import { ItemModel } from 'models';
import { Stack, NumberInput, InputGroup, InputLeftElement, FormControl, FormLabel, Button, FormErrorMessage, NumberInputField } from '@chakra-ui/react';
import { useForm, Controller } from 'react-hook-form';
import BasicItemCard from '../../Item/BasicItemCard/BasicItemCard';
import DurationDropdown from '../../DurationDropdown/DurationDropdown';

export interface ItemBidFormData {
	bidPrice?: number;
	bidExpiration?: number;
}

interface ItemBidFormProps {
	item: ItemModel;
	onSubmit?: (data: ItemBidFormData) => void;
	onChange?: (data: ItemBidFormData) => void;
	defaultFormData?: ItemBidFormData;
	durationOptions?: number[];
	isDisabled?: boolean;
	showSubmit?: boolean;
	showItemCard?: boolean;
	showExpirationDropdown?: boolean;
}

export default function ItemBidForm({
	item,
	onSubmit,
	onChange,
	defaultFormData = { bidExpiration: 30 },
	durationOptions = [30, 60, 90],
	isDisabled = false,
	showSubmit = true,
	showItemCard = true,
	showExpirationDropdown = false
}: ItemBidFormProps) {
	const { register, handleSubmit, control, getValues, setValue, formState: { errors } } = useForm<ItemBidFormData>({
		defaultValues: defaultFormData,
	});

	const onFormSubmit = (data: ItemBidFormData) => {
		onSubmit?.(data);
	};

	const handleFormChange = () => {
		if (onChange) {
			onChange(getValues());
		}
	};

	return (
		<Stack gap={4} width="100%">
			{showItemCard && <BasicItemCard item={item} />}
			<form onSubmit={handleSubmit(onFormSubmit)} onChange={handleFormChange}>
				<Stack spacing={8}>
					<FormControl isDisabled={isDisabled} isInvalid={!!errors.bidPrice}>
						<FormLabel htmlFor="bidPrice" fontSize={"sm"}>Bid Price</FormLabel>
						<InputGroup>
							<InputLeftElement
								pointerEvents='none'
								color='gray.500'
								children='$'
							/>
							<NumberInput width={'100%'}>
								<NumberInputField
									{...register('bidPrice', {
										valueAsNumber: true,
										validate: {
											isNumber: (v) => v && !isNaN(v) || 'Bid Price must be a valid number',
											isPositive: v => (v && v > 0) || 'Bid Price must be greater than 0',
										}
									})}
									paddingLeft={10}
									fontSize="sm"
									placeholder='Enter Bid Amount (USD)'
								/>
							</NumberInput>
						</InputGroup>
						<FormErrorMessage>{errors.bidPrice && errors.bidPrice.message}</FormErrorMessage>
					</FormControl>

					{showExpirationDropdown && (
						<FormControl isDisabled={isDisabled} isInvalid={!!errors.bidExpiration}>
							<FormLabel htmlFor="bidExpiration" fontSize={"sm"}>Bid Expiration</FormLabel>
							<Controller
								control={control}
								name="bidExpiration"
								rules={{ required: 'Bid expiration is required' }}
								render={({ field: { value } }) => (
									<DurationDropdown
										durations={durationOptions}
										onSelect={(selectedValue) => {
											setValue('bidExpiration', selectedValue, { shouldDirty: true });
											handleFormChange();
										}}
										selectedDuration={value}
										disabled={isDisabled}
									/>
								)}
							/>
							<FormErrorMessage>{errors.bidExpiration && errors.bidExpiration.message}</FormErrorMessage>
						</FormControl>
					)}

					{!isDisabled && showSubmit && (
						<Button mt={4} colorScheme="teal" type="submit">
							Place Bid
						</Button>
					)}
				</Stack>
			</form>
		</Stack >
	);
}
