import {
	Box,
	Avatar,
	Flex,
	Heading,
	Text,
	Stack,
	Image,
	Spacer,
	Icon,
	useColorModeValue,
	Badge
} from '@chakra-ui/react';
import { ItemModel } from 'models';
import { Condition } from 'models/types';
import { FcClock } from "react-icons/fc";
import { toDollarFormat } from 'utils/format';
import { getMarketValueTextConfig } from 'utils/activemarket';
import GrowthBadge from '../../Badge/GrowthBadge';
import ConditionBadge from '../../ConditionBadge/ConditionBadge';

interface ItemAskEditCardProps {
	item: ItemModel;
	condition?: Condition;
	originalAmount: number;
	currentAmount?: number;
	createdDaysAgo?: number;
	updatedDaysAgo?: number;
	useAvatar?: boolean;
	expired?: boolean;
	updated?: boolean;
	noLongerExpired?: boolean;
	onItemNameClick?: (itemId: string, itemName: string) => void;
	onItemArtistNameClick?: (artistId: string) => void;
}

export default function ItemAskEditCard({
	item,
	condition,
	originalAmount,
	currentAmount,
	createdDaysAgo,
	updatedDaysAgo,
	useAvatar = false,
	expired = false,
	updated = false,
	noLongerExpired = false,
	onItemNameClick,
	onItemArtistNameClick
}: ItemAskEditCardProps) {
	const marketPrice = item.getEstimatedMarketPrice();
	const isMarketValue = (currentAmount || originalAmount) === marketPrice;

	function renderItemImage() {
		return useAvatar ? (
			<Avatar backgroundColor={'white'} marginRight={4} name={item.name} src={item.image} />
		) : (
			<Image
				fit={'contain'}
				marginRight={4}
				src={item.image}
				alt={item.name}
				width="80px"
				height="80px"
			/>
		);
	}

	function renderBidMarketDataText() {
		const { text, color } = getMarketValueTextConfig(currentAmount || originalAmount, marketPrice, true);
		if (isMarketValue) {
			return (
				<Badge colorScheme={'purple'} mb={1}>
					{text}
				</Badge>
			);
		} else {
			return (
				<Text textAlign={'right'} fontSize="xs" color={color}>
					{text}
				</Text>
			);
		}
	}

	function renderAskMarketDataStatistics() {
		return marketPrice ? (
			<Stack spacing={1} alignItems={'flex-end'}>
				{!isMarketValue && <GrowthBadge starting={marketPrice} ending={currentAmount || originalAmount} inverse={true} />}
				{renderBidMarketDataText()}
			</Stack >
		) : (
			<Badge alignSelf={'flex-end'} colorScheme={'pink'} mb={1} width={'fit-content'}>
				No Market Data
			</Badge>
		);
	}

	function renderAskBadges() {
		const badges = [];
		badges.push(
			<Badge colorScheme='orange' width={'fit-content'} key={'askBadge_label'}>
				Ask
			</Badge>
		);

		if (condition) {
			badges.push(
				<ConditionBadge condition={condition} />
			);
		}

		if (expired) {
			if (noLongerExpired) {
				badges.push(
					<Badge colorScheme='red' width={'fit-content'} key={'askBadge_expired'} as={'s'}>
						Expired
					</Badge>
				)
			} else {
				badges.push(
					<Badge colorScheme='red' width={'fit-content'} key={'askBadge_expired'}>
						Expired
					</Badge>
				);
			}
		}

		return (
			<Flex direction={'row'} gap={2}>
				{badges}
			</Flex>
		);
	}

	function renderAskData() {
		return (
			<Flex direction="column" gap={2} alignItems="flex-end">
				<Heading fontSize="sm">{toDollarFormat(currentAmount || originalAmount)}</Heading>
				{!!currentAmount && originalAmount !== currentAmount && (
					<Text fontSize="xs" color="gray.500" as="s">
						{toDollarFormat(originalAmount)}
					</Text>
				)}

				<Stack spacing={1}>
					{renderAskMarketDataStatistics()}
				</Stack>
			</Flex>
		);
	}

	function renderAskCreatedDuration() {
		let durationText: string;

		if (updated) {
			durationText = updatedDaysAgo ? `Updated ${updatedDaysAgo} Days Ago` : 'Updated Today';
		} else {
			durationText = createdDaysAgo ? `Created ${createdDaysAgo} Days Ago` : 'Created Today';
		}

		return (
			<Flex direction="row" alignItems="center" gap={1}>
				<Icon as={FcClock} w={3} h={3} />
				<Text fontSize="xs">{durationText}</Text>
			</Flex>
		);
	}

	function renderAskItemName() {
		return (
			<Heading
				_hover={onItemNameClick ? { color: "blue.500", cursor: "pointer" } : {}}
				fontSize="sm"
				onClick={() => onItemNameClick?.(item.id, item.getDisplayName())}>
				{item.name}
			</Heading>
		);
	}

	function renderAskArtistName() {
		return (
			<Text
				_hover={onItemArtistNameClick ? { color: "blue.500", cursor: "pointer" } : {}}
				fontSize="xs"
				onClick={() => onItemArtistNameClick?.(item.artistId)}>
				{item.getArtistName()}
			</Text>
		);
	}

	return (
		<Box
			minHeight="80px"
			width="100%"
			overflow='hidden'
			padding={2}
			border="1px solid"
			borderColor={useColorModeValue('#f1f3f7', '#3f434c')}
		>
			<Stack>
				<Flex direction="row" alignItems="center" justifyContent="flex-start">
					{renderItemImage()}
					<Flex direction="column" textAlign="left" gap={2} paddingRight={4}>
						{renderAskItemName()}
						<Stack spacing={1}>
							{renderAskArtistName()}
							{renderAskCreatedDuration()}
							{renderAskBadges()}
						</Stack>
					</Flex>
					<Spacer />
					{renderAskData()}
				</Flex>
			</Stack>
		</Box>
	);
}
