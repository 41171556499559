import {
	Box,
	Flex,
	Heading,
	Text,
	Button,
	Stack,
	Icon,
	Link,
	Tooltip,
	Spacer,
	Image,
	StackDivider,
	Accordion,
	AccordionItem,
	AccordionPanel,
	AccordionButton,
	AccordionIcon
} from '@chakra-ui/react';
import { ArtistModel } from 'models/Artist/Artist';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import { FiExternalLink } from 'react-icons/fi';
import { FaInstagram } from 'react-icons/fa';
import { RiGalleryLine, RiContactsBook2Line } from 'react-icons/ri';
import { CgWebsite } from 'react-icons/cg';
import Feature from '../../Feature/Feature';
import ArtsyIcon from '../../CustomIcons/ArtsyIcon';
import ArtistMetricCard from '../ArtistMetricCard/ArtistMetricCard';
import ArtistIndexChartCore from '../../Charts/ArtistIndexChartCore';
import { ArtistIndexModel } from 'models';

export interface ArtistIndexBannerProps {
	artist: ArtistModel;
	editionIndexData?: ArtistIndexModel;
	buttonText?: string;
	buttonIcon?: JSX.Element;
	onNameClick?: (artist: ArtistModel) => void;
	buttonOnClick?: (artist: ArtistModel) => void;
}

export default function ArtistIndexBanner({
	artist,
	editionIndexData,
	buttonText,
	buttonIcon,
	onNameClick,
	buttonOnClick,
}: ArtistIndexBannerProps) {
	const dateSortedData = editionIndexData?.getDateSortedIndexData();

	function renderArtistContactIcons() {
		const icons = [];

		if (artist.contactInfo.artsyLink) {
			icons.push(
				<Tooltip label="Artsy" key="mini_artsy">
					<Link
						href={artist.contactInfo.artsyLink}
						isExternal
						fontSize="lg"
						_hover={{ color: 'blue.500' }}
					>
						<Icon as={ArtsyIcon} />
					</Link>
				</Tooltip>
			);
		}

		if (artist.contactInfo.instagramLink) {
			icons.push(
				<Tooltip label="Instagram" key="mini_instagram">
					<Link
						href={artist.contactInfo.instagramLink}
						isExternal
						fontSize="lg"
						_hover={{ color: 'blue.500' }}
					>
						<Icon as={FaInstagram} />
					</Link>
				</Tooltip>
			);
		}

		if (artist.contactInfo.representativeGallery) {
			icons.push(
				<Tooltip label="Representation" key="mini_gallery">
					<Link
						href={artist.contactInfo.representativeGallery}
						isExternal
						fontSize="lg"
						_hover={{ color: 'blue.500' }}
					>
						<Icon as={RiGalleryLine} />
					</Link>
				</Tooltip>
			);
		}

		if (artist.contactInfo.website) {
			icons.push(
				<Tooltip label="Website" key="mini_website">
					<Link
						href={artist.contactInfo.website}
						isExternal
						fontSize="lg"
						_hover={{ color: 'blue.500' }}
					>
						<Icon as={CgWebsite} />
					</Link>
				</Tooltip>
			);
		}

		return (
			<Flex alignItems="center" gap="8px">
				{icons}
			</Flex>
		);
	}

	function renderBannerButton() {
		if (buttonText) {
			return (
				<Button rightIcon={buttonIcon} onClick={() => buttonOnClick?.(artist)} size={{base: 'sm', md: 'md'}} cursor={buttonOnClick ? 'cursor' : 'pointer'} border="1px">
					{buttonText}
				</Button>
			)
		}
	}

	function renderMetricCard() {
		if (dateSortedData?.length) {
			return (
				<ArtistMetricCard artist={artist} metric={dateSortedData[0].weightedIndex} priorMetricValue={dateSortedData[1]?.weightedIndex} showBorder={false} showLabel={true} customLabel={'Edition Index Score'} />
			)
		}
	}

	function renderChartData() {
		if (editionIndexData) {
			return (
				<ArtistIndexChartCore artistIndexData={editionIndexData} showFilters={false} showYAxis={false} />
			);
		}
	}

	function renderDescriptionAccordion() {
		return (
			<Accordion allowToggle>
				<AccordionItem border={'none'}>
					<AccordionButton paddingX={0} paddingY={2}>
						<Flex
							w={8}
							h={8}
							marginRight={2}
							align={'center'}
							justify={'center'}
							rounded={'full'}
							bg={'gray.100'}>
							<Icon as={RiContactsBook2Line} />
						</Flex>
						<Text fontSize={"md"}>
							Biography
						</Text>
						<Spacer />
						<AccordionIcon />
					</AccordionButton>

					<AccordionPanel paddingX={0} paddingY={2} fontSize={'xs'}>
						{artist.description}
					</AccordionPanel>
				</AccordionItem>
			</Accordion>
		)
	}

	return (
		<Box>
			<Flex direction={{ 'base': 'column', 'md': 'row' }}>
				<Box width={{ base: '100%', md: '50%' }} marginRight={{ base: 0, md: 8 }} marginY={{ base: 4, md: 0 }}>
					<Image
						src={artist.image}
						alt={artist.name}
						objectFit="cover"
						loading='eager'
						height={240}
						width={'100%'}
						borderRadius={{ 'base': 0, 'md': 4 }}
					/>
				</Box>
				<Stack width="100%" paddingX={{ base: 5, md: 0 }} divider={<StackDivider borderColor={'gray.100'} />}>
					<Flex direction={'row'} gap={2} alignItems="flex-start">
						<Flex direction={{ base: 'column', md: 'row' }} gap={{ base: 0, md: 2}}>
							<Heading
								onClick={() => onNameClick?.(artist)}
								size={{ base: 'md', md: 'lg' }}
								_hover={onNameClick ? { color: 'blue.500', cursor: 'pointer' } : {}}>
								{artist.name}
							</Heading>
							<Flex direction={'row'} gap={2} alignItems={'center'}>
								<Heading fontSize={"sm"}>
									{`(b. ${artist.birthYear})`}
								</Heading>
								<Text>{getUnicodeFlagIcon(artist.country.code)}</Text>
							</Flex>
						</Flex>
						<Spacer />
						{renderBannerButton()}
					</Flex>
					{renderMetricCard()}
					<Feature
						icon={
							<Icon as={FiExternalLink} color={'blue.500'} w={5} h={5} />
						}
						iconBg={'blue.100'}
						text={renderArtistContactIcons()}
					/>
					{renderDescriptionAccordion()}
				</Stack>
			</Flex>
			{renderChartData()}
		</Box>
	);
}
