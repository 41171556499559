import { Button, Flex, Heading, Text } from '@chakra-ui/react';
import { BsBell } from 'react-icons/bs';
import UserProfileCard from './UserProfileCard';

interface UserProfileFollowingCardProps {
  followingCount: number;
  onManage?: () => void;
}

export default function UserProfileFollowingCard({
  followingCount,
  onManage,
}: UserProfileFollowingCardProps) {
  const followingLabel = followingCount > 1 ? 'Artists' : 'Artist';

  return (
    <UserProfileCard
      title="Following Artists"
      subtitle="Manage & Track Your Following Artists"
      icon={BsBell}
      bottomButton={
        <Button variant="outline" size="sm" onClick={onManage}>
          Manage Following
        </Button>
      }
      children={
        <Flex gap={1} align="center">
          <Heading fontSize="md">{followingCount}</Heading>
          <Text fontSize="sm">{followingLabel}</Text>
        </Flex>
      }
    />
  );
}
