import moment from 'moment';
import { ItemModel } from '../Item';
import { UserModel } from '../../User/User';
import { ItemBidStatus, RawItemBidData, ItemBidTimestamps, ItemBidType } from './types';
import { Condition } from '../../types';
import { AppConfig } from 'config';

export class ItemBidModel {
    id: string;
    itemId: string;
    item: ItemModel | null;
    userId: string;
    amount: number;
    currency: string;
    status: ItemBidStatus;
    verified: boolean;
    expirationDays: number;
    notes: string;
    condition: Condition | undefined;
    timestamps: ItemBidTimestamps;
    type: string;
    useEscrow: boolean;

  
    constructor(rawItemBidData: RawItemBidData) {
      this.id = rawItemBidData.id || '';
      this.itemId = rawItemBidData.item?.id || rawItemBidData.item_id || '';
      this.item = rawItemBidData.item ? new ItemModel(rawItemBidData.item) : null;
      this.userId = rawItemBidData.user_id || '';
      this.amount = rawItemBidData.amount || 0;
      this.currency = rawItemBidData.currency || 'USD';
      this.status = rawItemBidData.status || ItemBidStatus.PENDING;
      this.verified = rawItemBidData.verified || false;
      this.expirationDays = rawItemBidData.expiration_days || 0;
      this.notes = rawItemBidData.notes || '';
      this.condition = rawItemBidData.condition || undefined;
      this.useEscrow = rawItemBidData.use_escrow || false;
      this.timestamps = {
        createdAt: moment(rawItemBidData.timestamps?.created_at),
        updatedAt: moment(rawItemBidData.timestamps?.updated_at),
        expiresAt: moment(rawItemBidData.timestamps?.expires_at)
      };
      this.type = ItemBidType;
    }
  
    public isExpired(): boolean {
      return this.status === ItemBidStatus.EXPIRED;
    }

    public isAccepted(): boolean {
      return this.status === ItemBidStatus.ACCEPTED;
    }

    public isComplete() : boolean {
      return this.status === ItemBidStatus.COMPLETE;
    }

    public isVerified(): boolean {
      return this.verified;
    }
  
    public getFormattedAmount(): string {
      return this.amount.toLocaleString('en-US', {
        style: 'currency',
        currency: this.currency,
      });
    }
  
    public getTimeRemainingForDisplay(): string {
      const duration = moment.duration(this.timestamps.expiresAt.diff(moment()));
      return `${duration.days()} days, ${duration.hours()} hours`;
    }

    public getDaysLeft(): number {
      const duration = moment.duration(this.timestamps.expiresAt.diff(moment()));
      return Math.floor(duration.asDays());
    }

    public getCreatedDaysAgo(): number {
      const duration = moment.duration(this.timestamps.createdAt.diff(moment()));
      return Math.abs(Math.floor(duration.asDays()));
    }

    public getUpdatedDaysAgo(): number {
      const duration = moment.duration(this.timestamps.updatedAt.diff(moment()));
      return Math.abs(Math.floor(duration.asDays()));
    }

    public hasBeenUpdated(): boolean {
      return this.getUpdatedDaysAgo() < this.getCreatedDaysAgo();
    }

    public hasItemData(): boolean {
      return this.item != null;
    }

    public setItem(item: ItemModel): void {
      this.item = item;
    }

    public belongsToUser(user: UserModel): boolean {
      return this.userId === user.id;
    }

    public getCondition(): Condition | undefined {
      return this.condition;
    }

    public getPlatformFeePercentage(): number {
      return AppConfig.PLATFORM_SELLER_FEE_PERCENTAGE;
    }

    public getEscrowFeePercentage(): number {
      return AppConfig.PLATFORM_SELLER_FEE_PERCENTAGE;
    }

    public getPlatformFeeAmount(): number {
      const platformSellerFees = AppConfig.PLATFORM_SELLER_FEE_PERCENTAGE;
      return this.amount * (platformSellerFees / 100);
    }

    public getEscrowFeeAmount(): number {
      const platformSellerFees = AppConfig.PLATFORM_SELLER_FEE_PERCENTAGE;
      return this.amount * (platformSellerFees / 100);
    }

    public getSellerNetAmount(useEscrow?: boolean): number {
      const platformFeeAmount = this.getPlatformFeeAmount();
      const escrowFeeAmount = useEscrow ? this.getEscrowFeeAmount() : 0;
      const netAmount = this.amount - platformFeeAmount - escrowFeeAmount;
      return netAmount;
    }

    public getFormattedPlatformFeeAmount(): string {
      return this.getPlatformFeeAmount().toLocaleString('en-US', {
        style: 'currency',
        currency: this.currency,
      });
    }

    public getFormattedEscrowFeeAmount(): string {
      return this.getEscrowFeeAmount().toLocaleString('en-US', {
        style: 'currency',
        currency: this.currency,
      });
    }

    public getFormattedNetAmount(useEscrow?: boolean): string {
      return this.getSellerNetAmount(useEscrow).toLocaleString('en-US', {
        style: 'currency',
        currency: this.currency,
      });
    }
  }