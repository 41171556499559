import { useState } from 'react';
import { Box, Stack, Heading } from '@chakra-ui/react';
import { AddressModel } from 'models';
import SearchAddressForm from '../../Form/SearchAddressForm/SearchAddressForm';
import AddressForm from '../../Form/AddressForm/AddressForm';
import Wizard from '../Wizard';


export interface AddressWizardProps {
  onSubmit: (address: AddressModel) => void;
  disabled?: boolean;
  defaultAddress?: AddressModel | null;
  showStepper?: boolean;
}

export default function AddressWizard({ onSubmit, disabled = false, showStepper = true, defaultAddress }: AddressWizardProps) {
  const [address, setAddress] = useState<AddressModel>(
    defaultAddress || new AddressModel({
      line1: '',
      line2: '',
      city: '',
      state: '',
      postal_code: '',
      country: '',
    })
  );

  const handleSearchSelect = (newAddress: AddressModel) => {
    setAddress(newAddress);
  };

  const handleAddressFormChange = (updatedAddress: AddressModel) => {
    setAddress(updatedAddress);
  };

  const wizardSteps = [
    {
      label: 'Search',
      content: (
        <Box>
          <SearchAddressForm onSelect={handleSearchSelect} disabled={disabled} defaultAddress={address} />
        </Box>
      ),
      requiresValidation: true,
      isValidated: !!address.line1,
    },
    {
      label: 'Review',
      content: (
        <Box>
          <Heading fontSize="sm" mb={4}>Step 2: Review & Edit Address</Heading>
          <AddressForm
            defaultAddress={address}
            validated={true}
            onChange={handleAddressFormChange}
            disabledOptions={['line1', 'postal_code', 'city', 'state', 'country']}
            disabled={disabled} />
        </Box>
      ),
      requiresValidation: true,
      isValidated: true,
    },
  ];

  const finishStep = (
    <Box>
      <Heading fontSize="sm" mb={2}>Address Confirmed</Heading>
      <Box>
        <Box>Address Line 1: {address.line1}</Box>
        <Box>Address Line 2: {address.line2}</Box>
        <Box>City: {address.city}</Box>
        <Box>State: {address.state}</Box>
        <Box>Postal Code: {address.postalCode}</Box>
        <Box>Country: {address.country}</Box>
      </Box>
    </Box>
  );

  const handleWizardSubmit = () => {
    onSubmit(address);
  };

  return (
    <Stack gap={2} width="100%">
      <Wizard
        showStepper={showStepper}
        steps={wizardSteps}
        finishStep={finishStep}
        onSubmit={handleWizardSubmit}
      />
    </Stack>
  );
}