import { useState, useMemo } from 'react';
import { Stack, Heading, Icon, Box } from '@chakra-ui/react';
import { ItemAskModel, ItemModel, UserModel, ItemBidModel } from 'models';
import { Condition } from 'models/types';
import { ItemBidStatus } from 'models/Item/ItemBid/types';
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai';
import Wizard from '../Wizard';
import ItemAskForm, { ItemAskFormData } from '../../Form/ItemAskForm/ItemAskForm';
import ItemAskAdditionalDataForm, { ItemAskAdditionalDataFormData } from 'components/Form/ItemAskAdditionalDataForm/ItemAskAdditionalDataForm';
import ItemAskCard from '../../Item/ItemAskCard/ItemAskCard';
import ItemAskEditCard from '../../Item/ItemAskEditCard/ItemAskEditCard';
import BasicItemCard from '../../Item/BasicItemCard/BasicItemCard';
import UnauthorizedAccess from '../../UnauthorizedAccess/UnauthorizedAccess';
import PaymentBreakdown from '../../PaymentBreakdown/PaymentBreakdown';


interface ItemAskEditWizardProps {
	item: ItemModel;
	itemAsk: ItemAskModel;
	user: UserModel;
	onSubmit?: (itemAsk: ItemAskModel, data?: ItemAskFormData, additionalAskData?: ItemAskAdditionalDataFormData, useEscrow?: boolean) => void;
	isLoading?: boolean;
	hasErrors?: boolean;
}

export default function ItemAskEditWizard({
	item,
	itemAsk,
	user,
	onSubmit = () => { },
	isLoading = false,
	hasErrors = false,
}: ItemAskEditWizardProps) {
	const [editAskData, setEditAskData] = useState<ItemAskFormData>();
	const [additionalAskData, setAdditionalAskData] = useState<ItemAskAdditionalDataFormData>();
	const [useEscrow, setUseEscrow] = useState(itemAsk.useEscrow);
	const opposingBidTransaction = useMemo(() => new ItemBidModel({
		id: '',
		item_id: item.id,
		user_id: user.id,
		amount: editAskData?.askPrice || itemAsk.amount,
		currency: 'USD',
		status: ItemBidStatus.PENDING,
		condition: additionalAskData?.condition || itemAsk.condition || Condition.GOOD,
		notes: '',
		expiration_days: 30,
		verified: itemAsk.isVerified() || false,
		use_escrow: useEscrow
	}), [user, item.id, editAskData?.askPrice, additionalAskData?.condition, itemAsk, useEscrow]);

	const onWizardSubmit = () => {
		onSubmit(itemAsk, editAskData, additionalAskData, useEscrow);
	};

	const handleFormChange = (data: ItemAskFormData) => {
		setEditAskData(data);
	};

	const handleAdditionalDataChange = (data: ItemAskAdditionalDataFormData) => {
		setAdditionalAskData(data);
	};

	function renderBasicItemCard() {
		return (
			<BasicItemCard item={item} />
		);
	}

	function renderAskForm() {
		return (
			<ItemAskForm
				item={item}
				onChange={handleFormChange}
				defaultFormData={editAskData || { askPrice: itemAsk.amount }}
				showSubmit={false}
				showItemCard={false}
			/>
		);
	}

	function renderAdditionalDataForm() {
		return (
			<ItemAskAdditionalDataForm
				onChange={handleAdditionalDataChange}
				defaultFormData={additionalAskData || { 
					notes: itemAsk.notes, 
					condition: itemAsk.condition || Condition.GOOD
				}}
			/>
		);
	}

	function renderCurrentAskPreview() {
		return (
			<Stack spacing={4}>
				<Heading fontSize="sm">Current Ask</Heading>
				<ItemAskEditCard
					item={item}
					originalAmount={itemAsk.amount}
					currentAmount={editAskData?.askPrice}
					createdDaysAgo={itemAsk.getCreatedDaysAgo()}
					updatedDaysAgo={itemAsk.getUpdatedDaysAgo()}
					updated={itemAsk.hasBeenUpdated()}
					expired={itemAsk.isExpired()}
					noLongerExpired={(editAskData?.askExpiration || 0) > 0} 
					condition={additionalAskData?.condition || itemAsk.getCondition()} />
			</Stack>
		);
	}

	function renderConfirmationStep() {
		return (
			<Box height={{ base: 'auto', md: 340 }}>
				<Stack spacing={4}>
					<ItemAskCard
						item={item}
						askAmount={editAskData?.askPrice || itemAsk.amount}
						askCreatedDays={itemAsk.getCreatedDaysAgo()}
						askUpdatedDays={0}
						updated={true}
						expired={itemAsk.isExpired() && !editAskData?.askExpiration}
						condition={additionalAskData?.condition || itemAsk.getCondition()} />
					<Heading fontSize="sm">Confirm Your Updated Ask</Heading>
					<PaymentBreakdown transaction={opposingBidTransaction} type={'bid'} useEscrow={useEscrow} onToggleEscrow={setUseEscrow} showInfoGuide={false} showTransactionCard={false} />

				</Stack>
			</Box>
		);
	}

	function renderEditStep() {
		if (itemAsk.belongsToUser(user)) {
			return (
				<Box height={{ base: 'auto', md: 340 }}>
					<Stack spacing={4}>
						{renderBasicItemCard()}
						{renderCurrentAskPreview()}
						{renderAskForm()}
					</Stack>
				</Box>
			);
		} else {
			return (
				<UnauthorizedAccess
					title="Unauthorized"
					description="You are unauthorized to edit this ask."
				/>
			);
		}
	}

	function renderAdditionalDetailsStep() {
		if (itemAsk.belongsToUser(user)) {
			return (
				<Box height={{ base: 'auto', md: 340 }}>
					<Stack spacing={4}>
						{renderCurrentAskPreview()}
						{renderAdditionalDataForm()}
					</Stack>
				</Box>
			);
		} else {
			return (
				<UnauthorizedAccess
					title="Unauthorized"
					description="You are unauthorized to edit this ask."
				/>
			);
		}
	}

	function renderWizardFinishingSuccessStep() {
		let content: JSX.Element;

		if (hasErrors) {
			content = (
				<Stack gap={4} alignItems="center">
					<Icon as={AiFillCloseCircle} color="red.500" w={10} h={10} />
					<Heading size="xs" textAlign="center">
						Your Ask Could Not Be Updated.<br />Please Try Again
					</Heading>
				</Stack>
			);
		} else {
			content = (
				<Stack gap={4} alignItems="center">
					<Icon as={AiFillCheckCircle} color="green.500" w={10} h={10} />
					<Heading size="xs" textAlign="center">
						Your Ask Has Been Updated
					</Heading>
				</Stack>
			);
		}

		return (
			<Box height={{ base: 'auto', md: 340 }}>
				{content}
			</Box>
		)
	}

	const wizardSteps = [
		{ label: 'Edit', content: renderEditStep(), requiresValidation: false },
		{ label: 'Details', description: 'Optional', content: renderAdditionalDetailsStep(),  requiresValidation: false },
		{ label: 'Confirm', content: renderConfirmationStep(), requiresValidation: true, isValidated: !!editAskData || !!additionalAskData || useEscrow != itemAsk.useEscrow },
	];

	return (
		<Stack gap={2} width="100%">
			<Wizard steps={wizardSteps} finishStep={renderWizardFinishingSuccessStep()} loading={isLoading} onSubmit={onWizardSubmit} />
		</Stack>
	);
}
