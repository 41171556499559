import { Stack, Box, Heading, Text } from '@chakra-ui/react';
import { ItemModel } from 'models';
import { Condition } from 'models/types';
import Wizard from '../Wizard';
import BasicItemCard from 'components/Item/BasicItemCard/BasicItemCard';
import ItemBidAdditionalDataForm from 'components/Form/ItemBidAdditionalDataForm/ItemBidAdditionalDataForm';
import ItemBidCard from 'components/Item/ItemBidCard/ItemBidCard';

interface ItemBidGuideWizardProps {
  item: ItemModel;
}

export default function ItemBidGuideWizard({ item }: ItemBidGuideWizardProps) {
  const amount =
    item.getEstimatedMarketPrice() ||
    item.getReleaseConvertedPrice() ||
    1000;

  function renderStepContent(header: string, content: JSX.Element) {
    return (
      <Stack spacing={4}>
        <Heading size="sm" padding={2}>{header}</Heading>
        <Box height={350}>
          {content}
        </Box>
      </Stack>
    );
  }

  const step1Content = renderStepContent('Step 1: Specify a Bid Amount', (
    <Stack spacing={4}>
      <BasicItemCard item={item} />
      <Text fontWeight={300} fontSize="sm" textAlign={'left'} padding={2}>
        Submit an offer for an amount of your choice. Our pricing engine provides guidance on the fair market value of the work to guide you.
      </Text>
    </Stack>

  ));

  const step2Content = renderStepContent('Step 2: Provide Optional Details', (
    <Stack paddingX={2} spacing={4}>
      <ItemBidAdditionalDataForm isDisabled={true} defaultFormData={{condition: Condition.GOOD}}/>
      <Text fontWeight={300} fontSize="sm" textAlign={'left'}>
        You can optionally pick the minimum acceptable item condition for your price offer. Furthermore, you can add notes to provide some additional context relating to your offer.
      </Text>
    </Stack>
  ));

  const step3Content = renderStepContent('Step 3: Review, Submit & Relax', (
    <Stack spacing={4}>
      <ItemBidCard
        item={item}
        bidAmount={amount}
        bidCreatedDays={0}
        condition={Condition.GOOD}
        userOwnsBid={true}
        shorten={true}
      />
      <Text fontWeight={300} fontSize="sm" textAlign={'left'} padding={2}>
        Review your bid one final time before placing it. Once your bid has been placed, it will be added to our trading floor and ranked based on how strong of an offer you placed. Our sales team will reach out for any potential inquiries or counters on your bid. No purchase will ever be made without your explicit consent.
      </Text>
    </Stack>
  ));

  const wizardSteps = [
    { label: '', content: step1Content },
    { label: '', content: step2Content },
    { label: '', content: step3Content },
  ];

  return (
    <Stack width="100%">
      <Wizard
        steps={wizardSteps}
      />
    </Stack>
  );
};
