import { UrlConfig } from 'config';
import { ItemBidModel, ItemAskModel  } from 'models';
import { ItemActiveMarketData, RawItemActiveMarketData } from 'models/Item/types'; 
import { ApiResponse } from 'models/Api/ApiResponse';
import { AjaxService } from 'services';

export class ActiveMarketApiClient {
	public static async getAllActiveMarket(): Promise<ItemActiveMarketData> {
		const url = UrlConfig.ACTIVE_MARKET.ACTIVE_MARKET_ALL;
		const response: ApiResponse<RawItemActiveMarketData> = await AjaxService.getData(url);
		const activeMarket = {
			bids: response.data?.bids.map(rawBid => new ItemBidModel(rawBid)),
			asks: response.data?.asks.map(rawAsk => new ItemAskModel(rawAsk))
		};
		return activeMarket;
	}
}
