export class AppConfig {
  static API = {
    CORE_URL: 'https://api.artcore.tech',
    LOCAL_URL: 'http://0.0.0.0:4000'
  };

  static APP_NAME: string = 'Artcore';
  static CONTACT_EMAIL: string = 'contact@artcore.tech';
  static CONTACT_INSTAGRAM: string = 'artcore.tech';
  static DEFAULT_COUNTRY_CODE: string = 'us';
  static BASE_URL = 'https://beta.artcore.tech';

  static PLATFORM_SELLER_FEE_PERCENTAGE = 5;
  static PLATFORM_BUYER_FEE_PERCENTAGE = 5;
}
