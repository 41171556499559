import { useState, useEffect } from 'react';
import {
  Box,
  Text,
  Flex,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  MenuDivider,
  SimpleGrid,
  Spacer,
  useColorModeValue
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { ArtistModel } from 'models';
import ArtistCard from '../ArtistCard/ArtistCard';
import BasicArtistCard from '../BasicArtistCard/BasicArtistCard';
import SearchBar from '../../SearchBar/SearchBar';
import EmptyState from '../../EmptyState/EmptyState';
import ArtistGridSkeleton from '../ArtistGridSkeleton/ArtistGridSkeleton';

export interface ArtistGridProps {
  artists:  ArtistModel[];
  isLoading: boolean;
  showFilters?: boolean;
  showSort?: boolean;
  onArtistCardClick?: (artist: ArtistModel) => void;
  onBrowseClick?: () => void;
  gridDisplay?: boolean;
  gridMaxHeight?: number;
}

export enum ArtistGridSortFilter {
  DEFAULT = 'DEFAULT',
  TITLE_ASC = 'TITLE_ASC',
  TITLE_DESC = 'TITLE_DESC',
  BIRTH_YEAR_ASC = 'BIRTH_YEAR_ASC',
  BIRTH_YEAR_DESC = 'BIRTH_YEAR_DESC'
}

export default function ArtistGrid({ artists, isLoading, onBrowseClick, showFilters = true, showSort = false, onArtistCardClick = () => {}, gridDisplay = false, gridMaxHeight = 600 }: ArtistGridProps) {
  const [gridItems, setGridItems] = useState<ArtistModel[]>(artists);
  const [artistsQuery, setItemsQuery] = useState<string>('');
  const [artistsSort, setItemsSort] = useState<ArtistGridSortFilter>(ArtistGridSortFilter.DEFAULT);

  useEffect(() => {
    if (artists.length) {
      setGridItems(artists);
    }
  }, [artists]);

  const hasEmptyInitialData = (): boolean => {
    return !isLoading && artists.length === 0;
  }

  const isOnFilterMode = (): boolean => {
    return artistsQuery.length > 0 || artistsSort !== ArtistGridSortFilter.DEFAULT;
  }

  const onArtistSearch = (query: string): void => {
    setItemsQuery(query);
    const newItems = filterItemsByQuery(artists, query);
    processSortKey(newItems, artistsSort);
  }

  const onArtistSortSelect = (sortKey: ArtistGridSortFilter): void => {
    setItemsSort(sortKey);
    let newItems = artists;
    if (artistsQuery) {
      newItems = filterItemsByQuery(newItems, artistsQuery);
    }
    processSortKey(newItems, sortKey);
  }

  const filterItemsByQuery = (artists: ArtistModel[], query: string): ArtistModel[] => {
    return artists.filter((artist: ArtistModel) => artist.name.toLowerCase().indexOf(query.toLowerCase()) >= 0);
  }

  const processSortKey = (artists: ArtistModel[], sortKey: ArtistGridSortFilter): void => {
    let newItems = artists;
    if (sortKey === ArtistGridSortFilter.TITLE_ASC) {
      newItems = newItems.sort((a: ArtistModel, b: ArtistModel) => a.name > b.name ? 1 : -1);
    } else if (sortKey === ArtistGridSortFilter.TITLE_DESC) {
      newItems = newItems.sort((a: ArtistModel, b: ArtistModel) => a.name > b.name ? -1 : 1);
    } else if (sortKey === ArtistGridSortFilter.BIRTH_YEAR_ASC) {
      newItems = newItems.sort((a: ArtistModel, b: ArtistModel) => a.birthYear > b.birthYear ? -1 : 1);
    } else if (sortKey === ArtistGridSortFilter.BIRTH_YEAR_DESC) {
      newItems = newItems.sort((a: ArtistModel, b: ArtistModel) => a.birthYear > b.birthYear ? 1 : -1);
    }
    setGridItems(newItems);
  }

  const getItems = (): ArtistModel[] => {
    if (isOnFilterMode()) {
      return gridItems;
    } else {
      return artists;
    }
  }

  function renderFiltersSort() {
    if (showSort) {
      return (
        <Menu closeOnSelect={false}>
          <MenuButton as={Button} variant="outline" rightIcon={<ChevronDownIcon />}>
            Sort By
          </MenuButton>
          <MenuList minWidth='240px'>
            <MenuOptionGroup defaultValue='asc' title='Options' type='radio'>
              <MenuItemOption fontSize="sm" value='titleAsc' onClick={() => onArtistSortSelect(ArtistGridSortFilter.TITLE_ASC)}>A - Z</MenuItemOption>
              <MenuItemOption fontSize="sm" value='titleDesc' onClick={() => onArtistSortSelect(ArtistGridSortFilter.TITLE_DESC)}>Z - A</MenuItemOption>
              <MenuItemOption fontSize="sm" value='dateAsc' onClick={() => onArtistSortSelect(ArtistGridSortFilter.BIRTH_YEAR_ASC)}>Youngest to Oldest</MenuItemOption>
              <MenuItemOption fontSize="sm" value='dateDesc'onClick={() => onArtistSortSelect(ArtistGridSortFilter.BIRTH_YEAR_DESC)}>Oldest to Youngest</MenuItemOption>
            </MenuOptionGroup>
            <MenuDivider />
          </MenuList>
        </Menu>
      );
    }  
  }

  function renderItemsFilters() {
    if (showFilters) {
      return (
        <Flex minWidth='fit-content' alignItems={{base: 'start', md: 'center'}} gap='2' marginBottom="40px" p={{base: '20px', md: '8px 0px'}} direction={{base: 'column', md: 'row'}}>
          <Box>
            <Text fontSize='xs'>Filters: </Text>
          </Box>
          <Flex direction='row' width="100%">
            <SearchBar onChange={onArtistSearch} />
            <Spacer />
            {renderFiltersSort()}
          </Flex>
        </Flex>
      );
    }
  }

  function renderItems() {
    if (gridDisplay) {
      return (
        <Box backgroundColor={useColorModeValue("white", "black")} maxHeight={`${gridMaxHeight}px`} overflow="scroll" borderRadius="16px">
          <Flex direction='column'>
            {getItems().map((artist: ArtistModel, index: number) => <BasicArtistCard onClick={() => onArtistCardClick(artist)} key={`artist_card_${index}`} artist={artist} />)}
          </Flex>
        </Box>
      );
    } else {
      return (
        <SimpleGrid columns={[2, 4, 4]} spacing={{base: "0px", md: "20px"}}>
            {getItems().map((artist: ArtistModel, index: number) => <ArtistCard onClick={() => onArtistCardClick(artist)} key={`artist_card_${index}`} artist={artist} />)}
        </SimpleGrid>
      );
    }
  }

  function renderData() {
    const items = getItems();
    if (isLoading) {
      return <ArtistGridSkeleton gridDisplay={gridDisplay} gridMaxHeight={gridMaxHeight} rows={4} />;
    } else if (items.length) {
      return renderItems();
    } else if (isOnFilterMode() || hasEmptyInitialData()) {
      return (
        <EmptyState header="No Artists" description="No Artists Found" buttonText="Browse Artists" showButton={!!onBrowseClick} onButtonClick={onBrowseClick} />
      );
    }
  }

  return (
    <Box width="100%">
      {renderItemsFilters()}
      {renderData()}
    </Box>
  );
}
