import {
    Drawer as ChakraDrawer,
    DrawerOverlay,
    DrawerContent,
    DrawerHeader,
    DrawerBody
  } from '@chakra-ui/react';
  
  export interface DrawerProps {
    title?: string | JSX.Element;
    content: JSX.Element;
    isOpen: boolean;
    onClose?: () => void;
    placement?: 'top' | 'right' | 'bottom' | 'left';
  }
  
  export default function Drawer({
    title,
    content,
    isOpen,
    onClose = () => {},
    placement = 'right',
  }: DrawerProps) {
    return (
      <ChakraDrawer isOpen={isOpen} placement={placement} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          {title && (
            <DrawerHeader borderBottomWidth={1}>
              {title}
            </DrawerHeader>
          )}
          <DrawerBody paddingX={2}>
            {content}
          </DrawerBody>
        </DrawerContent>
      </ChakraDrawer>
    );
  }
  