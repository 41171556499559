import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
    Button,
    Avatar,
    Flex,
    Text,
    Heading,
    Stack,
    Image,
    Spacer,
    Icon,
    Tooltip,
    Badge,
    useColorModeValue,
} from '@chakra-ui/react';
import { WarningIcon } from '@chakra-ui/icons';
import { OrderModel } from 'models';
import { toDollarFormat } from 'utils/format';
import { BiCreditCard } from 'react-icons/bi';
import { FaShippingFast } from 'react-icons/fa';
import { OrderPaymentStatus, OrderStatus } from 'models/Order/types';

export interface BuyerOrderCardProps {
    order: OrderModel;
    useAvatar?: boolean;
    onOrderNumberClick?: (orderId: string) => void;
    onItemNameClick?: (itemId: string, itemName: string) => void;
    onItemArtistNameClick?: (artistId: string, artistName: string) => void;
}

export enum BuyerOrderCardSizingConfig {
    IMAGE_WIDTH = '80px',
    CARD_HEIGHT = '80px'
}

export enum BuyerOrderCardStylingConfig {
    BORDER_COLOR_LIGHT = '#f1f3f7',
    BORDER_COLOR_DARK = '#3f434c'
}

export default function BuyerOrderCard({
    order,
    useAvatar = false,
    onOrderNumberClick,
    onItemNameClick,
}: BuyerOrderCardProps) {

    const getOrderStatusColor = (status: OrderStatus): string => {
        switch (status) {
            case OrderStatus.UNCONFIRMED: return 'orange';
            case OrderStatus.CONFIRMED: return 'blue';
            case OrderStatus.IN_TRANSIT: return 'blue';
            case OrderStatus.COMPLETE: return 'green';
            case OrderStatus.CANCELLED: return 'red';
            default: return 'gray';
        }
    }

    const getPaymentStatusColor = (status: OrderPaymentStatus): string => {
        switch (status) {
            case OrderPaymentStatus.PENDING: return 'orange';
            case OrderPaymentStatus.COMPLETE: return 'green';
            case OrderPaymentStatus.FAILED: return 'red';
            default: return 'gray';
        }
    }


    function renderItemImage() {
        const item = order.item
        if (item) {
            if (useAvatar) {
                return (
                    <Avatar
                        marginRight={4}
                        name={item.name}
                        src={item.image}
                    />
                );
            } else {
                return (
                    <Image
                        fit="contain"
                        marginRight={4}
                        src={item.image}
                        alt={item.name}
                        width={BuyerOrderCardSizingConfig.IMAGE_WIDTH}
                    />
                );
            }
        }
    }


    function renderOrderNumber() {
        return (
            <Heading
                _hover={onOrderNumberClick ? { color: 'blue.500', cursor: 'pointer' } : {}}
                fontSize="sm"
                onClick={() => onOrderNumberClick?.(order.id)}
            >
                Order 1234-5678
            </Heading>
        );
    }

    function renderOrderItemName() {
        const item = order.item
        if (item) {
            return (
                <Text
                    _hover={onItemNameClick ? { color: 'blue.500', cursor: 'pointer' } : {}}
                    fontSize="xs"
                    onClick={() => onItemNameClick?.(item.id, item.getDisplayName())}

                >
                    {item.getDisplayName()}
                </Text>
            );
        }
    }

    function renderOrderStatusBadge() {
        return (
            <Badge colorScheme={getOrderStatusColor(order.orderStatus)} width="fit-content">
                {order.orderStatus}
            </Badge>
        );
    }

    function renderStatusIcons() {
        return (
            <Flex direction="row" gap={2}>
                <Tooltip label={`Payment Status: ${order.paymentStatus}`}>
                    <Flex>
                        <Icon
                            as={BiCreditCard}
                            w={4}
                            h={4}
                            color={getPaymentStatusColor(order.paymentStatus)}
                        />
                    </Flex>
                </Tooltip>
                {renderShippingIcon()}
            </Flex>
        );
    }

    function renderShippingIcon() {
        const shippedAt = order.timestamps.shippedAt;
        const deliveredAt = order.timestamps.deliveredAt;

        let shippingColor = 'gray';
        let tooltipLabel = 'Shipment Pending';

        if (shippedAt) {
            shippingColor = 'blue';
            tooltipLabel = 'Shipped - In Transit';
        }

        if (deliveredAt) {
            shippingColor = 'green';
            tooltipLabel = 'Delivered';
        }
        return (
            <Tooltip label={tooltipLabel}>
                <Flex>
                    <Icon as={FaShippingFast} w={4} h={4} color={shippingColor} />
                </Flex>
            </Tooltip>
        );
    }

    function renderOrderAmount() {
        return (
            <Flex direction="column">
                <Heading fontSize="sm">{toDollarFormat(order.amounts.grossTotal)}</Heading>
                <Text fontSize="xs">Order Total</Text>
            </Flex>
        );
    }

    function renderPaymentLinkButton() {
        if (order.paymentLink && order.paymentStatus === OrderPaymentStatus.PENDING) {
            return (
                <Button
                    size="xs"
                    colorScheme="red"
                    leftIcon={<WarningIcon />}
                    onClick={() => window.open(order.paymentLink, '_blank')}
                >
                    Pay Now
                </Button>
            );
        }
        return null;
    }

    return (
        <Accordion
            allowToggle
            border="1px solid"
            borderColor={useColorModeValue(
                BuyerOrderCardStylingConfig.BORDER_COLOR_LIGHT,
                BuyerOrderCardStylingConfig.BORDER_COLOR_DARK
            )}
            minHeight={BuyerOrderCardSizingConfig.CARD_HEIGHT}
            width="100%"
        >
            <AccordionItem
            >
                <AccordionButton>
                    <Flex direction="row" alignItems="center" width="100%">
                        {renderItemImage()}
                        <Flex direction="column" textAlign="left" gap={2}>
                            {renderOrderNumber()}
                            <Stack spacing={1}>
                                {renderOrderItemName()}
                                {renderStatusIcons()}
                                {renderOrderStatusBadge()}
                            </Stack>
                        </Flex>
                        <Spacer />
                        <Stack justify="space-between" alignItems="flex-end" height={'100%'}>
                            {renderOrderAmount()}
                            <Flex direction={'column'} gap={1} alignItems="flex-end">
                                {renderPaymentLinkButton()}
                                <AccordionIcon />
                            </Flex>
                        </Stack>
                    </Flex>
                </AccordionButton>
                <AccordionPanel pb={4}>
                    {/* Empty panel for additional details to be added later */}
                </AccordionPanel>
            </AccordionItem>

        </Accordion>
    );
}
