import { Card, CardBody, Flex, Heading, Text, Stack } from '@chakra-ui/react';
import Logo from '../Logo/Logo';

export interface GuidanceCardProps {
  header: string;
  text: string | JSX.Element;
  showLogo?: boolean;
}

export default function GuidanceCard({
  header,
  text,
  showLogo = true,
}: GuidanceCardProps) {
  return (
    <Card variant="outline">
      <Stack>
        <CardBody>
          <Flex gap={4} alignItems="center">
            {showLogo && <Logo height="40px" width="40px" />}
            <Heading fontSize="sm">{header}</Heading>
          </Flex>
          <Text fontSize="xs" pt={4}>
            {text}
          </Text>
        </CardBody>
      </Stack>
    </Card>
  );
}