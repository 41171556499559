import { ItemActiveMarketData } from 'models/Item/types';

export enum ActiveMarketActionTypes {
  GET_ALL_ACTIVE_MARKET = 'GET_ALL_ACTIVE_MARKET',
  GET_ALL_ACTIVE_MARKET_SUCCESS = 'GET_ALL_ACTIVE_MARKET_SUCCESS',
  GET_ALL_ACTIVE_MARKET_FAILURE = 'GET_ALL_ACTIVE_MARKET_FAILURE',
};

export interface ActiveMarketActionPayload {
  activeMarket?: ItemActiveMarketData;
};

export interface ActiveMarketAction {
  type: ActiveMarketActionTypes;
  payload?: ActiveMarketActionPayload;
};

export interface ActiveMarketStateLoading {
  all: boolean;
};

export interface ActiveMarketState {
	all: ItemActiveMarketData;
  error: Error | undefined;
  loading: ActiveMarketStateLoading;
};
