import { Cmd, loop, Loop } from 'redux-loop';
import { OrderActions, OrderInternalActions } from './actions';
import { MarketplaceApiClient } from 'api';
import { OrderModel } from 'models';
import { OrderState, OrderAction } from './types';
import { defaultState } from './consts';

export enum OrderStateErrors {
	ID_FETCH = 'Unable to fetch order',
	ADD_ADDRESS = 'Unable to add order address'
}

export class OrderHandlers {
	public static handleGetOrderById(state: OrderState, action: OrderAction): Loop<OrderState, OrderAction> {
		const { payload } = action;
		const newState = {
			...state,
			loading: {
				...state.loading,
				order: true
			}
		};

		return loop(
			newState,
			Cmd.run(MarketplaceApiClient.getOrderById, {
				args: [payload?.id || ''],
				successActionCreator: OrderInternalActions.getOrderByIdSuccess,
				failActionCreator: OrderInternalActions.getOrderByIdFailure,
			})
		);
	}

	public static handleGetOrderByIdSuccess(state: OrderState, action: OrderAction): OrderState | Loop<OrderState, OrderAction> {
		const order: OrderModel = action.payload?.order || defaultState.order;

		return {
			...state,
			order,
			loading: {
				...state.loading,
				order: false
			}
		};
	}

	public static handleGetOrderByIdFailure(state: OrderState): OrderState {
		return {
			...state,
			error: new Error(OrderStateErrors.ID_FETCH),
			loading: {
				...state.loading,
				order: false
			}
		};
	}

	public static handleAddOrderAddress(state: OrderState, action: OrderAction): Loop<OrderState, OrderAction> {
		const { payload } = action;
		const newState = {
			...state,
			loading: {
				...state.loading,
				addressAction: true
			}
		};

		return loop(
			newState,
			Cmd.run(MarketplaceApiClient.addOrderAddress, {
				args: [payload?.orderId || '', payload?.address],
				successActionCreator: OrderInternalActions.addOrderAddressSuccess,
				failActionCreator: OrderInternalActions.addOrderAddressFailure,
			})
		);
	}

	public static handleAddOrderAddressSuccess(state: OrderState, action: OrderAction): Loop<OrderState, OrderAction> {
		const orderId: string = action.payload?.orderId || '';
		const newState = {
			...state,
			loading: {
				...state.loading,
				addressAction: true
			}
		};

		return loop(
			newState,
			Cmd.list([
				Cmd.action(OrderActions.getOrderById(orderId))
			])
		);
	}

	public static handleAddOrderAddressFailure(state: OrderState): OrderState {
		return {
			...state,
			error: new Error(OrderStateErrors.ADD_ADDRESS),
			loading: {
				...state.loading,
				addressAction: false
			}
		};
	}


}