import { ApplicationState } from '../types';
import { RootSelectors } from '../rootSelectors';
import { OrderModel } from 'models';

export class OrderSelectors {
  public static getOrder(state: ApplicationState): OrderModel {
    return RootSelectors.getOrderState(state).order;
  }

  public static getOrderError(state: ApplicationState): Error | undefined {
    return RootSelectors.getOrderState(state).error;
  }

  public static getOrderLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getOrderState(state).loading.order;
  }

  public static getOrderAddressActionLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getOrderState(state).loading.addressAction;
  }
}
