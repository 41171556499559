import { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation, Location } from "react-router-dom";
import { UserSelectors } from 'reducers/User/selectors';
import { ApplicationState } from 'reducers/types';
import { UserModel } from 'models';
import { MixpanelService } from 'services';

interface MixpanelTrackingProps {
	user: UserModel;
}

const MixpanelTracking = ({ user }: MixpanelTrackingProps) => {
  const location: Location = useLocation();

  useEffect(() => {
    MixpanelService.trackPageview(location, user)
  }, [location, user]);

  return null;
};


const mapStateToProps = (state: ApplicationState) => ({
	user: UserSelectors.getUser(state),
});

export default connect(mapStateToProps)(MixpanelTracking);