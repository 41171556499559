import { MarketModel } from '../Market/Market';
import { MarketSummaryModel } from '../Market/MarketSummary';
import { RawMarketData } from '../Market/types';
import { ArtistModel, RawArtistData } from '../Artist/Artist';
import { ListingModel } from '../Listing/Listing';
import { RawListingData } from '../Listing/types';
import { Cloneable } from '../Utils/Cloneable';
import { MeasurementUnit, MeasurementData } from '../types';
import { toPercentageFormat } from 'utils/format';
import { extractFirstNumber } from 'utils/string';
import moment, { Moment } from 'moment';
import {
  RawItemData,
  RawEnhancedItemData,
  EditionData,
  ItemReleaseInfo,
  AuthenticityInfoData,
  ItemType,
  ItemActiveMarketData,
  RawItemActiveMarketData
} from './types';
import { ItemBidModel } from './ItemBid/ItemBid';
import { ItemAskModel } from './ItemAsk/ItemAsk';

export class ItemModel extends Cloneable {
  id: string
  artist: ArtistModel | string;
  artistId: string;
  date: Moment | null;
  description: string;
  edition: string;
  image: string;
  listings: (ListingModel | string)[];
  materialIds: string[];
  sourceLink: string;
  name: string;
  releasePrice: string;
  publisher: string;
  measurements: string;
  dimensions: MeasurementData | null;
  material: string;
  type: ItemType;
  market: MarketModel | string;
  releaseInfo: ItemReleaseInfo;
  editionInfo: EditionData;
  authenticityInfo: AuthenticityInfoData;
  handTreated: boolean;
  marketSummary: MarketSummaryModel | null;
  colors: string[];
  activeMarket: ItemActiveMarketData;

  constructor(rawItemData?: RawItemData) {
    super();
    this.id = rawItemData?.id || '';
    this.artist = rawItemData?.artist || '';
    this.artistId = rawItemData?.artist_id || '';
    this.date = rawItemData?.date ? moment(rawItemData.date) : null;
    this.description = rawItemData?.description || '';
    this.edition = rawItemData?.edition || '';
    this.image = rawItemData?.image || '';
    this.listings = rawItemData?.listings || [];
    this.sourceLink = rawItemData?.source_link || '';
    this.name = rawItemData?.name || '';
    this.releasePrice = rawItemData?.release_price || '';
    this.publisher = rawItemData?.publisher || '';
    this.measurements = rawItemData?.measurements || '';
    this.dimensions = rawItemData?.dimensions || null;
    this.material = rawItemData?.material || '';
    this.materialIds = rawItemData?.material_ids || [];
    this.type = rawItemData?.type || ItemType.UNKNOWN;
    this.market = rawItemData?.market || '';
    this.releaseInfo = {
      date: rawItemData?.release_info?.date ? moment(rawItemData.release_info.date) : null,
      symbol: rawItemData?.release_info?.symbol || '',
      rawPrice: rawItemData?.release_info?.raw_price || '',
      convertedPrice: rawItemData?.release_info?.converted_price || 0
    };
    this.editionInfo = {
      size: rawItemData?.edition_info?.size || 0,
      variants: rawItemData?.edition_info?.variants || [],
      isOpen: rawItemData?.edition_info?.is_open || false
    };
    this.authenticityInfo = {
      certificateIssued: rawItemData?.authenticity_info?.certificate_issued || false,
      hasFakes: rawItemData?.authenticity_info?.has_fakes || false,
      dated: rawItemData?.authenticity_info?.dated || false,
      numbered: rawItemData?.authenticity_info?.numbered || false,
      signed: rawItemData?.authenticity_info?.signed || false
    };
    this.handTreated = rawItemData?.hand_treated || false;
    this.marketSummary = rawItemData?.market_summary ? new MarketSummaryModel(rawItemData.market_summary) : null;
    this.colors = rawItemData?.colors || [];
    this.activeMarket = {
      bids: rawItemData?.active_market?.bids.map(rawBid => new ItemBidModel(rawBid)) || [],
      asks:rawItemData?.active_market?.asks.map(rawAsk => new ItemAskModel(rawAsk)) || []
    }
  }

  static fromRawEnhancedItemData(rawEnhancedItemData: RawEnhancedItemData): ItemModel {
    const item: ItemModel = new this({
      id: rawEnhancedItemData.id,
      artist_id: rawEnhancedItemData.artist.id,
      date: rawEnhancedItemData.date,
      description: rawEnhancedItemData.description,
      edition: rawEnhancedItemData.edition,
      image: rawEnhancedItemData.image,
      source_link: rawEnhancedItemData.source_link,
      name: rawEnhancedItemData.name,
      release_price: rawEnhancedItemData.release_price,
      publisher: rawEnhancedItemData.publisher,
      measurements: rawEnhancedItemData.measurements,
      dimensions: rawEnhancedItemData.dimensions,
      material: rawEnhancedItemData.material,
      material_ids: rawEnhancedItemData.material_ids,
      type: rawEnhancedItemData.type,
      release_info: rawEnhancedItemData.release_info,
      authenticity_info: rawEnhancedItemData.authenticity_info,
      edition_info: rawEnhancedItemData.edition_info,
      hand_treated: rawEnhancedItemData.hand_treated,
      market_summary: rawEnhancedItemData.market_summary,
      colors: rawEnhancedItemData.colors
    });

    item.setRawArtist(rawEnhancedItemData.artist);
    item.setRawMarket(rawEnhancedItemData.market);
    item.setRawListings(rawEnhancedItemData.listings);
    item.setRawActiveMarket(rawEnhancedItemData.active_market)

    return item;
  }

  public hasArtist(): boolean {
    return this.artist instanceof ArtistModel;
  }

  public getArtist(): ArtistModel | null {
    if (this.artist instanceof ArtistModel) {
      return this.artist;
    } else {
      return null;
    }
  }

  public getArtistName(): string {
    if (this.artist instanceof ArtistModel) {
      return this.artist.name;
    }

    return this.artist;
  }

  public hasArtistData(): boolean {
    return this.artist instanceof ArtistModel && !!this.artist.id;
  }

  public getArtistImage(): string {
    return this.getArtist()?.getImageUrl() || '';
  }

  public getArtistBirthYear(): number {
    return this.getArtist()?.getBirthYear() || 0;
  }

  public getArtistBirthCountryName(): string {
    return this.getArtist()?.getBirthCountryName() || '';
  }

  public setRawArtist(artist: RawArtistData) {
    this.setArtist(new ArtistModel(artist));
  }

  public setArtist(artist: ArtistModel | undefined) {
    if (artist) {
      this.artist = artist;
    }
  }


  public hasMarketData(): boolean {
    return this.market instanceof MarketModel && this.market.hasData();
  }

  public hasMarketSummaryData(): boolean {
    return !!this.marketSummary?.totalListings;
  }

  public getMarket(): MarketModel | null {
    if (this.market instanceof MarketModel) {
      return this.market;
    } else {
      return null;
    }
  }

  public setRawMarket(market: RawMarketData) {
    this.setItemMarket(new MarketModel(market));
  }

  public setItemMarket(market: MarketModel | undefined) {
    if (market) {
      this.market = market;
    }
  }

  public setRawActiveMarket(activeMarket: RawItemActiveMarketData | undefined) {
    if (activeMarket) {
      this.setItemActiveMarket({
        bids: activeMarket?.bids.map(rawBid => new ItemBidModel(rawBid)) || [],
        asks: activeMarket?.asks.map(rawAsk => new ItemAskModel(rawAsk)) || []
      });
    }
  }

  public setItemActiveMarket(activeMarket: ItemActiveMarketData | undefined | null) {
    if (activeMarket) {
      this.activeMarket = activeMarket;
    }
  }


  public setRawListings(listings: RawListingData[]) {
    this.setListings(listings.map(listing => new ListingModel(listing)));
  }

  public setListings(listings: ListingModel[]) {
    this.listings = listings;
  }

  public getSoldListings(): ListingModel[] {
    let listings: ListingModel[] = [];
    if (this.listings.length && this.listings[0] instanceof ListingModel) {
      listings = this.listings as ListingModel[];
    }

    return listings;
  }

  public hasActiveMarketData(): boolean {
    return this.hasBids() || this.hasAsks();
  }

  public hasBids(): boolean {
    return this.activeMarket?.bids.length > 0;
  }

  public hasAsks(): boolean {
    return this.activeMarket?.asks.length > 0;
  }

  public getBids(): ItemBidModel[] {
    return this.activeMarket?.bids || [];
  }

  public getAsks(): ItemAskModel[] {
    return this.activeMarket?.asks || [];
  }

  public hasSoldListings(): boolean {
    return this.listings.length > 0;
  }

  public getMeanPrice(): number {
    return this.marketSummary?.mean || 0;
  }

  public getMaxPrice(): number {
    return this.marketSummary?.max || 0;
  }

  public getClosePrice(): number {
    return this.marketSummary?.close || 0;
  }

  public getGrowth(): number {
    return this.marketSummary?.growth || 0;
  }

  public getEstimatedMarketPrice(): number {
    return this.marketSummary?.estimatedValue || 0;
  }

  public getSoldListingsQuantity(): number {
    return this.marketSummary?.totalListings || 0;
  }

  public hasEstimatedMarketPrice(): boolean {
    return !!this.marketSummary?.estimatedValue;
  }

  public getEstimatedMarketPriceForDisplay(currency?: string): string {
    return (this.getEstimatedMarketPrice()).toLocaleString('en-US', {
      style: 'currency',
      currency: currency || 'USD',
    });
  }


  public getClosePriceForDisplay(currency?: string): string {
    return (this.getClosePrice()).toLocaleString('en-US', {
      style: 'currency',
      currency: currency || 'USD',
    });
  }

  public getOverviewMeanPriceForDisplay(currency?: string): string {
    return (this.getMeanPrice()).toLocaleString('en-US', {
      style: 'currency',
      currency: currency || 'USD',
    });
  }

  public getOverviewMaxPriceForDisplay(currency?: string): string {
    return (this.getMaxPrice()).toLocaleString('en-US', {
      style: 'currency',
      currency: currency || 'USD',
    });
  }

  public getOverviewGrowthForDisplay(): string {
    return toPercentageFormat(this.getGrowth());
  }

  public getReleaseDate(): Moment | null {
    return this.releaseInfo.date || this.date;
  }

  public getReleaseYear(): number {
    return this.releaseInfo.date ? this.releaseInfo.date.year() : 0;
  }

  public hasReleasePriceData(): boolean {
    return this.releaseInfo.convertedPrice > 0;
  }

  public getReleaseConvertedPrice(): number {
    return this.releaseInfo.convertedPrice || 0;
  }

  public getReleaseConvertedPriceForDisplay(): string {
    return (this.getReleaseConvertedPrice()).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  }

  public getLastSalePercentageChange(): number {
    return (this.marketSummary?.lastSalePercentageChange || 0) * 100;
  }

  public getSecondaryMarketPremium(): number {
    return (this.marketSummary?.secondaryMarketPremium || 0) * 100;
  }

  public getSecondaryMarketVolume(): number {
    return this.marketSummary?.totalSales || 0;
  }

  public getLastSaleDate(): Moment | null {
    return this.marketSummary?.lastSaleDate || null;
  }

  public getSecondaryMarketPremiumForDisplay(): string {
    return toPercentageFormat(this.getSecondaryMarketPremium());
  }

  public getDimensionsForDisplay(unit?: MeasurementUnit): string {
    let dimensions: number[] = [];
    const multiplier = unit === MeasurementUnit.IN ? 0.393701 : 1
    const unitLabel = (unit || MeasurementUnit.CM).toLowerCase();
    if (this.dimensions?.l) {
      dimensions.push(Math.round(this.dimensions.l * multiplier));
    }

    if (this.dimensions?.w) {
      dimensions.push(Math.round(this.dimensions.w * multiplier));
    }

    if (this.dimensions?.h) {
      dimensions.push(Math.round(this.dimensions.h * multiplier));
    }

    return dimensions.length ? dimensions.join(' x ') + ` ${unitLabel}` : '';
  }

  public getAverageDimensionSize(unit?: MeasurementUnit): number {
    let dimensions: number[] = [];
    const multiplier = unit === MeasurementUnit.IN ? 0.393701 : 1;
    if (this.dimensions?.l) {
      dimensions.push(Math.round(this.dimensions.l * multiplier));
    }

    if (this.dimensions?.w) {
      dimensions.push(Math.round(this.dimensions.w * multiplier));
    }

    if (this.dimensions?.h) {
      dimensions.push(Math.round(this.dimensions.h * multiplier));
    }

    const sum = dimensions.reduce((a, b) => a + b, 0);

    return (sum / dimensions.length) || 0;    
  }

  public getEditionSize(): number {
    return this.editionInfo.size || extractFirstNumber(this.edition);
  }

  public hasFakes(): boolean {
    return this.authenticityInfo.hasFakes;
  }

  public hasCertificateIssued(): boolean {
    return this.authenticityInfo.certificateIssued;
  }

  public isSigned(): boolean {
    return this.authenticityInfo.signed;
  }

  public isNumbered(): boolean {
    return this.authenticityInfo.numbered;
  }

  public isDated(): boolean {
    return this.authenticityInfo.dated;
  }

  public isHandTreated(): boolean {
    return this.handTreated;
  }

  public getMaterialIds(): string[] {
    return this.materialIds;
  }

  public isOpenEdition(): boolean {
    return this.editionInfo.isOpen;
  }

  public hasEditionSize(): boolean {
    return this.editionInfo.size > 0;
  }

  public getDisplayName(): string {
    const displayNameComponents: string[] = [];
    
    const itemNameContainsArtist = this.name.toLowerCase().includes(this.getArtistName().toLowerCase());
    const itemNameContainsYear = this.name.includes(this.getReleaseYear().toString());
        
    if (!itemNameContainsArtist) {
      displayNameComponents.push(this.getArtistName() + ' ');
    }
    
    displayNameComponents.push(this.name);
    
    if (!itemNameContainsYear && this.getReleaseYear() !== 0) {
      displayNameComponents.push(`, ${this.getReleaseYear()}`);
    }
    
    return displayNameComponents.join('');  
  }

  public hasMultipleSales(): boolean {
    return (this.marketSummary?.totalListings || 0) > 1;
  }

  public getItemBidForUser(userId?: string): ItemBidModel | undefined {
    return this.activeMarket.bids.find((bid) => bid.itemId === this.id && bid.userId === userId);
  }

  public getItemAskForUser(userId?: string): ItemAskModel | undefined {
    return this.activeMarket.asks.find((ask) => ask.itemId === this.id && ask.userId === userId);
  }

  public getHighestBid(): ItemBidModel | null {
    const validBids = this.getBids().filter(bid => !bid.isExpired());
    if (validBids.length === 0) {
      return null;
    }
    return validBids.reduce((prev, curr) => (curr.amount > prev.amount ? curr : prev));
  }
  
  public getLowestAsk(): ItemAskModel | null {
    const validAsks = this.getAsks().filter(ask => !ask.isExpired());
    if (validAsks.length === 0) {
      return null;
    }
    return validAsks.reduce((prev, curr) => (curr.amount < prev.amount ? curr : prev));
  }

  public setItemAskPaymentLink(askId?: string, paymentLink?: string): void {
    if (askId && paymentLink) {
      const ask = this.activeMarket.asks.find(ask => ask.id === askId);
      if (ask) {
        ask.setPaymentLink(paymentLink);
      }
    }
  }
}
