import {
	Box,
	Flex,
	Stack,
	StackDivider,
	Heading,
	StatGroup,
	Stat,
	StatLabel,
	StatNumber,
	Avatar,
	Spinner,
	SkeletonText
} from '@chakra-ui/react';
import { UserModel, PortfolioModel } from 'models';
import GrowthBadge from '../../Badge/GrowthBadge';
import { toDollarFormat } from 'utils/format';

export interface UserPortfolioBadgeProps {
	user: UserModel;
	portfolio?: PortfolioModel;
	dataLoading?: boolean;
	userLoading?: boolean;
}

export default function UserPortfolioBadge({ user, portfolio, dataLoading, userLoading }: UserPortfolioBadgeProps) {
	function renderPortfolioInfo() {
		if (dataLoading) {
			return (
				<Spinner
					marginTop={8}
					thickness='4px'
					speed='0.65s'
					emptyColor='gray.200'
					color='blue.500'
					size='xl'
				/>
			)
		}
		const portfolioMarketData = (portfolio || user.portfolio).getPortfolioBasicMarketSummary();
		const showGrowthBadge = !!portfolioMarketData.cost || !!portfolioMarketData.value;
		const containerDivider = <StackDivider borderColor={'gray.100'} />;

		return (
			<Stack
				paddingTop={6}
				spacing={4}
				alignItems={{ base: 'center', md: 'start' }}
				divider={containerDivider}>
				<StatGroup flexDirection={'row'} gap={8} width="100%">
					<Stat>
						<StatLabel>Market Value</StatLabel>
						<StatNumber>{toDollarFormat(portfolioMarketData.value)}</StatNumber>
						{showGrowthBadge && <GrowthBadge starting={portfolioMarketData.cost} ending={portfolioMarketData.value} />}
					</Stat>
					<Stat>
						<StatLabel>Cost</StatLabel>
						<StatNumber>{toDollarFormat(portfolioMarketData.cost)}</StatNumber>
					</Stat>
				</StatGroup>
			</Stack>
		);
	}

	function renderUserName() {
		const content = userLoading ? (
			<SkeletonText width={"200px"} noOfLines={1} skeletonHeight={"40px"} />
		) : (
			<Heading>
				@{user.userName}
			</Heading>
		);

		return (
			<Flex justifyContent={{ 'base': 'center', 'md': 'left' }}>
				{content}
			</Flex>
		);
	}

	return (
		<Box borderBottomWidth='1px' borderRadius='lg' paddingBottom="20px">
			<Flex direction={{ 'base': 'column', 'md': 'row' }}>
				<Stack alignItems={'center'}>
					<Avatar
						src={user.getImage()}
						me={{ md: "22px" }}
						w='200px'
						h='200px'
					/>
				</Stack>
				<Box textAlign={{ 'base': 'center', 'md': 'left' }} paddingLeft={{ 'base': '0px', 'md': '48px' }} paddingTop={{ 'base': '20px', 'md': '0px' }}>
					{renderUserName()}
					{renderPortfolioInfo()}
				</Box>
			</Flex>
		</Box>
	);
}
