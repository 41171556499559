import { ApplicationState } from '../types';
import { RootSelectors } from '../rootSelectors';
import { ItemActiveMarketData } from 'models/Item/types';

export class ActiveMarketSelectors {
  public static getAllActiveMarket(state: ApplicationState): ItemActiveMarketData {
    return RootSelectors.getActiveMarketState(state).all;
  }

  public static getAllActiveMarketLoading(state: ApplicationState): boolean {
    return RootSelectors.getActiveMarketState(state).loading.all;
  }

  public static getError(state: ApplicationState): Error | undefined {
    return RootSelectors.getArtistState(state).error;
  }
}
