import { Loop, LoopReducer } from 'redux-loop';
import { ActiveMarketState, ActiveMarketAction, ActiveMarketActionTypes } from './types';
import { defaultState } from './consts';
import { ActiveMarketHandlers } from './handlers';

export const activeMarketReducer: LoopReducer<ActiveMarketState, ActiveMarketAction> = (
  state: ActiveMarketState = defaultState,
  action: ActiveMarketAction
): ActiveMarketState | Loop<ActiveMarketState, ActiveMarketAction> => {
  switch (action.type) {
    case ActiveMarketActionTypes.GET_ALL_ACTIVE_MARKET:
      return ActiveMarketHandlers.handleGetAllActiveMarkets(state);
    case ActiveMarketActionTypes.GET_ALL_ACTIVE_MARKET_SUCCESS:
      return ActiveMarketHandlers.handleGetAllActiveMarketsSuccess(state, action);
    case ActiveMarketActionTypes.GET_ALL_ACTIVE_MARKET:
      return ActiveMarketHandlers.handleGetAllActiveMarketsFailure(state);
    default:
      return state;
  }
}
