import { useForm } from 'react-hook-form';
import { Stack, Flex, Heading, Icon } from '@chakra-ui/react';
import { RiErrorWarningFill } from 'react-icons/ri';
import BasicItemCard from '../../Item/BasicItemCard/BasicItemCard';
import ItemAskCard from '../../Item/ItemAskCard/ItemAskCard';
import ActionButton from '../../Button/ActionButton';
import UnauthorizedAccess from '../../UnauthorizedAccess/UnauthorizedAccess';
import { ItemModel, ItemAskModel, UserModel } from 'models';

export interface ItemAskRemovalFormProps {
  item: ItemModel;
  ask: ItemAskModel;
  user: UserModel;
  onSubmit: (ask: ItemAskModel) => void;
  isLoading?: boolean;
}

export default function ItemAskRemovalForm({
  item,
  ask,
  user,
  onSubmit,
  isLoading
}: ItemAskRemovalFormProps) {
  const { handleSubmit } = useForm();

  const onFormSubmit = () => {
    onSubmit(ask);
  };

  function renderRemoveWarning() {
    return (
      <Flex justifyContent="center" alignItems="center" direction="row" gap={1} color="red.600">
        <Icon as={RiErrorWarningFill} />
        <Heading fontSize="sm">Are You Sure You Want To Remove This Ask?</Heading>
      </Flex>
    );
  }

  function renderRemoveForm() {
    return (
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <Stack spacing={8}>
          <BasicItemCard item={item} />
          {renderRemoveWarning()}

          <ItemAskCard
            item={item}
            askAmount={ask.amount}
            askCreatedDays={ask.getCreatedDaysAgo()}
            askUpdatedDays={ask.getUpdatedDaysAgo()}
            updated={ask.hasBeenUpdated()}
            condition={ask.condition}
            useAvatar={false}
            showIcons={false}
          />

          <Stack alignItems="center">
            <ActionButton
              type="submit"
              text="Remove Ask"
              loading={isLoading}
              size="md"
              disabled={isLoading}
            />
          </Stack>
        </Stack>
      </form>
    );
  }

  function renderUnauthorizedContent() {
    return (
      <UnauthorizedAccess
        title="Unauthorized"
        description="You are unauthorized to remove this ask."
      />
    );
  }

  function renderContent() {
    if (ask.belongsToUser(user)) {
      return renderRemoveForm();
    } else {
      return renderUnauthorizedContent();
    }
  }

  return (
    renderContent()
  )
}
