import { UrlConfig } from 'config';
import { AjaxService } from 'services';
import { ArtistConfig } from 'config';
import { ApiResponse } from 'models/Api/ApiResponse';
import { ArtistModel, ItemModel, ListingModel, ItemBidModel, ItemAskModel, ArtistIndexModel } from 'models';
import { arrayToCommaSeparatedString } from 'utils/format';
import { HomeFeedData, RawHomeFeedData } from 'models/Content/types';

export class ContentApiClient {
  public static async getHomeFeed(): Promise<HomeFeedData> {
    const enabledArtistIds = ArtistConfig.ENABLED_ARTISTS;
    const queryString = arrayToCommaSeparatedString(enabledArtistIds);
    const url = `${UrlConfig.CONTENT.HOME_FEED}?artist_ids=${queryString}`;
    const response: ApiResponse<RawHomeFeedData> = await AjaxService.getData(url);
    const homeFeedData: HomeFeedData = {
        featuredArtists: response.data.featured_artists.map(rawArtist => new ArtistModel(rawArtist)),
        featuredItems: response.data.featured_items.map(rawItem => new ItemModel(rawItem)),
        newReleasedItems: response.data.new_released_items.map(rawItem => new ItemModel(rawItem)),
        latestSales: response.data.latest_sales.map(rawListing => new ListingModel(rawListing)),
        biggestMarketMoverItems: response.data.biggest_market_mover_items.map(rawItem => new ItemModel(rawItem)),
        activeMarket: {
          bids: response.data?.active_market.bids.map(rawBid => new ItemBidModel(rawBid)),
          asks: response.data?.active_market.asks.map(rawAsk => new ItemAskModel(rawAsk))
        },
        newestCreatedArtists: response.data.newest_created_artists.map(rawArtist => new ArtistModel(rawArtist)),
        newestCreatedArtistsIndexes: response.data.newest_created_artist_indexes.map(rawIndex => new ArtistIndexModel(rawIndex))
    };
    return homeFeedData;
  }
}
