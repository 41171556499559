import {
	Box,
	Stack,
	StackDivider,
	Heading,
	Text,
	useColorModeValue
} from '@chakra-ui/react';
import { toDollarFormat } from 'utils/format';
import { PortfolioTooltipData } from '../types';
import GrowthBadge from '../../Badge/GrowthBadge';

interface PortfolioValueTooltipProps extends PortfolioTooltipData { };

export default function PortfolioValueTooltip({ payload, label, active = false }: PortfolioValueTooltipProps) {
	if (!active || !payload || !payload.length) return null;
	const data = payload[0].payload;

	return (
		<Box borderWidth="1px" borderRadius="lg" p={4} shadow="md" backgroundColor={useColorModeValue("white", "black")} zIndex={10}>
			<Box mb={4}>
				<Heading size='md'>{label}</Heading>
			</Box>

			<Stack divider={<StackDivider />} spacing='4' textAlign={'left'}>
				<Box>
					<Text fontSize='xs' textTransform='uppercase'>
						Value
					</Text>
					<Heading pt='2' fontSize='sm'>
						{toDollarFormat(data.value, false)}
					</Heading>
				</Box>
				<Box>
					<Text fontSize='xs' textTransform='uppercase'>
						Cost
					</Text>
					<Heading pt='2' fontSize='sm'>
						{toDollarFormat(data.cost, false)}
					</Heading>
				</Box>
				<GrowthBadge starting={data.cost} ending={data.value} />
			</Stack>
		</Box>
	);
}
