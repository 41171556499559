import { Button, Text } from '@chakra-ui/react';
import { WarningIcon, CheckCircleIcon } from '@chakra-ui/icons';
import { IoMailOutline } from 'react-icons/io5';
import { UserModel } from 'models/User/User';
import UserProfileCard from './UserProfileCard';

export interface UserProfileEmailCardProps {
  user: UserModel;
  onChangePassword?: () => void;
  onConfirmEmail?: () => void;
}

export default function UserProfileEmailCard({
  user,
  onChangePassword,
  onConfirmEmail,
}: UserProfileEmailCardProps) {
  const isEmailVerified = user.isEmailVerified();

  const topButton = isEmailVerified ? (
    <Button
      isDisabled
      leftIcon={<CheckCircleIcon />}
      variant="outline"
      colorScheme="green"
      size="sm"
    >
      Connected
    </Button>
  ) : (
    <Button
      leftIcon={<WarningIcon />}
      colorScheme="orange"
      size="sm"
      onClick={onConfirmEmail}
    >
      Confirm
    </Button>
  );

  const bottomButton = (
    <Button variant="outline" size="sm" onClick={onChangePassword}>
      Change password
    </Button>
  );

  return (
    <UserProfileCard
      title="Email"
      subtitle="You can use your email to login"
      icon={IoMailOutline}
      topButton={topButton}
      bottomButton={bottomButton}
      children={(
        <Text fontSize="sm">{user.email}</Text>
      )}
    />
  );
}
