import { Button, Flex } from '@chakra-ui/react';
import { IoStorefrontOutline } from 'react-icons/io5';
import { GiThorHammer } from 'react-icons/gi';
import { FaSign } from 'react-icons/fa';
import { UserModel } from 'models/User/User';
import UserProfileCard from './UserProfileCard';
import MarketBadge from '../../Badge/MarketBadge';

interface UserProfileActiveMarketCardProps {
	user: UserModel;
	onManage?: () => void;
}

export default function UserProfileActiveMarketCard({
	user,
	onManage,
}: UserProfileActiveMarketCardProps) {
	const bidCount = user.activeMarket?.bids?.length || 0;
	const askCount = user.activeMarket?.asks?.length || 0;

	const bottomButton = (
		<Button variant="outline" size="sm" onClick={onManage}>
			Manage Asks / Bids
		</Button>
	);

	return (
		<UserProfileCard
			title="Asks & Bids"
			subtitle="Manage Your Asks & Bids"
			icon={IoStorefrontOutline}
			bottomButton={bottomButton}
			children={(
				<Flex gap={2}>
					<MarketBadge icon={GiThorHammer} count={bidCount} label="Bids" color="blue" />
					<MarketBadge icon={FaSign} count={askCount} label="Asks" color="orange" />
				</Flex>
			)}
		/>
	);
}
