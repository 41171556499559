export enum FeatureToggleKeys {
  ITEM_DETAILS_DESCRIPTION = 'ITEM_DETAILS_DESCRIPTION',
  ITEM_DETAILS_ARTIST_BANNER = 'ITEM_DETAILS_ARTIST_BANNER',
  AUTH_OAUTH_BUTTONS = 'AUTH_OAUTH_BUTTONS',
  NAV_BAR_SEARCH_LISTINGS = 'NAV_BAR_SEARCH_LISTINGS',
  ENABLE_MARKETPLACE = 'ENABLE_MARKETPLACE'
}

export const FeatureToggleMap: Record<string, boolean> = {
  [FeatureToggleKeys.ITEM_DETAILS_DESCRIPTION]: false,
  [FeatureToggleKeys.ITEM_DETAILS_ARTIST_BANNER]: false,
  [FeatureToggleKeys.AUTH_OAUTH_BUTTONS]: false,
  [FeatureToggleKeys.NAV_BAR_SEARCH_LISTINGS]: false,
  [FeatureToggleKeys.ENABLE_MARKETPLACE]: false
};
