import { UrlConfig } from 'config';
import { UserModel, ArtistModel, ItemModel, PaginationModel, PortfolioModel, WishlistModel, ListingModel, ItemAskModel, ItemBidModel, AddressModel } from 'models';
import { RawArtistData } from 'models/Artist/Artist';
import { ItemActiveMarketData, RawItemData, RawItemActiveMarketData } from 'models/Item/types';
import { RawListingData } from 'models/Listing/types';
import { RawPaginationData } from 'models/Pagination/Pagination';
import { RawWishlistData } from 'models/Wishlist/Wishlist';
import { RawPortfolioData } from 'models/Portfolio/types';
import { RawUserData } from 'models/User/User';
import { AuthData } from 'models/Auth/types';
import { ApiResponse } from 'models/Api/ApiResponse';
import { ConfirmUserResponse } from './types/user';
import { AjaxService, CredentialService } from 'services';
import { JSONObject } from 'models/Api/types';

export class UserApiClient {
  public static async getFollowingArtists(): Promise<ArtistModel[]> {
    const url = UrlConfig.USER.FOLLOWING_ARTISTS;
    const response: ApiResponse<RawArtistData[]> = await AjaxService.getData(url);
    const artistList = response.data.map((rawArtist: RawArtistData) => new ArtistModel(rawArtist));
    return artistList;
  }

  public static async getPortfolio(): Promise<PortfolioModel> {
    const url = UrlConfig.USER.PORTFOLIO;
    const response: ApiResponse<RawPortfolioData> = await AjaxService.getData(url);
    const portfolio = PortfolioModel.fromRawPortfolioData(response.data);
    return portfolio;
  }

  public static async getWishlist(): Promise<WishlistModel> {
    const url = UrlConfig.USER.WISHLIST;
    const response: ApiResponse<RawWishlistData> = await AjaxService.getData(url);
    const wishlist = WishlistModel.fromRawWishlistData(response.data);
    return wishlist;
  }

  public static async getUserActiveMarket(): Promise<ItemActiveMarketData> {
    const url = UrlConfig.USER.ACTIVE_MARKET;
    const response: ApiResponse<RawItemActiveMarketData> = await AjaxService.getData(url);
    const activeMarket = {
      bids: response.data?.bids.map(rawBid => new ItemBidModel(rawBid)),
      asks: response.data?.asks.map(rawAsk => new ItemAskModel(rawAsk))
    };
    return activeMarket;
  }

  public static async getFollowingArtistItems(page: number = 1): Promise<PaginationModel<ItemModel>> {
    let url = UrlConfig.USER.FOLLOWING_ARTISTS_ITEMS + `?page=${page}`;
    const response: ApiResponse<RawPaginationData<RawItemData>> = await AjaxService.getData(url);
    const paginatedRespose: PaginationModel<RawItemData> = new PaginationModel(response.data);
    const formattedItems = paginatedRespose.data.map((rawItem: RawItemData) => new ItemModel(rawItem));
    const formattedPaginatedResponse: PaginationModel<ItemModel> = new PaginationModel({ ...response.data, data: formattedItems, fetched: true });
    return formattedPaginatedResponse;
  }

  public static async getFollowingArtistListings(page: number = 1): Promise<PaginationModel<ListingModel>> {
    let url = UrlConfig.USER.FOLLOWING_ARTISTS_LISTINGS + `?page=${page}`;
    const response: ApiResponse<RawPaginationData<RawListingData>> = await AjaxService.getData(url);
    const paginatedRespose: PaginationModel<RawListingData> = new PaginationModel(response.data);
    const formattedItems = paginatedRespose.data.map((rawListing: RawListingData) => new ListingModel(rawListing));
    const formattedPaginatedResponse: PaginationModel<ListingModel> = new PaginationModel({ ...response.data, data: formattedItems, fetched: true });
    return formattedPaginatedResponse;
  }

  public static async migrateImage(imageUrl: string): Promise<string> {
    const url = UrlConfig.USER.MIGRATE_IMAGE;
    let payload = {};
    if (imageUrl.length > 0) {
      payload = {
        image_url: imageUrl
      };
    }
    const response: ApiResponse<string> = await AjaxService.postData(url, payload);
    return response.data;
  }

  public static async updateUser(updatedData: JSONObject): Promise<UserModel> {
    const url = UrlConfig.USER.BASE;
    const response: ApiResponse<RawUserData> = await AjaxService.putData(url, updatedData);
    const user = new UserModel(response.data);
    return user;
  }

  public static async changePassword(oldPassword: string, newPassword: string): Promise<void> {
    const url = UrlConfig.USER.CHANGE_PASSWORD;
    await AjaxService.postData(url, {
      old_password: oldPassword,
      new_password: newPassword
    });
  }

  public static async confirmUser(token: string): Promise<UserModel> {
    const url = UrlConfig.USER.CONFIRM;
    const response: ApiResponse<ConfirmUserResponse> = await AjaxService.postData(url, {
      token: token,
    });
    const authData: AuthData = response.data.auth;
    if (authData) {
      if (authData.token) {
        CredentialService.setAuthToken(authData.token);
      }
      if (authData.refresh) {
        CredentialService.setRefreshToken(authData.refresh);
      }
    }

    const user = new UserModel(response.data.user);
    return user;
  }

  public static async changeUserForgottenPassword(token: string, newPassword: string): Promise<void> {
    const url = UrlConfig.USER.CHANGE_FORGOTTEN_PASSWORD;
    await AjaxService.postData(url, {
      token: token,
      new_password: newPassword
    });
  }

  public static async sendConfirmationEmail(): Promise<void> {
    const url = UrlConfig.MAIL.SEND_USER_CONFIRMATION;
    await AjaxService.postData(url);
  }

  public static async sendUserForgotPasswordEmail(email: string): Promise<void> {
    const url = UrlConfig.MAIL.SEND_USER_FORGOT_PASSWORD;
    await AjaxService.postData(url, {
      email: email
    });
  }

  public static async sendInboundContactRequest(email: string, name: string, message: string): Promise<void> {
    const url = UrlConfig.MAIL.SEND_INBOUND_CONTACT_REQUEST;
    await AjaxService.postData(url, {
      email: email,
      name: name,
      message: message
    });
  }

  public static async addUserPrimaryAddress(address: AddressModel): Promise<AddressModel> {
    const url = UrlConfig.USER.ADD_PRIMARY_ADDRESS;
    await AjaxService.postData(url, {
      address: address.toObject(),
    });
    return address;
  } 
}
