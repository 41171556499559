import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Stack,
  FormControl,
  InputGroup,
  FormLabel,
  InputLeftElement,
  Input,
  FormErrorMessage,
} from '@chakra-ui/react';
import { FaInstagram } from 'react-icons/fa';
import { BiCalendar } from 'react-icons/bi';
import { BsTextareaT } from 'react-icons/bs';
import { getFilteredObject, getNestedValueByPath } from 'utils/object';
import { getCountryNameFromCountryCode } from 'utils/country';
import { PhoneInput } from '../../Input/PhoneInput/PhoneInput';
import CountryInput from '../../Input/CountryInput/CountryInput';
import FilepondUploader from '../../Uploader/FilepondUploader/FilepondUploader';
import UserProfileBadge from '../../User/Badges/UserProfileBadge';
import ActionButton from '../../Button/ActionButton';
import { UserModel } from 'models';
import { CountryData, PhoneData } from 'models/types';


export type UserDataFormOption = 'birthYear' | 'country' | 'biography' | 'instagram' | 'image' | 'name' | 'number';

export interface UserDataFormProps {
  user?: UserModel;
  defaultFormData?: UserDataFormData;
  onSubmit?: (data: UserDataFormData) => void;
  onChange?: (data:UserDataFormData) => void;
  submitText?: string;
  submitLoading?: boolean;
  showSubmit?: boolean;
  displayOptions?: UserDataFormOption[];
  requiredOptions?: UserDataFormOption[];
  disabledOptions?: UserDataFormOption[];
  hideLabels?: boolean;
}

export interface UserDataFormData {
  birthYear?: number;
  country?: CountryData;
  biography?: string;
  instagram?: string;
  image?: string;
  name?: string;
  number?: PhoneData;
}

export default function UserDataForm({ user, defaultFormData, onSubmit, onChange, submitLoading, displayOptions, requiredOptions = [], disabledOptions = [], submitText = 'Submit', showSubmit = true, hideLabels = false }: UserDataFormProps) {
  const {
    control,
    handleSubmit,
    register,
    watch,
    setValue,
    reset,
    formState: { errors, isDirty, dirtyFields },
  } = useForm<UserDataFormData>({ defaultValues: defaultFormData });
  useEffect(() => {
    reset({ ...defaultFormData });
  }, [defaultFormData, reset]);
  
  useEffect(() => {
    if (onChange) {
      const subscription = watch((value, { name }) => {
        if (name) {
          const nestedValue = getNestedValueByPath(value, name);
          onChange({ [name]: nestedValue });
        }
      });
      return () => subscription.unsubscribe();
    }
  }, [watch, onChange]);

  const formDisplayOptions: Record<string, boolean> = (
    displayOptions || ['birthYear', 'country', 'biography', 'instagram', 'image', 'name', 'number']
  ).reduce((acc: Record<string, boolean>, curr: UserDataFormOption) => (acc[curr] = true, acc), {});

  const formDisabledOptions: Record<string, boolean> = disabledOptions.reduce((acc: Record<string, boolean>, curr: UserDataFormOption) => (acc[curr] = true, acc), {});

  const formRequiredOptions: Record<string, boolean> = requiredOptions.reduce((acc: Record<string, boolean>, curr: UserDataFormOption) => (acc[curr] = true, acc), {});

  const onFormSubmit = (data: UserDataFormData) => {
    const changedData = getFilteredObject(Object.keys(dirtyFields), data as Record<string, number | string | CountryData | PhoneData>);
    onSubmit?.(changedData);
  }

  function renderLabel(label: string, key: string) {
    if (!hideLabels) {
      return (
        <FormLabel htmlFor={key} fontSize="sm">{label}</FormLabel>
      );
    }
  }

  function renderImageUploader() {
    const shouldDisplay = !!formDisplayOptions['image'];
    const isRequired = !!formRequiredOptions['image'];
    const isDisabled = !!formDisabledOptions['image'];

    return shouldDisplay && (
      <FormControl isInvalid={isRequired && !watch('image')?.length} isRequired={isRequired} isDisabled={isDisabled}>
        {renderLabel('Image', 'image')}
        <Controller
          control={control}
          name="image"
          rules={{ required: isRequired }}
          render={() => (
            <FilepondUploader
              onFileProcess={(url) => setValue('image', url, { shouldDirty: true })}
              onFileRevert={() => setValue('image', '')}
              maxFiles={1}
              label={'Drag & Drop your Image'} />
          )} />
        <FormErrorMessage>
          {(isRequired && !watch('image')?.length) && 'Image Must Be Uploaded'}
        </FormErrorMessage>
      </FormControl>
    );
  }

  function renderName() {
    const shouldDisplay = !!formDisplayOptions['name'];
    const isRequired = !!formRequiredOptions['name'];
    const isDisabled = !!formDisabledOptions['name'];

    return shouldDisplay && (
      <FormControl isDisabled={isDisabled} isRequired={isRequired} isInvalid={!!errors.name}>
        {renderLabel('Name', 'name')}
        <InputGroup>
          <InputLeftElement
            pointerEvents='none'
            color='gray.500'
            children={<BsTextareaT />}
          />
          <Input
            fontSize="sm"
            placeholder="Enter Name"
            {...register('name', {
              validate: {
                isDefined: v => (!isRequired || (v && v.length > 0)) || 'Name must be defined',
              }
            })} />
        </InputGroup>
        <FormErrorMessage>
          {errors.name && errors.name.message}
        </FormErrorMessage>
      </FormControl>
    );
  }

  function renderBirthYear() {
    const shouldDisplay = !!formDisplayOptions['birthYear'];
    const isRequired = !!formRequiredOptions['birthYear'];
    const isDisabled = !!formDisabledOptions['birthYear'];

    return shouldDisplay && (
      <FormControl isDisabled={isDisabled} isRequired={isRequired} isInvalid={!!errors.birthYear}>
        {renderLabel('Birth Year', 'birthYear')}
        <InputGroup>
          <InputLeftElement
            pointerEvents='none'
            color='gray.500'
            children={<BiCalendar />}
          />
          <Input
            {...register('birthYear', {
              setValueAs: v => v === "" ? undefined : Number(v), 
              validate: {
                isPositive: v => (!isRequired || (v && v > 1900)) || 'Birth Year is invalid',
              }
            })}
            fontSize="sm"
            type="number"
            placeholder='Enter Birth Year'
          />
        </InputGroup>
        <FormErrorMessage>
          {errors.birthYear && errors.birthYear.message}
        </FormErrorMessage>
      </FormControl>
    );
  }

  function renderInstagram() {
    const shouldDisplay = !!formDisplayOptions['instagram'];
    const isRequired = !!formRequiredOptions['instagram'];
    const isDisabled = !!formDisabledOptions['instagram'];

    return shouldDisplay && (
      <FormControl isDisabled={isDisabled} isRequired={isRequired} isInvalid={!!errors.instagram}>
        {renderLabel('Instagram', 'instagram')}
        <InputGroup>
          <InputLeftElement
            pointerEvents='none'
            color='gray.500'
            children={<FaInstagram />}
          />
          <Input
            {...register('instagram', {
              validate: {
                isDefined: v => (!isRequired || (v && v.length > 0)) || 'Instagram Username must be specified',
              },
            })}
            fontSize="sm"
            placeholder='Instagram Username'
          />
        </InputGroup>
        <FormErrorMessage>
          {errors.instagram && errors.instagram.message}
        </FormErrorMessage>
      </FormControl>
    );
  }

  function renderCountry() {
    const shouldDisplay = !!formDisplayOptions['country'];
    const isRequired = !!formRequiredOptions['country'];
    const isDisabled = !!formDisabledOptions['country'];

    return shouldDisplay && (
      <FormControl isDisabled={isDisabled} isRequired={isRequired} isInvalid={!!errors.country}>
        {renderLabel('Country', 'country')}
        <Controller
          control={control}
          name="country"
          rules={{
            required: isRequired,
            validate: (country) => (!isRequired || !!country) || 'Country must be specified'
          }}
          render={() => (
            <CountryInput
              defaultData={defaultFormData?.country?.code}
              onChange={(countryCode) => setValue('country', {code: countryCode, name: getCountryNameFromCountryCode(countryCode)}, { shouldDirty: true })}
            />
          )} />
        <FormErrorMessage>
          {errors.country && errors.country.message}
        </FormErrorMessage>
      </FormControl>
    );
  }

  function renderPhone() {
    const shouldDisplay = !!formDisplayOptions['number'];
    const isRequired = !!formRequiredOptions['number'];
    const isDisabled = !!formDisabledOptions['number'];
    return shouldDisplay && (
      <FormControl isDisabled={isDisabled} isRequired={isRequired} isInvalid={!!errors.number}>
        {renderLabel('Number', 'number')}
        <Controller
          control={control}
          name="number"
          rules={{
            required: isRequired,
            validate: {
              isValid: (number) => (!isRequired || !!number) || 'Number must be specified',
            }
          }}
          render={() => (
            <PhoneInput
              defaultValue={defaultFormData?.number?.baseNumber || ''}
              countryCode={defaultFormData?.number?.countryCode || ''}
              onChange={(phone, countryCode) => setValue('number', { baseNumber: Number(phone), countryCode: countryCode }, { shouldDirty: true })}
            />
          )} />
        <FormErrorMessage>
          {errors.number && errors.number.message}
        </FormErrorMessage>
      </FormControl>
    );
  }

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      {!!user && <UserProfileBadge user={user} />}
      <Stack spacing={8}>
        {renderImageUploader()}
        {renderName()}
        {renderBirthYear()}
        {renderInstagram()}
        {renderPhone()}
        {renderCountry()}
        <Stack alignItems="center">
          {showSubmit && <ActionButton type='submit' text={submitText} loading={submitLoading} size={'md'} disabled={!isDirty} />}
        </Stack>
      </Stack>
    </form>
  );
}
