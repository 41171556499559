import { Loop, LoopReducer } from 'redux-loop';
import { UserHandlers } from './handlers';
import { UserState, UserAction, UserActionTypes } from './types';
import { defaultState } from './consts';

export const userReducer: LoopReducer<UserState, UserAction> = (
  state: UserState = defaultState,
  action: UserAction
): UserState | Loop<UserState, UserAction> => {
  switch (action.type) {
    case UserActionTypes.SET_USER:
      return UserHandlers.handleSetUser(state, action);
    case UserActionTypes.CLEAR_USER:
      return UserHandlers.handleClearUser(state);
    case UserActionTypes.GET_USER_PORTFOLIO:
      return UserHandlers.handleGetUserPortfolio(state);
    case UserActionTypes.GET_USER_PORTFOLIO_SUCCESS:
      return UserHandlers.handleGetUserPortfolioSuccess(state, action);
    case UserActionTypes.GET_USER_PORTFOLIO_FAILURE:
      return UserHandlers.handleGetUserPortfolioFailure(state);
    case UserActionTypes.GET_USER_WISHLIST:
      return UserHandlers.handleGetUserWishlist(state);
    case UserActionTypes.GET_USER_WISHLIST_SUCCESS:
      return UserHandlers.handleGetUserWishlistSuccess(state, action);
    case UserActionTypes.GET_USER_WISHLIST_FAILURE:
      return UserHandlers.handleGetUserWishlistFailure(state);
    case UserActionTypes.GET_USER_FOLLOWING_ARTISTS:
      return UserHandlers.handleGetUserFollowingArtists(state);
    case UserActionTypes.GET_USER_FOLLOWING_ARTISTS_SUCCESS:
      return UserHandlers.handleGetUserFollowingArtistsSuccess(state, action);
    case UserActionTypes.GET_USER_FOLLOWING_ARTISTS_FAILURE:
      return UserHandlers.handleGetUserFollowingArtistsFailure(state);
    case UserActionTypes.GET_USER_ACTIVE_MARKET:
      return UserHandlers.handleGetUserActiveMarket(state);
    case UserActionTypes.GET_USER_ACTIVE_MARKET_SUCCESS:
      return UserHandlers.handleGetUserActiveMarketSuccess(state, action);
    case UserActionTypes.GET_USER_ACTIVE_MARKET_FAILURE:
      return UserHandlers.handleGetUserActiveMarketFailure(state);
    case UserActionTypes.GET_ORDERS:
      return UserHandlers.handleGetUserOrders(state);
    case UserActionTypes.GET_ORDERS_SUCCESS:
      return UserHandlers.handleGetUserOrdersSuccess(state, action);
    case UserActionTypes.GET_ORDERS_FAILURE:
      return UserHandlers.handleGetUserOrdersFailure(state);
    case UserActionTypes.ADD_PRIMARY_ADDRESS:
      return UserHandlers.handleAddUserPrimaryAddress(state, action);
    case UserActionTypes.ADD_PRIMARY_ADDRESS_SUCCESS:
      return UserHandlers.handleAddUserPrimaryAddressSuccess(state, action);
    case UserActionTypes.ADD_PRIMARY_ADDRESS_FAILURE:
      return UserHandlers.handleAddUserPrimaryAddressFailure(state);
    case UserActionTypes.FOLLOW_ARTIST:
      return UserHandlers.handleFollowArtist(state, action);
    case UserActionTypes.FOLLOW_ARTIST_SUCCESS:
      return UserHandlers.handleFollowArtistSuccess(state);
    case UserActionTypes.FOLLOW_ARTIST_FAILURE:
      return UserHandlers.handleFollowArtistFailure(state);
    case UserActionTypes.UNFOLLOW_ARTIST:
      return UserHandlers.handleUnfollowArtist(state, action);
    case UserActionTypes.UNFOLLOW_ARTIST_SUCCESS:
      return UserHandlers.handleUnfollowArtistSuccess(state);
    case UserActionTypes.UNFOLLOW_ARTIST_FAILURE:
      return UserHandlers.handleUnfollowArtistFailure(state);
    case UserActionTypes.MIGRATE_USER_IMAGE:
      return UserHandlers.handleMigrateImage(state, action);
    case UserActionTypes.MIGRATE_USER_IMAGE_SUCCESS:
      return UserHandlers.handleMigrateImageSuccess(state, action);
    case UserActionTypes.MIGRATE_USER_IMAGE_FAILURE:
      return UserHandlers.handleMigrateImageFailure(state);
    case UserActionTypes.CHANGE_USER_PASSWORD:
      return UserHandlers.handleChangePassword(state, action);
    case UserActionTypes.CHANGE_USER_PASSWORD_SUCCESS:
      return UserHandlers.handleChangePasswordSuccess(state);
    case UserActionTypes.CHANGE_USER_PASSWORD_FAILURE:
      return UserHandlers.handleChangePasswordFailure(state);
    case UserActionTypes.SEND_CONFIRMATION_EMAIL:
      return UserHandlers.handleSendConfirmationEmail(state);
    case UserActionTypes.SEND_CONFIRMATION_EMAIL_SUCCESS:
      return UserHandlers.handleSendConfirmationEmailSuccess(state);
    case UserActionTypes.SEND_CONFIRMATION_EMAIL_FAILURE:
      return UserHandlers.handleSendConfirmationEmailFailure(state);
    case UserActionTypes.CONFIRM_EMAIL:
      return UserHandlers.handleConfirmEmail(state, action);
    case UserActionTypes.CONFIRM_EMAIL_SUCCESS:
      return UserHandlers.handleConfirmEmailSuccess(state, action);
    case UserActionTypes.CONFIRM_EMAIL_FAILURE:
      return UserHandlers.handleConfirmEmailFailure(state);
    case UserActionTypes.SEND_FORGOT_PASSWORD_EMAIL:
      return UserHandlers.handleSendForgotPasswordEmail(state, action);
    case UserActionTypes.SEND_FORGOT_PASSWORD_EMAIL_SUCCESS:
      return UserHandlers.handleSendForgotPasswordEmailSuccess(state);
    case UserActionTypes.SEND_FORGOT_PASSWORD_EMAIL_FAILURE:
      return UserHandlers.handleSendForgotPasswordEmailFailure(state);
    case UserActionTypes.CHANGE_FORGOTTEN_PASSWORD:
      return UserHandlers.handleChangeForgottenPassword(state, action);
    case UserActionTypes.CHANGE_FORGOTTEN_PASSWORD_SUCCESS:
      return UserHandlers.handleChangeForgottenPasswordSuccess(state);
    case UserActionTypes.CHANGE_FORGOTTEN_PASSWORD_FAILURE:
      return UserHandlers.handleChangeForgottenPasswordFailure(state);
    case UserActionTypes.JOIN_USER_WAITLIST:
      return UserHandlers.handleJoinUserWaitlist(state, action);
    case UserActionTypes.JOIN_USER_WAITLIST_SUCCESS:
      return UserHandlers.handleJoinUserWaitlistSuccess(state);
    case UserActionTypes.JOIN_USER_WAITLIST_FAILURE:
      return UserHandlers.handleJoinUserWaitlistFailure(state);
    case UserActionTypes.SEND_INBOUND_CONTACT_REQUEST:
      return UserHandlers.handleSendInboundContactRequest(state, action);
    case UserActionTypes.SEND_INBOUND_CONTACT_REQUEST_SUCCESS:
      return UserHandlers.handleSendInboundContactRequestSuccess(state);
    case UserActionTypes.SEND_INBOUND_CONTACT_REQUEST_FAILURE:
      return UserHandlers.handleSendInboundContactRequestFailure(state);
    case UserActionTypes.UPDATE_USER_DATA:
      return UserHandlers.handleUpdateUserData(state, action);
    case UserActionTypes.UPDATE_USER_DATA_SUCCESS:
      return UserHandlers.handleUpdateUserDataSuccess(state, action);
    case UserActionTypes.UPDATE_USER_DATA_FAILURE:
      return UserHandlers.handleUpdateUserDataFailure(state);
    case UserActionTypes.DELETE_ITEM_BID:
      return UserHandlers.handleDeleteItemBid(state, action);
    case UserActionTypes.DELETE_ITEM_BID_SUCCESS:
      return UserHandlers.handleDeleteItemBidSuccess(state);
    case UserActionTypes.DELETE_ITEM_BID_FAILURE:
      return UserHandlers.handleDeleteItemBidFailure(state);
    case UserActionTypes.DELETE_ITEM_ASK:
      return UserHandlers.handleDeleteItemAsk(state, action);
    case UserActionTypes.DELETE_ITEM_ASK_SUCCESS:
      return UserHandlers.handleDeleteItemAskSuccess(state);
    case UserActionTypes.DELETE_ITEM_ASK_FAILURE:
      return UserHandlers.handleDeleteItemAskFailure(state);
    case UserActionTypes.UPDATE_ITEM_BID:
      return UserHandlers.handleUpdateItemBid(state, action);
    case UserActionTypes.UPDATE_ITEM_BID_SUCCESS:
      return UserHandlers.handleUpdateItemBidSuccess(state);
    case UserActionTypes.UPDATE_ITEM_BID_FAILURE:
      return UserHandlers.handleUpdateItemBidFailure(state);
    case UserActionTypes.UPDATE_ITEM_ASK:
      return UserHandlers.handleUpdateItemAsk(state, action);
    case UserActionTypes.UPDATE_ITEM_ASK_SUCCESS:
      return UserHandlers.handleUpdateItemAskSuccess(state);
    case UserActionTypes.UPDATE_ITEM_ASK_FAILURE:
      return UserHandlers.handleUpdateItemAskFailure(state);
    case UserActionTypes.ACTIVATE_SELLING:
      return UserHandlers.handleActivateSelling(state);
    case UserActionTypes.ACTIVATE_SELLING_SUCCESS:
      return UserHandlers.handleActivateSellingSuccess(state, action);
    case UserActionTypes.ACTIVATE_SELLING_FAILURE:
      return UserHandlers.handleActivateSellingFailure(state);
    case UserActionTypes.GENERATE_SELLER_ONBOARDING_LINK:
      return UserHandlers.handleGenerateSellerOnboardingLink(state);
    case UserActionTypes.GENERATE_SELLER_ONBOARDING_LINK_SUCCESS:
      return UserHandlers.handleGenerateSellerOnboardingLinkSuccess(state, action);
    case UserActionTypes.GENERATE_SELLER_ONBOARDING_LINK_FAILURE:
      return UserHandlers.handleGenerateSellerOnboardingLinkFailure(state);
    case UserActionTypes.GENERATE_SELLER_DASHBOARD_LINK:
      return UserHandlers.handleGenerateSellerDashboardLink(state);
    case UserActionTypes.GENERATE_SELLER_DASHBOARD_LINK_SUCCESS:
      return UserHandlers.handleGenerateSellerDashboardLinkSuccess(state, action);
    case UserActionTypes.GENERATE_SELLER_DASHBOARD_LINK_FAILURE:
      return UserHandlers.handleGenerateSellerDashboardLinkFailure(state);
    default:
      return state;
  }
}
