import { ApplicationState } from '../types';
import { RootSelectors } from '../rootSelectors';
import { ItemModel, PaginationModel } from 'models';

export class ItemSelectors {
  public static getItem(state: ApplicationState): ItemModel {
    return RootSelectors.getItemState(state).item;
  }

  public static getItems(state: ApplicationState): ItemModel[] {
    return RootSelectors.getItemState(state).items;
  }

  public static getPaginatedItems(state: ApplicationState): PaginationModel<ItemModel> {
    return RootSelectors.getItemState(state).paginatedItems;
  }

  public static getError(state: ApplicationState): Error | undefined {
    return RootSelectors.getItemState(state).error;
  }

  public static getItemLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getItemState(state).loading.item;
  }

  public static getItemListingsLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getItemState(state).loading.listings;
  }

  public static getItemMarketLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getItemState(state).loading.market;
  }

  public static getItemActiveMarketLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getItemState(state).loading.activeMarket;
  }

  public static getItemArtistLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getItemState(state).loading.artist;
  }

  public static getItemSearchLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getItemState(state).loading.search;
  }

  public static getItemsLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getItemState(state).loading.items;
  }

  public static getItemActiveMarketActionLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getItemState(state).loading.activeMarketAction;
  }

  public static getItemMarketplaceActionLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getItemState(state).loading.marketplaceAction;
  }
}
