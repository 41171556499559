import { Loop, LoopReducer } from 'redux-loop';
import { UserAction, UserActionTypes } from '../User/types';
import { AuthAction, AuthActionTypes } from '../Auth/types';
import { ItemDataSuggestionAction, ItemDataSuggestionActionTypes } from './../ItemDataSuggestion/types';
import { ListingDataSuggestionAction, ListingDataSuggestionActionTypes } from './../ListingDataSuggestion/types';
import { PopupState, PopupAction, PopupActionTypes } from './types';
import { defaultState } from './consts';
import { PopupHandlers } from './handlers';
import { ItemSaleSuggestionAction, ItemSaleSuggestionActionTypes } from './../ItemSaleSuggestion/types';
import { ItemListingGroupingAction, ItemListingGroupingActionTypes } from './../ItemListingGrouping/types';
import { PortfolioAction, PortfolioActionTypes } from '../Portfolio/types';
import { WishlistAction, WishlistActionTypes } from 'reducers/Wishlist/types';
import { ItemAction, ItemActionTypes } from 'reducers/Item/types';
import { OrderAction, OrderActionTypes } from 'reducers/Order/types';

export const popupReducer: LoopReducer<PopupState, PopupAction> = (
  state: PopupState = defaultState,
  action: PopupAction | UserAction | AuthAction | ItemDataSuggestionAction | ItemSaleSuggestionAction | ItemListingGroupingAction | ListingDataSuggestionAction | PortfolioAction | WishlistAction | ItemAction | OrderAction
): PopupState | Loop<PopupState, PopupAction> => {
  switch (action.type) {
    case PopupActionTypes.ADD_POPUP:
      return PopupHandlers.handleAddPopup(state, action);
    case PopupActionTypes.REMOVE_POPUP:
      return PopupHandlers.handleRemovePopup(state, action);
    case PopupActionTypes.CLEAR_POPUPS:
      return PopupHandlers.handleClearPopups(state);
    case UserActionTypes.FOLLOW_ARTIST_SUCCESS:
      return PopupHandlers.handleFollowArtistSuccess(state);
    case UserActionTypes.FOLLOW_ARTIST_FAILURE:
      return PopupHandlers.handleFollowArtistFailure(state);
    case UserActionTypes.UNFOLLOW_ARTIST_SUCCESS:
      return PopupHandlers.handleUnfollowArtistSuccess(state);
    case UserActionTypes.UNFOLLOW_ARTIST_FAILURE:
      return PopupHandlers.handleUnfollowArtistFailure(state);
    case PortfolioActionTypes.ADD_ITEM_TO_PORTFOLIO_SUCCESS:
      return PopupHandlers.handleAddItemToPortfolioSuccess(state);
    case PortfolioActionTypes.ADD_ITEM_TO_PORTFOLIO_FAILURE:
      return PopupHandlers.handleAddItemToPortfolioFailure(state);
    case PortfolioActionTypes.REMOVE_ITEM_FROM_PORTFOLIO_SUCCESS:
      return PopupHandlers.handleRemoveItemFromPortfolioSuccess(state);
    case PortfolioActionTypes.REMOVE_ITEM_FROM_PORTFOLIO_FAILURE:
      return PopupHandlers.handleRemoveItemFromPortfolioFailure(state);
    case PortfolioActionTypes.REPLACE_ITEM_IN_PORTFOLIO_SUCCESS:
      return PopupHandlers.handleReplaceItemInPortfolioSuccess(state);
    case PortfolioActionTypes.REPLACE_ITEM_IN_PORTFOLIO_FAILURE:
      return PopupHandlers.handleReplaceItemInPortfolioFailure(state);
    case WishlistActionTypes.ADD_ITEM_TO_WISHLIST_SUCCESS:
      return PopupHandlers.handleAddItemToWishlistSuccess(state);
    case WishlistActionTypes.ADD_ITEM_TO_WISHLIST_FAILURE:
      return PopupHandlers.handleAddItemToWishlistFailure(state);
    case WishlistActionTypes.REMOVE_ITEM_FROM_WISHLIST_SUCCESS:
      return PopupHandlers.handleRemoveItemFromWishlistSuccess(state);
    case WishlistActionTypes.REMOVE_ITEM_FROM_WISHLIST_FAILURE:
      return PopupHandlers.handleRemoveItemFromWishlistFailure(state);
    case UserActionTypes.MIGRATE_USER_IMAGE_SUCCESS:
      return PopupHandlers.handleUserImageMigrationSuccess(state);
    case UserActionTypes.MIGRATE_USER_IMAGE_FAILURE:
      return PopupHandlers.hanldeUserImageMigrationFailure(state);
    case UserActionTypes.CHANGE_USER_PASSWORD_SUCCESS:
      return PopupHandlers.handleChangePasswordSuccess(state);
    case UserActionTypes.CHANGE_USER_PASSWORD_FAILURE:
      return PopupHandlers.handleChangePasswordFailure(state);
    case UserActionTypes.CHANGE_FORGOTTEN_PASSWORD_SUCCESS:
      return PopupHandlers.handleChangeForgottenPasswordSuccess(state);
    case UserActionTypes.CHANGE_FORGOTTEN_PASSWORD_FAILURE:
      return PopupHandlers.handleChangeForgottenPasswordFailure(state);
    case UserActionTypes.CONFIRM_EMAIL_SUCCESS:
      return PopupHandlers.handleConfirmEmailSuccess(state);
    case UserActionTypes.CONFIRM_EMAIL_FAILURE:
      return PopupHandlers.handleConfirmEmailFailure(state);
    case UserActionTypes.SEND_CONFIRMATION_EMAIL_SUCCESS:
      return PopupHandlers.handleSendConfirmationEmailSuccess(state);
    case UserActionTypes.SEND_CONFIRMATION_EMAIL_FAILURE:
      return PopupHandlers.handleSendConfirmationEmailFailure(state);
    case UserActionTypes.SEND_FORGOT_PASSWORD_EMAIL_SUCCESS:
      return PopupHandlers.handleSendForgotPasswordEmailSuccess(state);
    case UserActionTypes.SEND_FORGOT_PASSWORD_EMAIL_FAILURE:
      return PopupHandlers.handleSendForgotPasswordEmailFailure(state);
    case UserActionTypes.JOIN_USER_WAITLIST_SUCCESS:
      return PopupHandlers.handleJoinUserWaitlistSuccess(state);
    case UserActionTypes.JOIN_USER_WAITLIST_FAILURE:
      return PopupHandlers.handleJoinUserWaitlistFailure(state);
    case UserActionTypes.SEND_INBOUND_CONTACT_REQUEST_SUCCESS:
      return PopupHandlers.handleSendInboundContactRequestSuccess(state);
    case UserActionTypes.SEND_INBOUND_CONTACT_REQUEST_FAILURE:
      return PopupHandlers.handleSendInboundContactRequestFailure(state);
    case AuthActionTypes.LOGIN_BY_EMAIL_FAILURE:
      return PopupHandlers.handleLoginByEmailFailure(state);
    case AuthActionTypes.REGISTER_USER_FAILURE:
      return PopupHandlers.handleRegisterUserFailure(state);
    case AuthActionTypes.LOGOUT_SUCCESS:
      return PopupHandlers.handleLogoutSuccess(state);
    case AuthActionTypes.LOGOUT_FAILURE:
      return PopupHandlers.handleLogoutFailure(state);
    case ItemDataSuggestionActionTypes.CREATE_ITEM_DATA_SUGGESTION_SUCCESS:
      return PopupHandlers.handleItemDataSuggestionSubmissionSuccess(state);
    case ItemDataSuggestionActionTypes.CREATE_ITEM_DATA_SUGGESTION_FAILURE:
      return PopupHandlers.handleItemDataSuggestionSubmissionFailure(state);
    case ItemSaleSuggestionActionTypes.CREATE_ITEM_SALE_SUGGESTION_SUCCESS:
      return PopupHandlers.handleItemSaleSuggestionSubmissionSuccess(state);
    case ItemSaleSuggestionActionTypes.CREATE_ITEM_SALE_SUGGESTION_FAILURE:
      return PopupHandlers.handleItemSaleSuggestionSubmissionFailure(state);
    case ItemListingGroupingActionTypes.CREATE_ITEM_LISTING_GROUPING_SUCCESS:
      return PopupHandlers.handleItemListingGroupingSubmissionSuccess(state);
    case ItemListingGroupingActionTypes.CREATE_ITEM_LISTING_GROUPING_FAILURE:
      return PopupHandlers.handleItemListingGroupingSubmissionFailure(state);
    case ItemActionTypes.PLACE_ITEM_BID_SUCCESS:
      return PopupHandlers.handlePlaceItemBidSuccess(state);
    case ItemActionTypes.PLACE_ITEM_BID_FAILURE:
      return PopupHandlers.handlePlaceItemBidFailure(state);
    case ItemActionTypes.PLACE_ITEM_ASK_SUCCESS:
      return PopupHandlers.handlePlaceItemAskSuccess(state);
    case ItemActionTypes.PLACE_ITEM_ASK_FAILURE:
      return PopupHandlers.handlePlaceItemAskFailure(state);
    case ItemActionTypes.DELETE_ITEM_BID_SUCCESS:
      return PopupHandlers.handleDeleteItemBidSuccess(state);
    case ItemActionTypes.DELETE_ITEM_BID_FAILURE:
      return PopupHandlers.handleDeleteItemBidFailure(state);
    case ItemActionTypes.DELETE_ITEM_ASK_SUCCESS:
      return PopupHandlers.handleDeleteItemAskSuccess(state);
    case ItemActionTypes.DELETE_ITEM_ASK_FAILURE:
      return PopupHandlers.handleDeleteItemAskFailure(state);
    case ItemActionTypes.UPDATE_ITEM_BID_SUCCESS:
      return PopupHandlers.handleUpdateItemBidSuccess(state);
    case ItemActionTypes.UPDATE_ITEM_BID_FAILURE:
      return PopupHandlers.handleUpdateItemBidFailure(state);
    case ItemActionTypes.UPDATE_ITEM_ASK_SUCCESS:
      return PopupHandlers.handleUpdateItemAskSuccess(state);
    case ItemActionTypes.UPDATE_ITEM_ASK_FAILURE:
      return PopupHandlers.handleUpdateItemAskFailure(state);
    case ItemActionTypes.PLACE_ITEM_INQUIRY_SUCCESS:
      return PopupHandlers.handlePlaceItemInquirySuccess(state);
    case ItemActionTypes.PLACE_ITEM_INQUIRY_FAILURE:
      return PopupHandlers.handlePlaceItemInquiryFailure(state);
    case ListingDataSuggestionActionTypes.CREATE_LISTING_DATA_SUGGESTION_SUCCESS:
      return PopupHandlers.handleListingDataSuggestionSubmissionSuccess(state);
    case ListingDataSuggestionActionTypes.CREATE_LISTING_DATA_SUGGESTION_FAILURE:
      return PopupHandlers.handleListingDataSuggestionSubmissionFailure(state);
    case UserActionTypes.UPDATE_USER_DATA_SUCCESS:
      return PopupHandlers.handleUpdateUserDataSuccess(state);
    case UserActionTypes.UPDATE_USER_DATA_FAILURE:
      return PopupHandlers.handleUpdateUserDataFailure(state);
    case UserActionTypes.DELETE_ITEM_BID_SUCCESS:
      return PopupHandlers.handleDeleteItemBidSuccess(state);
    case UserActionTypes.DELETE_ITEM_BID_FAILURE:
      return PopupHandlers.handleDeleteItemBidFailure(state);
    case UserActionTypes.DELETE_ITEM_ASK_SUCCESS:
      return PopupHandlers.handleDeleteItemAskSuccess(state);
    case UserActionTypes.DELETE_ITEM_ASK_FAILURE:
      return PopupHandlers.handleDeleteItemAskFailure(state);
    case UserActionTypes.UPDATE_ITEM_BID_SUCCESS:
      return PopupHandlers.handleUpdateItemBidSuccess(state);
    case UserActionTypes.UPDATE_ITEM_BID_FAILURE:
      return PopupHandlers.handleUpdateItemBidFailure(state);
    case UserActionTypes.UPDATE_ITEM_ASK_SUCCESS:
      return PopupHandlers.handleUpdateItemAskSuccess(state);
    case UserActionTypes.UPDATE_ITEM_ASK_FAILURE:
      return PopupHandlers.handleUpdateItemAskFailure(state);
    case UserActionTypes.ACTIVATE_SELLING_SUCCESS:
      return PopupHandlers.handleActivateSellingSuccess(state);
    case UserActionTypes.ACTIVATE_SELLING_FAILURE:
      return PopupHandlers.handleActivateSellingFailure(state);
    case ItemActionTypes.GENERATE_ASK_PAYMENT_LINK_FAILURE:
      return PopupHandlers.handleGenerateItemAskPaymentLinkFailue(state);
    case ItemActionTypes.ACCEPT_ITEM_BID_SUCCESS:
      return PopupHandlers.handleAcceptItemBidSuccess(state);
    case ItemActionTypes.ACCEPT_ITEM_BID_FAILURE:
      return PopupHandlers.handleAcceptItemBidFailure(state);
    case OrderActionTypes.ADD_ORDER_ADDRESS_SUCCESS:
      return PopupHandlers.handleOrderAddressUpdateSuccess(state);
    case OrderActionTypes.ADD_ORDER_ADDRESS_FAILURE:
      return PopupHandlers.handleOrderAddressUpdateFailure(state);
    case UserActionTypes.ADD_PRIMARY_ADDRESS_SUCCESS:
      return PopupHandlers.handleUserAddressUpdateSuccess(state);
    case UserActionTypes.ADD_PRIMARY_ADDRESS_FAILURE:
      return PopupHandlers.handleUserAddressUpdateFailure(state)
    default:
      return state;
  }
}
