import { useState, useEffect } from 'react';
import {
	SimpleGrid,
	Flex,
	Stack,
	Heading,
	Icon,
	Image,
	Tooltip,
	Spacer,
	Spinner,
	StackDivider,
	Button
} from '@chakra-ui/react';
import { IconType } from 'react-icons';
import {
	BiDollar,
	BiPurchaseTagAlt
} from 'react-icons/bi';
import {
	BsFolder,
	BsFolderFill
} from 'react-icons/bs';
import {
	AiOutlineDollar,
	AiFillHeart,
	AiOutlineHeart,
	AiOutlineLineChart
} from 'react-icons/ai';
import {
	VscEdit
} from 'react-icons/vsc';
import {
	GiThorHammer
} from 'react-icons/gi';
import { IoFlagOutline } from "react-icons/io5";
import { FaSign } from "react-icons/fa";
import { ArtistModel, ItemBidModel, ItemAskModel, ItemModel, UserModel } from 'models';
import { FeatureToggleService } from 'services';
import BasicArtistCard from '../../Artist/BasicArtistCard/BasicArtistCard';
import Feature from '../../Feature/Feature';
import InfoGuide from '../../InfoGuide/InfoGuide';
import GrowthBadge from '../../Badge/GrowthBadge';
import { FeatureToggleKeys } from 'constants/toggles';


export interface ItemContainerProps {
	item: ItemModel;
	artist: ArtistModel | null | undefined;
	user: UserModel | null;
	isFollowingArtist: boolean;
	marketButtonsLoading: boolean;
	portfolioButtonLoading: boolean;
	wishlistButtonLoading: boolean;
	marketplaceActionLoading: boolean;
	followArtistLoading: boolean;
	portfolioContainsItem: boolean;
	wishlistContainsItem: boolean;
	onPortfolioClick: () => void;
	onWishlistClick: () => void;
	onEditClick: () => void;
	onFlagClick: () => void;
	onFollowArtistClick: () => void;
	onArtistClick: () => void;
	onItemBidClick?: () => void;
	onSellClick?: (bid: ItemBidModel) => void;
	onItemAskClick?: () => void;
	onBuyClick?: (ask: ItemAskModel) => void;
	onMarketInfoGuideClick?: () => void;
	bidButtonText?: string;
	askButtonText?: string;
	authenticated?: boolean;
}

export enum ItemContainerSizingConfig {
	IMAGE_MAX_HEIGHT = '500px',
	SECTION_SPACING = '20px',
}

export default function ItemContainer({
	item,
	artist,
	user,
	isFollowingArtist,
	marketButtonsLoading,
	portfolioButtonLoading,
	wishlistButtonLoading,
	marketplaceActionLoading,
	followArtistLoading,
	portfolioContainsItem,
	wishlistContainsItem,
	onPortfolioClick,
	onWishlistClick,
	onEditClick,
	onFlagClick,
	onFollowArtistClick,
	onArtistClick,
	onItemBidClick,
	onSellClick,
	onItemAskClick,
	onBuyClick,
	onMarketInfoGuideClick,
	authenticated = false
}: ItemContainerProps) {
	const [artistData, setArtistData] = useState<ArtistModel | null | undefined>(artist);
	const userHasPlacedBid: boolean = !!item.getItemBidForUser(user?.id);
	const userHasPlacedAsk: boolean = !!item.getItemAskForUser(user?.id);

    const bidButtonText = !!userHasPlacedBid ? 'Update Bid' : 'Place Bid';
    const askButtonText = !!userHasPlacedAsk ? 'Update Ask' : 'Place Ask';

	const marketplaceEnabled = FeatureToggleService.isToggleEnabled(FeatureToggleKeys.ENABLE_MARKETPLACE);

	useEffect(() => {
		if (artist) {
			setArtistData(artist);
		}
	}, [artist]);

	const getClosePriceColor = (): string => {
		let iconColor = 'gray';
		if (item.hasMarketData()) {
			if (item.getEstimatedMarketPrice() > item.getClosePrice()) {
				iconColor = 'red';
			} else if (item.getEstimatedMarketPrice() < item.getClosePrice()) {
				iconColor = 'green';
			}
		}
		return iconColor;
	}

	function renderContainerUserButton(icon: React.ElementType, onClick: () => void, isLoading: boolean) {
		return (
			<Flex
				w={8}
				h={8}
				align={'center'}
				justify={'center'}
				rounded={'full'}
				cursor={'pointer'}
				border="1px"
				onClick={onClick}
				_hover={{ color: 'blue.500' }}
			>
				{isLoading ? <Spinner size="sm" /> : <Icon as={icon} />}
			</Flex>
		);
	}

	function renderMarketButton(text: string, color: 'blue' | 'orange', icon: IconType, onClick?: () => void, disabled?: boolean) {
		return (
			<Button
				paddingY={6}
				color={`${color}.700`}
				border={"1px solid"}
				borderColor={`${color}.700`}
				backgroundColor={`${color}.50`}
				_hover={{ backgroundColor: `${color}.100` }}
				fontSize={'md'}
				disabled={marketButtonsLoading || disabled}
				leftIcon={<Icon as={icon} />}
				width={'100%'}
				onClick={onClick}>
				{marketButtonsLoading ? <Spinner size="sm" /> : text}
			</Button>
		);
	}

	function renderMarketPrimaryButton(text: string, color: 'blue' | 'orange', icon: IconType, onClick?: () => void, disabled?: boolean) {
		return (
			<Button
				paddingY={6}
				colorScheme={color}
				fontSize={'md'}
				disabled={marketButtonsLoading || marketplaceActionLoading || disabled}
				leftIcon={<Icon as={icon} />}
				width={'100%'}
				onClick={onClick}>
				{marketButtonsLoading || marketplaceActionLoading ? <Spinner size="sm" /> : text}
			</Button>
		);
	}


	function renderContainerUserButtons() {
		const portfolioTooltipLabel = portfolioContainsItem ? 'Remove from Collection' : 'Add to Collection';
		const wishlistTooltipLabel = wishlistContainsItem ? 'Unfavorite' : 'Favorite';
		const portfolioIconType = portfolioContainsItem ? BsFolderFill : BsFolder;
		const wishlistIconType = wishlistContainsItem ? AiFillHeart : AiOutlineHeart;

		return (
			<Flex direction="row" justifyContent="center">
				<Flex width="40%">
					<Tooltip label={portfolioTooltipLabel}>
						{renderContainerUserButton(portfolioIconType, onPortfolioClick, portfolioButtonLoading)}
					</Tooltip>
					<Spacer />
					<Tooltip label={wishlistTooltipLabel}>
						{renderContainerUserButton(wishlistIconType, onWishlistClick, wishlistButtonLoading)}
					</Tooltip>
					<Spacer />
					<Tooltip label="Suggest an Edit">
						{renderContainerUserButton(VscEdit, onEditClick, false)}
					</Tooltip>
					<Spacer />
					<Tooltip label="Flag this item">
						{renderContainerUserButton(IoFlagOutline, onFlagClick, false)}
					</Tooltip>
				</Flex>
			</Flex>
		);
	}

	function renderArtistSection() {
		if (artistData) {
			const buttonText = isFollowingArtist ? 'Unfollow' : 'Follow';
			const buttonIcon = isFollowingArtist ? <AiFillHeart /> : <AiOutlineHeart />;

			return (
				<BasicArtistCard
					artist={artistData}
					avatarSize="md"
					titleSize="lg"
					showAdditionalInfo
					showButton
					onArtistTextClick={onArtistClick}
					buttonText={buttonText}
					buttonIcon={buttonIcon}
					buttonLoading={followArtistLoading}
					onButtonClick={onFollowArtistClick}
					showBorder={false}
				/>
			);
		}
	}

	function renderContainerFeatures() {
		const lastSaleIconColor = getClosePriceColor();
		const containerDivider = <StackDivider borderColor={'gray.100'} />;
		let marketPriceText: string = 'No Market Data';
		let secondaryMarketPremiumText: string = 'No Market Data';
		let lastSaleTextContent: string | JSX.Element = 'No Recorded Sales';
		if (item.hasMarketData() && item.hasReleasePriceData()) {
			secondaryMarketPremiumText = item.getSecondaryMarketPremiumForDisplay();
		} else if (item.hasMarketData() && !item.hasReleasePriceData()) {
			secondaryMarketPremiumText = 'Retail Price Unknown';
		}

		if (item.hasMarketData()) {
			marketPriceText = item.getEstimatedMarketPriceForDisplay();
			lastSaleTextContent = (
				<Flex alignItems={'center'} gap={2}>
					{item.getClosePriceForDisplay()}
					{item.hasMultipleSales() && <GrowthBadge percentage={item.getLastSalePercentageChange()} />}
				</Flex>
			);
		}


		return (
			<Stack paddingTop={ItemContainerSizingConfig.SECTION_SPACING} spacing={4} divider={containerDivider}>
				<Feature
					icon={<Icon as={BiDollar} color={'purple.500'} w={5} h={5} />}
					iconBg={'purple.100'}
					text={authenticated ? marketPriceText : 'Login to View'}
					subText={item.hasMarketData() || !authenticated ? 'Est. market price' : ''}
				/>
				<Feature
					icon={<Icon as={BiDollar} color={`${lastSaleIconColor}.500`} w={5} h={5} />}
					iconBg={`${lastSaleIconColor}.100`}
					text={authenticated ? lastSaleTextContent : 'Login to View'}
					subText={item.hasMarketData() || !authenticated ? 'Last Sale' : ''}
				/>
				<Feature
					icon={
						<Icon as={AiOutlineLineChart} color={'yellow.500'} w={5} h={5} />
					}
					iconBg={'yellow.100'}
					text={authenticated ? secondaryMarketPremiumText : 'Login to View'}
					subText={item.hasMarketData() || !authenticated ? 'Secondary Market Premium' : ''}
				/>
			</Stack>
		);
	}

	function renderMarketButtonRow(leftButton: JSX.Element | null, rightButton: JSX.Element | null) {
		return (
			<Flex gap={2}>
				{leftButton}
				{leftButton && rightButton && <Spacer />}
				{rightButton}
			</Flex>
		);
	}


	function renderMarketButtons() {
		let content: JSX.Element;
		const highestBid = item.getHighestBid();
		const lowestAsk = item.getLowestAsk();

		if ((highestBid || lowestAsk) && marketplaceEnabled) {
			const bidRowLeftButton = lowestAsk
				? renderMarketPrimaryButton(
					`Buy for ${lowestAsk.getFormattedAmount()}`,
					'blue',
					BiPurchaseTagAlt,
					() => onBuyClick?.(lowestAsk),
					lowestAsk.userId === user?.id
				)
				: null;
			const bidRowRightButton = renderMarketButton(
				lowestAsk && !userHasPlacedBid ? 'Place Lower Bid' : bidButtonText,
				'blue',
				GiThorHammer,
				onItemBidClick
			);

			const sellRowLeftButton = highestBid
				? renderMarketPrimaryButton(
					`Sell for ${highestBid.getFormattedAmount()}`,
					'orange',
					AiOutlineDollar,
					() => onSellClick?.(highestBid),
					highestBid.userId === user?.id
				)
				: null;
			const sellRowRightButton = renderMarketButton(
				highestBid && !userHasPlacedAsk  ? 'Place Higher Ask' : askButtonText,
				'orange',
				FaSign,
				onItemAskClick
			);

			const rows = [
				renderMarketButtonRow(bidRowLeftButton, bidRowRightButton),
				renderMarketButtonRow(sellRowLeftButton, sellRowRightButton)
			]

			content = (
				<Stack gap={2} divider={<StackDivider borderColor={'gray.100'} />}>
					{rows}
				</Stack>
			);
		} else {
			content = (
				<Flex gap={4}>
					{renderMarketButton(bidButtonText, 'blue', GiThorHammer, onItemBidClick)}
					<Spacer />
					{renderMarketButton(askButtonText, 'orange', FaSign, onItemAskClick)}
				</Flex>
			);
		}

		return (
			<Stack gap={4}>
				{content}
				<InfoGuide text="Learn how Buying & Selling works" onClick={onMarketInfoGuideClick} />
			</Stack>
		)
	}

	function renderItemImage() {
		return (
			<Flex justifyContent="center" alignItems="center">
				<Image
					rounded="md"
					alt={item.name}
					src={item.image}
					objectFit="contain"
					maxHeight={ItemContainerSizingConfig.IMAGE_MAX_HEIGHT}
				/>
			</Flex>
		);
	}

	return (
		<SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
			{renderItemImage()}
			<Stack spacing={4}>
				<Heading>{item.name}</Heading>
				{renderContainerUserButtons()}
				{renderArtistSection()}
				{renderMarketButtons()}
				{renderContainerFeatures()}
			</Stack>
		</SimpleGrid>
	);
}
