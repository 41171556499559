import { AddressModel, OrderModel } from 'models';
import { OrderActionTypes, OrderAction } from './types';

export class OrderActions {
  public static getOrderById(id: string): OrderAction {
    return {
      type: OrderActionTypes.GET_ORDER_BY_ID,
      payload: { id },
    };
  }

  public static addOrderAddress(orderId: string, address: AddressModel): OrderAction {
    return {
      type: OrderActionTypes.ADD_ORDER_ADDRESS,
      payload: { orderId, address },
    };
  }
}

export class OrderInternalActions {
  public static getOrderByIdSuccess(order: OrderModel): OrderAction {
    return {
      type: OrderActionTypes.GET_ORDER_BY_ID_SUCCESS,
      payload: { order },
    };
  }

  public static getOrderByIdFailure(): OrderAction {
    return {
      type: OrderActionTypes.GET_ORDER_BY_ID_FAILURE,
    };
  }

  public static addOrderAddressSuccess(orderId: string): OrderAction {
    return {
      type: OrderActionTypes.ADD_ORDER_ADDRESS_SUCCESS,
      payload: { orderId },
    };
  }

  public static addOrderAddressFailure(): OrderAction {
    return {
      type: OrderActionTypes.ADD_ORDER_ADDRESS_FAILURE
    };
  }
}
