import { Button, Flex, Text, Icon, Spinner } from '@chakra-ui/react';
import { InfoIcon, CheckCircleIcon, WarningIcon } from '@chakra-ui/icons';
import { AiOutlineDollar } from 'react-icons/ai';
import { UserModel } from 'models/User/User';
import StripeIcon from '../../CustomIcons/StripeIcon';
import UserProfileCard from './UserProfileCard';

interface UserProfileSellerCardProps {
    user: UserModel;
    marketplaceActionLoading?: boolean;
    sellerOnboardingLink?: string;
    sellerDashboardLink?: string;
    onActivateSelling?: () => void;
    onOnboard?: () => void;
}

export default function UserProfileSellerCard({
    user,
    marketplaceActionLoading = false,
    sellerOnboardingLink = '',
    sellerDashboardLink = '',
    onActivateSelling = () => {},
    onOnboard = () => {},
}: UserProfileSellerCardProps) {
    const hasSellerActivatedSelling = user.hasStripeConnectedAccountId();
    const isSellerVerified = user.isSellerVerified();
    const isSellerFullyOnboarded = user.hasSubmittedSellerDetails();
    const arePayoutsEnabled = user.hasSellerPayoutsEnabled();
    const isDashboardDisabled = sellerDashboardLink.length === 0 || marketplaceActionLoading;

    let cardDescription: string = '';

    let topButtonConfig = { isDisabled: true, colorScheme: 'red', icon: <WarningIcon />, text: '', onClick: () => {} };

    if (isSellerVerified) {
        cardDescription = 'Manage Details, Payouts ect.';
        topButtonConfig = { isDisabled: true, colorScheme: 'green', icon: <CheckCircleIcon />, text: 'Connected', onClick: () => {} };
    } else if (!hasSellerActivatedSelling) {
        cardDescription = 'Active Your Seller Account';
        const buttonIcon = marketplaceActionLoading ? <Spinner size={'xs'} /> : <InfoIcon />
        topButtonConfig = { isDisabled: marketplaceActionLoading, colorScheme: 'blue', icon: buttonIcon, text: 'Activate Selling', onClick: onActivateSelling };
    } else if (!isSellerFullyOnboarded) {
        cardDescription = 'Complete Your Onboarding to Start Selling';
        const buttonIcon = marketplaceActionLoading ? <Spinner size={'xs'} /> : <WarningIcon />;
        topButtonConfig = { isDisabled: marketplaceActionLoading, colorScheme: 'orange', icon: buttonIcon, text: 'Onboard', onClick: () => {}};
    } else if (!arePayoutsEnabled) {
        cardDescription = 'Enable Your Payouts to Continue Selling';
        topButtonConfig = { isDisabled: false, colorScheme: 'orange', icon: <WarningIcon />, text: 'Enable Payouts', onClick: onOnboard };
    }

    const isLinkButton = !!sellerOnboardingLink && !isSellerFullyOnboarded;

    const topButton = (
        <Button
            as={isLinkButton ? 'a' : undefined}
            href={isLinkButton ? sellerOnboardingLink : undefined}
            target={isLinkButton ? '_blank' : undefined}
            rel={isLinkButton ? 'noopener noreferrer' : undefined}
            isDisabled={topButtonConfig.isDisabled}
            leftIcon={topButtonConfig.icon}
            colorScheme={topButtonConfig.colorScheme}
            onClick={topButtonConfig.onClick}
            size="sm"
        >
            {topButtonConfig.text}
        </Button>
    );

    const bottomButton = (
        <Button
            as={isDashboardDisabled ? undefined : 'a'}
            href={sellerDashboardLink}
            target={'_blank'}
            rel={'noopener noreferrer'}
            variant="outline"
            size="sm"
            isDisabled={isDashboardDisabled}
            leftIcon={marketplaceActionLoading ? <Spinner size={'xs'} /> : undefined}
        >
            Seller Dashboard
        </Button>
    );

    return (
        <UserProfileCard
            title="Seller"
            subtitle={cardDescription}
            icon={AiOutlineDollar}
            topButton={topButton}
            bottomButton={bottomButton}
            children={(
                <Flex gap={2} alignItems="center">
                    <Icon as={StripeIcon} />
                    <Text fontSize="sm" color="gray.500">
                        Powered by Stripe
                    </Text>
                </Flex>
            )}
        />
    );
}
