export class ArtistConfig {
    static ENABLED_ARTISTS = [
        '5fe37c03f3d74ddddf9d66cf',
        '5fe37b8bf3d74ddddf9d66c8',
        '5fe37bc2f3d74ddddf9d66cb',
        '5fe37c17f3d74ddddf9d66d0',
        '5fe37c28f3d74ddddf9d66d1',
        '5fe37c3af3d74ddddf9d66d2',
        '5fe37a7df3d74ddddf9d66c2',
        '6153e34c5060fd809350d623',
        '61a41b4fd2b772862a45efff',
        '5fe37badf3d74ddddf9d66ca',
        '5fe37bdff3d74ddddf9d66cc',
        '5fe37c57f3d74ddddf9d66d3',
        '660d77b3c21b79abaf3da507',
        '663b008d0971861e17c09038',
        '6653ce5d94c423a6ba03ccb9',
        '66b25bdd25481f109e90f7d3',
        '66bea046545416de91ac8d1d',
        '66bfd57686707a54c2ffcd7c',
        '66bfdef186707a54c2ffce3d',
        '66ca83a066cd6e1c3b64669a',
        '66cbe0485155d190ff59307e',
        '66ce88ce061cf1ee021a20a9',
        '66cf57b9061cf1ee021a2471',
        '66d2717d178a048bd3c1399d',
        '679ee0b48835cfeb5148a6d3',
        '67b35c5ebd07a80751b1d068',
        '67d7146396918f3ccad76cd0'
    ]
}