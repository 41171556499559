import mixpanel from 'mixpanel-browser';
import { Location } from "react-router-dom";
import { UserModel } from 'models';

export class MixpanelService {
  private static initialized = false;

  static init(token: string) {
    if (!this.initialized) {
      mixpanel.init(token, {
        debug: process.env.NODE_ENV !== 'production',
      });
      this.initialized = true;
    }
  }

  static identify(userId: string) {
    if (!this.initialized) return;
    mixpanel.identify(userId);
  }

  static setPeopleProperties(props: Record<string, any>) {
    if (!this.initialized) return;
    mixpanel.people.set(props);
  }

  static track(eventName: string, data?: Record<string, any>) {
    if (!this.initialized) return;
    mixpanel.track(eventName, data);
  }

  static reset() {
    if (!this.initialized) return;
    mixpanel.reset();
  }

  static trackPageview(location: Location, user?: UserModel) {
    if (!this.initialized) return;
    const payload = user ? { 
        path: location.pathname,
        userId: String(user.id),
        userEmail: user.email,
        userName: user.userName
    } : { 
        path: location.pathname,
    }
    
    mixpanel.track("Page View", payload);
  }
}
