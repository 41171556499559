import { useState, useMemo } from 'react';
import { Stack, Heading, Icon, Box } from '@chakra-ui/react';
import { UserModel, ItemModel, ItemBidModel } from 'models';
import { Condition } from 'models/types';
import { ItemBidStatus } from 'models/Item/ItemBid/types';
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai';
import Wizard from '../Wizard';
import ItemAskForm, { ItemAskFormData } from '../../Form/ItemAskForm/ItemAskForm';
import ItemAskAdditionalDataForm, { ItemAskAdditionalDataFormData } from '../../Form/ItemAskAdditionalDataForm/ItemAskAdditionalDataForm';
import ItemAskCard from '../../Item/ItemAskCard/ItemAskCard';
import UnauthorizedAccess from '../../UnauthorizedAccess/UnauthorizedAccess';
import PaymentBreakdown from '../../PaymentBreakdown/PaymentBreakdown';

interface ItemAskWizardProps {
	user: UserModel;
	item: ItemModel;
	defaultAsk?: ItemAskFormData;
	defaultAdditionalData?: ItemAskAdditionalDataFormData;
	onSubmit?: (data: ItemAskFormData, additionalData: ItemAskAdditionalDataFormData, useEscrow?: boolean) => void;
	isLoading?: boolean;
	hasErrors?: boolean;
	onUnauthorizedClick?: () => void;
}

export default function ItemAskWizard({
	user,
	item,
	defaultAsk = { askExpiration: 30 },
	defaultAdditionalData,
	onSubmit = () => { },
	isLoading = false,
	hasErrors = false,
	onUnauthorizedClick = () => { }
}: ItemAskWizardProps) {
	const [askData, setAskData] = useState<ItemAskFormData>(defaultAsk);
	const [additionalAskData, setAdditionalAskData] = useState<ItemAskAdditionalDataFormData>(
		defaultAdditionalData || { condition: Condition.GOOD }
	);
	const [useEscrow, setUseEscrow] = useState(false);
	const opposingBidTransaction = useMemo(() => new ItemBidModel({
		id: '',
		item_id: item.id,
		user_id: user.id,
		amount: askData.askPrice || 0,
		currency: 'USD',
		status: ItemBidStatus.PENDING,
		condition: additionalAskData.condition || Condition.GOOD,
		notes: '',
		expiration_days: 30,
		verified: false,
	}), [user, item.id, askData.askPrice, additionalAskData.condition]);

	const onWizardSubmit = () => {
		onSubmit(askData, additionalAskData, useEscrow);
	};

	const handleFormChange = (data: ItemAskFormData) => {
		setAskData(data);
	};

	const handleAdditionalDataChange = (data: ItemAskAdditionalDataFormData) => {
		setAdditionalAskData(data);
	};

	function renderAskForm() {
		if (user.isSellerVerified()) {
			return (
				<Box height={{ base: 'auto', md: 340 }}>
					<ItemAskForm item={item} onChange={handleFormChange} defaultFormData={askData} showSubmit={false} />
				</Box>
			);
		} else if (user.hasStripeConnectedAccountId()) {
			return (
				<UnauthorizedAccess
					title="Unauthorized"
					description="Please Complete Seller Onboarding To Be Eligible to Place An Ask"
					buttonText='Complete Onboarding'
					onClick={onUnauthorizedClick} />
			);
		} else {
			return (
				<UnauthorizedAccess
					title="Unauthorized"
					description="Please Activate Selling In Order To Be Eligible To Place An Ask"
					buttonText='Activate Selling'
					onClick={onUnauthorizedClick} />
			);
		}
	}

	function renderAskCard() {
		return (
			<ItemAskCard item={item} askAmount={askData.askPrice || 0} askCreatedDays={0} condition={additionalAskData.condition} />
		);
	}

	function renderAdditionalDataForm() {
		return (
			<Box height={{ base: 'auto', md: 340 }}>
				<Stack spacing={8}>
					{renderAskCard()}
					<ItemAskAdditionalDataForm
						onChange={handleAdditionalDataChange}
						defaultFormData={additionalAskData}
					/>
				</Stack>
			</Box>
		);
	}

	function renderConfirmationStep() {
		return (
			<Box height={{ base: 'auto', md: 340 }}>
				<Stack spacing={4}>
					{renderAskCard()}
					<Heading fontSize={'sm'}>
						Confirm Your Ask
					</Heading>
					<PaymentBreakdown transaction={opposingBidTransaction} type={'bid'} useEscrow={useEscrow} onToggleEscrow={setUseEscrow} showInfoGuide={false} showTransactionCard={false} />
				</Stack>
			</Box>
		);
	}

	function renderWizardFinishingSuccessStep() {
		let content: JSX.Element;
		if (hasErrors) {
			content = (
				<Stack gap={4} alignItems={'center'}>
					<Icon as={AiFillCloseCircle} color={'red.500'} w={10} h={10} />
					<Heading size={'xs'} textAlign={'center'}>
						Your Ask Could Not Be Submitted.<br />Please Try Again
					</Heading>
				</Stack>
			);
		} else {
			content = (
				<Stack gap={4} alignItems={'center'}>
					<Icon as={AiFillCheckCircle} color={'green.500'} w={10} h={10} />
					<Heading size={'xs'} textAlign={'center'}>
						Your Ask Has Been Submitted
					</Heading>
				</Stack>
			);
		}

		return (
			<Box height={{ base: 'auto', md: 340 }}>
				{content}
			</Box>
		)
	}

	const wizardSteps = [
		{ label: 'Amount', content: renderAskForm(), requiresValidation: true, isValidated: (askData?.askPrice || 0) > 0 },
		{ label: 'Details', description: 'Optional', content: renderAdditionalDataForm(), requiresValidation: false },
		{ label: 'Confirm', content: renderConfirmationStep() },
	];

	return (
		<Stack gap={2} width="100%">
			<Wizard steps={wizardSteps} finishStep={renderWizardFinishingSuccessStep()} loading={isLoading} onSubmit={onWizardSubmit} />
		</Stack>
	);
}
