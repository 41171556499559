import { useForm, Controller } from 'react-hook-form';
import {
	FormControl,
	FormLabel,
	Textarea,
	Stack,
} from '@chakra-ui/react';
import { Condition } from 'models/types';
import ConditionDropdown from '../../ConditionDropdown/ConditionDropdown';


export interface ItemAskAdditionalDataFormData {
	condition?: Condition;
	notes?: string;
}

interface ItemAskAdditionalDataFormProps {
	defaultFormData?: ItemAskAdditionalDataFormData;
	onChange?: (data: ItemAskAdditionalDataFormData) => void;
	onSubmit?: (data: ItemAskAdditionalDataFormData) => void;
	isDisabled?: boolean;
}

export default function ItemAskAdditionalDataForm({
	defaultFormData,
	onChange,
	onSubmit,
	isDisabled
}: ItemAskAdditionalDataFormProps) {
	const { control, register, getValues, setValue, handleSubmit } = useForm<ItemAskAdditionalDataFormData>({
		defaultValues: {
			condition: defaultFormData?.condition || Condition.GOOD,
			notes: defaultFormData?.notes || '',
		},
	});

	const onFormSubmit = (data: ItemAskAdditionalDataFormData) => {
		onSubmit?.(data);
	};

	const handleFormChange = () => {
		if (onChange) {
			onChange(getValues());
		}
	};


	const renderConditionDropdown = () => {
		return (
			<FormControl isDisabled={isDisabled} mb={4}>
				<FormLabel fontSize="sm" htmlFor="condition">
					Condition
				</FormLabel>
				<Controller
					control={control}
					name="condition"
					render={() => (
						<ConditionDropdown
							defaultValue={defaultFormData?.condition}
							onChange={(condition: Condition) => {
								setValue('condition', condition, { shouldDirty: true })
								handleFormChange();
							}}
							isDisabled={isDisabled}
							size="sm"
						/>
					)}
				/>
			</FormControl>
		)
	};

	function renderNotes() {
		return (
			<FormControl isDisabled={isDisabled}>
					<FormLabel fontSize="sm" htmlFor="notes">
						Notes
					</FormLabel>
					<Textarea
						id="notes"
						placeholder="Enter any additional notes (max 200 characters)"
						size="sm"
						maxLength={200}
						{...register('notes')}
					/>
				</FormControl>
		);
	}

	return (
		<form onSubmit={handleSubmit(onFormSubmit)} onChange={handleFormChange}>
			<Stack spacing={4}>
				{renderConditionDropdown()}
				{renderNotes()}
			</Stack>
		</form>
	);
}