import { Flex, Icon, Heading, Badge, Text } from '@chakra-ui/react';
import { IconType } from "react-icons";

export interface MarketBadgeProps {
	icon: IconType;
	count: number;
	label: string;
	color: 'orange' | 'blue';
}

export default function MarketBadge({ icon, count, label, color }: MarketBadgeProps) {
	return (
		<Badge px={2} py={1} borderRadius="md" colorScheme={color}>
			<Flex align="center" gap={1}>
				<Flex
					p={1}
					align="center"
					justify="center"
					rounded="full"
					border="1px"
				>
					<Icon as={icon} w={2} h={2} />
				</Flex>
				<Heading fontSize="sm">{count}</Heading>
				<Text fontSize="xs">{label}</Text>
			</Flex>
		</Badge>
	);
};
