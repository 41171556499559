import { Cmd, loop, Loop } from 'redux-loop';
import { PopupState, PopupAction } from './types';
import { PopupActions } from './actions';
import { Toast } from 'models/types';
import { delayedDispatch } from 'utils/dispatch';

export class PopupHandlers {
  public static handleAddPopup(state: PopupState, action: PopupAction): Loop<PopupState, PopupAction> {
    const { payload } = action;
    let newState: PopupState = state;

    if (payload?.toast) {
      newState = {
        ...state,
        toasts: [payload?.toast, ...state.toasts]
      };
    }

    return loop(
      newState,
      Cmd.run(delayedDispatch, {
        args: [true, payload?.toast?.duration, Cmd.dispatch, PopupActions.removePopup, payload?.toast?.id],
      })
    );
  }

  public static handleRemovePopup(state: PopupState, action: PopupAction): PopupState {
    const id = action.payload?.id || '';
    return {
      ...state,
      toasts: id ? state.toasts.filter((n) => n.id !== id) : state.toasts
    }
  }

  public static handleClearPopups(state: PopupState): PopupState {
    return {
      ...state,
      toasts: [],
    }
  }

  public static handleFollowArtistSuccess(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Artist Followed',
      duration: 5000,
      status: 'success'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleFollowArtistFailure(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Unable To Follow Artist',
      duration: 5000,
      status: 'error'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleUnfollowArtistSuccess(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Artist Unfollowed',
      duration: 5000,
      status: 'success'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleUnfollowArtistFailure(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Unable To Unfollow Artist',
      duration: 5000,
      status: 'error'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleAddItemToPortfolioSuccess(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Item Added To Portfolio',
      duration: 5000,
      status: 'success'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleAddItemToPortfolioFailure(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Unable To Add Item To Portfolio',
      duration: 5000,
      status: 'error'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handlePlaceItemBidSuccess(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Item Bid Successfully Placed',
      duration: 5000,
      status: 'success'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handlePlaceItemBidFailure(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Unable To Place Item Bid. Please Try Again Later',
      duration: 5000,
      status: 'error'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handlePlaceItemAskSuccess(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Item Ask Successfully Placed',
      duration: 5000,
      status: 'success'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handlePlaceItemAskFailure(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Unable To Place Item Ask. Please Try Again Later',
      duration: 5000,
      status: 'error'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleDeleteItemBidSuccess(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Item Bid Successfully Deleted',
      duration: 5000,
      status: 'success'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleDeleteItemBidFailure(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Unable To Delete Item Bid. Please Try Again Later',
      duration: 5000,
      status: 'error'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleDeleteItemAskSuccess(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Item Ask Successfully Deleted',
      duration: 5000,
      status: 'success'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleDeleteItemAskFailure(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Unable To Delete Item Ask. Please Try Again Later',
      duration: 5000,
      status: 'error'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleUpdateItemBidSuccess(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Item Bid Successfully Updated',
      duration: 5000,
      status: 'success'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleUpdateItemBidFailure(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Unable To Update Item Bid. Please Try Again Later',
      duration: 5000,
      status: 'error'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleUpdateItemAskSuccess(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Item Ask Successfully Updated',
      duration: 5000,
      status: 'success'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleUpdateItemAskFailure(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Unable To Update Item Ask. Please Try Again Later',
      duration: 5000,
      status: 'error'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handlePlaceItemInquirySuccess(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Inquiry Submitted! Our team will reach out to you shortly via email!',
      duration: 5000,
      status: 'success'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handlePlaceItemInquiryFailure(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Unable To Place an Inquiry. Please Try Again Later',
      duration: 5000,
      status: 'error'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleRemoveItemFromPortfolioSuccess(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Item Removed From Portfolio',
      duration: 5000,
      status: 'success'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleRemoveItemFromPortfolioFailure(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Unable To Remove Item From Portfolio',
      duration: 5000,
      status: 'error'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleReplaceItemInPortfolioSuccess(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Item Replaced In Portfolio',
      duration: 5000,
      status: 'success'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleReplaceItemInPortfolioFailure(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Unable To Replace Item In Portfolio',
      duration: 5000,
      status: 'error'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleAddItemToWishlistSuccess(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Item Added To Wishlist',
      duration: 5000,
      status: 'success'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleAddItemToWishlistFailure(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Unable To Add Item To Wishlist',
      duration: 5000,
      status: 'error'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleRemoveItemFromWishlistSuccess(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Item Removed From Wishlist',
      duration: 5000,
      status: 'success'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleRemoveItemFromWishlistFailure(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Unable To Remove Item From Wishlist',
      duration: 5000,
      status: 'error'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleUserImageMigrationSuccess(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Avatar Updated',
      duration: 5000,
      status: 'success'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static hanldeUserImageMigrationFailure(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Unable to Update Avatar',
      duration: 5000,
      status: 'error'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleLogoutSuccess(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Logged Out',
      duration: 5000,
      status: 'success'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleLogoutFailure(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Unable to Logout',
      duration: 5000,
      status: 'error'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleChangePasswordSuccess(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Password Updated',
      duration: 5000,
      status: 'success'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleChangePasswordFailure(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Unable to Update Password',
      duration: 5000,
      status: 'error'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleLoginByEmailFailure(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Unable to Login',
      duration: 5000,
      status: 'error'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleRegisterUserFailure(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Unable to Register',
      duration: 5000,
      status: 'error'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleConfirmEmailSuccess(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Email confirmed',
      duration: 5000,
      status: 'success'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleConfirmEmailFailure(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Unable to confirm email',
      duration: 5000,
      status: 'error'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleSendConfirmationEmailSuccess(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Confirmation email successfully sent',
      duration: 5000,
      status: 'success'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleSendConfirmationEmailFailure(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Unable to send confirmation email',
      duration: 5000,
      status: 'error'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleSendForgotPasswordEmailSuccess(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Forgot Password Request Sent',
      duration: 5000,
      status: 'success'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleSendForgotPasswordEmailFailure(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Unable to Process Forgot Password Request',
      duration: 5000,
      status: 'error'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleItemDataSuggestionSubmissionSuccess(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Data Suggestion Submitted',
      duration: 5000,
      status: 'success'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleItemDataSuggestionSubmissionFailure(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Unable To Submit Data Suggestion',
      duration: 5000,
      status: 'error'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleListingDataSuggestionSubmissionSuccess(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Sale Suggestion Submitted',
      duration: 5000,
      status: 'success'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleListingDataSuggestionSubmissionFailure(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Unable To Submit Sale Suggestion',
      duration: 5000,
      status: 'error'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleItemSaleSuggestionSubmissionSuccess(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Sale Suggestion Submitted',
      duration: 5000,
      status: 'success'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleItemSaleSuggestionSubmissionFailure(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Unable To Submit Sale Suggestion',
      duration: 5000,
      status: 'error'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }


  public static handleItemListingGroupingSubmissionSuccess(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Sale Suggestion Submitted',
      duration: 5000,
      status: 'success'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleItemListingGroupingSubmissionFailure(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Unable To Submit Sale Suggestion',
      duration: 5000,
      status: 'error'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleChangeForgottenPasswordSuccess(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Password Updated',
      duration: 5000,
      status: 'success'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleChangeForgottenPasswordFailure(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Unable to Update Password',
      duration: 5000,
      status: 'error'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleJoinUserWaitlistSuccess(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Waitlist Joined',
      duration: 5000,
      status: 'success'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleJoinUserWaitlistFailure(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Unable to Join Waitlist. Try Again',
      duration: 5000,
      status: 'error'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleSendInboundContactRequestSuccess(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: "Message Sent! We'll be in touch shortly",
      duration: 5000,
      status: 'success'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleSendInboundContactRequestFailure(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Unable to Send Message. Try Again',
      duration: 5000,
      status: 'error'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleUpdateUserDataSuccess(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Information Updated',
      duration: 5000,
      status: 'success'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleUpdateUserDataFailure(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Unable to Update Information',
      duration: 5000,
      status: 'error'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleActivateSellingSuccess(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Activated! Complete Onboarding to Start Selling',
      duration: 5000,
      status: 'success'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleActivateSellingFailure(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Unable to Activate Selling. Try Again Later',
      duration: 5000,
      status: 'error'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleGenerateItemAskPaymentLinkFailue(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Unable to Checkout Right Now. Try Again Later',
      duration: 5000,
      status: 'error'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleAcceptItemBidSuccess(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Bid successfully accepted! We will notify you as soon as the buyer pays with further instructions.',
      duration: 5000,
      status: 'success'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleAcceptItemBidFailure(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Unable to Accept Item Bid! Please Try Again Later',
      duration: 5000,
      status: 'error'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleOrderAddressUpdateSuccess(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Order Address Updated Successfully!',
      duration: 5000,
      status: 'success'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleOrderAddressUpdateFailure(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Unable to Update Order Address! Please Try Again Later or Contact Us For Support',
      duration: 5000,
      status: 'error'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleUserAddressUpdateSuccess(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Address Updated Successfully!',
      duration: 5000,
      status: 'success'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }

  public static handleUserAddressUpdateFailure(state: PopupState): Loop<PopupState, PopupAction> {
    const toast: Toast = {
      id: Math.floor(Math.random() * 100),
      title: 'Unable to Update Adrress! Please Try Again Later or Contact Us For Support',
      duration: 5000,
      status: 'error'
    };

    return loop(
      state,
      Cmd.list([
        Cmd.action(PopupActions.addPopup(toast)),
      ])
    );
  }
}
