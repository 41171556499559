import {
  Box,
  Flex,
  Stack,
  Heading,
  Text,
  Image,
  Avatar,
  Spacer,
  Icon,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  useColorModeValue
} from '@chakra-ui/react';
import {
  BsFillArrowRightCircleFill,
  BsFillTrashFill
} from 'react-icons/bs';
import {
  FiEdit
} from 'react-icons/fi';
import { DetailedPortfolioItemModel } from 'models';
import GrowthBadge from '../../../Badge/GrowthBadge';
import {toDollarFormat } from 'utils/format';

export interface PortfolioItemCardProps {
  portfolioItem: DetailedPortfolioItemModel;
  useAvatar?: boolean;
  onEditIconClick?: () => void;
  onRemoveIconClick?: () => void;
  onSeeItemLinkClick?: () => void;
}

export enum PortfolioItemCardSizingConfig {
  CARD_BORDER_WIDTH = '2px',
  IMAGE_WIDTH = '80px',
  IMAGE_HEIGHT = '80px',
  PANEL_MIN_HEIGHT = '80px'
}

export enum PortfolioItemCardStylingConfig {
  SIDE_BORDER_COLOR_LIGHT = '#f1f3f7',
  SIDE_BORDER_COLOR_DARK = '#3f434c'
}


export default function PortfolioItemCard({ portfolioItem, useAvatar = false, onEditIconClick = () => { }, onRemoveIconClick = () => { }, onSeeItemLinkClick = () => { } }: PortfolioItemCardProps) {
  function renderItemImage() {
    const item = portfolioItem.item;
    if (useAvatar) {
      return <Avatar marginRight="16px" name={item.name} src={item.image} />
    } else {
      return <Image fit={'contain'} marginRight="16px" src={item.image} alt={item.name} width={PortfolioItemCardSizingConfig.IMAGE_HEIGHT} height={PortfolioItemCardSizingConfig.IMAGE_WIDTH} />;
    }
  }

  function renderPricingData() {
    const item = portfolioItem.item;
    if (item.hasEstimatedMarketPrice()) {
      return (
        <Flex direction="column" gap={2} alignItems="flex-end">
          <Heading fontSize="sm">{item.getEstimatedMarketPriceForDisplay()}</Heading>
          <GrowthBadge starting={portfolioItem.getMeanPurchasePrice()} ending={item.getEstimatedMarketPrice()} />
          <AccordionIcon />
        </Flex>
      );
    } else {
      return (
        <Flex direction="column" gap={2} alignItems="flex-end">
          <Heading fontSize="sm">No Market Data</Heading>
          <AccordionIcon />
        </Flex>
      );
    } 
  }


  function renderItemCard() {
    const item = portfolioItem.item;
    return (
      <Box
        minHeight={PortfolioItemCardSizingConfig.PANEL_MIN_HEIGHT}
        width="100%"
        overflow='hidden'
        cursor={'pointer'}
      >
        <Flex direction="row" alignItems="center" justifyContent="flex-start">
          {renderItemImage()}
          <Flex direction="column" textAlign="left" gap={2}>
            <Heading fontSize="sm">{item.name}</Heading>
            <Text fontSize="xs">{item.getArtistName()}</Text>
            <Heading fontSize="xs">Purchase Price: {portfolioItem.getTotalPurchasePriceForDisplay()}</Heading>
          </Flex>
          <Spacer />
          {renderPricingData()}
        </Flex>
      </Box>
    );
  }

  function renderItemPurchaseInfo() {
    return (
      <Stack>
        {portfolioItem.purchaseInfo.map((purchaseItem, index) => (
          <Flex key={`portfolioItem_${portfolioItem.item.id}_purchaseInfo_${index}`} direction={'row'} alignItems="center">
            <Heading fontSize="xs"> {purchaseItem.quantity} x {toDollarFormat(purchaseItem.price)}</Heading>
            <Spacer />
            <Text fontSize="xs">{purchaseItem.date?.format('MM/DD/YYYY')}</Text>
          </Flex>
        ))}
      </Stack>
    )
  }

  function renderItemPanel() {
    return (
      <Box minHeight={PortfolioItemCardSizingConfig.PANEL_MIN_HEIGHT} paddingTop={2}>
        <Stack gap={2}>
          <Flex direction={'row'} gap={2} alignItems={'center'}>
            <Heading fontSize="sm">Purchase History</Heading>
            <Icon as={FiEdit} w={4} h={4} cursor={'pointer'} _hover={{ color: "blue.500" }} onClick={onEditIconClick} />
            <Icon as={BsFillTrashFill} w={4} h={4} cursor={'pointer'} _hover={{ color: "red.500" }} onClick={onRemoveIconClick} />
            <Spacer />
            <Flex cursor={'pointer'} gap={1} alignItems={'center'} _hover={{ color: "blue.500" }} onClick={onSeeItemLinkClick}>
              <Heading fontSize="xs">See Item</Heading>
              <Icon as={BsFillArrowRightCircleFill} w={3} h={3} />
            </Flex>
          </Flex>
          {renderItemPurchaseInfo()}
        </Stack>
      </Box>
    )
  }

  return (
    <Accordion
      allowToggle
      borderLeft={PortfolioItemCardSizingConfig.CARD_BORDER_WIDTH}
      borderLeftColor={useColorModeValue(PortfolioItemCardStylingConfig.SIDE_BORDER_COLOR_LIGHT, PortfolioItemCardStylingConfig.SIDE_BORDER_COLOR_DARK)}
      borderRight={PortfolioItemCardSizingConfig.CARD_BORDER_WIDTH}
      borderRightColor={useColorModeValue(PortfolioItemCardStylingConfig.SIDE_BORDER_COLOR_LIGHT, PortfolioItemCardStylingConfig.SIDE_BORDER_COLOR_DARK)}
    >
      <AccordionItem>
        <AccordionButton>
          {renderItemCard()}
        </AccordionButton>
        <AccordionPanel
          borderTop={PortfolioItemCardSizingConfig.CARD_BORDER_WIDTH}
          borderTopColor={useColorModeValue(PortfolioItemCardStylingConfig.SIDE_BORDER_COLOR_LIGHT, PortfolioItemCardStylingConfig.SIDE_BORDER_COLOR_DARK)}
        >
          {renderItemPanel()}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
