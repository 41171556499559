import { Cmd, loop, Loop } from 'redux-loop';
import { ItemActions, ItemInternalActions } from './actions';
import { ItemState, ItemAction } from './types';
import { defaultState } from './consts';
import { ItemApiClient, ArtistApiClient, UserApiClient, MarketplaceApiClient } from 'api';
import { ItemModel, MarketModel, ListingModel, ArtistModel } from 'models';
import { ItemActiveMarketData } from 'models/Item/types';
import { CredentialService } from 'services';

export enum ItemStateErrors {
  ID_FETCH = 'Unable to fetch item',
  ID_LISTINGS_FETCH = 'Unable to fetch item listings',
  ID_MARKET_FETCH = 'Unable to fetch item market',
  ID_ACTIVE_MARKET_FETCH = 'Unable to fetch item bids and asks',
  ID_ARTIST_FETCH = 'Unable to fetch item artist',
  ID_SUGGESTIONS_FETCH = 'Unable to fetch item suggestions',
  SEARCH = 'Unable to search items',
  PLACE_BID = 'Unable to place item bid',
  PLACE_ASK = 'Unable to place item ask',
  DELETE_BID = 'Unable to delete item bid',
  DELETE_ASK = 'Unable to delete item ask',
  UPDATE_BID = 'Unable to update item bid',
  UPDATE_ASK = 'Unable to update item ask',
  PLACE_INQUIRY = 'Unable to place item inquiry',
  GENERATE_ASK_PAYMENT_LINK = 'Unable to generate ask payment link',
  ACCEPT_BID = 'Unable to accept item bid'
}

export class ItemHandlers {
  public static handleGetItemById(state: ItemState, action: ItemAction): Loop<ItemState, ItemAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        item: true
      }
    };

    return loop(
      newState,
      Cmd.run(ItemApiClient.getItemById, {
        args: [payload?.id || ''],
        successActionCreator: ItemInternalActions.getItemByIdSuccess,
        failActionCreator: ItemInternalActions.getItemByIdFailure,
      })
    );
  }

  public static handleGetItemByIdSuccess(state: ItemState, action: ItemAction): ItemState | Loop<ItemState, ItemAction> {
    const item: ItemModel = action.payload?.item || defaultState.item;

    const newState = {
      ...state,
      item,
      loading: {
        ...state.loading,
        item: false
      }
    };

    if (CredentialService.isAuthenticated()) {
      return loop(
        newState,
        Cmd.list([
          Cmd.action(ItemActions.getItemMarket(item.id)),
          Cmd.action(ItemActions.getItemActiveMarket(item.id))
        ])
      );
    } else {
      return newState;
    }
  }

  public static handleGetItemByIdFailure(state: ItemState): ItemState {
    return {
      ...state,
      error: new Error(ItemStateErrors.ID_FETCH),
      loading: {
        ...state.loading,
        item: false
      }
    };
  }

  public static handleGetItemListings(state: ItemState, action: ItemAction): Loop<ItemState, ItemAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        listings: true
      }
    };
    return loop(
      newState,
      Cmd.run(ItemApiClient.getItemListings, {
        args: [payload?.id || '', payload?.page || 0],
        successActionCreator: ItemInternalActions.getItemListingsSuccess,
        failActionCreator: ItemInternalActions.getItemListingsFailure,
      })
    );
  }

  public static handleGetItemListingsSuccess(state: ItemState, action: ItemAction): ItemState {
    const { item } = state;
    let listings: ListingModel[] = action.payload?.listings || [];
    const newItem = ItemModel.deepCopy(item);
    newItem.setListings(listings);
    return {
      ...state,
      item: newItem,
      loading: {
        ...state.loading,
        listings: false
      }
    };
  }

  public static handleGetItemListingsFailure(state: ItemState): ItemState {
    return {
      ...state,
      error: new Error(ItemStateErrors.ID_LISTINGS_FETCH),
      loading: {
        ...state.loading,
        listings: false
      }
    };
  }

  public static handleGetItemMarket(state: ItemState, action: ItemAction): Loop<ItemState, ItemAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        market: true
      }
    };
    return loop(
      newState,
      Cmd.run(ItemApiClient.getItemMarket, {
        args: [payload?.id || ''],
        successActionCreator: ItemInternalActions.getItemMarketSuccess,
        failActionCreator: ItemInternalActions.getItemMarketFailure,
      })
    );
  }

  public static handleGetItemMarketSuccess(state: ItemState, action: ItemAction): ItemState {
    const { item } = state;
    let market: MarketModel | undefined = action.payload?.market;
    const newItem = ItemModel.deepCopy(item);
    newItem.setItemMarket(market);
    return {
      ...state,
      item: newItem,
      loading: {
        ...state.loading,
        market: false
      }
    };
  }

  public static handleGetItemMarketFailure(state: ItemState): ItemState {
    return {
      ...state,
      error: new Error(ItemStateErrors.ID_MARKET_FETCH),
      loading: {
        ...state.loading,
        market: false
      }
    };
  }

  public static handleGetItemActiveMarket(state: ItemState, action: ItemAction): Loop<ItemState, ItemAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        activeMarket: true
      }
    };
    return loop(
      newState,
      Cmd.run(ItemApiClient.getItemActiveMarket, {
        args: [payload?.id || ''],
        successActionCreator: ItemInternalActions.getItemActiveMarketSuccess,
        failActionCreator: ItemInternalActions.getItemActiveMarketFailure,
      })
    );
  }

  public static handleGetItemActiveMarketSuccess(state: ItemState, action: ItemAction): ItemState {
    const { item } = state;
    let activeMarket: ItemActiveMarketData | undefined = action.payload?.activeMarket;
    activeMarket?.asks.forEach(ask => ask.setItem(item));
    activeMarket?.bids.forEach(bid => bid.setItem(item));
    const newItem = ItemModel.deepCopy(item);
    newItem.setItemActiveMarket(activeMarket);
    return {
      ...state,
      item: newItem,
      loading: {
        ...state.loading,
        activeMarket: false
      }
    };
  }

  public static handleGetItemActiveMarketFailure(state: ItemState): ItemState {
    return {
      ...state,
      error: new Error(ItemStateErrors.ID_ACTIVE_MARKET_FETCH),
      loading: {
        ...state.loading,
        activeMarket: false
      }
    };
  }

  public static handleGetItemArtist(state: ItemState, action: ItemAction): Loop<ItemState, ItemAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        artist: true
      }
    };
    return loop(
      newState,
      Cmd.run(ArtistApiClient.getArtistByItemId, {
        args: [payload?.id || ''],
        successActionCreator: ItemInternalActions.getItemArtistSuccess,
        failActionCreator: ItemInternalActions.getItemArtistFailure,
      })
    );
  }

  public static handleGetItemArtistSuccess(state: ItemState, action: ItemAction): ItemState {
    const { item } = state;
    let artist: ArtistModel | undefined = action.payload?.artist;
    const newItem = ItemModel.deepCopy(item);
    newItem.setArtist(artist);
    return {
      ...state,
      item: newItem,
      loading: {
        ...state.loading,
        artist: false
      }
    };
  }

  public static handleGetItemArtistFailure(state: ItemState): ItemState {
    return {
      ...state,
      error: new Error(ItemStateErrors.ID_ARTIST_FETCH),
      loading: {
        ...state.loading,
        artist: false
      }
    };
  }

  public static handleSearchItemByQuery(state: ItemState, action: ItemAction): Loop<ItemState, ItemAction> {
    const { payload } = action;
    const newState = {
      ...state,
      paginatedItems: defaultState.paginatedItems,
      loading: {
        ...state.loading,
        search: true,
      }
    };
    return loop(
      newState,
      Cmd.run(ItemApiClient.searchItemsByQuery, {
        args: [payload?.query || '', payload?.page || 1],
        successActionCreator: ItemInternalActions.searchItemByQuerySuccess,
        failActionCreator: ItemInternalActions.searchItemByQueryFailure,
      })
    );
  }

  public static handleSearchItemByQuerySuccess(state: ItemState, action: ItemAction): ItemState {
    const paginatedItems = action.payload?.paginatedItems || defaultState.paginatedItems;
    return {
      ...state,
      paginatedItems,
      loading: {
        ...state.loading,
        search: false,
      }
    };
  }

  public static handleSearchItemByQueryFailure(state: ItemState): ItemState {
    return {
      ...state,
      error: new Error(ItemStateErrors.SEARCH),
      loading: {
        ...state.loading,
        search: false,
      }
    };
  }


  public static handleSearchItem(state: ItemState, action: ItemAction): Loop<ItemState, ItemAction> {
    const { payload } = action;
    const newState = {
      ...state,
      paginatedItems: defaultState.paginatedItems,
      loading: {
        ...state.loading,
        search: true,
      }
    };
    return loop(
      newState,
      Cmd.run(ItemApiClient.searchItems, {
        args: [payload?.searchQuery],
        successActionCreator: ItemInternalActions.searchItemSuccess,
        failActionCreator: ItemInternalActions.searchItemFailure,
      })
    );
  }

  public static handleSearchItemSuccess(state: ItemState, action: ItemAction): ItemState {
    const paginatedItems = action.payload?.paginatedItems || defaultState.paginatedItems;
    return {
      ...state,
      paginatedItems,
      loading: {
        ...state.loading,
        search: false,
      }
    };
  }

  public static handleSearchItemFailure(state: ItemState): ItemState {
    return {
      ...state,
      error: new Error(ItemStateErrors.SEARCH),
      loading: {
        ...state.loading,
        search: false,
      }
    };
  }

  public static handleGetUserFollowingArtistsItems(state: ItemState, action: ItemAction): Loop<ItemState, ItemAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        search: true,
      }
    };
    return loop(
      newState,
      Cmd.run(UserApiClient.getFollowingArtistItems, {
        args: [payload?.page || 1],
        successActionCreator: ItemInternalActions.getUserFollowingArtistsItemsSuccess,
        failActionCreator: ItemInternalActions.getUserFollowingArtistsItemsFailure,
      })
    );
  }

  public static handleGetUserFollowingArtistsItemsSuccess(state: ItemState, action: ItemAction): ItemState {
    const paginatedItems = action.payload?.paginatedItems || defaultState.paginatedItems;
    return {
      ...state,
      paginatedItems,
      loading: {
        ...state.loading,
        search: false,
      }
    };
  }

  public static handleGetUserFollowingArtistsItemsFailure(state: ItemState): ItemState {
    return {
      ...state,
      error: new Error(ItemStateErrors.SEARCH),
      loading: {
        ...state.loading,
        search: false,
      }
    };
  }

  public static handleGetItemSuggestions(state: ItemState, action: ItemAction): Loop<ItemState, ItemAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        items: true
      }
    };
    return loop(
      newState,
      Cmd.run(ItemApiClient.getItemSuggestions, {
        args: [payload?.id || ''],
        successActionCreator: ItemInternalActions.getItemSuggestionsSuccess,
        failActionCreator: ItemInternalActions.getItemSuggestionsFailure,
      })
    );
  }

  public static handleGetItemSuggestionsSuccess(state: ItemState, action: ItemAction): ItemState {
    const items = action.payload?.items || [];
    return {
      ...state,
      items,
      loading: {
        ...state.loading,
        items: false
      }
    };
  }

  public static handleGetItemSuggestionsFailure(state: ItemState): ItemState {
    return {
      ...state,
      error: new Error(ItemStateErrors.ID_SUGGESTIONS_FETCH),
      loading: {
        ...state.loading,
        items: false
      }
    };
  }

  public static handleGetAllItems(state: ItemState, action: ItemAction): Loop<ItemState, ItemAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        search: true,
      }
    };
    return loop(
      newState,
      Cmd.run(ItemApiClient.getAllItems, {
        args: [payload?.page || 1],
        successActionCreator: ItemInternalActions.getAllItemsSuccess,
        failActionCreator: ItemInternalActions.getAllItemsFailure,
      })
    );
  }

  public static handleGetAllItemsSuccess(state: ItemState, action: ItemAction): ItemState {
    const paginatedItems = action.payload?.paginatedItems || defaultState.paginatedItems;
    return {
      ...state,
      paginatedItems,
      loading: {
        ...state.loading,
        search: false,
      }
    };
  }

  public static handleGetAllItemsFailure(state: ItemState): ItemState {
    return {
      ...state,
      error: new Error(ItemStateErrors.SEARCH),
      loading: {
        ...state.loading,
        search: false,
      }
    };
  }

  public static handlePlaceItemBid(state: ItemState, action: ItemAction): Loop<ItemState, ItemAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        activeMarketAction: true,
      }
    };
    return loop(
      newState,
      Cmd.run(ItemApiClient.placeItemBid, {
        args: [payload?.id || '', payload?.bidData || {}],
        successActionCreator: ItemInternalActions.placeItemBidSuccess,
        failActionCreator: ItemInternalActions.placeItemBidFailure,
      })
    );
  }

  public static handlePlaceItemBidSuccess(state: ItemState, action: ItemAction): ItemState | Loop<ItemState, ItemAction> {
    const itemId: string = action.payload?.id || '';

    const newState = {
      ...state,
      loading: {
        ...state.loading,
        activeMarketAction: false
      }
    };

    return loop(
      newState,
      Cmd.list([
        Cmd.action(ItemActions.getItemActiveMarket(itemId))
      ])
    );
  }

  public static handlePlaceItemBidFailure(state: ItemState): ItemState {
    return {
      ...state,
      error: new Error(ItemStateErrors.PLACE_BID),
      loading: {
        ...state.loading,
        activeMarketAction: false,
      }
    };
  }

  public static handlePlaceItemAsk(state: ItemState, action: ItemAction): Loop<ItemState, ItemAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        activeMarketAction: true,
      }
    };
    return loop(
      newState,
      Cmd.run(ItemApiClient.placeItemAsk, {
        args: [payload?.id || '', payload?.askData || {}],
        successActionCreator: ItemInternalActions.placeItemAskSuccess,
        failActionCreator: ItemInternalActions.placeItemAskFailure,
      })
    );
  }

  public static handlePlaceItemAskSuccess(state: ItemState, action: ItemAction): ItemState | Loop<ItemState, ItemAction> {
    const itemId: string = action.payload?.id || '';

    const newState = {
      ...state,
      loading: {
        ...state.loading,
        activeMarketAction: false
      }
    };

    return loop(
      newState,
      Cmd.list([
        Cmd.action(ItemActions.getItemActiveMarket(itemId))
      ])
    );
  }

  public static handlePlaceItemAskFailure(state: ItemState): ItemState {
    return {
      ...state,
      error: new Error(ItemStateErrors.PLACE_ASK),
      loading: {
        ...state.loading,
        activeMarketAction: false,
      }
    };
  }


  public static handleDeleteItemBid(state: ItemState, action: ItemAction): Loop<ItemState, ItemAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        activeMarketAction: true,
      }
    };
    return loop(
      newState,
      Cmd.run(ItemApiClient.deleteItemBid, {
        args: [payload?.itemBidId || '', payload?.itemId || ''],
        successActionCreator: ItemInternalActions.deleteItemBidSuccess,
        failActionCreator: ItemInternalActions.deleteItemBidFailure,
      })
    );
  }

  public static handleDeleteItemBidSuccess(state: ItemState, action: ItemAction): ItemState | Loop<ItemState, ItemAction> {
    const itemId: string = action.payload?.itemId || '';

    const newState = {
      ...state,
      loading: {
        ...state.loading,
        activeMarketAction: false
      }
    };

    return loop(
      newState,
      Cmd.list([
        Cmd.action(ItemActions.getItemActiveMarket(itemId))
      ])
    );
  }

  public static handleDeleteItemBidFailure(state: ItemState): ItemState {
    return {
      ...state,
      error: new Error(ItemStateErrors.DELETE_BID),
      loading: {
        ...state.loading,
        activeMarketAction: false,
      }
    };
  }

  public static handleDeleteItemAsk(state: ItemState, action: ItemAction): Loop<ItemState, ItemAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        activeMarketAction: true,
      }
    };
    return loop(
      newState,
      Cmd.run(ItemApiClient.deleteItemAsk, {
        args: [payload?.itemAskId || '', payload?.itemId || ''],
        successActionCreator: ItemInternalActions.deleteItemAskSuccess,
        failActionCreator: ItemInternalActions.deleteItemAskFailure,
      })
    );
  }

  public static handleDeleteItemAskSuccess(state: ItemState, action: ItemAction): ItemState | Loop<ItemState, ItemAction> {
    const itemId: string = action.payload?.itemId || '';

    const newState = {
      ...state,
      loading: {
        ...state.loading,
        activeMarketAction: false
      }
    };

    return loop(
      newState,
      Cmd.list([
        Cmd.action(ItemActions.getItemActiveMarket(itemId))
      ])
    );
  }

  public static handleDeleteItemAskFailure(state: ItemState): ItemState {
    return {
      ...state,
      error: new Error(ItemStateErrors.DELETE_ASK),
      loading: {
        ...state.loading,
        activeMarketAction: false,
      }
    };
  }


  public static handleUpdateItemBid(state: ItemState, action: ItemAction): Loop<ItemState, ItemAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        activeMarketAction: true,
      }
    };
    return loop(
      newState,
      Cmd.run(ItemApiClient.updateItemBid, {
        args: [payload?.itemBidId || '', payload?.itemId || '', payload?.bidData || {}],
        successActionCreator: ItemInternalActions.updateItemBidSuccess,
        failActionCreator: ItemInternalActions.updateItemBidFailure,
      })
    );
  }

  public static handleUpdateItemBidSuccess(state: ItemState, action: ItemAction): ItemState | Loop<ItemState, ItemAction> {
    const itemId: string = action.payload?.itemId || '';

    const newState = {
      ...state,
      loading: {
        ...state.loading,
        activeMarketAction: false
      }
    };

    return loop(
      newState,
      Cmd.list([
        Cmd.action(ItemActions.getItemActiveMarket(itemId))
      ])
    );
  }

  public static handleUpdateItemBidFailure(state: ItemState): ItemState {
    return {
      ...state,
      error: new Error(ItemStateErrors.UPDATE_BID),
      loading: {
        ...state.loading,
        activeMarketAction: false,
      }
    };
  }

  public static handleUpdateItemAsk(state: ItemState, action: ItemAction): Loop<ItemState, ItemAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        activeMarketAction: true,
      }
    };
    return loop(
      newState,
      Cmd.run(ItemApiClient.updateItemAsk, {
        args: [payload?.itemAskId || '', payload?.itemId || '', payload?.askData || {}],
        successActionCreator: ItemInternalActions.updateItemAskSuccess,
        failActionCreator: ItemInternalActions.updateItemAskFailure,
      })
    );
  }

  public static handleUpdateItemAskSuccess(state: ItemState, action: ItemAction): ItemState | Loop<ItemState, ItemAction> {
    const itemId: string = action.payload?.itemId || '';

    const newState = {
      ...state,
      loading: {
        ...state.loading,
        activeMarketAction: false
      }
    };

    return loop(
      newState,
      Cmd.list([
        Cmd.action(ItemActions.getItemActiveMarket(itemId))
      ])
    );
  }

  public static handleUpdateItemAskFailure(state: ItemState): ItemState {
    return {
      ...state,
      error: new Error(ItemStateErrors.UPDATE_ASK),
      loading: {
        ...state.loading,
        activeMarketAction: false,
      }
    };
  }

  public static handlePlaceItemInquiry(state: ItemState, action: ItemAction): Loop<ItemState, ItemAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        inquiryAction: true,
      }
    };
    return loop(
      newState,
      Cmd.run(ItemApiClient.placeItemInquiry, {
        args: [payload?.id || '', payload?.inquiryData || {}],
        successActionCreator: ItemInternalActions.placeItemInquirySuccess,
        failActionCreator: ItemInternalActions.placeItemInquiryFailure,
      })
    );
  }

  public static handlePlaceItemInquirySuccess(state: ItemState): ItemState {
    return {
      ...state,
      loading: {
        ...state.loading,
        inquiryAction: false
      }
    };
  }

  public static handlePlaceItemInquiryFailure(state: ItemState): ItemState {
    return {
      ...state,
      error: new Error(ItemStateErrors.PLACE_INQUIRY),
      loading: {
        ...state.loading,
        inquiryAction: false,
      }
    };
  }

  public static handleGenerateItemAskPaymentLink(state: ItemState, action: ItemAction): Loop<ItemState, ItemAction> {
    const { payload } = action;
    const newState = {
      ...state,
      
      loading: {
        ...state.loading,
        marketplaceAction: true,
      }
    };

    return loop(
      newState,
      Cmd.run(MarketplaceApiClient.generateItemPaymentLink, {
        args: [payload?.itemAskId || '', payload?.useEscrow || false],
        successActionCreator: (result: { itemAskId: string; paymentLink: string }) =>
          ItemInternalActions.generateItemAskPaymentLinkSuccess(result.itemAskId, result.paymentLink),
        failActionCreator: ItemInternalActions.generateItemAskPaymentLinkFailure,
      })
    );
  }

  public static handleGenerateItemAskPaymentLinkSuccess(state: ItemState, action: ItemAction): ItemState {
    const { payload } = action;
    const { item } = state;
    const newItem = ItemModel.deepCopy(item);
    newItem.setItemAskPaymentLink(payload?.itemAskId, payload?.paymentLink);

    return {
      ...state,
      item: newItem,
      loading: {
        ...state.loading,
        marketplaceAction: false
      }
    };
  }

  public static handleGenerateItemAskPaymentLinkFailure(state: ItemState): ItemState {
    return {
      ...state,
      error: new Error(ItemStateErrors.GENERATE_ASK_PAYMENT_LINK),
      loading: {
        ...state.loading,
        marketplaceAction: false,
      }
    };
  }

  public static handleAcceptItemBid(state: ItemState, action: ItemAction): Loop<ItemState, ItemAction> {
    const { payload } = action;
    const newState = {
      ...state,
      
      loading: {
        ...state.loading,
        marketplaceAction: true,
      }
    };

    return loop(
      newState,
      Cmd.run(MarketplaceApiClient.acceptItemBid, {
        args: [payload?.itemBidId || '', payload?.useEscrow || false],
        successActionCreator: ItemInternalActions.acceptItemBidSuccess,
        failActionCreator: ItemInternalActions.acceptItemBidFailure,
      })
    );
  }

  public static handleAcceptItemBidSuccess(state: ItemState): ItemState {
    return {
      ...state,
      loading: {
        ...state.loading,
        marketplaceAction: false
      }
    };
  }

  public static handleAcceptItemBidFailure(state: ItemState): ItemState {
    return {
      ...state,
      error: new Error(ItemStateErrors.ACCEPT_BID),
      loading: {
        ...state.loading,
        marketplaceAction: false,
      }
    };
  }
}
