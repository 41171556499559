import { AppConfig } from 'config';
import { ItemModel, ArtistModel } from 'models';
import { NavigationService } from 'services';

export function generateSEOTitle(key: string): string {
    return `${AppConfig.APP_NAME} | ${key}`;
}

export function generateSEOLink(path: string): string {
    return AppConfig.BASE_URL + path;
}

export function generateItemSEOLink(item: ItemModel) {
    return generateSEOLink(NavigationService.getItemDetailsPath(item.id, item.getDisplayName()));
}

export function generateArtistSEOLink(artist: ArtistModel) {
    return generateSEOLink(NavigationService.getArtistDetailsPath(artist.id, artist.name));
}

export function createItemStructuredData(item: ItemModel) {
  return {
    "@context": "https://schema.org",
    "@type": "Product",
    "name": item.getDisplayName(),
    "description": item.description,
    "image": item.image,
    "brand": {
      "@type": "Brand",
      "name": item.getArtistName()
    },
    "offers": {
      "@type": "Offer",
      "priceCurrency": "USD",
      "price": item.getEstimatedMarketPrice().toString(),
      "availability": "https://schema.org/InStock",
      "url": generateItemSEOLink(item)
    }
  };
};

export function createArtistStructuredData(artist: ArtistModel) {
  return {
    "@context": "https://schema.org",
    "@type": "Person",
    "name": artist.name,
    "description": artist.description,
    "image": artist.getImageUrl(),
    "url": generateArtistSEOLink(artist)
  };
};