import { Component } from 'react';
import { connect, Dispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { RouterProps, withRouter } from "utils/route";
import { generateSEOTitle } from 'utils/seo';
import { ApplicationState } from 'reducers/types';
import { Button, Flex, Heading, Box, Text } from '@chakra-ui/react';
import { Logo, SEOHelmet } from 'components';
import { NavigationService } from 'services';
import { FiArrowRight } from 'react-icons/fi';
import { AppConstants } from '../../constants';


interface NotFoundViewProps extends RouterProps { }

class NotFound extends Component<NotFoundViewProps, {}> {

  handleGoHome = () => {
    const { navigate } = this.props;
    navigate(NavigationService.getHomePath());
  };

  render() {
    return (
      <>
        <SEOHelmet
          title={generateSEOTitle('Error')}
          description={`404 - Page Not Found. Sorry, the page you are looking for doesn’t exist.`}
        />
        <Box maxWidth={`${AppConstants.GRIDPAGE_WIDTH}px`} paddingTop={['80px', '100px', '100px']} justifySelf="center" minWidth={['100%', `${AppConstants.GRIDPAGE_WIDTH}px`]}>
          <Flex direction="column" alignItems="center" justifyContent="center" gap={10}>
            <Logo height={'120px'} width={'120px'} />
            <Box>
              <Heading as="h1" size="xl">404 - Page Not Found</Heading>
              <Text fontSize="lg">Sorry, the page you are looking for doesn’t exist.</Text>
            </Box>

            <Button
              colorScheme={'orange'}
              borderRadius="8px"
              py="4"
              px="4"
              lineHeight="1"
              size="lg"
              onClick={this.handleGoHome}
              rightIcon={<FiArrowRight />}
            >
              Return Home
            </Button>
          </Flex>
        </Box>
      </>
    );
  }
}

function mapStateToProps(state: ApplicationState) {
  return {};
}

function mapDispatchToProps(dispatch: Dispatch<ApplicationState>) {
  return bindActionCreators(
    {},
    dispatch
  );
}

export const NotFoundView = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NotFound));
