import { useState } from 'react';
import { Box, Button, Flex, ButtonGroup, Tooltip, Spacer } from '@chakra-ui/react';
import SearchBar from '../SearchBar/SearchBar';


export interface TradingFloorFiltersProps {
  showSearch?: boolean;
  onSearchChange?: (query: string) => void;
  onFilterChange?: (filter: { showBids: boolean; showAsks: boolean }) => void;
}

export default function TradingFloorFilters({
  showSearch = true,
  onSearchChange = () => {},
  onFilterChange = () => {},
}: TradingFloorFiltersProps) {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [showBids, setShowBids] = useState<boolean>(false);
  const [showAsks, setShowAsks] = useState<boolean>(false);

  const handleSearchChange = (query: string) => {
    if (query != searchQuery) {
      setSearchQuery(query);
      onSearchChange(query);
    }
  };

  const handleBidsToggle = () => {
    const newShowBids = !showBids;
    setShowBids(newShowBids);
    onFilterChange({ showBids: newShowBids, showAsks });
  };

  const handleAsksToggle = () => {
    const newShowAsks = !showAsks;
    setShowAsks(newShowAsks);
    onFilterChange({ showBids, showAsks: newShowAsks });
  };

  return (
    <Flex direction="row" gap={4} mb={4} alignItems="center">
      {showSearch && (
        <Box>
          <SearchBar
            defaultValue={searchQuery} 
            onChange={handleSearchChange} 
            inputPlaceholder={'Search bids or asks...'} 
          />
        </Box>
      )}
			<Spacer />
      <Flex direction={'row'} gap={2}>
        <Tooltip label="Show or Hide Bids">
          <Button
            colorScheme={showBids ? 'blue' : 'gray'}
            onClick={handleBidsToggle}
          >
            Bids
          </Button>
        </Tooltip>
        <Tooltip label="Show or Hide Asks">
          <Button
            colorScheme={showAsks ? 'orange' : 'gray'}
            onClick={handleAsksToggle}
          >
            Asks
          </Button>
        </Tooltip>
      </Flex>
    </Flex>
  );
}
