import { useState } from 'react';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItemOption,
  MenuOptionGroup,
  Button,
  Icon,
  Text,
  Flex
} from '@chakra-ui/react';
import { MdDateRange } from 'react-icons/md';

interface DurationDropdownProps {
  durations: number[];
  onSelect: (duration: number) => void;
  selectedDuration?: number;
  disabled?: boolean;
}

export default function DurationDropdown({
  durations = [30, 60, 90],
  onSelect,
  selectedDuration = 0,
  disabled = false,
}: DurationDropdownProps) {
  const [selected, setSelected] = useState<number>(selectedDuration);

  const handleDurationChange = (newDuration: string | string[]) => {
    const duration = Array.isArray(newDuration) ? parseInt(newDuration[0]) : parseInt(newDuration);
    setSelected(duration);
    onSelect(duration);
  };

  function renderTitle() {
    return (
      <Text>
        {selected ? `${selected} days` : 'Select Duration'}
      </Text>
    );
  }

  const menuItems = durations.map((duration, index) => (
    <MenuItemOption key={`DurationDropdown_${index}`} value={duration.toString()}>
      {duration} days
    </MenuItemOption>
  ));

  return (
    <Menu closeOnSelect={true}>
      <MenuButton
        as={Button}
        disabled={disabled}
        variant={'outline'}
        width={'100%'}
        textAlign={'left'}
        fontSize={'sm'}
        fontWeight={300}
        paddingLeft={3}
      >
        <Flex display={'flex'} flexDirection={'row'} gap={3}>
          <Icon w={4} h={4} color={'gray.500'} as={MdDateRange} />
          {renderTitle()}
        </Flex>
      </MenuButton>
      <MenuList minWidth="240px" zIndex={200} fontSize={'sm'}>
        <MenuOptionGroup
          title="Duration"
          type="radio"
          value={selected.toString()}
          onChange={handleDurationChange}
        >
          {menuItems}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
}
