import { ActiveMarketActionTypes, ActiveMarketAction } from './types';
import { ItemActiveMarketData } from 'models/Item/types';

export class ActiveMarketActions {
  public static getAllActiveMarket(): ActiveMarketAction {
    return {
      type: ActiveMarketActionTypes.GET_ALL_ACTIVE_MARKET
    };
  }
}

export class ActiveMarketInternalActions {
  public static getAllActiveMarketsSuccess(activeMarket: ItemActiveMarketData): ActiveMarketAction {
    return {
      type: ActiveMarketActionTypes.GET_ALL_ACTIVE_MARKET_SUCCESS,
      payload: { activeMarket },
    };
  }

  public static getAllActiveMarketsFailure(): ActiveMarketAction {
    return { type: ActiveMarketActionTypes.GET_ALL_ACTIVE_MARKET_FAILURE };
  }

}
