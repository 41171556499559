import { Component } from 'react';
import { connect, Dispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { RouterProps, withRouter } from "utils/route";
import { ApplicationState } from 'reducers/types';
import { generateSEOTitle } from 'utils/seo';
import { UserSelectors } from 'reducers/User/selectors';
import { AuthSelectors } from 'reducers/Auth/selectors';
import { UserActions } from 'reducers/User/actions';
import {
  Box,
  Flex,
  Text,
  Stack
} from '@chakra-ui/react';
import { UserActiveMarketBadge, TradingFloor, Modal, ItemAskRemovalForm, ItemBidRemovalForm, ItemBidEditWizard, ItemAskEditWizard, SEOHelmet } from 'components';
import { UserModel, ItemBidModel, ItemAskModel } from 'models';
import { NavigationService } from 'services';
import { AppConstants } from '../../constants';
import { QuerySearchParameters } from 'constants/url';
import { ItemBidFormData } from 'components/Form/ItemBidForm/ItemBidForm';
import { ItemAskFormData } from 'components/Form/ItemAskForm/ItemAskForm';
import { JSONObject } from 'models/Api/types';
import { getEligibleQueryParamsFromUrl } from 'utils/url';
import { ItemBidAdditionalDataFormData } from 'components/Form/ItemBidAdditionalDataForm/ItemBidAdditionalDataForm';
import { ItemAskAdditionalDataFormData } from 'components/Form/ItemAskAdditionalDataForm/ItemAskAdditionalDataForm';


interface UserActiveMarketViewProps extends RouterProps {
  user: UserModel;
  userLoading: boolean;
  userActiveMarketLoading: boolean;
  userActiveMarketActionLoading: boolean;
  getActiveMarket: () => void;
  deleteItemBid: (itemBidId: string, itemId: string) => void;
  deleteItemAsk: (itemAskId: string, itemId: string) => void;
  updateItemBid: (itemBidId: string, itemId: string, bidData: JSONObject) => void;
  updateItemAsk: (itemAskId: string, itemId: string, askData: JSONObject) => void;
}

interface UserActiveMarketViewModalState {
  removeItemBid: ItemBidModel | undefined;
  removeItemAsk: ItemAskModel | undefined;
  editItemBid: ItemBidModel | undefined;
  editItemAsk: ItemAskModel | undefined;
}

interface UserActiveMarketViewState {
  modals: UserActiveMarketViewModalState;
  pendingQueryParams: JSONObject | null;
}

class UserActiveMarket extends Component<UserActiveMarketViewProps, UserActiveMarketViewState> {
  state: UserActiveMarketViewState = {
    modals: {
      removeItemBid: undefined,
      removeItemAsk: undefined,
      editItemBid: undefined,
      editItemAsk: undefined
    },
    pendingQueryParams: null
  };

  componentDidMount() {
    const { user, getActiveMarket, location } = this.props;
    const queryParams: JSONObject = getEligibleQueryParamsFromUrl(location, [QuerySearchParameters.BID_ID, QuerySearchParameters.ASK_ID, QuerySearchParameters.ACTION]);
    if (user.isAuthenticated()) {
      getActiveMarket();
    }
    if (queryParams) {
      this.setState({ pendingQueryParams: queryParams });
    }
  }

  componentDidUpdate(prevProps: UserActiveMarketViewProps) {
    const { user, getActiveMarket } = this.props;
    if (user.isAuthenticated() && !prevProps.user.isAuthenticated()) {
      getActiveMarket();
    }

    if (this.state.pendingQueryParams && user.hasActiveMarketData()) {
      this.processActiveMarketQueryParams();
    }
  }

  processActiveMarketQueryParams() {
    const { pendingQueryParams } = this.state;
    const { user } = this.props;

    if (!pendingQueryParams) return;

    const { bidId, askId, action } = pendingQueryParams;

    const matchingBid = user.getBids().find((bid: ItemBidModel) => bid.id === bidId);
    const matchingAsk = user.getAsks().find((ask: ItemAskModel) => ask.id === askId);

    if (matchingBid && action === 'Edit') {
      this.onEditItemBidModalOpen(matchingBid);
    } else if (matchingBid && action === 'Remove') {
      this.onRemoveItemBidModalOpen(matchingBid);
    } else if (matchingAsk && action === 'Edit') {
      this.onEditItemAskModalOpen(matchingAsk);
    } else if (matchingAsk && action === 'Remove') {
      this.onRemoveItemAskModalOpen(matchingAsk);
    }

    this.setState({ pendingQueryParams: null });
  }

  onRemoveItemBidModalOpen = (bid: ItemBidModel) => {
    const { user, navigate } = this.props;
    if (!user.isAuthenticated()) {
      navigate(
        NavigationService.getAuthLoginPath()
      );
    } else {
      this.setState({
        modals: {
          ...this.state.modals,
          removeItemBid: bid
        }
      });
    }
  }

  onRemoveItemAskModalOpen = (ask: ItemAskModel) => {
    const { user, navigate } = this.props;
    if (!user.isAuthenticated()) {
      navigate(
        NavigationService.getAuthLoginPath()
      );
    } else {
      this.setState({
        modals: {
          ...this.state.modals,
          removeItemAsk: ask
        }
      });
    }
  }

  onRemoveItemBidModalClose = () => {
    this.setState({
      modals: {
        ...this.state.modals,
        removeItemBid: undefined
      }
    });
  }

  onRemoveItemAskModalClose = () => {
    this.setState({
      modals: {
        ...this.state.modals,
        removeItemAsk: undefined
      }
    });
  }

  onEditItemBidModalOpen = (bid: ItemBidModel) => {
    const { user, navigate } = this.props;
    if (!user.isAuthenticated()) {
      navigate(
        NavigationService.getAuthLoginPath()
      );
    } else {
      this.setState({
        modals: {
          ...this.state.modals,
          editItemBid: bid
        }
      });
    }
  }

  onEditItemAskModalOpen = (ask: ItemAskModel) => {
    const { user, navigate } = this.props;
    if (!user.isAuthenticated()) {
      navigate(
        NavigationService.getAuthLoginPath()
      );
    } else {
      this.setState({
        modals: {
          ...this.state.modals,
          editItemAsk: ask
        }
      });
    }
  }

  onEditItemBidModalClose = () => {
    this.setState({
      modals: {
        ...this.state.modals,
        editItemBid: undefined
      }
    });
  }

  onEditItemAskModalClose = () => {
    this.setState({
      modals: {
        ...this.state.modals,
        editItemAsk: undefined
      }
    });
  }

  onItemNameClick = (itemId: string, itemName: string) => {
    this.props.navigate(NavigationService.getItemDetailsPath(itemId, itemName));
  }

  onArtistNameClick = (artistId: string, artistName: string) => {
    this.props.navigate(NavigationService.getArtistDetailsPath(artistId, artistName));
  }

  onRemoveItemBidFormSubmit = (itemBid: ItemBidModel) => {
    const { deleteItemBid } = this.props;
    deleteItemBid(itemBid.id, itemBid.itemId);
    this.onRemoveItemBidModalClose();
  }

  onRemoveItemAskFormSubmit = (itemAsk: ItemAskModel) => {
    const { deleteItemAsk } = this.props;
    deleteItemAsk(itemAsk.id, itemAsk.itemId);
    this.onRemoveItemAskModalClose();
  }

  onEditItemBidWizardSubmit = (itemBid: ItemBidModel, data?: ItemBidFormData, additionalData?: ItemBidAdditionalDataFormData, useEscrow?: boolean) => {
    const { updateItemBid } = this.props;
    const bidData: JSONObject = {};
    if (data?.bidPrice && data.bidPrice > 0) {
      bidData.amount = data.bidPrice;
    }
    if (data?.bidExpiration && data.bidExpiration > 0) {
      bidData.expiration_days = data.bidExpiration;
    }
    if (additionalData?.condition) {
      bidData.condition = additionalData.condition;
    }

    if (additionalData?.notes) {
      bidData.notes = additionalData.notes;
    }

    if (useEscrow) {
      bidData.use_escrow = true;
    } else if (!useEscrow && itemBid.useEscrow) {
      bidData.use_escrow = false;
    }

    updateItemBid(itemBid.id, itemBid.itemId, bidData);
    this.onEditItemBidModalClose();
  }

  onEditItemAskWizardSubmit = (itemAsk: ItemAskModel, data?: ItemAskFormData, additionalData?: ItemAskAdditionalDataFormData, useEscrow?: boolean) => {
    const { updateItemAsk } = this.props;
    const askData: JSONObject = {};
    if (data?.askPrice && data.askPrice > 0) {
      askData.amount = data.askPrice;
    }
    if (data?.askExpiration && data.askExpiration > 0) {
      askData.expiration_days = data.askExpiration;
    }
    if (additionalData?.condition) {
      askData.condition = additionalData.condition;
    }

    if (additionalData?.notes) {
      askData.notes = additionalData.notes;
    }

    if (useEscrow) {
      askData.use_escrow = true;
    } else if (!useEscrow && itemAsk.useEscrow) {
      askData.use_escrow = false;
    }
    
    updateItemAsk(itemAsk.id, itemAsk.itemId, askData);
    this.onEditItemAskModalClose();
  }

  renderProfileSectionHeader(header: string) {
    return (
      <Flex p={{ base: '12px 20px', md: '12px 0px' }} mb='12px'>
        <Text fontSize='lg' fontWeight='bold'>
          {header}
        </Text>
      </Flex>
    );
  }

  renderTradingFloor() {
    const { user, userLoading, userActiveMarketLoading, userActiveMarketActionLoading } = this.props;
    return (
      <Box padding={{ base: "10px", md: "0px" }} marginTop={8}>
        <TradingFloor
          asks={user.activeMarket.asks}
          bids={user.activeMarket.bids}
          user={user}
          showSearchFilter={true}
          onItemNameClick={this.onItemNameClick}
          onItemArtistNameClick={this.onArtistNameClick}
          isLoading={userLoading || userActiveMarketLoading || userActiveMarketActionLoading}
          onRemoveBidClick={this.onRemoveItemBidModalOpen}
          onRemoveAskClick={this.onRemoveItemAskModalOpen}
          onEditBidClick={this.onEditItemBidModalOpen}
          onEditAskClick={this.onEditItemAskModalOpen}
        />
      </Box>
    );
  }

  renderContent() {
    const { user, userLoading, userActiveMarketLoading, userActiveMarketActionLoading } = this.props;
    return (
      <Box>
        <UserActiveMarketBadge user={user} userLoading={userLoading} dataLoading={userLoading || userActiveMarketLoading || userActiveMarketActionLoading} />
        {this.renderTradingFloor()}
      </Box>
    );
  }

  renderRemoveItemBidModalContent() {
    const { user, userActiveMarketLoading, userActiveMarketActionLoading } = this.props;
    const { modals } = this.state;
    if (modals.removeItemBid && modals.removeItemBid.item) {
      return (
        <ItemBidRemovalForm user={user} item={modals.removeItemBid.item} bid={modals.removeItemBid} onSubmit={this.onRemoveItemBidFormSubmit} isLoading={userActiveMarketLoading || userActiveMarketActionLoading} />
      );
    } else {
      return <Box />;
    }
  }

  renderRemoveItemAskModalContent() {
    const { userActiveMarketLoading, userActiveMarketActionLoading, user } = this.props;
    const { modals } = this.state;
    if (modals.removeItemAsk && modals.removeItemAsk.item) {
      return (
        <ItemAskRemovalForm user={user} item={modals.removeItemAsk.item} ask={modals.removeItemAsk} onSubmit={this.onRemoveItemAskFormSubmit} isLoading={userActiveMarketLoading || userActiveMarketActionLoading} />
      );
    } else {
      return <Box />;
    }
  }

  renderEditItemBidModalContent() {
    const { user, userActiveMarketLoading, userActiveMarketActionLoading } = this.props;
    const { modals } = this.state;
    if (modals.editItemBid && modals.editItemBid.item) {
      return (
        <ItemBidEditWizard user={user} item={modals.editItemBid.item} itemBid={modals.editItemBid} onSubmit={this.onEditItemBidWizardSubmit} isLoading={userActiveMarketLoading || userActiveMarketActionLoading} />
      );
    } else {
      return <Box />;
    }
  }

  renderEditItemAskModalContent() {
    const { userActiveMarketLoading, userActiveMarketActionLoading, user } = this.props;
    const { modals } = this.state;
    if (modals.editItemAsk && modals.editItemAsk.item) {
      return (
        <ItemAskEditWizard user={user} item={modals.editItemAsk.item} itemAsk={modals.editItemAsk} onSubmit={this.onEditItemAskWizardSubmit} isLoading={userActiveMarketLoading || userActiveMarketActionLoading} />
      );
    } else {
      return <Box />;
    }
  }

  renderModals() {
    const { modals } = this.state;
    return (
      <Stack>
        <Modal title={'Remove Bid'} isOpen={!!modals.removeItemBid} onClose={this.onRemoveItemBidModalClose} content={this.renderRemoveItemBidModalContent()} showCloseButton={false} showSubmitButton={false} />
        <Modal title={'Remove Ask'} isOpen={!!modals.removeItemAsk} onClose={this.onRemoveItemAskModalClose} content={this.renderRemoveItemAskModalContent()} showCloseButton={false} showSubmitButton={false} />
        <Modal title={'Edit Bid'} isOpen={!!modals.editItemBid} onClose={this.onEditItemBidModalClose} content={this.renderEditItemBidModalContent()} showCloseButton={false} showSubmitButton={false} />
        <Modal title={'Edit Ask'} isOpen={!!modals.editItemAsk} onClose={this.onEditItemAskModalClose} content={this.renderEditItemAskModalContent()} showCloseButton={false} showSubmitButton={false} />
      </Stack>
    );
  }

  render() {
    return (
      <>
        <SEOHelmet
          title={generateSEOTitle('Active Market')}
          description={`View and update your active asks and bids.`}
        />
        <Box maxWidth={`${AppConstants.GRIDPAGE_WIDTH}px`} paddingTop={['80px', '100px', '100px']} justifySelf="center" minWidth={['100%', `${AppConstants.GRIDPAGE_WIDTH}px`]}>
          {this.renderContent()}
          {this.renderModals()}
        </Box>
      </>
    );
  }
}

function mapStateToProps(state: ApplicationState) {
  return {
    user: UserSelectors.getUser(state),
    userLoading: UserSelectors.getUserLoading(state) || AuthSelectors.getUserLoading(state),
    userActiveMarketLoading: UserSelectors.getUserActiveMarketLoading(state),
    userActiveMarketActionLoading: UserSelectors.getUserActiveMarkeActionLoading(state)
  };
}

function mapDispatchToProps(dispatch: Dispatch<ApplicationState>) {
  return bindActionCreators(
    {
      getActiveMarket: () => UserActions.getUserActiveMarket(),
      deleteItemBid: (itemBidId: string, itemId: string) => UserActions.deleteItemBid(itemBidId, itemId),
      deleteItemAsk: (itemAskId: string, itemId: string) => UserActions.deleteItemAsk(itemAskId, itemId),
      updateItemBid: (itemBidId: string, itemId: string, bidData: JSONObject) => UserActions.updateItemBid(itemBidId, itemId, bidData),
      updateItemAsk: (itemAskId: string, itemId: string, askData: JSONObject) => UserActions.updateItemAsk(itemAskId, itemId, askData)
    },
    dispatch
  );
}

export const UserActiveMarketView = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserActiveMarket));
