import { useForm, Controller } from 'react-hook-form';
import { Stack, FormControl, FormLabel, Input } from '@chakra-ui/react';
import CountryInput from '../../Input/CountryInput/CountryInput';
import { RawAddress } from 'models/Address/Address';
import { AddressModel } from 'models';

export type AddressFormOption = 'line1' | 'line2' | 'city' | 'state' | 'postal_code' | 'country';

export interface AddressFormData {
  line1: string;
  line2: string;
  city: string;
  state: string;
  postal_code: string;
  country: string;
}

export interface AddressFormProps {
  defaultAddress?: AddressModel;
  validated?: boolean;
  onChange?: (address: AddressModel) => void;
  disabled?: boolean;
  displayOptions?: AddressFormOption[];
  requiredOptions?: AddressFormOption[];
  disabledOptions?: AddressFormOption[];
}

export default function AddressForm({
  defaultAddress,
  validated = false,
  onChange = () => {},
  disabled = false,
  displayOptions,
  requiredOptions = [],
  disabledOptions = [],
}: AddressFormProps) {
  const defaultValues: AddressFormData = {
    line1: defaultAddress?.line1 || '',
    line2: defaultAddress?.line2 || '',
    city: defaultAddress?.city || '',
    state: defaultAddress?.state || '',
    postal_code: defaultAddress?.postalCode || '',
    country: defaultAddress?.country || '',
  };

  const { control, getValues } = useForm<AddressFormData>({ defaultValues });

  const formDisplayOptions: Record<AddressFormOption, boolean> = (displayOptions || ['line1', 'line2', 'city', 'state', 'postal_code', 'country']).reduce((acc, curr) => {
    acc[curr] = true;
    return acc;
  }, {} as Record<AddressFormOption, boolean>);

  const formRequiredOptions: Record<AddressFormOption, boolean> = (requiredOptions || []).reduce((acc, curr) => {
    acc[curr] = true;
    return acc;
  }, {} as Record<AddressFormOption, boolean>);

  const formDisabledOptions: Record<AddressFormOption, boolean> = (disabledOptions || []).reduce((acc, curr) => {
    acc[curr] = true;
    return acc;
  }, {} as Record<AddressFormOption, boolean>);

  const handleFieldChange = (fieldOnChange: (value: any) => void) => (e: any) => {
    fieldOnChange(e);
    const values = getValues();
    const raw: RawAddress = {
      line1: values.line1,
      line2: values.line2,
      city: values.city,
      state: values.state,
      postal_code: values.postal_code,
      country: values.country,
    };
    onChange(new AddressModel(raw));
  };

  const disabledStyles = { bg: 'gray.100', color: 'gray.500', cursor: 'not-allowed' };

  return (
    <Stack spacing={4}>
      {formDisplayOptions['line1'] && (
        <FormControl 
          isDisabled={validated || disabled || formDisabledOptions['line1']} 
          isRequired={formRequiredOptions['line1']}
        >
          <FormLabel fontSize="sm">Address Line 1</FormLabel>
          <Controller
            control={control}
            name="line1"
            rules={{ required: formRequiredOptions['line1'] ? 'Address Line 1 is required' : false }}
            render={({ field, fieldState }) => (
              <Input
                {...field}
                fontSize="sm"
                placeholder="Address Line 1"
                disabled={validated || disabled || formDisabledOptions['line1']}
                _disabled={disabledStyles}
                onChange={handleFieldChange(field.onChange)}
              />
            )}
          />
        </FormControl>
      )}

      {formDisplayOptions['line2'] && (
        <FormControl isDisabled={disabled || formDisabledOptions['line2']}>
          <FormLabel fontSize="sm">Address Line 2</FormLabel>
          <Controller
            control={control}
            name="line2"
            render={({ field }) => (
              <Input
                {...field}
                fontSize="sm"
                placeholder="Apt, Suite, etc (optional)"
                onChange={handleFieldChange(field.onChange)}
              />
            )}
          />
        </FormControl>
      )}

      {formDisplayOptions['city'] && (
        <FormControl 
          isDisabled={validated || disabled || formDisabledOptions['city']} 
          isRequired={formRequiredOptions['city']}
        >
          <FormLabel fontSize="sm">City</FormLabel>
          <Controller
            control={control}
            name="city"
            rules={{ required: formRequiredOptions['city'] ? 'City is required' : false }}
            render={({ field }) => (
              <Input
                {...field}
                fontSize="sm"
                placeholder="City"
                disabled={validated || disabled || formDisabledOptions['city']}
                _disabled={disabledStyles}
                onChange={handleFieldChange(field.onChange)}
              />
            )}
          />
        </FormControl>
      )}

      {formDisplayOptions['state'] && (
        <FormControl 
          isDisabled={validated || disabled || formDisabledOptions['state']} 
          isRequired={formRequiredOptions['state']}
        >
          <FormLabel fontSize="sm">State/Province</FormLabel>
          <Controller
            control={control}
            name="state"
            rules={{ required: formRequiredOptions['state'] ? 'State/Province is required' : false }}
            render={({ field }) => (
              <Input
                {...field}
                fontSize="sm"
                placeholder="State/Province"
                disabled={validated || disabled || formDisabledOptions['state']}
                _disabled={disabledStyles}
                onChange={handleFieldChange(field.onChange)}
              />
            )}
          />
        </FormControl>
      )}

      {formDisplayOptions['postal_code'] && (
        <FormControl 
          isDisabled={validated || disabled || formDisabledOptions['postal_code']} 
          isRequired={formRequiredOptions['postal_code']}
        >
          <FormLabel fontSize="sm">Postal Code</FormLabel>
          <Controller
            control={control}
            name="postal_code"
            rules={{ required: formRequiredOptions['postal_code'] ? 'Postal Code is required' : false }}
            render={({ field }) => (
              <Input
                {...field}
                fontSize="sm"
                placeholder="Postal Code"
                disabled={validated || disabled || formDisabledOptions['postal_code']}
                _disabled={disabledStyles}
                onChange={handleFieldChange(field.onChange)}
              />
            )}
          />
        </FormControl>
      )}

      {formDisplayOptions['country'] && (
        <FormControl 
          isDisabled={validated || disabled || formDisabledOptions['country']} 
          isRequired={formRequiredOptions['country']}
        >
          <FormLabel fontSize="sm">Country</FormLabel>
          <Controller
            control={control}
            name="country"
            rules={{ required: formRequiredOptions['country'] ? 'Country is required' : false }}
            render={({ field }) => (
              <CountryInput
                defaultData={defaultAddress?.country || ''}
                onChange={(countryCode: string) => {
                  field.onChange(countryCode);
                  const values = getValues();
                  const raw: RawAddress = {
                    line1: values.line1,
                    line2: values.line2,
                    city: values.city,
                    state: values.state,
                    postal_code: values.postal_code,
                    country: countryCode,
                  };
                  onChange(new AddressModel(raw));
                }}
                disabled={validated || disabled || formDisabledOptions['country']}
              />
            )}
          />
        </FormControl>
      )}
    </Stack>
  );
}
