import { Component } from 'react';
import { connect, Dispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { RouterProps, withRouter } from "utils/route";
import { ApplicationState } from 'reducers/types';
import { UserActions } from 'reducers/User/actions';
import { UserSelectors } from 'reducers/User/selectors';
import {
  Box
} from '@chakra-ui/react';
import { UserModel } from 'models';
import { JSONObject } from 'models/Api/types';
import { UserDataForm, SEOHelmet } from 'components';
import { UserDataFormData } from 'components/Form/UserDataForm/UserDataForm';
import { AppConstants } from '../../constants';
import { convertJSONObjectToSnakecase } from 'utils/object';
import { generateSEOTitle } from 'utils/seo';

interface UserSettingsViewProps extends RouterProps {
  user: UserModel;
  updateUserData: (userData: JSONObject) => void;
  userUpdateActionLoading: boolean;
}

class UserSettings extends Component<UserSettingsViewProps, {}> {
  getUserDataFormDefaultData = (): UserDataFormData => {
    const { user } = this.props;
    let data: UserDataFormData = {};
    if (user.name) {
      data.name = user.name;
    }

    if (user.country && user.country.code) {
      data.country = {
        code: user.country.code.toUpperCase(),
        name: user.country.name
      };
    }

    if (user.number && user.number.baseNumber) {
      data.number = {
        baseNumber: user.number.baseNumber,
        countryCode: user.number.countryCode
      };
    }

    if (user.birthYear) {
      data.birthYear = user.birthYear;
    }

    if (user.instagram) {
      data.instagram = user.instagram;
    }

    return data;
  }

  onUserDataFormSubmit = (data: UserDataFormData) => {
    const { updateUserData } = this.props;
    const snakeCasedData: JSONObject = convertJSONObjectToSnakecase(data as JSONObject);
    updateUserData(snakeCasedData);
  }


  renderUserSettingsGeneral() {
    const { user, userUpdateActionLoading } = this.props;
    return (
      <UserDataForm user={user} submitLoading={userUpdateActionLoading} defaultFormData={this.getUserDataFormDefaultData()} displayOptions={['birthYear', 'biography', 'instagram', 'name', 'number']} submitText={'Update Information'} onSubmit={this.onUserDataFormSubmit} />
    );
  }

  render() {
    return (
      <>
        <SEOHelmet
          title={generateSEOTitle('Settings')}
          description={`View and update your profile settings.`}
        />
        <Box maxWidth={`${AppConstants.GRIDPAGE_WIDTH}px`} paddingTop={['80px', '100px', '100px']} justifySelf="center" minWidth={['100%', `${AppConstants.GRIDPAGE_WIDTH}px`]}>
          {this.renderUserSettingsGeneral()}
        </Box>
      </>

    );
  }
}

function mapStateToProps(state: ApplicationState) {
  return {
    user: UserSelectors.getUser(state),
    userUpdateActionLoading: UserSelectors.getUserUpdateActionLoading(state)
  }
}

function mapDispatchToProps(dispatch: Dispatch<ApplicationState>) {
  return bindActionCreators(
    {
      updateUserData: (userData: JSONObject) => UserActions.updateUserData(userData),
    },
    dispatch
  );
}

export const UserSettingsView = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserSettings));
