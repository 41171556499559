import { useState } from 'react';
import { Stack, Radio, RadioGroup, Heading, Icon } from '@chakra-ui/react';
import Wizard from '../Wizard';
import { ItemModel } from 'models';
import BasicItemCard from '../../Item/BasicItemCard/BasicItemCard';
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";

interface ItemFlagWizardProps {
	item: ItemModel;
	isLoading?: boolean;
	onSubmit?: (item: ItemModel, flagReason: string) => void;
	onRetry?: () => void;
	hasErrors?: boolean;
}

export default function ItemFlagWizard({
	item,
	isLoading = false,
	onSubmit = () => { },
	onRetry = () => { },
	hasErrors
}: ItemFlagWizardProps) {
	const [selectedOption, setSelectedOption] = useState('');

	const onWizardSubmit = () => {
		onSubmit(item, selectedOption);
	}

	function renderRadioGroupSelection() {
		return (
			<RadioGroup onChange={setSelectedOption} value={selectedOption} textAlign={'left'}>
				<Stack direction="column" gap={2}>
					<Radio size="sm" value="UNOFFICIAL">This item is an unofficial release</Radio>
					<Radio size="sm" value="DUPLICATE">This item is a duplicate of another item</Radio>
					<Radio size="sm" value="INCORRECT_SALES">This item contains incorrect sales data</Radio>
					<Radio size="sm" value="INCORRECT_DATA">This item contains incorrect data (non-sales)</Radio>
				</Stack>
			</RadioGroup>
		);
	}

	const getConfirmationStepHeader = () => {
		switch (selectedOption) {
			case 'UNOFFICIAL':
				return 'This item is an unofficial release';
			case 'DUPLICATE':
				return 'This item is a duplicate of another item';
			case 'INCORRECT_SALES':
				return 'This item contains incorrect sales data';
			case 'INCORRECT_DATA':
				return 'This item contains incorrect data (non-sales)';
			default:
				return '';
		}
	}

	function renderRadioSelectionConfirmation() {
		return (
			<Stack>
				<Heading fontSize={'sm'} textAlign={'center'}>{getConfirmationStepHeader()}</Heading>
			</Stack>
		);
	}

	function renderWizardFinishingSuccessStep() {
		if (hasErrors) {
			return (
				<Stack gap={4} alignItems={'center'}>
					<Icon as={AiFillCloseCircle} color={'red.500'} w={10} h={10} />
					<Heading size={'xs'} textAlign={'center'}>
						Your Feedback Could Not Be Submitted.<br />Please Try Again
					</Heading>
				</Stack>
			);
		} else {
			return (
				<Stack gap={4} alignItems={'center'}>
					<Icon as={AiFillCheckCircle} color={'green.500'} w={10} h={10} />
					<Heading size={'xs'}>
						Your Feedback Has Been Submitted
					</Heading>
				</Stack>
			);
		}
	}

	const wizardSteps = [
		{ label: 'Reason', content: renderRadioGroupSelection(), requiresValidation: true, isValidated: selectedOption !== '' },
		{ label: 'Confirm', content: renderRadioSelectionConfirmation() }
	];

	return (
		<Stack gap={2} width={'100%'}>
			<BasicItemCard item={item} />
			<Wizard steps={wizardSteps} finishStep={renderWizardFinishingSuccessStep()} onSubmit={onWizardSubmit} loading={isLoading} />
		</Stack>
	);
}
