import { useState, useMemo } from 'react';
import { Stack, Heading, Icon, Box } from '@chakra-ui/react';
import { ItemModel, ItemAskModel } from 'models';
import { Condition } from 'models/types';
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai';
import Wizard from '../Wizard';
import ItemBidForm, { ItemBidFormData } from '../../Form/ItemBidForm/ItemBidForm';
import ItemBidAdditionalDataForm, { ItemBidAdditionalDataFormData } from '../../Form/ItemBidAdditionalDataForm/ItemBidAdditionalDataForm';
import ItemBidCard from '../../Item/ItemBidCard/ItemBidCard';
import PaymentBreakdown from '../../PaymentBreakdown/PaymentBreakdown';
import { ItemAskStatus } from 'models/Item/ItemAsk/types';

interface ItemBidWizardProps {
	item: ItemModel;
	defaultBid?: ItemBidFormData;
	defaultAdditionalData?: ItemBidAdditionalDataFormData;
	onSubmit?: (data: ItemBidFormData, additionalData: ItemBidAdditionalDataFormData, useEscrow?: boolean) => void;
	isLoading?: boolean;
	hasErrors?: boolean;
}

export default function ItemBidWizard({
	item,
	defaultBid = { bidExpiration: 30 },
	defaultAdditionalData,
	onSubmit = () => { },
	isLoading = false,
	hasErrors = false,
}: ItemBidWizardProps) {
	const [bidData, setBidData] = useState<ItemBidFormData>(defaultBid);
	const [additionalBidData, setAdditionalBidData] = useState<ItemBidAdditionalDataFormData>(
		defaultAdditionalData || { condition: Condition.GOOD }
	);
	const [useEscrow, setUseEscrow] = useState(false);

	const opposingAskTransaction = useMemo(() => new ItemAskModel({
		id: '',
		item_id: item.id,
		user_id: '',
		amount: bidData.bidPrice || 0,
		currency: 'USD',
		status: ItemAskStatus.PENDING,
		condition: additionalBidData.condition || Condition.GOOD,
		notes: '',
		expiration_days: 30,
		verified: false,
	}), [item.id, bidData.bidPrice, additionalBidData.condition]);
	
	const onWizardSubmit = () => {
		onSubmit(bidData, additionalBidData, useEscrow);
	};

	const handleFormChange = (data: ItemBidFormData) => {
		setBidData(data);
	};

	const handleAdditionalDataChange = (data: ItemBidAdditionalDataFormData) => {
		setAdditionalBidData(data);
	};

	function renderBidForm() {
		return (
			<Box height={{ base: 'auto', md: 340 }}>
				<ItemBidForm item={item} onChange={handleFormChange} defaultFormData={bidData} showSubmit={false} />
			</Box>
		);
	}

	function renderBidCard() {
		return (
			<ItemBidCard item={item} bidAmount={bidData.bidPrice || 0} bidCreatedDays={0} condition={additionalBidData.condition} />
		);
	}

	function renderAdditionalDataForm() {
		return (
			<Box height={{ base: 'auto', md: 340 }}>
				<Stack spacing={8}>
					{renderBidCard()}
					<ItemBidAdditionalDataForm
						onChange={handleAdditionalDataChange}
						defaultFormData={additionalBidData}
					/>
				</Stack>
			</Box>
		);
	}

	function renderConfirmationStep() {
		return (
			<Box height={{ base: 'auto', md: 340 }}>
				<Stack spacing={4}>
					{renderBidCard()}
					<Heading fontSize={'sm'}>
						Confirm Your Bid
					</Heading>
					<PaymentBreakdown transaction={opposingAskTransaction} type={'ask'} useEscrow={useEscrow} onToggleEscrow={setUseEscrow} showInfoGuide={false} showTransactionCard={false} />
				</Stack>
			</Box>
		);
	}

	function renderWizardFinishingSuccessStep() {
		let content: JSX.Element;
		if (hasErrors) {
			content = (
				<Stack gap={4} alignItems={'center'}>
					<Icon as={AiFillCloseCircle} color={'red.500'} w={10} h={10} />
					<Heading size={'xs'} textAlign={'center'}>
						Your Bid Could Not Be Submitted.<br />Please Try Again
					</Heading>
				</Stack>
			);
		} else {
			content = (
				<Stack gap={4} alignItems={'center'}>
					<Icon as={AiFillCheckCircle} color={'green.500'} w={10} h={10} />
					<Heading size={'xs'} textAlign={'center'}>
						Your Bid Has Been Submitted
					</Heading>
				</Stack>
			);
		}

		return (
			<Box height={{ base: 'auto', md: 340 }}>
				{content}
			</Box>)
	}

	const wizardSteps = [
		{ label: 'Amount', content: renderBidForm(), requiresValidation: true, isValidated: (bidData?.bidPrice || 0) > 0 },
		{ label: 'Details', description: 'Optional', content: renderAdditionalDataForm(), requiresValidation: false },
		{ label: 'Confirm', content: renderConfirmationStep() },
	];

	return (
		<Stack gap={2} width="100%">
			<Wizard steps={wizardSteps} finishStep={renderWizardFinishingSuccessStep()} loading={isLoading} onSubmit={onWizardSubmit} />
		</Stack>
	);
}
