import { Helmet } from 'react-helmet';

interface SEOHelmetProps {
    title: string;
    description: string;
    link?: string;
    structuredData?: Object;
}

export default function SEOHelmet({ title, description, link, structuredData }: SEOHelmetProps) {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            {link && <link rel="canonical" href={link} />}
            {structuredData && (
                <script type="application/ld+json">
                    {JSON.stringify(structuredData)}
                </script>
            )}
        </Helmet>
    );
}
