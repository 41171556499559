import { Stack, Text, Button, Flex, Heading, Icon } from '@chakra-ui/react';
import { FaExclamationCircle } from 'react-icons/fa';
import { IoHomeOutline } from "react-icons/io5";
import { UserModel } from 'models/User/User';
import { getCountryNameFromCountryCode } from 'utils/country';
import UserProfileCard from './UserProfileCard';

interface UserProfileAddressCardProps {
    user: UserModel;
    onManage?: () => void;
}

export default function UserProfileAddressCard({
    user,
    onManage,
}: UserProfileAddressCardProps) {
    let content;
    const userAddress = user.getPrimaryAddress();
    const bottomButtonText = user.hasPrimaryAddress() ? 'Edit Address' : 'Add Address';

    const bottomButton = (
        <Button variant="outline" size="sm" onClick={onManage}>
            {bottomButtonText}
        </Button>
    );

    if (userAddress) {
        const lineRow = [userAddress.line1, userAddress.line2].filter(Boolean).join(', ');
        const restRow = [userAddress.city, userAddress.state, userAddress.postalCode].filter(Boolean).join(', ');
        content = (
            <Stack gap={1}>
                <Text fontSize="sm" textAlign="left">{lineRow}</Text>
                <Text fontSize="sm" textAlign="left">{restRow}</Text>
                <Text fontSize='sm' textAlign="left">
                    {getCountryNameFromCountryCode(userAddress.country)}
                </Text>
            </Stack>
        );
    } else {
        content = (
            <Flex align="center" color="red.500">
                <Icon as={FaExclamationCircle} w={5} h={5} mr={2} />
                <Heading fontSize="sm">Shipping address is required to proceed.</Heading>
            </Flex>
        );
    }

    return (
        <UserProfileCard
            title="Address"
            subtitle="Manage Your Primary Address"
            icon={IoHomeOutline}
            bottomButton={bottomButton}
            children={content}
        />
    );
}
