import { Box, Heading, Stack, Text, Icon, Button, useColorModeValue } from '@chakra-ui/react';
import { WarningIcon } from '@chakra-ui/icons';

export interface UnauthorizedAccessProps {
  title?: string;
  description?: string;
  buttonText?: string;
  onClick?: () => void;
}

export default function UnauthorizedAccess({
  title = 'Unauthorized Access',
  description = 'You do not have permission to access this content.',
  buttonText = '',
  onClick
}: UnauthorizedAccessProps) {
  return (
    <Box
      backgroundColor={useColorModeValue("white", "black")}
      ml={0}
      mr={0}
      borderRadius={8}
      boxShadow="0px 4px 10px rgba(0, 0, 0, 0.05)"
    >
      <Stack alignItems="center" justifyContent="center" paddingX={8} paddingY={8} spacing={6}>
        <Stack
          spacing={8}
          borderRadius={8}
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          <Icon as={WarningIcon} w={12} h={12} color="red.500" />
          <Stack spacing={2}>
            <Heading size="lg">{title}</Heading>
            {description && <Text>{description}</Text>}
          </Stack>
          {buttonText && (
            <Button colorScheme={'red'} variant={'outline'} onClick={onClick}>
              {buttonText}
            </Button>
          )}
        </Stack>
      </Stack>
    </Box>
  );
}
