import { Cmd, loop, Loop } from 'redux-loop';
import { ActiveMarketInternalActions } from './actions';
import { ActiveMarketApiClient } from 'api';
import { ActiveMarketState, ActiveMarketAction } from './types';
import { ItemActiveMarketData } from 'models/Item/types';
import { defaultState } from './consts';

export enum ActiveMarketStateErrors {
  ALL_FETCH = 'Unable to fetch all active market',
}

export class ActiveMarketHandlers {
  public static handleGetAllActiveMarkets(state: ActiveMarketState): Loop<ActiveMarketState, ActiveMarketAction> {
    const newState: ActiveMarketState = {
      ...state,
      loading: {
        ...state.loading,
        all: true
      }
    };

    return loop(
      newState,
      Cmd.run(ActiveMarketApiClient.getAllActiveMarket, {
        args: [],
        successActionCreator: ActiveMarketInternalActions.getAllActiveMarketsSuccess,
        failActionCreator: ActiveMarketInternalActions.getAllActiveMarketsFailure,
      })
    );
  }

  public static handleGetAllActiveMarketsSuccess(state: ActiveMarketState, action: ActiveMarketAction): ActiveMarketState {
    const allActiveMarket: ItemActiveMarketData = action.payload?.activeMarket || defaultState.all;
    return {
      ...state,
      all: allActiveMarket,
      loading: {
        ...state.loading,
        all: false
      }
    };
  }

  public static handleGetAllActiveMarketsFailure(state: ActiveMarketState): ActiveMarketState {
    return {
      ...state,
      error: new Error(ActiveMarketStateErrors.ALL_FETCH),
      loading: {
        ...state.loading,
        all: false
      }
    };
  }
}
