import { UrlConfig } from 'config';
import { ArtistModel, ItemModel, PaginationModel, ListingModel, ArtistIndexModel, ItemBidModel, ItemAskModel } from 'models';
import { RawArtistData } from 'models/Artist/Artist';
import { RawArtistIndex } from 'models/Artist/ArtistIndex';
import { RawItemData, RawItemActiveMarketData, ItemActiveMarketData } from 'models/Item/types';
import { RawListingData } from 'models/Listing/types';
import { RawPaginationData } from 'models/Pagination/Pagination';
import { ApiResponse } from 'models/Api/ApiResponse';
import { AjaxService } from 'services';
import { ArtistConfig } from 'config';
import { arrayToCommaSeparatedString } from 'utils/format';

export class ArtistApiClient {
  public static async getArtistById(id: string): Promise<ArtistModel> {
    const url = UrlConfig.ARTIST.ARTIST_BY_ID + `${id}`;
    const response: ApiResponse<RawArtistData> = await AjaxService.getData(url);
    const artist = new ArtistModel(response.data);
    return artist;
  }

  public static async getArtistByItemId(id: string): Promise<ArtistModel> {
    const url = UrlConfig.ARTIST.ARTIST_BY_ITEM + `${id}`;
    const response: ApiResponse<RawArtistData> = await AjaxService.getData(url);
    const artist = new ArtistModel(response.data);
    return artist;
  }

  public static async getAllArtists(): Promise<ArtistModel[]> {
    const enabledArtistIds = ArtistConfig.ENABLED_ARTISTS;
    const queryString = arrayToCommaSeparatedString(enabledArtistIds);
    const url = `${UrlConfig.ARTIST.ARTIST_ENABLED}?artist_ids=${queryString}`;
    const response: ApiResponse<RawArtistData[]> = await AjaxService.getData(url);
    const artistList = response.data.map((rawArtist: RawArtistData) => new ArtistModel(rawArtist));
    return artistList;
  }

  public static async getArtistItems(id: string): Promise<ItemModel[]> {
    const url = UrlConfig.ARTIST.ARTIST_BY_ID + `${id}/items`;
    const response: ApiResponse<RawItemData[]> = await AjaxService.getData(url);
    const itemsList = response.data.map((rawItem: RawItemData) => new ItemModel(rawItem));
    return itemsList;
  }

  public static async getArtistListings(id: string, page: number): Promise<PaginationModel<ListingModel>> {
    let url = UrlConfig.ARTIST.ARTIST_BY_ID + `${id}/listings`;
    if (page) {
      url += `?page=${page}`;
    }
    const response: ApiResponse<RawPaginationData<RawListingData>> = await AjaxService.getData(url);
    const paginatedRespose: PaginationModel<RawListingData> = new PaginationModel(response.data);
    const formattedListings = paginatedRespose.data.map((rawListing: RawListingData) => new ListingModel(rawListing));
    const formattedPaginatedResponse: PaginationModel<ListingModel> = new PaginationModel({ ...response.data, data: formattedListings });
    return formattedPaginatedResponse;
  }

  public static async getArtistEditionIndex(id: string): Promise<ArtistIndexModel> {
    const url = UrlConfig.ARTIST.ARTIST_BY_ID + `${id}/index/edition`;
    const response: ApiResponse<RawArtistIndex> = await AjaxService.getData(url);
    const artistIndex = new ArtistIndexModel(response.data);
    return artistIndex
  }

  public static async getArtistActiveMarket(id: string): Promise<ItemActiveMarketData> {
    const url = UrlConfig.ARTIST.ARTIST_BY_ID + `${id}/active-market`;
    const response: ApiResponse<RawItemActiveMarketData> = await AjaxService.getData(url);
    const activeMarket = {
      bids: response.data?.bids.map(rawBid => new ItemBidModel(rawBid)),
      asks: response.data?.asks.map(rawAsk => new ItemAskModel(rawAsk))
    };
    return activeMarket;
  }

  public static async followArtist(id: string): Promise<void> {
    const url = UrlConfig.ARTIST.ARTIST_FOLLOW;
    await AjaxService.postData(url, {
      artist_id: id
    });
  }

  public static async unfollowArtist(id: string): Promise<void> {
    const url = UrlConfig.ARTIST.ARTIST_UNFOLLOW;
    await AjaxService.postData(url, {
      artist_id: id
    });
  }
}
