import {
	Box,
	SimpleGrid,
	Skeleton,
	SkeletonText,
	Flex,
	Spacer
} from '@chakra-ui/react';

export interface TradingFloorSkeletonProps {
	rows?: number;
}

export default function TradingFloorSkeleton({ rows = 10 }: TradingFloorSkeletonProps) {

	function renderSkeletonCard() {
		return (
			<Box
				minHeight="80px"
				width="100%"
				overflow='hidden'
				padding={2}
				border="1px solid"
				borderColor="gray.300"
			>
				<Flex direction="row" alignItems="center" justifyContent="flex-start">
					<Skeleton height="80px" width="80px" marginRight={4} />
					<Flex direction="column" textAlign="left" gap={2} paddingRight={4}>
						<SkeletonText noOfLines={1} width="120px" />
						<SkeletonText noOfLines={1} width="80px" />
						<Flex direction="row" alignItems="center" gap={1}>
							<Skeleton height="15px" width="20px" />
							<SkeletonText noOfLines={1} width="60px" />
						</Flex>
						<Skeleton height="15px" width="40px" />
					</Flex>
					<Spacer />
					<Flex direction="column" gap={2} alignItems="flex-end">
						<Skeleton height="20px" width="80px" />
						<SkeletonText noOfLines={1} width="60px" />
						<Skeleton height="15px" width="30px" />
					</Flex>
				</Flex>
			</Box>
		);
	}

	function renderSkeletonSection(title: string) {
		return (
			<Box>
				<SkeletonText noOfLines={1} width="80px" marginBottom={2} />
				<SimpleGrid columns={1} spacing={3}>
					{Array.from({ length: rows }).map((_, index) => (
						<Box key={index}>{renderSkeletonCard()}</Box>
					))}
				</SimpleGrid>
			</Box>
		);
	}

	return (
		<SimpleGrid columns={[1, 2]} spacing={6}>
			{renderSkeletonSection('Bids')}
			{renderSkeletonSection('Asks')}
		</SimpleGrid>
	);
}
