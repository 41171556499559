import moment, { Moment } from 'moment';
import { PortfolioModel } from '../Portfolio/Portfolio';
import { RawBasicPortfolioData } from '../Portfolio/types';
import { WishlistModel, RawBasicWishlistData } from '../Wishlist/Wishlist';
import { ItemModel } from '../Item/Item';
import { ItemBidModel } from '../Item/ItemBid/ItemBid';
import { ItemAskModel } from '../Item/ItemAsk/ItemAsk';
import { ArtistModel } from '../Artist/Artist';
import { OrderModel } from '../Order/Order';
import { Cloneable } from '../Utils/Cloneable';
import { CountryData, RawPhoneData, PhoneData, RawTimestampData, TimestampData } from '../types';
import { ItemActiveMarketData, RawItemActiveMarketData } from '../Item/types';
import { RawAddress, AddressModel } from '../Address/Address';
import { RawOrderData } from '../Order/types';

export interface UserVerficiationData {
  email: boolean;
  number: boolean;
  seller: boolean;
}

export interface UserRawTimestampData extends RawTimestampData {
  confirmed_at: string;
}

export interface UserTimestampData extends TimestampData {
  confirmedAt: Moment | null;
}

export interface UserRawAddress {
  primary_address: RawAddress;
  secondary_addresses: RawAddress[];
}

export interface UserRawStripeInfo {
  connected_account_id: string
  customer_id: string
  details_submitted: boolean;
  payouts_enabled: boolean;
}

export interface UserStripeInfo {
  connectedAccountId: string
  customerId: string
  detailsSubmitted: boolean;
  payoutsEnabled: boolean;
}

export enum UserAccountStatus {
  PENDING = 'Pending',
  CONFIRMED = 'Confirmed',
  BLOCKED = 'Blocked'
}

export interface UserRawOrderInfo {
  buyer: RawOrderData[];
  seller: RawOrderData[];
}

export interface UserOrderInfo {
  buyer: OrderModel[];
  seller: OrderModel[];
}

export interface UserAddress {
  primaryAddress: AddressModel | null;
  secondaryAddresses: AddressModel[];
}

export interface RawUserData {
  email: string;
  id: string;
  name: string;
  number: RawPhoneData;
  user_name: string;
  birth_year: number;
  portfolio: RawBasicPortfolioData;
  wishlist: RawBasicWishlistData;
  following_artists: string[];
  image?: string;
  country?: CountryData;
  verification?: UserVerficiationData;
  timestamps?: UserRawTimestampData;
  account_status?: UserAccountStatus;
  instagram?: string;
  biography?: string;
  active_market?: RawItemActiveMarketData;
  stripe_info?: UserRawStripeInfo;
  orders?: UserRawOrderInfo;
  address?: UserRawAddress;
}

export class UserModel extends Cloneable {
  email: string;
  id: string;
  name: string;
  number: PhoneData;
  userName: string;
  portfolio: PortfolioModel;
  wishlist: WishlistModel;
  followingArtists: ArtistModel[] | string[];
  country: CountryData;
  image: string;
  verification: UserVerficiationData;
  timestamps: UserTimestampData;
  accountStatus: UserAccountStatus;
  birthYear: number;
  instagram: string;
  biography: string;
  activeMarket: ItemActiveMarketData;
  stripeInfo: UserStripeInfo;
  orders: UserOrderInfo;
  address: UserAddress;

  constructor(rawUserData?: RawUserData) {
    super();
    this.email = rawUserData?.email || '';
    this.id = rawUserData?.id || '';
    this.name = rawUserData?.name || '';
    this.number = {
      countryCode: rawUserData?.number.country_code || '',
      baseNumber: rawUserData?.number.base_number || 0,
    };
    this.userName = rawUserData?.user_name || '';
    this.portfolio = rawUserData?.portfolio ? new PortfolioModel(rawUserData.portfolio) : new PortfolioModel();
    this.wishlist = rawUserData?.wishlist ? new WishlistModel(rawUserData.wishlist) : new WishlistModel();
    this.followingArtists = rawUserData?.following_artists || [];
    this.country = rawUserData?.country || { code: '', name: '' };
    this.image = rawUserData?.image || '';
    this.verification = {
      email: rawUserData?.verification?.email || false,
      number: rawUserData?.verification?.number || false,
      seller: rawUserData?.verification?.seller || false
    };
    this.timestamps = {
      createdAt: rawUserData?.timestamps?.created_at ? moment(rawUserData.timestamps.created_at) : null,
      updatedAt: rawUserData?.timestamps?.updated_at ? moment(rawUserData.timestamps.updated_at) : null,
      confirmedAt: rawUserData?.timestamps?.confirmed_at ? moment(rawUserData.timestamps.confirmed_at) : null
    };
    this.accountStatus = rawUserData?.account_status || UserAccountStatus.PENDING;
    this.birthYear = rawUserData?.birth_year || 0;
    this.instagram = rawUserData?.instagram || '';
    this.biography = rawUserData?.biography || '';
    this.activeMarket = {
      bids: rawUserData?.active_market?.bids.map(rawBid => new ItemBidModel(rawBid)) || [],
      asks:rawUserData?.active_market?.asks.map(rawAsk => new ItemAskModel(rawAsk)) || []
    };
    this.orders = {
      buyer: rawUserData?.orders?.buyer.map(rawOrder => new OrderModel(rawOrder)) || [],
      seller: rawUserData?.orders?.seller.map(rawOrder => new OrderModel(rawOrder)) || [],
    };
    this.stripeInfo = {
      connectedAccountId: rawUserData?.stripe_info?.connected_account_id || '',
      customerId: rawUserData?.stripe_info?.customer_id || '',
      detailsSubmitted: rawUserData?.stripe_info?.details_submitted || false,
      payoutsEnabled: rawUserData?.stripe_info?.payouts_enabled || false
    };
    this.address = {
      primaryAddress: rawUserData?.address?.primary_address ? new AddressModel(rawUserData.address.primary_address) : null,
      secondaryAddresses: rawUserData?.address?.secondary_addresses?.length 
        ? rawUserData?.address?.secondary_addresses.map(address => new AddressModel(address)) 
        : []
    }
  }

  isAuthenticated(): boolean {
    return !!this.userName && !!this.id;
  }

  isConfirmed(): boolean {
    return this.accountStatus === UserAccountStatus.CONFIRMED;
  }

  isBlocked(): boolean {
    return this.accountStatus === UserAccountStatus.BLOCKED;
  }

  isPhoneVerified(): boolean {
    return this.verification.number;
  }

  isEmailVerified(): boolean {
    return this.verification.email;
  }

  public setFollowingArtists(artists: ArtistModel[]) {
    if (artists) {
      this.followingArtists = artists;
    }
  }

  public setPortfolio(portfolio: PortfolioModel | undefined) {
    if (portfolio) {
      this.portfolio = portfolio;
    }
  }

  public setWishlist(wishlist: WishlistModel | undefined) {
    if (wishlist) {
      this.wishlist = wishlist;
    }
  }

  getPortfolioId(): string {
    return this.portfolio.id;
  }

  getWishlistId(): string {
    return this.wishlist.id;
  }

  hasFetchedWishlist(): boolean {
    return !!this.wishlist.id;
  }

  hasFetchedPortfolio(): boolean {
    return !!this.portfolio.id;
  }

  portfolioContainsItem(itemId: string): boolean {
    return !!this.portfolio.containsItem(itemId);
  }

  wishlistContainsItem(itemId: string): boolean {
    return !!this.wishlist.containsItem(itemId);
  }

  getFollowingArtistIds(): string[] {
    const artistIds: string[] = [];
    this.followingArtists.forEach((artist: string | ArtistModel) => {
      if (artist instanceof ArtistModel) {
        artistIds.push(artist.id);
      } else {
        artistIds.push(artist);
      }
    });

    return artistIds;
  }

  getFollowingArtists(): ArtistModel[] {
    const artists: ArtistModel[] = [];
    this.followingArtists.forEach((artist: string | ArtistModel) => {
      if (artist instanceof ArtistModel) {
        artists.push(artist);
      }
    });

    return artists;
  }

  isFollowingArtists(): boolean {
    return !!this.followingArtists.length;
  }

  getWishlistItems(): ItemModel[] {
    return this.wishlist.getWishlistItems();
  }

  getWishlistItemCount(): number {
    return this.wishlist.items.length;
  }

  getPortfolioItems(): ItemModel[] {
    return this.portfolio.getPortfolioItems();
  }

  followsArtist(artistId: string): boolean {
    return !!this.getFollowingArtistIds().includes(artistId);
  }

  getImage(): string {
    return this.image;
  }

  setImage(url: string): void {
    this.image = url;
  }

  getFormattedNumber(): string {
    return this.number.countryCode + this.number.baseNumber;
  }

  getBirthYear(): number {
    return this.birthYear;
  }

  getJoinedYear(): number {
    return this.timestamps.createdAt?.year() || 0;
  }

  getJoinedMonth(): number {
    return this.timestamps.createdAt?.month() || 0;
  }

  hasImage(): boolean {
    return !!this.image;
  }

  hasBirthYear(): boolean {
    return this.birthYear > 0;
  }

  hasInstagram(): boolean {
    return !!this.instagram;
  }

  public setRawActiveMarket(activeMarket: RawItemActiveMarketData | undefined) {
    if (activeMarket) {
      this.setActiveMarket({
        bids: activeMarket?.bids.map(rawBid => new ItemBidModel(rawBid)) || [],
        asks: activeMarket?.asks.map(rawAsk => new ItemAskModel(rawAsk)) || []
      });
    }
  }

  public setActiveMarket(activeMarket: ItemActiveMarketData | undefined | null) {
    if (activeMarket) {
      this.activeMarket = activeMarket;
    }
  }

  public setRawOrders(orders: UserRawOrderInfo | undefined) {
    if (orders) {
      this.setOrders({
        buyer: orders?.buyer.map(rawOrder => new OrderModel(rawOrder)) || [],
        seller: orders?.seller.map(rawOrder => new OrderModel(rawOrder)) || [],
      });
    }
  }

  public setOrders(orders:  UserOrderInfo | undefined | null) {
    if (orders) {
      this.orders = orders;
    }
  }

  public getTotalBidsValue(): number {
    return this.activeMarket.bids.reduce((total, bid) => total + bid.amount, 0);
  }

  public getTotalAsksValue(): number {
    return this.activeMarket.asks.reduce((total, ask) => total + ask.amount, 0);
  }

  public hasActiveMarketData(): boolean {
    return this.hasBids() || this.hasAsks();
  }

  public hasBids(): boolean {
    return this.activeMarket?.bids.length > 0;
  }

  public hasAsks(): boolean {
    return this.activeMarket?.asks.length > 0;
  }

  public getBids(): ItemBidModel[] {
    return this.activeMarket?.bids || [];
  }

  public getAsks(): ItemAskModel[] {
    return this.activeMarket?.asks || [];
  }

  public isSellerVerified(): boolean {
    return this.verification.seller;
  }

  public hasSellerPayoutsEnabled(): boolean {
    return this.stripeInfo.payoutsEnabled;
  }

  public hasSubmittedSellerDetails(): boolean { 
    return this.stripeInfo.detailsSubmitted;
  }

  public getStripeConnectedAccountId(): string {
    return this.stripeInfo.connectedAccountId;
  }

  public hasStripeConnectedAccountId(): boolean {
    return !!this.stripeInfo.connectedAccountId;
  }

  public getStripeCustomerId(): string {
    return this.stripeInfo.customerId;
  }

  public hasPrimaryAddress(): boolean {
    return !!this.address.primaryAddress;
  }

  public getPrimaryAddress(): AddressModel | null {
    return this.address.primaryAddress;
  }

  public setPrimaryAddress(address: AddressModel) {
    this.address.primaryAddress = address;
  }
}
