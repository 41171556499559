import { Loop, LoopReducer } from 'redux-loop';
import { ArtistState, ArtistAction, ArtistActionTypes } from './types';
import { defaultState } from './consts';
import { ArtistHandlers } from './handlers';

export const artistReducer: LoopReducer<ArtistState, ArtistAction> = (
  state: ArtistState = defaultState,
  action: ArtistAction
): ArtistState | Loop<ArtistState, ArtistAction> => {
  switch (action.type) {
    case ArtistActionTypes.GET_ARTIST_BY_ID:
      return ArtistHandlers.handleGetArtistById(state, action);
    case ArtistActionTypes.GET_ARTIST_BY_ID_SUCCESS:
      return ArtistHandlers.handleGetArtistByIdSuccess(state, action);
    case ArtistActionTypes.GET_ARTIST_BY_ID_FAILURE:
      return ArtistHandlers.handleGetArtistByIdFailure(state);
    case ArtistActionTypes.GET_ALL_ARTISTS:
      return ArtistHandlers.handleGetAllArtists(state);
    case ArtistActionTypes.GET_ALL_ARTISTS_SUCCESS:
      return ArtistHandlers.handleGetAllArtistsSuccess(state, action);
    case ArtistActionTypes.GET_ALL_ARTISTS_FAILURE:
      return ArtistHandlers.handleGetAllArtistsFailure(state);
    case ArtistActionTypes.GET_ARTIST_ITEMS:
      return ArtistHandlers.handleGetArtistItems(state, action);
    case ArtistActionTypes.GET_ARTIST_ITEMS_SUCCESS:
      return ArtistHandlers.handleGetArtistItemsSuccess(state, action);
    case ArtistActionTypes.GET_ARTIST_ITEMS_FAILURE:
      return ArtistHandlers.handleGetArtistItemsFailure(state);
    case ArtistActionTypes.GET_ARTIST_LISTINGS:
      return ArtistHandlers.handleGetArtistListings(state, action);
    case ArtistActionTypes.GET_ARTIST_LISTINGS_SUCCESS:
      return ArtistHandlers.handleGetArtistListingsSuccess(state, action);
    case ArtistActionTypes.GET_ARTIST_LISTINGS_FAILURE:
      return ArtistHandlers.handleGetArtistListingsFailure(state);
    case ArtistActionTypes.GET_ARTIST_EDITIONS_INDEX:
      return ArtistHandlers.handleGetArtistEditionIndex(state, action);
    case ArtistActionTypes.GET_ARTIST_EDITIONS_INDEX_SUCCESS:
      return ArtistHandlers.handleGetArtistEditionIndexSuccess(state, action);
    case ArtistActionTypes.GET_ARTIST_EDITIONS_INDEX_FAILURE:
      return ArtistHandlers.handleGetArtistEditionIndexFailure(state);
    case ArtistActionTypes.GET_ARTIST_ACTIVE_MARKET:
      return ArtistHandlers.handleGetArtistActiveMarket(state, action);
    case ArtistActionTypes.GET_ARTIST_ACTIVE_MARKET_SUCCESS:
      return ArtistHandlers.handleGetArtistActiveMarketSuccess(state, action);
    case ArtistActionTypes.GET_ARTIST_ACTIVE_MARKET_FAILURE:
      return ArtistHandlers.handleGetArtistActiveMarketFailure(state);
    default:
      return state;
  }
}
