
import {
	Stack,
	Flex,
	Text,
	StackDivider,
	Switch,
	Tooltip,
} from '@chakra-ui/react';
import { QuestionIcon } from '@chakra-ui/icons';
import { ItemAskModel, ItemBidModel } from 'models';
import ItemBidCard from '../Item/ItemBidCard/ItemBidCard';
import ItemAskCard from '../Item/ItemAskCard/ItemAskCard';
import GuidanceCard from '../GuidanceCard/GuidanceCard';
import InfoGuide from '../InfoGuide/InfoGuide';
import { toDollarFormat } from 'utils/format';

interface PaymentBreakdownProps {
	transaction: ItemBidModel | ItemAskModel;
	type: 'bid' | 'ask';
	useEscrow: boolean;
	onToggleEscrow?: (checked: boolean) => void;
	onInfoGuideClick?: () => void;
	showTransactionCard?: boolean;
	showInfoGuide?: boolean;
}

export default function PaymentBreakdown({
	transaction,
	type,
	useEscrow,
	onToggleEscrow,
	onInfoGuideClick,
	showTransactionCard = true,
	showInfoGuide = true
}: PaymentBreakdownProps) {
	let netAmount: number = 0;
	let card: JSX.Element | null = null;
	const escrowGuidanceText = type === 'bid' 
		? 'This optional service provides an additional layer of protection & anonymity. You ship the work directly to us and get paid sooner once we inspect and verify the work. We also make sure your details never get disclosed to the buyer.'
		: 'This optional service provides an additional layer of purchase protection and peace of mind. Your work gets inspected by us and verified by our experts for its condition and authenticity. We work closely with you throughout the purchase to make sure you have an extremely smooth experience.'


	if (type === 'bid') {
		const bid = transaction as ItemBidModel;
		netAmount = bid.getSellerNetAmount(useEscrow);
		card = (
			<ItemBidCard
				item={bid.item!}
				bidAmount={bid.amount}
				bidCreatedDays={bid.getCreatedDaysAgo()}
				bidUpdatedDays={bid.getUpdatedDaysAgo()}
				updated={bid.hasBeenUpdated()}
				condition={bid.condition}
			/>
		)
	} else {
		const ask = transaction as ItemAskModel;
		netAmount = ask.getBuyerTotalAmount(useEscrow);
		card = (
			<ItemAskCard
				item={ask.item!}
				askAmount={ask.amount}
				askCreatedDays={ask.getCreatedDaysAgo()}
				askUpdatedDays={ask.getUpdatedDaysAgo()}
				updated={ask.hasBeenUpdated()}
				condition={ask.condition}
			/>
		)
	}

	const formattedNetAmount = toDollarFormat(netAmount);
	const feeSign = type === 'bid' ? '-' : '+';

	return (
		<Stack spacing={4}>
			{showTransactionCard && card}
			{showInfoGuide && (
				<InfoGuide
					text={type === 'bid' ? 'Learn how Selling works' : 'Learn how Buying works'}
					onClick={onInfoGuideClick}
				/>
			)}
			<Stack
				spacing={2}
				p={4}
				borderWidth="1px"
				borderRadius="md"
				divider={<StackDivider borderColor="gray.100" />}
			>
				<Flex justifyContent="space-between">
					<Text fontSize="sm" color="gray.600">
						{'Subtotal Amount'}
					</Text>
					<Text fontSize="sm" color="gray.600">
						{transaction.getFormattedAmount()}
					</Text>
				</Flex>
				<Stack spacing={1}>
					<Flex justifyContent="space-between">
						<Text fontSize="xs" color="gray.600">
							Platform fees ({transaction.getPlatformFeePercentage()}%)
						</Text>
						<Text fontSize="xs" color="gray.600">
							{feeSign}
							{transaction.getFormattedPlatformFeeAmount()}
						</Text>
					</Flex>
					<Flex align="center" justifyContent="space-between">
						<Flex align="center" gap={1}>
							<Text fontSize="xs" color="gray.600">
								Use Escrow Services
							</Text>
							<Tooltip
								label={
									<GuidanceCard
										header="Escrow Services"
										text={escrowGuidanceText}
									/>
								}
								bg="none"
								shadow="none"
							>
								<QuestionIcon boxSize={3} />
							</Tooltip>
						</Flex>
						<Switch isChecked={useEscrow} onChange={(e) => onToggleEscrow?.(e.target.checked)} />
					</Flex>
					{useEscrow && (
						<Flex justifyContent="space-between">
							<Text fontSize="xs" color="gray.600">
								Escrow Fee ({transaction.getEscrowFeePercentage()}%)
							</Text>
							<Text fontSize="xs" color="gray.600">
								{feeSign}
								{transaction.getFormattedEscrowFeeAmount()}
							</Text>
						</Flex>
					)}
				</Stack>
				<Flex direction="row" justifyContent="space-between">
					<Text fontSize="md" fontWeight="bold">
						{type === 'bid' ? 'Net Amount:' : 'Total Amount:'}
					</Text>
					<Text fontSize="md" fontWeight="bold">
						{formattedNetAmount}
					</Text>
				</Flex>
			</Stack>
		</Stack>
	);
}