import { Cmd, loop, Loop } from 'redux-loop';
import { AuthActions, AuthInternalActions } from './actions';
import { AuthState, AuthAction } from './types';
import { defaultState } from './consts';
import { AuthApiClient } from 'api';
import { UserModel } from 'models';

export enum AuthStateErrors {
  REGISTER = 'Unable to register user',
  USER_FETCH = 'Unable to fetch authenticated user',
  LOGIN_EMAIL = 'Unable to login with credentials',
  REFRESH_AUTH = 'Unable to refresh credentials',
  USER_LOGOUT = 'Unable to logout'
}

export class AuthHandlers {
  public static handleGetAuthenticatedUser(state: AuthState): Loop<AuthState, AuthAction> {
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        user: true
      }
    };

    return loop(
      newState,
      Cmd.run(AuthApiClient.getAuthenticatedUser, {
        args: [],
        successActionCreator: AuthInternalActions.getAuthenticatedUserSuccess,
        failActionCreator: AuthInternalActions.getAuthenticatedUserFailure,
      })
    );
  }

  public static handleGetAuthenticatedUserSuccess(state: AuthState, action: AuthAction): AuthState {
    const authUser: UserModel = action.payload?.user || defaultState.authUser;
    return {
      ...state,
      authUser,
      loading: {
        ...state.loading,
        user: false
      }
    };
  }

  public static handleGetAuthenticatedUserFailure(state: AuthState): Loop<AuthState, AuthAction> {
    const newState = {
      ...state,
      error: new Error(AuthStateErrors.USER_FETCH),
      loading: {
        ...state.loading,
        user: false
      }
    };

    return loop(
      newState,
      Cmd.list([
        Cmd.action(AuthActions.refreshAuthToken())
      ])
    );
  }

  public static handleRegisterUser(state: AuthState, action: AuthAction): Loop<AuthState, AuthAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        register: true
      }
    };

    return loop(
      newState,
      Cmd.run(AuthApiClient.registerUser, {
        args: [payload?.email || '', payload?.username, payload?.password || ''],
        successActionCreator: AuthInternalActions.registerUserSuccess,
        failActionCreator: AuthInternalActions.registerUserFailure,
      })
    );
  }

  public static handleRegisterUserSuccess(state: AuthState, action: AuthAction): Loop<AuthState, AuthAction> {
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        register: false
      }
    };

    return loop(
      newState,
      Cmd.list([
        Cmd.action(AuthActions.getAuthenticatedUser())
      ])
    );
  }

  public static handleRegisterUserFailure(state: AuthState): AuthState {
    return {
      ...state,
      error: new Error(AuthStateErrors.REGISTER),
      loading: {
        ...state.loading,
        register: false
      }
    };
  }

  public static handleLoginByEmail(state: AuthState, action: AuthAction): Loop<AuthState, AuthAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        login: true
      }
    };

    return loop(
      newState,
      Cmd.run(AuthApiClient.loginByEmail, {
        args: [payload?.email || '', payload?.password || ''],
        successActionCreator: AuthInternalActions.loginByEmailSuccess,
        failActionCreator: AuthInternalActions.loginByEmailFailure,
      })
    );
  }

  public static handleLoginByEmailSuccess(state: AuthState): Loop<AuthState, AuthAction> {
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        login: false
      }
    };

    return loop(
      newState,
      Cmd.list([
        Cmd.action(AuthActions.getAuthenticatedUser())
      ])
    );
  }

  public static handleLoginByEmailFailure(state: AuthState): AuthState {
    return {
      ...state,
      error: new Error(AuthStateErrors.LOGIN_EMAIL),
      loading: {
        ...state.loading,
        login: false
      }
    };
  }

  public static handleRefreshAuthToken(state: AuthState): Loop<AuthState, AuthAction> {
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        refreshAuth: true
      }
    };

    return loop(
      newState,
      Cmd.run(AuthApiClient.refreshAuthToken, {
        args: [],
        successActionCreator: AuthInternalActions.refreshAuthTokenSuccess,
        failActionCreator: AuthInternalActions.refreshAuthTokenFailure,
      })
    );
  }

  public static handleRefreshAuthTokenSuccess(state: AuthState): Loop<AuthState, AuthAction> {
    const newState = {
      ...state,
      error: new Error(AuthStateErrors.REFRESH_AUTH),
      loading: {
        ...state.loading,
        refreshAuth: false
      }
    };

    return loop(
      newState,
      Cmd.list([
        Cmd.action(AuthActions.getAuthenticatedUser())
      ])
    );
  }

  public static handleRefreshAuthTokenFailure(state: AuthState): Loop<AuthState, AuthAction> {
    const newState = {
      ...state,
      error: new Error(AuthStateErrors.REFRESH_AUTH),
      loading: {
        ...state.loading,
        refreshAuth: false
      }
    };

    return loop(
      newState,
      Cmd.list([
        Cmd.action(AuthActions.logout())
      ])
    );
  }

  public static handleLogout(state: AuthState): Loop<AuthState, AuthAction> {
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        logout: true
      }
    };

    return loop(
      newState,
      Cmd.run(AuthApiClient.logout, {
        args: [],
        successActionCreator: AuthInternalActions.logoutSuccess,
        failActionCreator: AuthInternalActions.logoutFailure,
      })
    );
  }

  public static handleLogoutSuccess(state: AuthState): AuthState {
    return {
      ...state,
      authUser: defaultState.authUser,
      loading: {
        ...state.loading,
        logout: false
      }
    };
  }

  public static handleLogoutFailure(state: AuthState): AuthState {
    return {
      ...state,
      error: new Error(AuthStateErrors.USER_LOGOUT),
      loading: {
        ...state.loading,
        logout: false
      }
    };
  }
}
