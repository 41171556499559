import { useState } from 'react';
import { Flex, Icon, Text, Spacer, CloseButton } from '@chakra-ui/react';
import { AiFillInfoCircle } from 'react-icons/ai';

export interface InfoGuideProps {
  text: string | JSX.Element;
  onClick?: () => void;
  dismissable?: boolean;
}

export default function InfoGuide({
  text,
  onClick,
  dismissable = false
}: InfoGuideProps) {
  const [visible, setVisible] = useState(true);
  if (!visible) return null;

  return (
    <Flex
      gap={2}
      paddingX={2}
      paddingY={2}
      borderRadius={8}
      backgroundColor="gray.100"
      cursor={onClick ? 'pointer' : 'default'}
      onClick={onClick}
      alignItems={'center'}
    >
      <Icon as={AiFillInfoCircle} color="blue.300" />
      <Text fontSize="sm" fontWeight="thin" textAlign={'left'}>
        {text}
      </Text>
      <Spacer />
      {dismissable && (
        <CloseButton
          onClick={() => {
            setVisible(false);
          }}
        />
      )}
    </Flex>
  );
}
