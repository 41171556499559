import { useForm, Controller } from 'react-hook-form';
import {
	FormControl,
	FormLabel,
	Textarea,
	Stack,
} from '@chakra-ui/react';
import { Condition } from 'models/types';
import ConditionDropdown from '../../ConditionDropdown/ConditionDropdown';


export interface ItemBidAdditionalDataFormData {
	condition?: Condition;
	notes?: string;
}

interface ItemBidAdditionalDataFormProps {
	defaultFormData?: ItemBidAdditionalDataFormData;
	onChange?: (data: ItemBidAdditionalDataFormData) => void;
	onSubmit?: (data: ItemBidAdditionalDataFormData) => void;
	isDisabled?: boolean;
}

export default function ItemBidAdditionalDataForm({
	defaultFormData,
	onChange,
	onSubmit,
	isDisabled
}: ItemBidAdditionalDataFormProps) {
	const { control, register, getValues, setValue, handleSubmit } = useForm<ItemBidAdditionalDataFormData>({
		defaultValues: {
			condition: defaultFormData?.condition || Condition.GOOD,
			notes: defaultFormData?.notes || '',
		},
	});

	const onFormSubmit = (data: ItemBidAdditionalDataFormData) => {
		onSubmit?.(data);
	};

	const handleFormChange = () => {
		if (onChange) {
			onChange(getValues());
		}
	};


	const renderConditionDropdown = () => {
		return (
			<FormControl isDisabled={isDisabled} mb={4}>
				<FormLabel fontSize="sm" htmlFor="condition">
					Acceptable Condition
				</FormLabel>
				<Controller
					control={control}
					name="condition"
					render={() => (
						<ConditionDropdown
							defaultValue={defaultFormData?.condition}
							onChange={(condition: Condition) => {
								setValue('condition', condition, { shouldDirty: true })
								handleFormChange();
							}}
							customLabels={{
							  [Condition.POOR]: 'Poor or Better',
							  [Condition.FAIR]: 'Fair or Better',
							  [Condition.GOOD]: 'Good or Better',
							  [Condition.EXCELLENT]: 'Excellent or Mint',
							  [Condition.MINT]: 'Mint Only',
							}}
							isDisabled={isDisabled}
							size="sm"
						/>
					)}
				/>
			</FormControl>
		)
	};

	function renderNotes() {
		return (
			<FormControl isDisabled={isDisabled}>
					<FormLabel fontSize="sm" htmlFor="notes">
						Notes
					</FormLabel>
					<Textarea
						id="notes"
						placeholder="Enter any additional notes (max 200 characters)"
						size="sm"
						maxLength={200}
						{...register('notes')}
					/>
				</FormControl>
		);
	}

	return (
		<form onSubmit={handleSubmit(onFormSubmit)} onChange={handleFormChange}>
			<Stack spacing={4}>
				{renderConditionDropdown()}
				{renderNotes()}
			</Stack>
		</form>
	);
}
