import { useMemo } from 'react';
import { ArtistIndexModel, ArtistModel } from 'models';
import {
	Flex,
	Text,
	Card,
	CardHeader,
} from '@chakra-ui/react';
import RefreshBadge from 'components/RefreshBadge/RefreshBadge';
import ArtistMetricCard from '../Artist/ArtistMetricCard/ArtistMetricCard';
import ArtistIndexChartCore from './ArtistIndexChartCore';

interface ArtistIndexChartProps {
	artist: ArtistModel;
	artistIndexData: ArtistIndexModel;
	showLastCalculationDate?: boolean;
	showTitle?: boolean;
	showArtistName?: boolean;
	showFilters?: boolean;
	showMetricCard?: boolean;
}

function ArtistIndexChart({ artist, artistIndexData, showLastCalculationDate, showTitle = true, showArtistName = true, showFilters = true, showMetricCard = true }: ArtistIndexChartProps) {
	const dateSortedData = useMemo(() => {
		return artistIndexData.getDateSortedIndexData();
	}, [artistIndexData]);


	const renderTitle = () => {
		return (
			<Flex direction={'row'} gap={4} alignItems={'center'}>
				<Text textAlign="left" fontSize='md' fontWeight='bold' mb='6px'>
					Edition Index History
				</Text>
				{artistIndexData.recalculate && <RefreshBadge label={'Updating'} />}
			</Flex>
		);
	};

	function renderMetricCard() {
		if (showMetricCard && dateSortedData.length) {
			return (
				<ArtistMetricCard artist={artist} metric={dateSortedData[0].weightedIndex} priorMetricValue={dateSortedData[1]?.weightedIndex} showBorder={false} showLabel={showArtistName} />
			)
		}
	}

	function renderLastCalculationDate() {
		return (
			<Text fontWeight='light' textAlign={'start'} fontSize={'xs'}>
				{artistIndexData.lastCalculationDate?.format('llll')}
			</Text>
		)
	}

	return (
		<Card paddingTop={7} paddingX={4} borderRadius={8} width={'100%'} display={'flex'} flexDirection={'column'}>
			<CardHeader paddingY={0}>
				<Flex direction='column' alignSelf='flex-start' width={'100%'}>
					{showTitle && renderTitle()}
					{renderMetricCard()}
					{showLastCalculationDate && renderLastCalculationDate()}
				</Flex>
			</CardHeader>
			<ArtistIndexChartCore artistIndexData={artistIndexData} showFilters={showFilters} />
		</Card>
	);
}

export default ArtistIndexChart;
