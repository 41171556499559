import {
	SimpleGrid,
	Stack,
	Flex,
	Box,
	Heading,
	Text,
	Button,
	Icon,
	StackDivider,
	Spacer
} from '@chakra-ui/react';
import { FaCheckCircle, FaTimesCircle, FaShippingFast, FaInfoCircle, FaLifeRing, FaExclamationCircle } from 'react-icons/fa';
import { OrderModel } from 'models';
import { OrderStatus } from 'models/Order/types';
import { getCountryNameFromCountryCode } from 'utils/country';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import OrderReceipt from '../OrderReceipt/OrderReceipt';
import ProgressBar from '../../ProgressBar/ProgressBar';
import InfoGuide from 'components/InfoGuide/InfoGuide';

export interface OrderContainerProps {
	order: OrderModel;
	isBuyer: boolean;
	onSupportClick?: () => void;
	onItemNameClick?: (id: string, name: string) => void;
	onItemArtistClick?: (id: string, name: string) => void;
	onAddressButtonClick?: () => void;
}

export default function OrderContainer({
	order,
	isBuyer,
	onSupportClick,
	onItemNameClick,
	onItemArtistClick,
	onAddressButtonClick
}: OrderContainerProps) {
	function renderOrderStatusIndicator() {
		let color = 'gray.500';
		let icon = null;
		let label: string = order.orderStatus;
		let progress = 0;

		switch (order.orderStatus) {
			case OrderStatus.COMPLETE:
				color = 'green.500';
				icon = FaCheckCircle;
				label = 'Complete';
				progress = 100;
				break;
			case OrderStatus.CANCELLED:
				color = 'red.500';
				icon = FaTimesCircle;
				label = 'Cancelled';
				progress = 0;
				break;
			case OrderStatus.CONFIRMED:
				color = 'blue.500';
				icon = FaCheckCircle;
				label = 'Confirmed';
				progress = 50;
				break;
			case OrderStatus.IN_TRANSIT:
				color = 'blue.500';
				icon = FaShippingFast;
				label = 'In Transit';
				progress = 75;
				break;
			case OrderStatus.UNCONFIRMED:
				color = 'orange.500';
				icon = FaInfoCircle;
				label = 'Unconfirmed';
				progress = 25;
				break;
			default:
				color = 'gray.500';
				icon = FaInfoCircle;
				label = order.orderStatus;
				progress = 0;
		}

		return (
			<Stack gap={4} alignItems={'flex-start'}>
				<Stack gap={0} textAlign={'left'}>
					<Text fontSize="sm" fontWeight={'light'}>Status</Text>
					<Heading fontSize="lg">{order.orderStatus}</Heading>
				</Stack>

				<ProgressBar
					progress={progress}
					icon={icon}
					staticColor={color}
				/>
			</Stack>
		);
	}

	function renderAddressSection() {
		const shippingAddress = order.shippingAddress;
		const lineRow = shippingAddress
			? [shippingAddress.line1, shippingAddress.line2].filter(Boolean).join(', ')
			: '';
		const restRow = shippingAddress
			? [shippingAddress.city, shippingAddress.state, shippingAddress.postalCode]
				.filter(Boolean)
				.join(', ')
			: '';
		return (
			<Stack gap={4}>
				<Flex gap={4}>
					<Stack gap={0} textAlign={'left'}>
						<Flex alignItems={"center"} gap={2}>
							<Heading fontSize="md">Address</Heading>
							{shippingAddress && (
								<Text>{getUnicodeFlagIcon(shippingAddress.country)}</Text>
							)}
						</Flex>
						<Text fontSize="sm">Delivery Address</Text>
					</Stack>
					<Spacer />
					<Button onClick={onAddressButtonClick} variant="outline" disabled={!order.isUnconfirmed()}>
						{shippingAddress ? "Edit Address" : "Add Address"}
					</Button>
				</Flex>
				<Box mt={2}>
					{shippingAddress ? (
						<Stack gap={1}>
							<Heading fontSize="sm" textAlign="left">{lineRow}</Heading>
							<Heading fontSize="sm" textAlign="left">{restRow}</Heading>
							<Heading fontSize='sm' textAlign="left">
								{getCountryNameFromCountryCode(shippingAddress.country)}
							</Heading>
						</Stack>
					) : (
						<Flex align="center" color="red.500">
							<Icon as={FaExclamationCircle} w={5} h={5} mr={2} />
							<Heading fontSize="sm">Shipping address is required to proceed.</Heading>
						</Flex>
					)}
				</Box>
				{order.hasShippingAddress() && !order.isUnconfirmed() && (
					<InfoGuide text={'Please Contact Support If You Need to Update Address'} />
				)}
			</Stack>
		);
	}

	function renderSupportSection() {
		return (
			<Stack gap={4} alignItems={'flex-start'}>
				<Stack gap={0} textAlign={'left'}>
					<Heading fontSize="md">Support</Heading>
					<Text fontSize="sm">Need help with your order? We're here 24/7.</Text>
				</Stack>
				<Button
					paddingY={6}
					color="blue.700"
					border="1px solid"
					borderColor="blue.700"
					backgroundColor="blue.50"
					_hover={{ backgroundColor: "blue.100" }}
					fontSize="md"
					leftIcon={<Icon as={FaLifeRing} />}
					width="100%"
					onClick={onSupportClick}
				>
					Support
				</Button>
			</Stack>
		);
	}

	return (
		<SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
			<OrderReceipt order={order} isBuyer={isBuyer} onItemArtistClick={onItemArtistClick} onItemNameClick={onItemNameClick} />
			<Stack spacing={8} divider={<StackDivider borderColor='gray.100' />}>
				{renderOrderStatusIndicator()}
				{renderAddressSection()}
				{renderSupportSection()}
			</Stack>
		</SimpleGrid>
	);
}
