import { Component } from 'react';
import { connect, Dispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { RouterProps, withRouter, getItemActionRoute } from "utils/route";
import { ApplicationState } from 'reducers/types';
import { UserSelectors } from 'reducers/User/selectors';
import { AuthSelectors } from 'reducers/Auth/selectors';
import {
	Box,
} from '@chakra-ui/react';
import { TradingFloor, Hero, LockedContent, SEOHelmet } from 'components';
import { UserModel, ItemBidModel, ItemAskModel } from 'models';
import { NavigationService } from 'services';
import { AppConstants } from '../../constants';
import { ItemActiveMarketData } from 'models/Item/types';
import { ActiveMarketActions } from 'reducers/ActiveMarket/actions';
import { ActiveMarketSelectors } from 'reducers/ActiveMarket/selectors';
import { generateSEOLink, generateSEOTitle } from 'utils/seo';


interface TradingFloorViewProps extends RouterProps {
	user: UserModel;
	userLoading: boolean;
	allActiveMarket: ItemActiveMarketData;
	allActiveMarketLoading: boolean;
	userActiveMarketActionLoading: boolean;
	getAllActiveMarket: () => void;
}


class TradingFloorBase extends Component<TradingFloorViewProps, {}> {
	componentDidMount() {
		const { getAllActiveMarket, user } = this.props;
		if (user.isAuthenticated()) {
			getAllActiveMarket();
		}
	}

	componentDidUpdate(prevProps: TradingFloorViewProps) {
		const { getAllActiveMarket, user } = this.props;
		if (user.isAuthenticated() && !prevProps.user.isAuthenticated()) {
			getAllActiveMarket();
		}
	}

	onItemBidEditClick = (itemBid: ItemBidModel) => {
		const { user } = this.props;
		this.props.navigate(getItemActionRoute(user, itemBid, 'Edit'));
	}

	onItemBidRemoveClick = (itemBid: ItemBidModel) => {
		const { user } = this.props;
		this.props.navigate(getItemActionRoute(user, itemBid, 'Remove'));
	}

	onItemBidContactClick = (itemBid: ItemBidModel) => {
		const { user } = this.props;
		this.props.navigate(getItemActionRoute(user, itemBid, 'Contact'));
	}

	onItemBidSellClick = (itemBid: ItemBidModel) => {
		const { user } = this.props;
		this.props.navigate(getItemActionRoute(user, itemBid, 'Sell'));
	}

	onItemAskEditClick = (itemAsk: ItemAskModel) => {
		const { user } = this.props;
		this.props.navigate(getItemActionRoute(user, itemAsk, 'Edit'));
	}

	onItemAskRemoveClick = (itemAsk: ItemAskModel) => {
		const { user } = this.props;
		this.props.navigate(getItemActionRoute(user, itemAsk, 'Remove'));
	}

	onItemAskContactClick = (itemAsk: ItemAskModel) => {
		const { user } = this.props;
		this.props.navigate(getItemActionRoute(user, itemAsk, 'Contact'));
	}

	onItemAskBuyClick = (itemAsk: ItemAskModel) => {
		const { user } = this.props;
		this.props.navigate(getItemActionRoute(user, itemAsk, 'Buy'));
	}

	onTradingFloorItemClick = (id: string, name: string) => {
		this.props.navigate(NavigationService.getItemDetailsPath(id, name));
	};

	onTraingFloorArtistNameClick = (artistId: string, artistName: string) => {
		this.props.navigate(NavigationService.getArtistDetailsPath(artistId, artistName));
	};

	onLockedContentLoginClick = () => {
		this.props.navigate(NavigationService.getAuthLoginPath());
	}

	renderTradingFloor() {
		const { user, userLoading, allActiveMarket, allActiveMarketLoading } = this.props;
		if (user.isAuthenticated()) {
			return (
				<Box padding={{ base: "10px", md: "0px" }} marginTop={8}>
					<TradingFloor
						asks={allActiveMarket.asks}
						bids={allActiveMarket.bids}
						user={user}
						showSearchFilter={true}
						onItemNameClick={this.onTradingFloorItemClick}
						onItemArtistNameClick={this.onTraingFloorArtistNameClick}
						isLoading={userLoading || allActiveMarketLoading}
						onEditBidClick={this.onItemBidEditClick}
						onRemoveBidClick={this.onItemBidRemoveClick}
						onEditAskClick={this.onItemAskEditClick}
						onRemoveAskClick={this.onItemAskRemoveClick}
						onContactBidClick={this.onItemBidContactClick}
						onContactAskClick={this.onItemAskContactClick}
						onSellBidClick={this.onItemBidSellClick}
						onBuyAskClick={this.onItemAskBuyClick}
					/>
				</Box>
			);
		} else {
			return (
				<LockedContent description={`Please Log In To View Trading Floor`} onButtonClick={this.onLockedContentLoginClick} />
			);
		}
	}

	renderHero() {
		return (
			<Hero
				title="The Art Market: Redefined"
				subtitle='Fueled with efficiency and transparency, our Trading Floor gives visibility to the best deals in our network with complete ease.'
				image="https://storage.googleapis.com/artcore-image-set/content-images/Beige%20Artist%208%20Grid%20Collage.png" />
		);
	}

	render() {
		return (
			<>
				<SEOHelmet
					title={generateSEOTitle('Trading Floor')}
					description={`Discover the best investment-grade art market deals with effeciency and transparency provided by our valuation engine.`}
					link={generateSEOLink(NavigationService.getTradingFloorPath())}
				/>
				<Box maxWidth={`${AppConstants.GRIDPAGE_WIDTH}px`} paddingTop={['80px', '100px', '100px']} justifySelf="center" minWidth={['100%', `${AppConstants.GRIDPAGE_WIDTH}px`]}>
					{this.renderHero()}
					{this.renderTradingFloor()}
				</Box>
			</>
		);
	}
}

function mapStateToProps(state: ApplicationState) {
	return {
		user: UserSelectors.getUser(state),
		userLoading: UserSelectors.getUserLoading(state) || AuthSelectors.getUserLoading(state),
		allActiveMarket: ActiveMarketSelectors.getAllActiveMarket(state),
		allActiveMarketLoading: ActiveMarketSelectors.getAllActiveMarketLoading(state)
	};
}

function mapDispatchToProps(dispatch: Dispatch<ApplicationState>) {
	return bindActionCreators(
		{
			getAllActiveMarket: () => ActiveMarketActions.getAllActiveMarket(),
		},
		dispatch
	);
}

export const TradingFloorView = connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(TradingFloorBase));
