import { RawItemData } from "../Item/types";
import { RawAddress } from "../Address/Address";
import { Fee } from '../types';
import { Condition } from '../types';

export interface RawOrderFees {
	buyer_fees: Fee[];
	seller_fees: Fee[];
}

export interface RawOrderAmounts {
	gross_total: number;
	net_total: number;
	sub_total: number;
}

export interface RawOrderTimestamps {
	confirmed_at: string | null,
	created_at: string,
	delivered_at: string | null,
	paid_at: string | null,
	shipped_at: string | null,
	updated_at: string
}

export interface OrderRatings {
	buyer: number;
	seller: number;
}

export interface TrackingInfo {
	number: string;
	company: string;
}

export interface RawOrderData {
	id: string;
	item: RawItemData;
	artist_id: string;
	buyer_id: string;
	seller_id: string;
	order_status: OrderStatus;
	payment_status: OrderPaymentStatus;
	payout_status: OrderPayoutStatus;
	use_escrow: boolean;
	fees: RawOrderFees;
	amounts: RawOrderAmounts;
	tracking_info?: TrackingInfo;
	shipping_address?: RawAddress;
	ratings?: OrderRatings;
	timestamps: RawOrderTimestamps;
	payment_link?: string;
  condition: Condition;
}

export enum OrderPaymentStatus {
	PENDING = 'Pending',
	COMPLETE = 'Complete',
	FAILED = 'Failed'
}

export enum OrderPayoutStatus {
	PENDING = 'Pending',
	COMPLETE = 'Complete',
	FAILED = 'Failed'
}

export enum OrderStatus {
	UNCONFIRMED = 'Unconfirmed',
	CONFIRMED = 'Confirmed',
	IN_TRANSIT = 'In Transit',
	COMPLETE = 'Complete',
	CANCELLED = 'Cancelled'
}