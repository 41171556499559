import { Component } from 'react';
import { connect, Dispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { RouterProps, withRouter } from "utils/route";
import { ApplicationState } from 'reducers/types';
import { UserActions } from 'reducers/User/actions';
import { UserSelectors } from 'reducers/User/selectors';
import {
  Box,
  Flex
} from '@chakra-ui/react';
import { UserOnboardingWizard, Logo } from 'components';
import { UserOnboardingWizardData } from 'components/Wizard/UserOnboardingWizard/UserOnboardingWizard';
import { UserModel } from 'models';
import { JSONObject } from 'models/Api/types';
import { NavigationService } from 'services';
import { AppConstants } from '../../constants';
import { convertJSONObjectToSnakecase } from 'utils/object';

interface UserOnboardingViewProps extends RouterProps {
  user: UserModel;
  migrateUserImage: (url: string) => void;
  updateUserData: (userData: JSONObject) => void;
  userImageActionLoading: boolean;
  userUpdateActionLoading: boolean;
}

class UserOnboarding extends Component<UserOnboardingViewProps, {}> {
  componentDidUpdate(prevProps: UserOnboardingViewProps) {
    const { user, navigate } = this.props;
    if (prevProps.user.isAuthenticated() && !user.isAuthenticated()) {
      navigate(NavigationService.getAuthLoginPath());
    }
  }

  onOnboardingWizardSubmit = (data: UserOnboardingWizardData ) => {
    const { migrateUserImage, updateUserData, navigate } = this.props;
    const userAddress = data.address;
    const userImage = data.image;
    delete data.image;
    delete data.address;

    const snakeCasedData: JSONObject = convertJSONObjectToSnakecase(data as JSONObject);

    if (userImage) {
      migrateUserImage(userImage);
    }

    if (userAddress) {
      snakeCasedData['address'] = {
        'primary_address': userAddress.toObject()
      };
    }

    updateUserData(snakeCasedData);
    navigate(NavigationService.getHomePath())
  }


  render() {
    const { userImageActionLoading,  userUpdateActionLoading, user } = this.props;
    return (
      <Box maxWidth={`${AppConstants.GRIDPAGE_WIDTH}px`} paddingTop={['80px', '100px', '100px']} justifySelf="center" minWidth={['100%', `${AppConstants.GRIDPAGE_WIDTH}px`]}>
        <Flex direction={'column'} alignItems={'center'} gap={10}>
          <Logo height={'120px'} width={'120px'} />
          <UserOnboardingWizard user={user} submitLoading={userImageActionLoading || userUpdateActionLoading} onSubmit={this.onOnboardingWizardSubmit} />
        </Flex>
      </Box>
    );
  }
}

function mapStateToProps(state: ApplicationState) {
  return {
    user: UserSelectors.getUser(state),
    userImageActionLoading: UserSelectors.getUserImageActionLoading(state),
    userUpdateActionLoading: UserSelectors.getUserUpdateActionLoading(state)
  }
}

function mapDispatchToProps(dispatch: Dispatch<ApplicationState>) {
  return bindActionCreators(
    {
      migrateUserImage: (url: string) => UserActions.migrateImage(url),
      updateUserData: (userData: JSONObject) => UserActions.updateUserData(userData),
    },
    dispatch
  );
}

export const UserOnboardingView = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserOnboarding));
