import { Component } from 'react';
import { connect, Dispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { RouterProps, withRouter } from "utils/route";
import { ApplicationState } from 'reducers/types';
import { ArtistSelectors } from 'reducers/Artist/selectors';
import { ItemSelectors } from 'reducers/Item/selectors';
import { MaterialSelectors } from 'reducers/Material/selectors';
import { ItemActions } from 'reducers/Item/actions';
import {
  Box,
  Circle,
  Icon,
  Stack
} from '@chakra-ui/react';
import { PaginationModel, ItemModel, ArtistModel, MaterialModel } from 'models';
import { PaginatedItemGrid, Modal, ItemSearchFiltersForm, SEOHelmet } from 'components';
import { ItemSearchFiltersFormData } from 'components/Form/ItemSearchFiltersForm/ItemSearchFiltersForm';
import { NavigationService } from 'services';
import { AppConstants } from '../../constants';
import { JSONObject } from 'models/Api/types';
import { BsSearch } from 'react-icons/bs';
import { convertSearchFilterDataToQuery, convertQueryToSearchFilterData } from 'utils/search';
import { QuerySearchParameters } from 'constants/url';
import { getEligibleQueryParamsFromUrl } from 'utils/url';
import { generateSEOTitle, generateSEOLink } from 'utils/seo';

interface ItemSearchViewProps extends RouterProps {
  searchLoading: boolean;
  searchResults: PaginationModel<ItemModel>;
  artistList: ArtistModel[];
  artistListLoading: boolean;
  materialList: MaterialModel[];
  materialListLoading: boolean;
  onSearch: (query: JSONObject) => void;
  getAllItems: (page?: number) => void;
}

interface ItemSearchViewState {
  modals: ItemSearchViewModalState;
  searchFilters: ItemSearchFiltersFormData | undefined;
}

interface ItemSearchViewModalState {
  searchFiltersModal: boolean;
}


class ItemSearch extends Component<ItemSearchViewProps, ItemSearchViewState> {
  state = {
    searchFilters: undefined,
    modals: {
      searchFiltersModal: false
    }
  };

  componentDidMount(): void {
    const { location } = this.props;
    const queryParams: JSONObject = getEligibleQueryParamsFromUrl(location, [QuerySearchParameters.SORT_BY, QuerySearchParameters.SORT_TYPE, QuerySearchParameters.TYPE, QuerySearchParameters.PAGE]);

    if (!!Object.keys(queryParams).length) {
      this.setState({ searchFilters: convertQueryToSearchFilterData(queryParams) });
      this.props.onSearch(queryParams);
    } else {
      this.props.getAllItems();
    }
  }

  onItemSearchQueryModalOpen = () => {
    this.setState({
      modals: {
        ...this.state.modals,
        searchFiltersModal: true
      }
    });
  }

  onItemSearchQueryModalClose = () => {
    this.setState({
      modals: {
        ...this.state.modals,
        searchFiltersModal: false
      }
    });
  }

  onItemSearchFiltersFormSubmit = (searchQuery: ItemSearchFiltersFormData) => {
    this.setState({ searchFilters: searchQuery });
    this.props.onSearch(convertSearchFilterDataToQuery(searchQuery));
    this.onItemSearchQueryModalClose();
  }

  onItemsPageSelect = (page: number) => {
    const { searchFilters } = this.state;
    if (searchFilters) {
      this.props.onSearch({
        ...convertSearchFilterDataToQuery(searchFilters),
        page: page
      });
    } else {
      this.props.getAllItems(page);
    }
    this.updatePageInURL(page);
  }

  updatePageInURL = (page: number): void => {
    const queryParams = new URLSearchParams(this.props.location.search);
    queryParams.set(QuerySearchParameters.PAGE, page.toString());
    this.props.navigate(`${this.props.location.pathname}?${queryParams.toString()}`, { replace: true });
  };

  onItemClick = (item: ItemModel) => {
    this.props.navigate(NavigationService.getItemDetailsPath(item.id, item.getDisplayName()));
  };

  renderSearchResults() {
    const { searchLoading, searchResults } = this.props;
    return (
      <PaginatedItemGrid
        onItemCardClick={this.onItemClick}
        paginatedItems={searchResults}
        onPageSelect={this.onItemsPageSelect}
        scrollMode={false}
        gridMaxHeight={1200}
        isLoading={searchLoading} />
    );
  }

  renderSearchButton() {
    return (
      <Circle size='40px' bg='gray.300' left={'50%'} transform={'translateX(-50%)'} position={'fixed'} bottom={100} cursor={'pointer'} onClick={this.onItemSearchQueryModalOpen}>
        <Icon as={BsSearch} color={'white.100'} />
      </Circle>
    );
  }

  renderItemSearchFiltersModalContent() {
    const { artistList, materialList, searchLoading } = this.props;
    const { searchFilters } = this.state;
    return (
      <ItemSearchFiltersForm artistList={artistList} materialList={materialList} submitLoading={searchLoading} defaultFormData={searchFilters} onSubmit={this.onItemSearchFiltersFormSubmit} />
    );
  }


  renderModals() {
    const { modals } = this.state;
    return (
      <Stack>
        <Modal title={'Search Filters'} isOpen={!!modals.searchFiltersModal} onClose={this.onItemSearchQueryModalClose} content={this.renderItemSearchFiltersModalContent()} showCloseButton={false} showSubmitButton={false} />
      </Stack>
    );
  }


  render() {
    return (
      <>
        <SEOHelmet
          title={generateSEOTitle('Search')}
          description={`Explore the catalogue of your favorite artists with ease and complete transparency.`}
          link={generateSEOLink(NavigationService.getItemSearchPath())}
        />
        <Box maxWidth={`${AppConstants.GRIDPAGE_WIDTH}px`} paddingTop={['80px', '100px', '100px']} justifySelf="center" minWidth={['100%', `${AppConstants.GRIDPAGE_WIDTH}px`]}>
          {this.renderSearchResults()}
          {this.renderSearchButton()}
          {this.renderModals()}
        </Box>
      </>
    );
  }
}

function mapStateToProps(state: ApplicationState) {
  return {
    searchLoading: ItemSelectors.getItemSearchLoading(state),
    searchResults: ItemSelectors.getPaginatedItems(state),
    artistList: ArtistSelectors.getArtistList(state),
    artistListLoading: ArtistSelectors.getArtistListLoading(state),
    materialList: MaterialSelectors.getMaterialList(state),
    materialListLoading: MaterialSelectors.getMaterialListLoading(state)
  }
}

function mapDispatchToProps(dispatch: Dispatch<ApplicationState>) {
  return bindActionCreators(
    {
      onSearch: ItemActions.searchItem,
      getAllItems: ItemActions.getAllItems
    },
    dispatch
  );
}

export const ItemSearchView = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ItemSearch));
