import { connect, Dispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { ApplicationState } from 'reducers/types';
import { AuthActions } from 'reducers/Auth/actions';
import { ItemActions } from 'reducers/Item/actions';
import { ListingActions } from 'reducers/Listing/actions';
import { UserSelectors } from 'reducers/User/selectors';
import { ItemSelectors } from 'reducers/Item/selectors';
import { ListingSelectors } from 'reducers/Listing/selectors';
import {
  Box,
  Flex,
  Avatar,
  Heading,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuDivider,
  Spacer,
  useColorModeValue,
  useBreakpointValue,
  Stack,
  Center,
  Icon,
  Text,
  Tooltip
} from '@chakra-ui/react';
import { AiFillHome, AiOutlineHeart } from 'react-icons/ai';
import { ImStatsDots } from 'react-icons/im';
import { RiAuctionLine } from 'react-icons/ri';
import { GiThorHammer } from "react-icons/gi";
import { BsGear, BsPersonCircle, BsFolder, BsFolderFill, BsBell } from 'react-icons/bs';
import { AiOutlinePlusCircle, AiFillFormatPainter } from 'react-icons/ai';
import { IoStorefront } from 'react-icons/io5';
import { RouterProps, withRouter } from "utils/route";
import { CredentialService, NavigationService, FeatureToggleService } from 'services';
import { SearchBar, Logo, BasicItemCard, ListingAccordion, TabGroup } from 'components';
import { UserModel, ItemModel, ListingModel, PaginationModel } from 'models';
import { AppConstants } from '../../constants';
import { FeatureToggleKeys } from '../../constants/toggles';

export interface NavBarProps extends RouterProps {
  user: UserModel;
  onLogout?: () => void;
  onSearch?: (query: string) => any;
  onListingSearch?: (query: string) => any;
  searchLoading?: boolean;
  searchResults: PaginationModel<ItemModel>;
  listingSearchResults: PaginationModel<ListingModel>;
  listingSearchLoading?: boolean;
}

function NavBarUnconnected({ navigate, user, onLogout, onSearch, onListingSearch, searchLoading, searchResults, listingSearchResults, listingSearchLoading }: NavBarProps) {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const isAuthenticated = user.isAuthenticated() || CredentialService.isAuthenticated();
  const requestMoreHoverColor = useColorModeValue('gray.200', 'gray.800');
  const userPortfolioPath = NavigationService.getPortfolioDetailsPath(user.getPortfolioId());
  const userWishlistPath = NavigationService.getWishlistDetailsPath(user.getWishlistId());
  const isPortfolioRoute = location.pathname === userPortfolioPath;
  const isWishlistRoute = location.pathname === userWishlistPath;
  const isArtistsRoute = location.pathname === NavigationService.getArtistsPath();
  const isHomeRoute = location.pathname === NavigationService.getHomePath();
  const isTradingFloorRoute = location.pathname === NavigationService.getTradingFloorPath();
  const isProfileRoute = location.pathname === NavigationService.getUserProfilePath();
  const isUserActiveMarketRoute = location.pathname === NavigationService.getUserActiveMarketPath();
  const isFollowingRoute = location.pathname === NavigationService.getUserFollowingPath();
  const isSettingsRoute = location.pathname === NavigationService.getUserSettingsPath();

  function renderItemRequestMore() {
    return (
      <Flex direction={'row'} gap='10px' padding='20px' justifyContent='center' alignItems='center' cursor='pointer' onClick={onItemRequestMoreClick} _hover={{ 'backgroundColor': requestMoreHoverColor }}>
        <Icon as={AiOutlinePlusCircle} />
        <Heading size="sm">
          Request an Item
        </Heading>
      </Flex>
    );
  }

  function renderNavSearchResults() {
    return (
      <Flex direction="column" justifyContent="center">
        {searchResults.data.map((item, index) => <BasicItemCard key={`navBar_item_${index}`} item={item} onClick={() => onSearchResultClick(item)} useAvatar={true} />)}
        {renderItemRequestMore()}
      </Flex>
    );
  }

  function renderNavListingsSearchResults() {
    return (
      <Box>
        {listingSearchResults.data.map((listing, index) => <ListingAccordion key={`navBar_listing_${index}`} listing={listing} />)}
      </Box>
    );
  }

  function renderSearchResults() {
    if (FeatureToggleService.isToggleEnabled(FeatureToggleKeys.NAV_BAR_SEARCH_LISTINGS)) {
      const labels = [
        <Flex gap="4px" alignItems="center" direction={{ base: 'column', md: 'row' }}>
          <Icon as={ImStatsDots} />
          <Text> Items </Text>
        </Flex>,
        <Flex gap="4px" alignItems="center" direction={{ base: 'column', md: 'row' }}>
          <Icon as={RiAuctionLine} />
          <Text> Past Sales </Text>
        </Flex>
      ];

      const content = [
        renderNavSearchResults(),
        renderNavListingsSearchResults()
      ]

      return <TabGroup labels={labels} content={content} />
    } else {
      return renderNavSearchResults();
    }
  }

  const onItemRequestMoreClick = () => {
    navigate(NavigationService.getContactPath())
  }

  const onLogoClick = () => {
    navigate(NavigationService.getHomePath());
  }

  const onLinkButtonClick = (path: string) => {
    navigate(path);
  };

  const onSearchResultClick = (item: ItemModel) => {
    navigate(NavigationService.getItemDetailsPath(item.id, item.getDisplayName()))
  };

  const onNavbarSearch = (query: string) => {
    if (query.length > 3) {
      if (onSearch) {
        onSearch(query);
      }

      if (onListingSearch && FeatureToggleService.isToggleEnabled(FeatureToggleKeys.NAV_BAR_SEARCH_LISTINGS)) {
        onListingSearch(query);
      }
    }
  }

  function renderUserMenuButtons() {
    return (
      <Flex direction={'row'} gap={'16px'} display={{ base: 'none', md: 'flex' }}>
        <Tooltip label={'Home'}>
          <Button onClick={() => onLinkButtonClick(NavigationService.getHomePath())} disabled={isHomeRoute}>
            <Icon as={AiFillHome} />
          </Button>
        </Tooltip>
        <Tooltip label={'Trading Floor'}>
          <Button onClick={() => onLinkButtonClick(NavigationService.getTradingFloorPath())} disabled={isTradingFloorRoute}>
            <Icon as={IoStorefront} />
          </Button>
        </Tooltip>
        <Tooltip label={'Artists'}>
          <Button onClick={() => onLinkButtonClick(NavigationService.getArtistsPath())} disabled={isArtistsRoute}>
            <Icon as={AiFillFormatPainter} />
          </Button>
        </Tooltip>
        <Tooltip label={'Portfolio'}>
          <Button onClick={() => onLinkButtonClick(userPortfolioPath)} disabled={isPortfolioRoute}>
            <Icon as={BsFolderFill} />
          </Button>
        </Tooltip>
      </Flex>
    );
  }

  function renderUserMenu() {
    return (
      <Stack direction={'row'} spacing={{ base: '2', md: '8' }}>
        {renderUserMenuButtons()}
        <Menu closeOnSelect={false}>
          <MenuButton
            as={Button}
            rounded={'full'}
            variant={'link'}
            cursor={'pointer'}
            paddingLeft={{ base: 2, md: 0 }}
            minW={0}>
            <Avatar
              src={user.getImage()}
              size={'sm'}
            />
          </MenuButton>
          <MenuList alignItems={'center'} fontSize={'sm'}>
            <br />
            <Center>
              <Avatar
                src={user.getImage()}
                size={'xl'}
              />
            </Center>
            <br />
            <Center>
              <Heading fontSize="lg">{user.userName}</Heading>
            </Center>
            <br />
            <MenuDivider />
            <MenuItemOption onClick={() => onLinkButtonClick(NavigationService.getUserProfilePath())} isChecked={isProfileRoute}>
              <Icon as={BsPersonCircle} marginRight={2} /> Profile
            </MenuItemOption>
            <MenuItemOption onClick={() => onLinkButtonClick(NavigationService.getPortfolioDetailsPath(user.getPortfolioId()))} isChecked={isPortfolioRoute}>
              <Icon as={BsFolder} marginRight={2} /> Portfolio
            </MenuItemOption>
            <MenuItemOption onClick={() => onLinkButtonClick(NavigationService.getUserActiveMarketPath())} isChecked={isUserActiveMarketRoute}>
              <Icon as={GiThorHammer} marginRight={2} /> Asks & Bids
            </MenuItemOption>
            <MenuItemOption onClick={() => onLinkButtonClick(NavigationService.getWishlistDetailsPath(user.getWishlistId()))} isChecked={isWishlistRoute}>
              <Icon as={AiOutlineHeart} marginRight={2} /> Favorites
            </MenuItemOption>
            <MenuItemOption onClick={() => onLinkButtonClick(NavigationService.getUserFollowingPath())} isChecked={isFollowingRoute}>
              <Icon as={BsBell} marginRight={2} /> Following
            </MenuItemOption>
            <MenuItemOption onClick={() => onLinkButtonClick(NavigationService.getUserSettingsPath())} isChecked={isSettingsRoute}>
              <Icon as={BsGear} marginRight={2} /> Settings
            </MenuItemOption>
            <MenuDivider />
            <MenuItem onClick={onLogout} justifyContent={'center'}>
              Logout
            </MenuItem>
          </MenuList>
        </Menu>
      </Stack>
    );
  }

  function renderHomeLogo() {
    if (isMobile) {
      return (
        <Menu closeOnSelect={false}>
          <MenuButton
            as={Button}
            rounded={'full'}
            variant={'link'}
            cursor={'pointer'}
            minW={0}>
            <Logo h={10} />
          </MenuButton>
          <MenuList alignItems={'center'} fontSize="sm">
            <MenuItemOption onClick={() => onLinkButtonClick(NavigationService.getHomePath())} isChecked={isHomeRoute}>
              <Icon as={AiFillHome} marginRight={2} /> Home
            </MenuItemOption>
            <MenuItemOption onClick={() => onLinkButtonClick(NavigationService.getTradingFloorPath())} isChecked={isTradingFloorRoute}>
              <Icon as={IoStorefront} marginRight={2} /> Trading Floor
            </MenuItemOption>
            <MenuItemOption onClick={() => onLinkButtonClick(NavigationService.getArtistsPath())} isChecked={isArtistsRoute}>
              <Icon as={AiFillFormatPainter} marginRight={2} /> Artists
            </MenuItemOption>
            <MenuItemOption onClick={() => onLinkButtonClick(userPortfolioPath)} isChecked={isPortfolioRoute}>
              <Icon as={BsFolderFill} marginRight={2} /> Portfolio
            </MenuItemOption>
          </MenuList>
        </Menu>
      );
    } else {
      return (
        <Logo onClick={onLogoClick} h={10} cursor="pointer" />
      );
    }
  }

  function renderAuthButtons() {
    return (
      <Stack direction={'row'} spacing={{ base: '2', md: '8' }}>
        <Button color={'black'} variant='outline' width='100px' onClick={() => onLinkButtonClick(NavigationService.getAuthRegisterPath())}>
          Register
        </Button>
        <Button color={'black'} variant='solid' width='100px' onClick={() => onLinkButtonClick(NavigationService.getAuthLoginPath())}>
          Log In
        </Button>
      </Stack>
    );
  }

  function renderSearchbar() {
    if (isAuthenticated) {
      return (
        <Box paddingLeft="28px">
          <SearchBar onChange={onNavbarSearch} resultsLoading={searchLoading || listingSearchLoading} results={renderSearchResults()} showResultBox={true} hasValidResults={searchResults.hasFetchedData()} />
        </Box>
      );
    }
  }

  return (
    <>
      <Box zIndex="overlay" position="sticky" top="0" alignItems="center" height={`${AppConstants.NAVBAR_HEIGHT}px`} bg={useColorModeValue('white', 'black')} px={4} borderBottom='1px' borderColor={useColorModeValue('gray.100', 'gray.900')}>
        <Flex h={16} alignItems={'center'} maxWidth={`${AppConstants.NAVBAR_MAXWIDTH}px`} margin="0 auto">
          <Box>{renderHomeLogo()}</Box>
          {renderSearchbar()}
          <Spacer />
          <Flex alignItems={'center'}>
            {isAuthenticated ? renderUserMenu() : renderAuthButtons()}
          </Flex>
        </Flex>
      </Box>
    </>
  );
}

function mapStateToProps(state: ApplicationState) {
  return {
    user: UserSelectors.getUser(state),
    searchLoading: ItemSelectors.getItemSearchLoading(state),
    searchResults: ItemSelectors.getPaginatedItems(state),
    listingSearchLoading: ListingSelectors.getListingSearchLoading(state),
    listingSearchResults: ListingSelectors.getPaginatedListings(state)
  }
}

function mapDispatchToProps(dispatch: Dispatch<ApplicationState>) {
  return bindActionCreators(
    {
      onLogout: AuthActions.logout,
      onSearch: ItemActions.searchItemsByQuery,
      onListingSearch: ListingActions.searchListingsByQuery
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NavBarUnconnected));
