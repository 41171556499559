import { Component } from 'react';
import { connect, Dispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { RouterProps, withRouter, getItemActionRoute, useAuthCheck } from "utils/route";
import { ApplicationState } from 'reducers/types';
import { ArtistSelectors } from 'reducers/Artist/selectors';
import { UserSelectors } from 'reducers/User/selectors';
import { ArtistActions } from 'reducers/Artist/actions';
import { UserActions } from 'reducers/User/actions';
import { ListingActions } from 'reducers/Listing/actions';
import { ListingSelectors } from 'reducers/Listing/selectors';
import { ItemListingGroupingActions } from 'reducers/ItemListingGrouping/actions';
import { ItemListingGroupingSelectors } from 'reducers/ItemListingGrouping/selectors';
import { ListingDataSuggestionActions } from 'reducers/ListingDataSuggestion/actions';
import { ListingDataSuggestionSelectors } from 'reducers/ListingDataSuggestion/selectors';
import {
  Box,
  Icon,
  Flex,
  Text,
  Stack,
  Circle,
  Tag
} from '@chakra-ui/react';
import { IoGridOutline, IoStorefrontOutline } from 'react-icons/io5';
import { RiAuctionLine } from 'react-icons/ri';
import { BsSearch } from 'react-icons/bs';
import { ImStatsDots } from 'react-icons/im';
import { JSONObject } from 'models/Api/types';
import { ArtistModel, ItemModel, UserModel, ListingModel, PaginationModel, ItemBidModel, ItemAskModel } from 'models';
import { ItemGrid, PaginatedListingGrid, TabGroup, ArtistBanner, ListingItemSuggestionWizard, ListingDataSuggestionWizard, Modal, ListingSearchFiltersForm, ArtistBannerSkeleton, TradingFloor, LockedContent, InfoGuide, SEOHelmet } from 'components';
import { ListingSearchFiltersFormData } from 'components/Form/ListingSearchFiltersForm/ListingSearchFiltersForm';
import { NavigationService, FeatureToggleService } from 'services';
import { FeatureToggleKeys } from 'constants/toggles';
import { AppConstants } from '../../constants';
import { QuerySearchParameters } from 'constants/url';
import { convertListingSearchFilterDataToQuery } from 'utils/search';
import { getSelectedTabFromURL, getPageFromURL } from 'utils/url';
import { generateSEOTitle, createArtistStructuredData, generateArtistSEOLink } from 'utils/seo';
import ArtistIndexChart from 'components/Charts/ArtistIndexChart';

interface ArtistDetailsViewProps extends RouterProps {
  artist: ArtistModel;
  user: UserModel;
  artistItemLoading: boolean;
  artistLoading: boolean;
  artistListingLoading: boolean;
  artistEditionIndexLoading: boolean;
  listingSearchLoading: boolean;
  userFollowingArtistsLoading: boolean;
  userArtistActionLoading: boolean;
  artistActiveMarketLoading: boolean;
  createItemListingGroupingLoading: boolean;
  createListingDataSuggestionLoading: boolean;
  listingSearchResults: PaginationModel<ListingModel>;
  getArtist: (id: string) => void;
  getArtistItems: (id: string) => void;
  getArtistEditionIndex: (id: string) => void;
  getArtistActiveMarket: (id: string) => void;
  getArtistListings: (id: string, page?: number) => void;
  followArtist: (id: string) => void;
  unfollowArtist: (id: string) => void;
  createItemListingGrouping: (itemId: string, listingIds: string[]) => void;
  createListingDataSuggestion: (listingId: string, data: JSONObject) => void;
  onListingSearch: (query: JSONObject) => void;
}

interface ArtistDetailsViewModalState {
  listingItemSuggestionModal: ListingModel | undefined;
  listingDataSuggestionModal: ListingModel | undefined;
  listingSearchFiltersModal: boolean;
}

interface ArtistDetailsState {
  fetchedAdditionalData: boolean;
  modals: ArtistDetailsViewModalState;
  listingItemSuggestionSelectedItem: ItemModel | undefined;
  listingSearchFilters: ListingSearchFiltersFormData | undefined;
  listingSearchMode: boolean;
}

class ArtistDetails extends Component<ArtistDetailsViewProps, ArtistDetailsState> {
  state = {
    fetchedAdditionalData: false,
    listingItemSuggestionSelectedItem: undefined,
    listingSearchFilters: undefined,
    listingSearchMode: false,
    modals: {
      listingItemSuggestionModal: undefined,
      listingDataSuggestionModal: undefined,
      listingSearchFiltersModal: false
    },
  };

  componentDidMount() {
    const { params } = this.props;
    if (params && params.id) {
      this.props.getArtist(params.id);
    }
  }

  componentDidUpdate(prevProps: ArtistDetailsViewProps) {
    const { artist, user, params } = this.props;

    if (params.id && this.hasRouteChanged(prevProps)) {
      this.props.getArtist(params.id);
      this.setState({ fetchedAdditionalData: false })
    }

    if (!this.state.fetchedAdditionalData && user.isAuthenticated()) {
      this.props.getArtistItems(params.id || artist.id);
      this.props.getArtistListings(params.id || artist.id, 1);
      this.props.getArtistEditionIndex(params.id || artist.id);
      this.props.getArtistActiveMarket(params.id || artist.id);
      this.setState({ fetchedAdditionalData: true, listingSearchFilters: this.getDefaultListingSearchFilters() });
    }
  }

  getDefaultListingSearchFilters = (): ListingSearchFiltersFormData => {
    const { artist, params } = this.props;
    return { artistId: [params.id || artist.id] };
  }

  hasRouteChanged = (prevProps: ArtistDetailsViewProps): boolean => {
    const { params } = this.props;
    return !!params.id && (params.id !== prevProps.params.id);
  }

  updateTabInURL = (tabIndex: number): void => {
    const queryParams = new URLSearchParams(this.props.location.search);
    queryParams.set(QuerySearchParameters.TAB, tabIndex.toString());
    this.props.navigate(`${this.props.location.pathname}?${queryParams.toString()}`, { replace: true });
  };

  updatePageInURL = (page: number): void => {
    const queryParams = new URLSearchParams(this.props.location.search);
    queryParams.set(QuerySearchParameters.PAGE, page.toString());
    this.props.navigate(`${this.props.location.pathname}?${queryParams.toString()}`, { replace: true });
  };

  onTabChange = (index: number): void => {
    const currentTab = getSelectedTabFromURL(this.props.location);
    if (currentTab !== index) {
      this.updateTabInURL(index);
    }
  };

  onGridPageChange = (page: number): void => {
    this.updatePageInURL(page);
  };

  onPaginatedListingGridLoad = (): void => {
    const { artist, getArtistListings, listingSearchResults, onListingSearch } = this.props;
    const { listingSearchMode, listingSearchFilters } = this.state;

    if (listingSearchMode && listingSearchFilters && listingSearchResults.hasMorePages()) {
      onListingSearch({
        ...convertListingSearchFilterDataToQuery(listingSearchFilters, true),
        page: listingSearchResults.currentPage + 1
      });
    } else {
      getArtistListings(artist.id, artist.getSoldListings().currentPage + 1);
    }
  }

  onPaginatedListingPageSelect = (page: number) => {
    const { artist, getArtistListings, onListingSearch } = this.props
    const { listingSearchMode, listingSearchFilters } = this.state;

    if (listingSearchMode && listingSearchFilters) {
      onListingSearch({
        ...convertListingSearchFilterDataToQuery(listingSearchFilters, true),
        page
      });
    } else {
      getArtistListings(artist.id, page);
    }
  }

  onLockedContentLoginClick = () => {
    this.props.navigate(NavigationService.getAuthLoginPath());
  }

  onArtistItemGridClick = (item: ItemModel) => {
    this.props.navigate(NavigationService.getItemDetailsPath(item.id, item.getDisplayName()));
  };

  onTradingFloorItemNameClick = (itemId: string, itemName: string) => {
    this.props.navigate(NavigationService.getItemDetailsPath(itemId, itemName));
  }

  onListingItemClick = (id: string, name: string) => {
    this.props.navigate(NavigationService.getItemDetailsPath(id, name));
  };

  onListingItemSuggestionModalOpen = (listing: ListingModel) => {
    const { user, navigate } = this.props;
    useAuthCheck(user, navigate, () => {
      this.setState({
        modals: {
          ...this.state.modals,
          listingItemSuggestionModal: listing
        }
      });
    });
  };

  onListingItemSuggestionModalClose = () => {
    this.setState({
      modals: {
        ...this.state.modals,
        listingItemSuggestionModal: undefined
      }
    });
  };

  onListingDataSuggestionModalOpen = (listing: ListingModel) => {
    const { user, navigate } = this.props;
    useAuthCheck(user, navigate, () => {
      this.setState({
        modals: {
          ...this.state.modals,
          listingDataSuggestionModal: listing
        }
      });
    });
  };

  onListingDataSuggestionModalClose = () => {
    this.setState({
      modals: {
        ...this.state.modals,
        listingDataSuggestionModal: undefined
      }
    });
  };

  onListingSearchQueryModalOpen = () => {
    this.setState({
      modals: {
        ...this.state.modals,
        listingSearchFiltersModal: true
      }
    });
  }

  onListingSearchQueryModalClose = () => {
    this.setState({
      modals: {
        ...this.state.modals,
        listingSearchFiltersModal: false
      }
    });
  }

  onListingItemSuggestionSubmit = (listing: ListingModel, item: ItemModel) => {
    const { createItemListingGrouping } = this.props;
    createItemListingGrouping(item.id, [listing.id]);
    this.onListingItemSuggestionModalClose();
  }

  onListingDataSuggestionSubmit = (listing: ListingModel, data: JSONObject) => {
    const { createListingDataSuggestion } = this.props;
    createListingDataSuggestion(listing.id, data);
    this.onListingDataSuggestionModalClose();
  }

  onListingSearchFiltersFormSubmit = (searchQuery: ListingSearchFiltersFormData) => {
    this.setState({ listingSearchFilters: searchQuery, listingSearchMode: true });
    this.props.onListingSearch(convertListingSearchFilterDataToQuery(searchQuery, true));
    this.onListingSearchQueryModalClose();
  }

  onListingSearchFiltersFormReset = (): void => {
    this.setState({ listingSearchFilters: this.getDefaultListingSearchFilters() })
  }

  onArtistBannerFollowClick = () => {
    const { user, artist, followArtist, navigate } = this.props;
    useAuthCheck(user, navigate, () => {
      followArtist(artist.id);
    });
  }

  onArtistBannerUnfollowClick = () => {
    const { user, artist, unfollowArtist, navigate } = this.props;
    useAuthCheck(user, navigate, () => {
      unfollowArtist(artist.id);
    });
  }

  onItemBidEditClick = (itemBid: ItemBidModel) => {
    const { user } = this.props;
    this.props.navigate(getItemActionRoute(user, itemBid, 'Edit'));
  }

  onItemBidRemoveClick = (itemBid: ItemBidModel) => {
    const { user } = this.props;
    this.props.navigate(getItemActionRoute(user, itemBid, 'Remove'));
  }

  onItemBidContactClick = (itemBid: ItemBidModel) => {
    const { user } = this.props;
    this.props.navigate(getItemActionRoute(user, itemBid, 'Contact'));
  }

  onItemBidSellClick = (itemBid: ItemBidModel) => {
    const { user } = this.props;
    this.props.navigate(getItemActionRoute(user, itemBid, 'Sell'));
  }

  onItemAskEditClick = (itemAsk: ItemAskModel) => {
    const { user } = this.props;
    this.props.navigate(getItemActionRoute(user, itemAsk, 'Edit'));
  }

  onItemAskRemoveClick = (itemAsk: ItemAskModel) => {
    const { user } = this.props;
    this.props.navigate(getItemActionRoute(user, itemAsk, 'Remove'));
  }

  onItemAskContactClick = (itemAsk: ItemAskModel) => {
    const { user } = this.props;
    this.props.navigate(getItemActionRoute(user, itemAsk, 'Contact'));
  }

  onItemAskBuyClick = (itemAsk: ItemAskModel) => {
    const { user } = this.props;
    this.props.navigate(getItemActionRoute(user, itemAsk, 'Buy'));
  }

  renderListingDataSuggestionWizard() {
    const { createListingDataSuggestionLoading } = this.props;
    const { modals } = this.state;
    const { listingDataSuggestionModal } = modals;

    return (
      <Flex>
        {listingDataSuggestionModal &&
          <ListingDataSuggestionWizard
            listing={listingDataSuggestionModal}
            isLoading={createListingDataSuggestionLoading}
            onSubmit={this.onListingDataSuggestionSubmit} />
        }
      </Flex>
    );
  }

  renderListingItemSuggestionWizard() {
    const { artist, artistItemLoading, createItemListingGroupingLoading } = this.props;
    const { modals } = this.state;
    const { listingItemSuggestionModal } = modals;

    return (
      <Flex>
        {listingItemSuggestionModal &&
          <ListingItemSuggestionWizard
            listing={listingItemSuggestionModal}
            items={artist.getItems()}
            isLoading={artistItemLoading || createItemListingGroupingLoading}
            onSubmit={this.onListingItemSuggestionSubmit} />
        }
      </Flex>
    )
  }

  renderListingSearchFiltersModalContent() {
    const { artist, listingSearchLoading } = this.props;
    const { listingSearchFilters } = this.state;
    return (
      <ListingSearchFiltersForm artistList={[artist]} artistSelectionEnabled={false} submitLoading={listingSearchLoading} defaultFormData={listingSearchFilters} resetState={this.getDefaultListingSearchFilters()} onSubmit={this.onListingSearchFiltersFormSubmit} onReset={this.onListingSearchFiltersFormReset} />
    );
  }

  renderArtistItemsGrid() {
    const { artist, artistItemLoading, location, user } = this.props;
    if (user.isAuthenticated()) {
      return (
        <ItemGrid
          onItemCardClick={this.onArtistItemGridClick}
          items={artist.getItems()}
          isLoading={artistItemLoading}
          paginate={true}
          startingPage={getPageFromURL(location)}
          onPageChange={this.onGridPageChange}
        />
      );
    } else {
      return (
        <LockedContent description={`Please Log In To View Items`} onButtonClick={this.onLockedContentLoginClick} />
      )
    }
  }

  renderListingSearchButton() {
    return (
      <Circle size='40px' bg='gray.300' left={'50%'} transform={'translateX(-50%)'} position={'fixed'} bottom={100} cursor={'pointer'} onClick={this.onListingSearchQueryModalOpen}>
        <Icon as={BsSearch} color={'white.100'} />
      </Circle>
    );
  }

  renderArtistListingsGrid() {
    const { artistListingLoading, artist, listingSearchResults, listingSearchLoading, user } = this.props;
    const { listingSearchMode } = this.state;
    const paginatedListings = listingSearchMode ? listingSearchResults : artist.getSoldListings();

    if (user.isAuthenticated()) {
      return (
        <Stack gap={4}>
          <InfoGuide text={'Note: We Only Show Publicly Available, Verifiable Auction House Sales Data.'} dismissable={true} />
          <PaginatedListingGrid
            paginatedListings={paginatedListings}
            onScrollEndLoad={this.onPaginatedListingGridLoad}
            onPageSelect={this.onPaginatedListingPageSelect}
            isLoading={artistListingLoading || listingSearchLoading}
            onListingItemClick={this.onListingItemClick}
            onItemSuggestionClick={this.onListingItemSuggestionModalOpen}
            onTagListingClick={this.onListingDataSuggestionModalOpen}
            scrollMode={!listingSearchMode}
          />
          {this.renderListingSearchButton()}
        </Stack>
      );
    } else {
      return (
        <LockedContent description={`Please Log In To View Past Sales`} onButtonClick={this.onLockedContentLoginClick} />
      );
    }
  }

  renderArtistEditionIndex() {
    const { artist } = this.props;
    const editionIndexData = artist.getEditionIndex();
    if (editionIndexData)
      return (
        <Stack>
          <InfoGuide text={(
            <Text>
              Note: Our Edition Index Does Not Consider Data For
              <Tag size={'sm'} variant='solid' colorScheme={'pink'} marginX={1}>
                {'Original'}
              </Tag>
              Or
              <Tag size={'sm'} variant='solid' colorScheme={'teal'} marginX={1}>
                {'Set'}
              </Tag>
              Sales.
            </Text>
          )} dismissable={true} />
          <ArtistIndexChart artistIndexData={editionIndexData} artist={artist} />
        </Stack>
      );
  }

  renderArtistTradingFloor() {
    const { artist, user, artistLoading, artistActiveMarketLoading } = this.props;
    if (user.isAuthenticated()) {
      return (
        <TradingFloor
          asks={artist?.activeMarket?.asks || []}
          bids={artist?.activeMarket?.bids || []}
          user={user}
          showSearchFilter={true}
          onItemNameClick={this.onTradingFloorItemNameClick}
          isLoading={artistLoading || artistActiveMarketLoading}
          onEditBidClick={this.onItemBidEditClick}
          onRemoveBidClick={this.onItemBidRemoveClick}
          onEditAskClick={this.onItemAskEditClick}
          onRemoveAskClick={this.onItemAskRemoveClick}
          onContactBidClick={this.onItemBidContactClick}
          onContactAskClick={this.onItemAskContactClick}
          onSellBidClick={this.onItemBidSellClick}
          onBuyAskClick={this.onItemAskBuyClick}
        />
      );
    } else {
      return (
        <LockedContent description={`Please Log In To View Trading Floor`} onButtonClick={this.onLockedContentLoginClick} />
      );
    }
  }

  renderArtistStats() {
    const { user } = this.props;
    if (user.isAuthenticated()) {
      return (
        <Stack>
          {this.renderArtistEditionIndex()}
        </Stack>
      );
    } else {
      return (
        <LockedContent description={`Please Log In To View Stats`} onButtonClick={this.onLockedContentLoginClick} />
      )
    }
  }

  renderBanner() {
    const { artist, artistLoading, user, userFollowingArtistsLoading, userArtistActionLoading } = this.props;
    const isFollowing = user.followsArtist(artist.id);
    const followActionLoading = userFollowingArtistsLoading || userArtistActionLoading;

    if (artistLoading) {
      return <ArtistBannerSkeleton />
    } else {
      return (
        <ArtistBanner
          artist={artist}
          isFollowing={isFollowing}
          followActionLoading={followActionLoading}
          onFollowClick={this.onArtistBannerFollowClick}
          onUnfollowClick={this.onArtistBannerUnfollowClick}
        />
      );
    }
  }

  renderTabs() {
    let labels = [
      <Flex gap="4px" alignItems="center" direction={{ base: 'column', md: 'row' }}>
        <Icon as={IoGridOutline} />
        <Text> Items </Text>
      </Flex>,
      <Flex gap="4px" alignItems="center" direction={{ base: 'column', md: 'row' }}>
        <Icon as={IoStorefrontOutline} />
        <Text> Trading Floor </Text>
      </Flex>,
      <Flex gap="4px" alignItems="center" direction={{ base: 'column', md: 'row' }}>
        <Icon as={RiAuctionLine} />
        <Text> Past Sales </Text>
      </Flex>,
      <Flex gap="4px" alignItems="center" direction={{ base: 'column', md: 'row' }}>
        <Icon as={ImStatsDots} />
        <Text> Stats </Text>
      </Flex>
    ];

    let content = [
      this.renderArtistItemsGrid(),
      this.renderArtistTradingFloor(),
      this.renderArtistListingsGrid(),
      this.renderArtistStats()
    ];

    return (
      <TabGroup
        labels={labels}
        content={content}
        startingTab={getSelectedTabFromURL(this.props.location)}
        onChange={this.onTabChange}
      />
    );
  }

  renderModals() {
    const { modals } = this.state;
    return (
      <Stack>
        <Modal title={'Suggest Item'} isOpen={!!modals.listingItemSuggestionModal} onClose={this.onListingItemSuggestionModalClose} content={this.renderListingItemSuggestionWizard()} showCloseButton={false} showSubmitButton={false} />
        <Modal title={'Search Filters'} isOpen={!!modals.listingSearchFiltersModal} onClose={this.onListingSearchQueryModalClose} content={this.renderListingSearchFiltersModalContent()} showCloseButton={false} showSubmitButton={false} />
        <Modal title={'Tag Sale'} isOpen={!!modals.listingDataSuggestionModal} onClose={this.onListingDataSuggestionModalClose} content={this.renderListingDataSuggestionWizard()} showCloseButton={false} showSubmitButton={false} />
      </Stack>
    );
  }

  render() {
    const { artist } = this.props;
    return (
      <>
        <SEOHelmet
          title={generateSEOTitle(artist.name)}
          description={`Discover artworks and realtime market insights about ${artist.name}.`}
          link={generateArtistSEOLink(artist)}
          structuredData={createArtistStructuredData(artist)}
        />
        <Box maxWidth={`${AppConstants.GRIDPAGE_WIDTH}px`} minWidth={{ base: '100%', md: `${AppConstants.GRIDPAGE_WIDTH}px` }} paddingTop={['80px', '100px', '100px']} justifySelf="center">
          {this.renderBanner()}
          {this.renderTabs()}
          {this.renderModals()}
        </Box>
      </>
    );
  }
}

function mapStateToProps(state: ApplicationState) {
  return {
    artist: ArtistSelectors.getArtist(state),
    artistLoading: ArtistSelectors.getArtistLoading(state),
    artistItemLoading: ArtistSelectors.getArtistItemLoading(state),
    artistListingLoading: ArtistSelectors.getArtistListingLoading(state),
    artistEditionIndexLoading: ArtistSelectors.getArtistEditionIndexLoading(state),
    artistActiveMarketLoading: ArtistSelectors.getArtistActiveMarketLoading(state),
    userArtistActionLoading: UserSelectors.getUserArtistActionLoading(state),
    userFollowingArtistsLoading: UserSelectors.getUserFollowingArtistsLoading(state),
    createItemListingGroupingLoading: ItemListingGroupingSelectors.getItemListingGroupingCreateLoading(state),
    createListingDataSuggestionLoading: ListingDataSuggestionSelectors.getListingDataSuggestionCreateLoading(state),
    user: UserSelectors.getUser(state),
    listingSearchLoading: ListingSelectors.getListingSearchLoading(state),
    listingSearchResults: ListingSelectors.getPaginatedListings(state),
  }
}

function mapDispatchToProps(dispatch: Dispatch<ApplicationState>) {
  return bindActionCreators(
    {
      getArtist: (id: string) => ArtistActions.getArtistById(id),
      getArtistItems: (id: string) => ArtistActions.getArtistItems(id),
      getArtistListings: (id: string, page?: number) => ArtistActions.getArtistListings(id, page),
      getArtistEditionIndex: (id: string) => ArtistActions.getArtistEditionIndex(id),
      getArtistActiveMarket: (id: string) => ArtistActions.getArtistActiveMarket(id),
      followArtist: (id: string) => UserActions.followArtist(id),
      unfollowArtist: (id: string) => UserActions.unfollowArtist(id),
      createItemListingGrouping: (itemId: string, listingIds: string[]) => ItemListingGroupingActions.createItemListingGrouping(itemId, listingIds),
      createListingDataSuggestion: (listingId: string, data: JSONObject) => ListingDataSuggestionActions.createListingDataSuggestion(listingId, data),
      onListingSearch: (query: JSONObject) => ListingActions.searchListings(query)
    },
    dispatch
  );
}

export const ArtistDetailsView = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ArtistDetails));
