import { Component } from 'react';
import { connect, Dispatch } from 'react-redux';
import { withRouter, RouterProps } from 'utils/route';
import { Box, Text } from '@chakra-ui/react';
import { ApplicationState } from 'reducers/types';
import { SEOHelmet } from 'components';
import { generateSEOTitle } from 'utils/seo';
import { UserModel } from 'models';
import { BuyerOrderCard, SellerOrderCard, TabGroup } from 'components';
import { UserSelectors } from 'reducers/User/selectors';
import { AuthSelectors } from 'reducers/Auth/selectors';
import { UserActions } from 'reducers/User/actions';
import { AppConstants } from 'constants/app';

interface UserOrdersViewProps extends RouterProps {
	user: UserModel
	useOrdersLoading: boolean;
	getUserOrders: () => void;
}

class UserOrders extends Component<UserOrdersViewProps> {
	componentDidMount() {
		this.props.getUserOrders();
	}

	componentDidUpdate(prevProps: UserOrdersViewProps) {
		const { user, getUserOrders } = this.props;
		if (user.isAuthenticated() && !prevProps.user.isAuthenticated()) {
			getUserOrders();
		}
	}

	renderOrdersTabs() {
		const { user } = this.props;
		const buyerOrders = user.orders.buyer;
		const sellerOrders = user.orders.seller;

		const buyerTabContent =
			buyerOrders && buyerOrders.length > 0 ? (
				<Box>
					{buyerOrders.map(order => (
					<BuyerOrderCard key={`buyerOrderCard_${order.id}`} order={order} />
				))}
				</Box>
			) : (
				<Text>No buyer orders found.</Text>
			);

		const sellerTabContent =
			sellerOrders && sellerOrders.length > 0 ? (
				<Box>
					{sellerOrders.map(order => (
						<SellerOrderCard key={`sellerOrderCard_${order.id}`} order={order} />
					))}
				</Box>
			) : (
				<Text>No seller orders found.</Text>
			);

		const labels = [
			<Text key="buyer-tab">Buyer Orders</Text>,
			<Text key="seller-tab">Seller Orders</Text>
		];

		const content = [buyerTabContent, sellerTabContent];

		return <TabGroup labels={labels} content={content} />;
	}

	render() {
		return (
			<>
				<SEOHelmet
					title={generateSEOTitle('Orders')}
					description="View your orders."
				/>
				<Box maxWidth={`${AppConstants.GRIDPAGE_WIDTH}px`} minWidth={{ base: '100%', md: `${AppConstants.GRIDPAGE_WIDTH}px` }} paddingTop={['80px', '100px', '100px']} justifySelf="center">
					{this.renderOrdersTabs()}
				</Box>
			</>
		);
	}
}

function mapStateToProps(state: ApplicationState) {
	return {
		user: UserSelectors.getUser(state),
		userLoading: UserSelectors.getUserLoading(state) || AuthSelectors.getUserLoading(state),
		userOrdersLoading: UserSelectors.getUserOrdersLoading(state),
		ordersLoading: UserSelectors.getUserOrdersLoading(state)
	};
}

function mapDispatchToProps(dispatch: Dispatch<ApplicationState>) {
	return {
		getUserOrders: () => dispatch(UserActions.getUserOrders())
	};
}

export const UserOrdersView = connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(UserOrders));
