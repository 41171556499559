export enum QuerySearchParameters {
    BID_ID = 'bidId',
    ASK_ID = 'askId',
    ITEM_ID = 'itemId',
    ACTION = 'action',
    SORT_TYPE = 'sortType',
    SORT_BY = 'sortBy',
    PAGE = 'page',
    TAB = 'tab',
    TYPE = 'type'
}