import {
  Box,
  Flex,
  Skeleton,
  SkeletonText,
  SkeletonCircle,
  Spacer,
  Stack,
  StackDivider,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon
} from '@chakra-ui/react';

export default function ArtistIndexBannerSkeleton() {
  return (
    <Box borderRadius="md" overflow="hidden" boxShadow="sm">
      <Flex direction={{ base: 'column', md: 'row' }} height={{base: "517px", md: "240px"}}>
        <Box
          height={"240px"}
          width={{ base: '100%', md: '50%' }}
          mr={{ base: 0, md: 8 }}
          my={{ base: 4, md: 0 }}
        >
          <Skeleton
            height="100%"
            width="100%"
            borderRadius={{ base: '0px', md: '4px' }}
          />
        </Box>

        <Stack
          width="100%"
          px={{ base: 5, md: 0 }}
          spacing={4}
          divider={<StackDivider borderColor="gray.100" />}
        >
          <Flex direction="row" gap={2} alignItems="flex-start">
            <Flex
              direction={{ base: 'column', md: 'row' }}
              gap={2}
            >
              <SkeletonText noOfLines={1} width="200px" skeletonHeight="24px" />
              <Flex direction="row" gap={2} alignItems="center">
                <SkeletonText noOfLines={1} width="80px" skeletonHeight="16px" />
                <SkeletonText noOfLines={1} width="30px" skeletonHeight="16px" />
              </Flex>
            </Flex>
            <Spacer />
            <Skeleton height="36px" width="100px" borderRadius="md" />
          </Flex>

          <Flex alignItems="center" gap={2}>
            <SkeletonCircle size="8" />
            <SkeletonText noOfLines={1} width="150px" skeletonHeight="20px" />
          </Flex>

          <Flex alignItems="center" gap="8px">
            <SkeletonCircle size="8" />
            <SkeletonCircle size="6" />
            <SkeletonCircle size="6" />
            <SkeletonCircle size="6" />
          </Flex>
          <Accordion>
            <AccordionItem border={'none'}>
              <AccordionButton p={0} py={2}>
                <Flex
                  w={8}
                  h={8}
                  mr={2}
                  align="center"
                  justify="center"
                  rounded="full"
                  bg="gray.100"
                >
                  <SkeletonCircle size="8" />
                </Flex>
                <SkeletonText
                  noOfLines={1}
                  width="100px"
                  skeletonHeight="20px"
                />
                <Spacer />
                <AccordionIcon />
              </AccordionButton>
            </AccordionItem>
          </Accordion>
        </Stack>
      </Flex>

      <Box mt={4}>
        <Skeleton height={300} width="100%" borderRadius="md" />
      </Box>
    </Box>
  );
}
