import { useState, useMemo } from 'react';
import {
    Area,
    YAxis,
    XAxis,
    Tooltip,
    ResponsiveContainer,
    ReferenceLine,
    CartesianGrid,
    ComposedChart
} from 'recharts';
import { Box, Stack, Flex, useBreakpointValue } from '@chakra-ui/react';
import { ArtistIndexModel } from 'models';
import { MarketDateFilter } from 'models/types';
import { IndexYTick, TimeseriesXTick } from './Ticks';
import { ArtistIndexTooltip } from './Tooltips';
import { MarketDateFilters } from './Filters';
import { gradientOffset, getXAxisTicksAmountForTimeChart } from 'utils/chart';

export interface ArtistIndexChartCoreProps {
    artistIndexData: ArtistIndexModel;
    showFilters?: boolean;
    showYAxis?: boolean;
    showXAxis?: boolean;
}

export default function ArtistIndexChartCore({
    artistIndexData,
    showFilters = true,
    showXAxis = true,
    showYAxis = true
}: ArtistIndexChartCoreProps) {
    const [filter, setFilter] = useState<MarketDateFilter>(MarketDateFilter.MAX);
    const isMobile = useBreakpointValue({ base: true, md: false });
    const gradientId = `splitColor_${artistIndexData.artistId}`;

    const { filteredData } = useMemo(
		() => {
			const filteredData = artistIndexData.filterDataByMarketDateFilter(filter, true);
			return { filteredData }
		}, [filter, artistIndexData]
	);

	const { off, fullRed } = useMemo(() => {
		const off = gradientOffset(filteredData, 'weightedIndex', 0);
		const startPoint = filteredData[0]?.weightedIndex ?? 0;
		const endPoint = filteredData[filteredData.length - 1]?.weightedIndex ?? 0;
		const fullRed = startPoint > endPoint;
		return { off, fullRed };
	}, [filteredData]);

    const renderXAxis = () => {
		const totalTicks = getXAxisTicksAmountForTimeChart(!!isMobile, filter);
		const dataLength = filteredData.length;

		let interval = Math.ceil(dataLength / totalTicks);

        if (showXAxis) {
            return (
                <XAxis tick={(props) => <TimeseriesXTick {...props} data={filteredData} />} interval={interval} />
            );
        }
	};

    const renderYAxis = () => {
        if (showYAxis) {
            return (
                <YAxis
                    tick={IndexYTick}
                />
            );
        }
    }

    const renderChartData = () => {
        return (
            <ComposedChart data={filteredData}>
                <CartesianGrid horizontal vertical={false} />
                {renderYAxis()}
                {renderXAxis()}
                <Tooltip content={(props) => <ArtistIndexTooltip indexPriceData={filteredData} {...props} />} />
                <Area
                    type="monotone"
                    dataKey="weightedIndex"
                    stroke="none"
                    fill={`url(#${gradientId})`}
                />
                <defs>
                    <linearGradient id={gradientId} x1="0" y1="0" x2="0" y2="1">
                        <stop offset={off} stopColor={fullRed ? "red" : "green"} stopOpacity={1} />
                        {!fullRed && <stop offset={off} stopColor="red" stopOpacity={1} />}
                    </linearGradient>
                </defs>
                <ReferenceLine y={0} stroke="#000" strokeDasharray="3 3" />
            </ComposedChart>
        );
    };

    const renderFilters = () => {
        if (showFilters) {
            return (
                <Box paddingLeft={4}>
                    <MarketDateFilters selectedFilter={filter} onFilterSelect={setFilter} />
                </Box>
            )
        }
    }

    return (
        <Flex w="100%" height={showFilters ? 400 : 300} mt={4} alignItems={'flex-start'} direction={'column'}>
            {renderFilters()}
            <ResponsiveContainer>{renderChartData()}</ResponsiveContainer>
        </Flex>
    );
}
